import { InfiniteTable as UIKitTable } from "@fm-frontend/uikit";
import {
    createColumnHelper,
    getCoreRowModel,
    getSortedRowModel,
    SortingState,
} from "@tanstack/react-table";
import { useMemo, useState } from "react";
import styled from "styled-components";
import Gap from "~components/Gap";
import OptionsContainer from "~components/Table/Options/Container";
import ExportButton from "~components/Table/Options/ExportButton";
import Search from "~components/Table/Options/Search";
import { TableContext, useTableContextValue } from "~components/Table/TableContext";
import { Network, NetworkName } from "~entities/network";
import { useInstrumentsApi } from "~hooks/api/useInstrumentsApi";
import { AddButton } from "~pages/AssetsAndInstruments/Networks/AddButton";
import { NetworkActionsCell } from "~pages/AssetsAndInstruments/Networks/NetworkActionsCell";
import { NetworkCurrenciesCell } from "~pages/AssetsAndInstruments/Networks/NetworkCurrenciesCell";
import { NetworkDescriptionCell } from "~pages/AssetsAndInstruments/Networks/NetworkDescriptionCell";
import { NetworkNameCell } from "~pages/AssetsAndInstruments/Networks/NetworkNameCell";
import { TabList } from "~pages/AssetsAndInstruments/TabList";

type TableData = Network & { currencies: string[] };

const Table = styled(UIKitTable<TableData>)`
    min-width: 1010px;

    th:first-of-type,
    td:first-of-type {
        padding-left: 12px !important;
    }
`;

const columnHelper = createColumnHelper<TableData>();

const networksTableColumns = [
    columnHelper.accessor("name", {
        header: "Name",
        cell: (info) => <NetworkNameCell value={info.getValue()} />,
        meta: {
            headerStyleProps: {
                width: "160px",
            },
        },
    }),
    columnHelper.accessor("description", {
        header: "Description",
        cell: (info) => <NetworkDescriptionCell value={info.getValue()} />,
        meta: {
            headerStyleProps: {
                width: "300px",
            },
        },
    }),
    columnHelper.accessor("currencies", {
        header: "Assets",
        cell: (info) => <NetworkCurrenciesCell value={info.getValue()} />,
    }),
    columnHelper.display({
        id: "actions",
        cell: (info) => <NetworkActionsCell network={info.row.original} />,
        enableSorting: false,
        meta: {
            headerStyleProps: {
                width: "100px",
            },
        },
    }),
];

const isMatch = (data: TableData, query: string) => {
    const { name, description, currencies } = data;
    const normalizedQuery = query.toUpperCase();

    return (
        name.toUpperCase().includes(normalizedQuery) ||
        description.toUpperCase().includes(normalizedQuery) ||
        currencies.some((currency) => currency.toUpperCase().includes(normalizedQuery))
    );
};

export const Networks = () => {
    const { data, isLoading } = useInstrumentsApi();
    const { currencies = [], networks = [] } = data ?? {};
    const [sorting, setSorting] = useState<SortingState>([]);
    const tableContextValue = useTableContextValue();
    const { query, setQuery } = tableContextValue;

    const networkCurrencies = useMemo(
        () =>
            currencies.reduce((acc, currency) => {
                currency.networks.forEach((networkName) => {
                    acc[networkName] ??= [];
                    acc[networkName].push(currency.name);
                });

                return acc;
            }, {} as Record<NetworkName, string[]>),
        [currencies],
    );

    const tableData = useMemo(() => {
        const extendedNetworks = networks.map<TableData>((network) => ({
            ...network,
            currencies: networkCurrencies[network.name] ?? [],
        }));

        if (query === "") {
            return extendedNetworks;
        }

        return extendedNetworks.filter((extendedNetwork) => isMatch(extendedNetwork, query));
    }, [networkCurrencies, networks, query]);

    const getExportData = () =>
        tableData.map((item) => ({
            Name: item.name,
            Description: item.description,
            Currencies: item.currencies.join(", "),
        }));

    return (
        <TableContext.Provider value={tableContextValue}>
            <OptionsContainer>
                <Search query={query} onChange={setQuery} />
                <TabList />
                <Gap />
                <ExportButton
                    data={getExportData}
                    filename="networks"
                    loading={isLoading}
                    disabled={tableData.length === 0}
                />
                <AddButton />
            </OptionsContainer>
            <Table
                tableOptions={{
                    data: tableData,
                    columns: networksTableColumns,
                    state: {
                        sorting,
                    },
                    onSortingChange: setSorting,
                    getCoreRowModel: getCoreRowModel(),
                    getSortedRowModel: getSortedRowModel(),
                }}
                onInitialize={(networksTable) => {
                    networksTable.setSorting([{ id: "name", desc: false }]);
                }}
                isLoading={isLoading}
            />
        </TableContext.Provider>
    );
};
