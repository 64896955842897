import { IconButton, Icons } from "@fm-frontend/uikit";
import { FC, ReactNode } from "react";
import ModalButton from "~components/ModalButton";

type EditActionProps = {
    renderEditForm: (closePopover: () => void) => ReactNode;
};

const EditAction: FC<EditActionProps> = ({ renderEditForm }) => {
    return (
        <ModalButton
            renderModalContent={renderEditForm}
            renderButton={(handleClick) => (
                <IconButton
                    title="Edit"
                    variant="basic"
                    Icon={Icons.Pencil}
                    onClick={handleClick}
                />
            )}
        />
    );
};

export default EditAction;
