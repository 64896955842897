import { TabContext, TabPanel } from "@fm-frontend/uikit";
import { RiskManagementViewInstrumentsList } from "pages/RisksManagement/View/InstrumentsList";
import { FC, useState } from "react";
import { RisksManagementViewAssetsControl } from "~pages/RisksManagement/View/AssetsControl";
import { Tabs } from "~pages/RisksManagement/View/TabList";
import { RisksManagementViewTradingLimits } from "~pages/RisksManagement/View/TradingLimits";

export const RisksManagementViewPage: FC = () => {
    const [tab, setTab] = useState<string>(Tabs.TradingLimits);

    return (
        <TabContext value={tab} handleClick={setTab}>
            <TabPanel value={Tabs.TradingLimits} overflow>
                <RisksManagementViewTradingLimits />
            </TabPanel>
            <TabPanel value={Tabs.AssetsControl} overflow>
                <RisksManagementViewAssetsControl />
            </TabPanel>
            <TabPanel value={Tabs.InstrumentList} overflow>
                <RiskManagementViewInstrumentsList />
            </TabPanel>
        </TabContext>
    );
};
