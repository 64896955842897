import useSWR from "swr";
import { ClientId } from "~entities/client";
import { requestAdminery } from "~utils/api";

type RawInstrumentMarkup = [
    counterpartyId: number,
    instrument: string,
    bidMarkup: number,
    askMarkup: number,
];

type InstrumentMarkup = {
    counterpartyId: number;
    instrument: string;
    bidMarkup: number;
    askMarkup: number;
};
type SWRKey = [clientId: ClientId, key: string];

const prepareData = ([
    counterpartyId,
    instrument,
    bidMarkup,
    askMarkup,
]: RawInstrumentMarkup): InstrumentMarkup => ({
    counterpartyId,
    instrument,
    bidMarkup,
    askMarkup,
});

const fetcher = ([clientId]: SWRKey) =>
    requestAdminery("instrumentMarkups", { clientId }).then((data) => data.map(prepareData));

export const useInstrumentMarkups = (clientId: ClientId) =>
    useSWR<InstrumentMarkup[], unknown, SWRKey>([clientId, "useInstrumentMarkups"], fetcher);
