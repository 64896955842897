import { Badge } from "@fm-frontend/uikit";
import { FC } from "react";
import { ReferralStatus } from "~entities/referral";
import { SubaccountStatus } from "~entities/subaccount";
import {
    getSubaccountStatusBadgeVariant,
    getSubaccountStatusTitle,
} from "~pages/Subaccounts/utils";

type StatusCellProps = {
    value: SubaccountStatus | ReferralStatus;
};

export const StatusCell: FC<StatusCellProps> = ({ value }) => {
    return (
        <Badge
            variant={getSubaccountStatusBadgeVariant(value)}
            value={getSubaccountStatusTitle(value)}
        />
    );
};
