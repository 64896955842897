import { getMatch } from "@fm-frontend/uikit";
import {
    Container as ItemContainer,
    Content as ItemContent,
} from "@fm-frontend/uikit/src/components/dropdowns/common/Item";
import { Checkmark } from "@fm-frontend/uikit/src/components/icons/styled";
import { FC } from "react";
import styled, { css, useTheme } from "styled-components";
import { Instrument, InstrumentName } from "~entities/instrument";

const Container = styled(ItemContainer)`
    border-radius: 0;
    box-shadow: inset 0px -1px 0px ${(p) => p.theme.colors.ui8};
    min-height: 44px;
    padding: 0 12px;

    ${(p) =>
        p.isActive &&
        css`
            color: ${p.theme.colors.uiWhite100};
            background: ${p.theme.colors.ui100};

            :hover {
                background: ${p.theme.colors.ui96} !important;
            }
        `}

    mark {
        background-color: ${(p) => p.theme.colors.brand32};
    }

    svg {
        position: initial;
    }

    :hover {
        background: ${(p) => p.theme.colors.ui4};
    }
`;

const Name = styled(ItemContent)`
    flex: 1;
    font-weight: 600;
    font-size: 14px;
    white-space: pre-wrap;
`;

type InstrumentSelectorItemProps = {
    instrument: Instrument;
    active: boolean;
    query?: string;
    onSelect?: (value: InstrumentName) => void;
};

export const Item: FC<InstrumentSelectorItemProps> = ({
    instrument,
    active,
    query = "",
    onSelect,
}) => {
    const { colors } = useTheme();
    const name = query === "" ? instrument.name : getMatch(instrument.name, query);

    return (
        <Container isActive={active} onClick={() => onSelect?.(instrument.name)}>
            <Name>{name}</Name>
            {active && <Checkmark color={colors.uiWhite100} />}
        </Container>
    );
};
