import { PSmall } from "@fm-frontend/uikit";
import { format } from "date-fns";
import { FC } from "react";
import styled from "styled-components";
import { DATE_FORMAT, TIME_FORMAT } from "~constants/date";

const DateContainer = styled(PSmall)`
    color: ${(p) => p.theme.colors.ui72};
`;

const TimeContainer = styled(PSmall)`
    color: ${(p) => p.theme.colors.ui72};
`;

type DateTimeViewerProps = {
    value?: number;
    className?: string;
};

const DateTimeViewer: FC<DateTimeViewerProps> = ({ value, className }) => {
    if (value === undefined || value === 0 || Number.isNaN(value)) {
        return null;
    }

    return (
        <span className={className}>
            <DateContainer>{format(value * 1000, DATE_FORMAT)}</DateContainer>
            <br />
            <TimeContainer>{format(value * 1000, TIME_FORMAT)}</TimeContainer>
        </span>
    );
};

export default DateTimeViewer;
