import { H2, HStack } from "@fm-frontend/uikit";
import { useTabContext } from "@fm-frontend/uikit/src/components/tabs/utils";
import React from "react";
import styled from "styled-components";

export enum Tabs {
    Clients = "Clients",
    Invitations = "Invitations",
}

const TabList = styled(HStack)`
    flex: 1;
    gap: 16px;
`;

const Tab = styled(H2)<{ isActive?: boolean }>`
    cursor: pointer;
    color: ${(p) => (p.isActive ? p.theme.colors.ui100 : p.theme.colors.ui32)};

    :hover {
        color: ${(p) => p.theme.colors.ui100};
    }
`;

export const ClientsOverviewTabs: React.FC = () => {
    const context = useTabContext();

    return (
        <TabList>
            <Tab
                isActive={context?.value === Tabs.Clients}
                onClick={() => context?.handleClick(Tabs.Clients)}
            >
                Clients
            </Tab>
            <Tab
                isActive={context?.value === Tabs.Invitations}
                onClick={() => context?.handleClick(Tabs.Invitations)}
            >
                Invitations
            </Tab>
        </TabList>
    );
};
