import {
    ConfirmationModal,
    DesctructiveButton,
    FieldGroup,
    Form,
    FormActions,
    FormBody,
    Header as FormHeader,
    HeaderTitle,
    SimpleDropdown,
    SimpleInput,
} from "@fm-frontend/uikit";
import { EMPTY_ARRAY } from "@fm-frontend/uikit/src/const";
import { ValueFormat, ValueParse } from "@fm-frontend/utils";
import { FC, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import FormError from "~components/FormError";
import { PrimaryButton } from "~components/PrimaryButton";
import { ERROR_MESSAGES } from "~constants/errors";
import { ValidateErrors, ValidateRules } from "~constants/form";
import { CounterpartyLimit } from "~entities/counterpartyLimit";
import { useCounterpartyLimitsApi } from "~hooks/api/useCounterpartyLimitsApi";
import { useInstrumentsApi } from "~hooks/api/useInstrumentsApi";
import useAppSelector from "~hooks/useAppSelector";
import { useClientId } from "~hooks/useClientId";
import useNotifications from "~hooks/useNotifications";
import {
    combineMarkupLabel,
    isMarginEditable,
    isMarkupEditable,
} from "~pages/RisksManagement/utils/limit";
import { useCounterpartyId } from "~pages/RisksManagement/View/hooks/useCounterpartyId";
import { RowType } from "~pages/RisksManagement/View/TradingLimits/types";
import { getRiskManagementListUrl } from "~pages/Router";
import { getClient } from "~store/clients/selectors";
import { requestAdminery } from "~utils/api";
import { prepareRequestError } from "~utils/prepareRequestError";

const FormContainer = styled(Form)`
    width: 100%;
    min-width: initial;
    padding: 8px;
`;

type EditCounterpartyLimitFormType = {
    currency: string;
    grossLimit: string;
    maintenanceMargin: string;
    restrictedTrading: string;
    initialMargin: string;
    takerMarkup: string;
};

const getFocusField = (triggerRowType?: RowType): keyof EditCounterpartyLimitFormType => {
    switch (triggerRowType) {
        case RowType.MarginRequirements:
            return "maintenanceMargin";
        default:
            return "grossLimit";
    }
};

type EditCounterpartyLimitFormProps = {
    onDone: () => void;
    triggerRowType?: RowType;
};

export const EditCounterpartyLimitForm: FC<EditCounterpartyLimitFormProps> = ({
    onDone,
    triggerRowType,
}) => {
    const navigate = useNavigate();
    const getClientById = useAppSelector(getClient);
    const { showSuccessNotification } = useNotifications();
    const clientId = useClientId();
    const counterpartyId = useCounterpartyId();
    const client = getClientById(clientId);
    const counterparty = getClientById(counterpartyId);

    const markupEditable = isMarkupEditable(client, counterparty);
    const marginEditable = isMarginEditable(client, counterparty);

    const {
        data: counterpartyLimitsData,
        isLoading: isCounterpartyLimitsLoading,
        mutate,
    } = useCounterpartyLimitsApi(clientId);
    const counterpartyLimitIndex = counterpartyLimitsData?.findIndex(
        (data) => data.counterpartyId === counterpartyId,
    );
    const counterpartyLimit = counterpartyLimitsData?.[counterpartyLimitIndex ?? 0];

    const { data: instruments, isLoading: isInstrumentsLoading } = useInstrumentsApi();
    const { currencies = EMPTY_ARRAY } = instruments ?? {};

    const {
        control,
        register,
        setFocus,
        watch,
        formState: { isSubmitting, errors, isDirty },
        handleSubmit,
    } = useForm<EditCounterpartyLimitFormType>({
        mode: "onSubmit",
        defaultValues: {
            currency: counterpartyLimit?.currencyName,
            grossLimit: ValueFormat.formSize(counterpartyLimit?.grossLimit ?? 0),
            maintenanceMargin: String(
                marginEditable ? (counterpartyLimit?.maintenanceMargin ?? 0) / 1e4 : 0,
            ),
            restrictedTrading: String(
                marginEditable ? (counterpartyLimit?.restrictedTrading ?? 0) / 1e4 : 0,
            ),
            initialMargin: String(
                marginEditable ? (counterpartyLimit?.initialMargin ?? 0) / 1e4 : 0,
            ),
            takerMarkup: String(markupEditable ? (counterpartyLimit?.takerMarkup ?? 0) / 1e4 : 0),
        },
    });
    const [submitError, setSubmitError] = useState<string>();
    const [confirmationShown, setConfirmationShown] = useState(false);
    const [removing, setRemoving] = useState<boolean>(false);

    const maintenanceMargin = Number(watch("maintenanceMargin"));
    const restrictedTrading = Number(watch("restrictedTrading"));
    const initialMargin = Number(watch("initialMargin"));

    const requiredMargin = Boolean(maintenanceMargin || restrictedTrading || initialMargin);

    useEffect(() => {
        setFocus(getFocusField(triggerRowType));
    }, [setFocus, triggerRowType]);

    const currenciesOptions = useMemo(
        () =>
            currencies
                .map(({ name }) => ({ text: name, value: name }))
                .sort((a, b) => a.text.localeCompare(b.text)),
        [currencies],
    );

    const handleModifySubmit = handleSubmit(async (data) => {
        try {
            setSubmitError(undefined);

            const clientResponse = await requestAdminery("setCLimit", {
                clientId,
                counterpartyId,
                currency: data.currency,
                grossLimit: ValueParse.size(data.grossLimit),
                ...(marginEditable && {
                    maintenanceMargin: ValueParse.percent(data.maintenanceMargin),
                }),
                ...(marginEditable && {
                    restrictedTrading: ValueParse.percent(data.restrictedTrading),
                }),
                ...(marginEditable && {
                    initialMargin: ValueParse.percent(data.initialMargin),
                }),
                ...(markupEditable && {
                    takerMarkup: ValueParse.percent(data.takerMarkup),
                }),
            });
            const error = clientResponse?.error;

            if (!error) {
                await mutate([
                    ...(counterpartyLimitsData?.slice(0, counterpartyLimitIndex ?? 0) ?? []),
                    {
                        ...counterpartyLimit,
                        clientId,
                        counterpartyId,
                        currencyName: data.currency,
                        grossLimit: ValueParse.size(data.grossLimit),
                        grossLimitUtilisation: 0n,
                        ...(marginEditable && {
                            maintenanceMargin: ValueParse.percent(data.maintenanceMargin),
                        }),
                        ...(marginEditable && {
                            restrictedTrading: ValueParse.percent(data.restrictedTrading),
                        }),
                        ...(marginEditable && {
                            initialMargin: ValueParse.percent(data.initialMargin),
                        }),
                        ...(markupEditable && {
                            takerMarkup: ValueParse.percent(data.takerMarkup),
                        }),
                    } as CounterpartyLimit,
                    ...(counterpartyLimitsData?.slice((counterpartyLimitIndex ?? 0) + 1) ?? []),
                ]);
                onDone();
                showSuccessNotification(`Counterparty limit was successfully modified`);
            }
            if (error) {
                setSubmitError(ERROR_MESSAGES[error] ?? error);
            }
        } catch (e) {
            setSubmitError(prepareRequestError(e));
        }
    });

    const handleRemove = async () => {
        try {
            setSubmitError(undefined);
            setRemoving(true);

            const response = await requestAdminery("delCLimit", {
                clientId,
                counterpartyId,
            });

            if (!response?.error) {
                await mutate([
                    ...(counterpartyLimitsData?.slice(0, counterpartyLimitIndex ?? 0) ?? []),
                    ...(counterpartyLimitsData?.slice((counterpartyLimitIndex ?? 0) + 1) ?? []),
                ]);
                onDone();
                showSuccessNotification("Counterparty limit was removed");
                navigate(getRiskManagementListUrl(clientId));
            }

            if (response?.error) {
                setSubmitError(ERROR_MESSAGES[response?.error] ?? response?.error);
            }
        } catch (e) {
            setSubmitError(prepareRequestError(e));
        } finally {
            setRemoving(false);
        }
    };

    const handleConfirmationDone = () => {
        setConfirmationShown(false);
        handleRemove();
    };
    const handleConfirmationClose = () => {
        setConfirmationShown(false);
    };

    return (
        <FormContainer onSubmit={handleModifySubmit}>
            <FormHeader>
                <HeaderTitle
                    title={`Modify limit on ${counterparty?.username} (${counterpartyId})`}
                />
            </FormHeader>
            <FormBody alignItems="stretch">
                <FieldGroup>
                    <SimpleDropdown
                        label="Currency"
                        name="currency"
                        control={control}
                        rules={{ required: ValidateErrors.required }}
                        error={errors.currency?.message}
                        options={currenciesOptions}
                        disabled={isInstrumentsLoading}
                        searchable
                    />
                    <SimpleInput
                        label="Gross limit"
                        placeholder="0.00000000"
                        disabled={isCounterpartyLimitsLoading}
                        error={errors.grossLimit?.message}
                        {...register("grossLimit", {
                            required: ValidateErrors.required,
                            validate: { size: ValidateRules.size },
                        })}
                    />
                </FieldGroup>
                <FieldGroup>
                    <SimpleInput
                        label="Maintenance margin, %"
                        placeholder="00.00"
                        disabled={isCounterpartyLimitsLoading || !marginEditable}
                        error={errors.maintenanceMargin?.message}
                        {...register("maintenanceMargin", {
                            required: requiredMargin ? ValidateErrors.required : false,
                            min: {
                                value: 0,
                                message: ValidateErrors.min(0),
                            },
                            max: {
                                value: Math.min(restrictedTrading, initialMargin) ?? 100,
                                message: ValidateErrors.max(
                                    Math.min(restrictedTrading, initialMargin) ?? 100,
                                ),
                            },
                            validate: { decimalPercent: ValidateRules.decimalPercent },
                        })}
                    />
                    <SimpleInput
                        label="Margin call, %"
                        placeholder="00.00"
                        disabled={isCounterpartyLimitsLoading || !marginEditable}
                        error={errors.restrictedTrading?.message}
                        {...register("restrictedTrading", {
                            required: requiredMargin ? ValidateErrors.required : false,
                            min: {
                                value: 0,
                                message: ValidateErrors.min(0),
                            },
                            max: {
                                value: initialMargin ?? 100,
                                message: ValidateErrors.max(initialMargin ?? 100),
                            },
                            validate: { decimalPercent: ValidateRules.decimalPercent },
                        })}
                    />
                    <SimpleInput
                        label="Initial margin, %"
                        placeholder="00.00"
                        disabled={isCounterpartyLimitsLoading || !marginEditable}
                        error={errors.initialMargin?.message}
                        {...register("initialMargin", {
                            required: requiredMargin ? ValidateErrors.required : false,
                            min: { value: 0, message: ValidateErrors.min(0) },
                            max: { value: 100, message: ValidateErrors.max(100) },
                            validate: { decimalPercent: ValidateRules.decimalPercent },
                        })}
                    />
                </FieldGroup>
                <FieldGroup>
                    <SimpleInput
                        label={combineMarkupLabel(counterparty?.type)}
                        placeholder="00.00"
                        disabled={isCounterpartyLimitsLoading || !markupEditable}
                        error={errors.takerMarkup?.message}
                        {...register("takerMarkup", {
                            validate: { decimalPercent: ValidateRules.decimalPercent },
                            min: { value: -10, message: ValidateErrors.min(-10) },
                            max: { value: 10, message: ValidateErrors.max(10) },
                        })}
                    />
                </FieldGroup>
                {submitError && <FormError message={submitError} />}
            </FormBody>
            <FormActions variant="plain">
                <DesctructiveButton
                    type="button"
                    size="large"
                    fullWidth
                    loading={isSubmitting || removing}
                    onClick={() => setConfirmationShown(true)}
                >
                    Remove Limit
                </DesctructiveButton>
                {confirmationShown && (
                    <ConfirmationModal
                        title={`Remove ${counterparty?.username} (${counterpartyId}) limit?`}
                        description="This action is irreversible. Are you sure?"
                        confirmButtonTitle="Yes, delete"
                        onConfirm={handleConfirmationDone}
                        onClose={handleConfirmationClose}
                    />
                )}
                <PrimaryButton
                    disabled={!isDirty}
                    size="large"
                    fullWidth
                    loading={isSubmitting || removing}
                >
                    Modify
                </PrimaryButton>
            </FormActions>
        </FormContainer>
    );
};
