import { useEffect, useRef } from "react";
import { Feed, FeedId, MessageContent, MessageType } from "~constants/feeds";
import useWebSocketApi from "~hooks/useWebSocketApi";

type MessageData = [Feed, FeedId, MessageType, MessageContent];
type MessageCallback = (type: MessageType, content: MessageContent, feed: Feed) => void;

type CallbackRef = {
    receiveCallback?: MessageCallback;
    bindCallback?: () => void;
    unbindCallback?: () => void;
};

const feedsCounter: Record<string, number> = {};

const useFeed = (bindFeed: Feed, listenFeeds = [bindFeed], feedId: FeedId = 0) => {
    const callbackRef = useRef<CallbackRef>({});
    const { send, subscribe, onRefreshToken } = useWebSocketApi();

    useEffect(() => {
        const feedsCounterId = `${bindFeed}-${feedId}`;

        feedsCounter[feedsCounterId] ??= 0;
        feedsCounter[feedsCounterId] += 1;

        if (feedsCounter[feedsCounterId] === 1) {
            callbackRef.current.bindCallback?.();
            send({ event: "bind", feed: bindFeed, feedId });
        }

        return () => {
            feedsCounter[feedsCounterId] -= 1;

            if (feedsCounter[feedsCounterId] === 0) {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                callbackRef.current.unbindCallback?.();
                send({ event: "unbind", feed: bindFeed, feedId });
            }
        };
    }, [bindFeed, feedId, send]);

    useEffect(() => {
        const messageHandler = (data: MessageData) => {
            const [messageFeed, messageFeedId, messageType, messageContent] = data;

            if (listenFeeds.includes(messageFeed) && feedId === messageFeedId) {
                callbackRef.current.receiveCallback?.(messageType, messageContent, messageFeed);
            }
        };

        return subscribe(messageHandler);
    }, [feedId, listenFeeds, subscribe]);

    useEffect(() => {
        // Re-subscribe feed when the token renewed.
        // Fix the problem when we send `bind feed` message
        // but get auth an error and auth under the hood,
        const refreshTokenHandler = () => {
            send({ event: "unbind", feed: bindFeed, feedId });
            send({ event: "bind", feed: bindFeed, feedId });
        };

        return onRefreshToken(refreshTokenHandler);
    }, [bindFeed, feedId, onRefreshToken, send]);

    return {
        send,
        onReceive: (cb: MessageCallback) => {
            callbackRef.current.receiveCallback = cb;
        },
        onBind: (cb: () => void) => {
            callbackRef.current.bindCallback = cb;
        },
        onUnbind: (cb: () => void) => {
            callbackRef.current.unbindCallback = cb;
        },
    };
};

export default useFeed;
