import {
    ConfirmationModal,
    DesctructiveButton,
    FieldGroup,
    Form,
    FormActions,
    FormBody,
    Header as FormHeader,
    HeaderTitle,
    SimpleDropdown,
    SimpleInput,
    Tab,
    TabContext,
    TabList,
} from "@fm-frontend/uikit";
import { EMPTY_ARRAY } from "@fm-frontend/uikit/src/const";
import { ValueFormat, ValueParse } from "@fm-frontend/utils";
import { FC, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import FormError from "~components/FormError";
import { PartnerSelectType } from "~components/PartnerSelect";
import { PrimaryButton } from "~components/PrimaryButton";
import { ERROR_MESSAGES } from "~constants/errors";
import { ValidateErrors, ValidateRules } from "~constants/form";
import { ClientType } from "~entities/client";
import { useExternalLimitApi } from "~hooks/api/useExternalLimitApi";
import { useInstrumentsApi } from "~hooks/api/useInstrumentsApi";
import { useInternalLimitApi } from "~hooks/api/useInternalLimitApi";
import useAppSelector from "~hooks/useAppSelector";
import { useClientId } from "~hooks/useClientId";
import useNotifications from "~hooks/useNotifications";
import { GlobalGrossType } from "~pages/RisksManagement/List/types";
import { getClient } from "~store/clients/selectors";
import { requestAdminery } from "~utils/api";
import { prepareRequestError } from "~utils/prepareRequestError";

const FormContainer = styled(Form)`
    width: 100%;
    min-width: initial;
    padding: 8px;
`;

type EditPrimeBrokerGlobalGrossFormType = {
    currency: string;
    grossLimit: string;
};

type EditPrimeBrokerGlobalGrossFormProps = {
    onDone: () => void;
    globalGrossType: GlobalGrossType | undefined;
};

const initialPartnerSelectTypeMap: Record<GlobalGrossType, PartnerSelectType> = {
    [GlobalGrossType.Default]: PartnerSelectType.Counterparties,
    [GlobalGrossType.External]: PartnerSelectType.Counterparties,
    [GlobalGrossType.Internal]: PartnerSelectType.SubAccounts,
};

export const EditPrimeBrokerGlobalGrossForm: FC<EditPrimeBrokerGlobalGrossFormProps> = ({
    onDone,
    globalGrossType = GlobalGrossType.Default,
}) => {
    const getClientById = useAppSelector(getClient);

    const { showSuccessNotification } = useNotifications();
    const clientId = useClientId();
    const [partner, setPartner] = useState<PartnerSelectType>(
        initialPartnerSelectTypeMap[globalGrossType],
    );
    const client = getClientById(clientId);
    const isMaster = client?.type === ClientType.PrimeBroker;
    const {
        data: internalGlobalLimit,
        isLoading: isExternalGlobalLimitLoading,
        mutate: internalGlobalLimitMutate,
    } = useInternalLimitApi(clientId);
    const {
        data: externalGlobalLimit,
        isLoading: isInternalGlobalLimitLoading,
        mutate: externalGlobalLimitMutate,
    } = useExternalLimitApi(clientId);

    const globalLimit = useMemo(() => {
        if (isMaster && partner === PartnerSelectType.Counterparties) {
            return externalGlobalLimit;
        }
        return internalGlobalLimit;
    }, [isMaster, partner, internalGlobalLimit, externalGlobalLimit]);
    const globalLimitMutate = useMemo(
        () =>
            isMaster && partner === PartnerSelectType.Counterparties
                ? externalGlobalLimitMutate
                : internalGlobalLimitMutate,
        [isMaster, partner, internalGlobalLimitMutate, externalGlobalLimitMutate],
    );
    const isGlobalLimitsLoading = useMemo(
        () =>
            isMaster && partner === PartnerSelectType.Counterparties
                ? isExternalGlobalLimitLoading
                : isInternalGlobalLimitLoading,
        [isMaster, partner, isInternalGlobalLimitLoading, isExternalGlobalLimitLoading],
    );

    const { globalLimitTitle, globalLimitSetMethod, globalLimitDelMethod } = useMemo(() => {
        if (isMaster && partner === PartnerSelectType.Counterparties) {
            return {
                globalLimitTitle: "External Global Gross Limit",
                globalLimitSetMethod: "setExternalLimit" as const,
                globalLimitDelMethod: "delExternalLimit" as const,
            };
        }

        return {
            globalLimitTitle: "Internal Global Gross Limit",
            globalLimitSetMethod: "setInternalLimit" as const,
            globalLimitDelMethod: "delInternalLimit" as const,
        };
    }, [isMaster, partner]);

    const { currencyName, grossLimit } = globalLimit ?? {};
    const { data: instruments, isLoading: isInstrumentsLoading } = useInstrumentsApi();
    const { currencies = EMPTY_ARRAY } = instruments ?? {};

    const {
        control,
        register,
        setFocus,
        setValue,
        formState: { isSubmitting, errors },
        handleSubmit,
    } = useForm<EditPrimeBrokerGlobalGrossFormType>({
        defaultValues: {
            currency: currencyName ?? "",
            grossLimit: ValueFormat.formSize(grossLimit ?? 0),
        },
    });
    const [submitError, setSubmitError] = useState<string>();
    const [confirmationShown, setConfirmationShown] = useState(false);
    const [removing, setRemoving] = useState<boolean>(false);

    useEffect(() => {
        setFocus("grossLimit");
    }, [setFocus]);

    useEffect(() => {
        setValue("currency", currencyName ?? "");
    }, [currencyName, setValue]);
    useEffect(() => {
        setValue("grossLimit", ValueFormat.formSize(grossLimit ?? 0));
    }, [grossLimit, setValue]);

    const currenciesOptions = useMemo(
        () =>
            currencies
                .map(({ name }) => ({ text: name, value: name }))
                .sort((a, b) => a.text.localeCompare(b.text)),
        [currencies],
    );

    const handleModifySubmit = handleSubmit(async (data) => {
        try {
            setSubmitError(undefined);

            const clientResponse = await requestAdminery(globalLimitSetMethod, {
                clientId,
                currency: data.currency,
                grossLimit: ValueParse.size(data.grossLimit),
            });
            const error = clientResponse?.error;

            if (!error) {
                globalLimitMutate({
                    currencyName: data.currency,
                    grossLimit: ValueParse.size(data.grossLimit),
                    grossLimitUtilisation: globalLimit?.grossLimitUtilisation ?? 0n,
                });
                onDone();
                showSuccessNotification(`${globalLimitTitle} was successfully modified`);
            }
            if (error) {
                setSubmitError(ERROR_MESSAGES[error] ?? error);
            }
        } catch (e) {
            setSubmitError(prepareRequestError(e));
        }
    });

    const handleRemove = async () => {
        try {
            setSubmitError(undefined);
            setRemoving(true);

            const response = await requestAdminery(globalLimitDelMethod, {
                clientId,
            });

            if (!response?.error) {
                globalLimitMutate({
                    currencyName: "USD",
                    grossLimit: 0n,
                    grossLimitUtilisation: 0n,
                });
                onDone();
                showSuccessNotification(`${globalLimitTitle} limit was removed`);
            }

            if (response?.error) {
                setSubmitError(ERROR_MESSAGES[response?.error] ?? response?.error);
            }
        } catch (e) {
            setSubmitError(prepareRequestError(e));
        } finally {
            setRemoving(false);
        }
    };

    const handleConfirmationDone = () => {
        setConfirmationShown(false);
        handleRemove();
    };
    const handleConfirmationClose = () => {
        setConfirmationShown(false);
    };

    return (
        <FormContainer onSubmit={handleModifySubmit}>
            <FormHeader>
                <HeaderTitle title={`Modify ${globalLimitTitle}`} />
            </FormHeader>
            <FormBody alignItems="stretch">
                {isMaster && (
                    <FieldGroup>
                        <TabContext
                            value={partner}
                            handleClick={(value) => setPartner(value as PartnerSelectType)}
                        >
                            <TabList size="small">
                                <Tab
                                    title="Counterparties"
                                    value={PartnerSelectType.Counterparties}
                                />
                                <Tab title="Sub-accounts" value={PartnerSelectType.SubAccounts} />
                            </TabList>
                        </TabContext>
                    </FieldGroup>
                )}
                <FieldGroup>
                    <SimpleDropdown
                        label="Currency"
                        name="currency"
                        control={control}
                        options={currenciesOptions}
                        disabled={isInstrumentsLoading}
                    />
                    <SimpleInput
                        label="Gross Limit"
                        placeholder="0.00000000"
                        disabled={isGlobalLimitsLoading}
                        error={errors.grossLimit?.message}
                        {...register("grossLimit", {
                            required: ValidateErrors.required,
                            validate: { size: ValidateRules.size },
                        })}
                    />
                </FieldGroup>
                {submitError && <FormError message={submitError} />}
            </FormBody>
            <FormActions variant="plain">
                <DesctructiveButton
                    type="button"
                    size="large"
                    fullWidth
                    loading={isSubmitting || removing}
                    onClick={() => setConfirmationShown(true)}
                >
                    Remove Limit
                </DesctructiveButton>
                {confirmationShown && (
                    <ConfirmationModal
                        title={`Remove ${globalLimitTitle} Limit`}
                        description="This action is irreversible. Are you sure?"
                        confirmButtonTitle="Yes, delete"
                        onConfirm={handleConfirmationDone}
                        onClose={handleConfirmationClose}
                    />
                )}
                <PrimaryButton size="large" fullWidth loading={isSubmitting}>
                    Modify
                </PrimaryButton>
            </FormActions>
        </FormContainer>
    );
};
