export type SubaccountId = number;

export enum SubaccountType {
    Maker = "maker",
    Taker = "taker",
    PrimeBroker = "primeBroker",
}

export enum SubaccountStatus {
    Active = "active",
    Pending = "pending",
    Disabled = "disabled",
}

type SubaccountInfo = {
    clientId?: SubaccountId;
    username: string;
    email: string;
};

export type Subaccount = {
    type: SubaccountType;
    status: SubaccountStatus;
    info: SubaccountInfo;
    createdAt: number;
};
