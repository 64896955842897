import styled from "styled-components";

export const HeaderCell = styled.div`
    display: flex;
    align-items: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    width: 280px;
`;
