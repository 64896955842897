import { InfiniteTable as UIKitTable } from "@fm-frontend/uikit";
import { ValueFormat } from "@fm-frontend/utils";
import {
    createColumnHelper,
    getCoreRowModel,
    getSortedRowModel,
    SortingState,
} from "@tanstack/react-table";
import { format } from "date-fns";
import { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import Gap from "~components/Gap";
import { Spread } from "~components/Spread";
import DateTimeViewer from "~components/Table/Cell/DateTimeViewer";
import OptionsContainer from "~components/Table/Options/Container";
import ExportButton from "~components/Table/Options/ExportButton";
import { DATE_TIME_FORMAT } from "~constants/date";
import { Order, OrderSide } from "~entities/order";
import { usePositionsFeed } from "~hooks/feeds/usePositionsFeed";
import { useClientId } from "~hooks/useClientId";
import { useDebouncedValue } from "~hooks/useDebouncedValue";
import {
    InstrumentSelect,
    InstrumentSelectValue,
    INSTRUMENT_SELECT_ALL_VALUE,
} from "~pages/Orders/InstrumentSelect";
import { SideCell } from "~pages/Orders/SideCell";
import { sortTimestamp } from "~utils/sortTimestamp";

const Table = styled(UIKitTable<Order>)`
    min-width: 1010px;

    th:first-of-type,
    td:first-of-type {
        padding-left: 12px !important;
    }
`;

const columnHelper = createColumnHelper<Order>();

const usersAndRolesTableColumns = [
    columnHelper.accessor("side", {
        header: "Side",
        cell: (info) => <SideCell value={info.getValue()} />,
        meta: {
            headerStyleProps: {
                width: "60px",
            },
        },
    }),
    columnHelper.accessor("instrumentName", {
        header: "Instrument",
        cell: (info) => info.getValue(),
        meta: {
            headerStyleProps: {
                width: "90px",
            },
        },
    }),
    columnHelper.accessor("orderPrice", {
        header: "Price",
        cell: (info) => ValueFormat.price(info.getValue()),
        meta: {
            headerStyleProps: {
                width: "180px",
            },
        },
    }),
    columnHelper.accessor("orderRemainingSizeOrVolume", {
        header: "Size",
        cell: (info) => ValueFormat.size(info.getValue()),
        meta: {
            headerStyleProps: {
                width: "180",
            },
        },
    }),
    columnHelper.accessor("orderInitialSizeOrVolume", {
        header: "Initial size",
        cell: (info) => ValueFormat.size(info.getValue()),
        meta: {
            headerStyleProps: {
                width: "180px",
            },
        },
    }),
    columnHelper.accessor("orderCreatedAt", {
        header: "Date created",
        sortingFn: sortTimestamp,
        cell: (info) => <DateTimeViewer value={info.getValue() / 1000} />,
        meta: {
            headerStyleProps: {
                width: "120px",
            },
        },
    }),
    columnHelper.accessor("orderId", {
        header: "Order ID",
        cell: (info) => info.getValue(),
        meta: {
            headerStyleProps: {
                width: "150px",
            },
        },
    }),
    columnHelper.accessor("orderClientId", {
        header: "Client Order ID",
        cell: (info) => info.getValue(),
        meta: {
            headerStyleProps: {
                width: "120px",
            },
        },
    }),
];

export const OrdersPage = () => {
    const clientId = useClientId();
    const { orders, isLoading } = usePositionsFeed(clientId);
    const debouncedOrders = useDebouncedValue(orders);
    const [sorting, setSorting] = useState<SortingState>([]);
    const [instrument, setInstrument] = useState<InstrumentSelectValue>(
        INSTRUMENT_SELECT_ALL_VALUE,
    );

    const instrumentOrders = useMemo(
        () =>
            instrument === INSTRUMENT_SELECT_ALL_VALUE
                ? debouncedOrders
                : debouncedOrders.filter((order) => order.instrumentName === instrument),
        [debouncedOrders, instrument],
    );

    const getExportData = useCallback(
        () =>
            instrumentOrders.map((order) => ({
                Side: order.side === OrderSide.Bid ? "Bid" : "Ask",
                Instrument: order.instrumentName,
                Price: ValueFormat.price(order.orderPrice),
                Size: ValueFormat.size(order.orderRemainingSizeOrVolume),
                "Initial size": ValueFormat.size(order.orderInitialSizeOrVolume),
                "Date created": format(order.orderCreatedAt, DATE_TIME_FORMAT),
                "Order ID": order.orderId,
                "Client Order ID": order.orderClientId,
            })),
        [instrumentOrders],
    );

    const { bidPrice, askPrice } = useMemo(
        () =>
            instrumentOrders.reduce<{
                bidPrice: number | bigint | undefined;
                askPrice: number | bigint | undefined;
            }>(
                (acc, order) => {
                    if (
                        order.side === OrderSide.Ask &&
                        (acc.askPrice === undefined || order.orderPrice < acc.askPrice)
                    ) {
                        acc.askPrice = order.orderPrice;
                    }
                    if (
                        order.side === OrderSide.Bid &&
                        (acc.bidPrice === undefined || order.orderPrice > acc.bidPrice)
                    ) {
                        acc.bidPrice = order.orderPrice;
                    }

                    return acc;
                },
                { bidPrice: undefined, askPrice: undefined },
            ),
        [instrumentOrders],
    );

    return (
        <>
            <OptionsContainer>
                <InstrumentSelect
                    value={instrument}
                    onChange={setInstrument}
                    orders={debouncedOrders}
                />
                <Gap />
                {instrument !== INSTRUMENT_SELECT_ALL_VALUE && (
                    <Spread bidPrice={bidPrice} askPrice={askPrice} />
                )}
                <ExportButton
                    data={getExportData}
                    filename={`orders_${clientId}`}
                    loading={isLoading}
                    disabled={orders.length === 0}
                />
            </OptionsContainer>
            <Table
                tableOptions={{
                    getRowId: (originalRow) => String(originalRow.orderId),
                    data: instrumentOrders,
                    columns: usersAndRolesTableColumns,
                    state: {
                        sorting,
                    },
                    onSortingChange: setSorting,
                    getCoreRowModel: getCoreRowModel(),
                    getSortedRowModel: getSortedRowModel(),
                }}
                isLoading={isLoading}
            />
        </>
    );
};
