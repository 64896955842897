const getMatchStartsWith = (input: string, query: string) => {
    if (query === "") {
        return;
    }

    if (input.toUpperCase().startsWith(query.toUpperCase())) {
        return (
            <>
                <mark>{input.substring(0, query.length)}</mark>
                {input.substring(query.length)}
            </>
        );
    }
};

export default getMatchStartsWith;
