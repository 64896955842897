import useSWR from "swr";
import { ClientId } from "~entities/client";
import { GlobalLimit } from "~entities/globalLimit";
import { requestAdminery } from "~utils/api";

type RawGlobalLimitEntity = [
    currencyName: string,
    grossLimit: number | bigint,
    grossLimitUtilisation: number | bigint,
];

const prepareData = ([
    currencyName,
    grossLimit,
    grossLimitUtilisation,
]: RawGlobalLimitEntity): GlobalLimit => ({
    currencyName,
    grossLimit: BigInt(grossLimit),
    grossLimitUtilisation: BigInt(grossLimitUtilisation),
});

type SWRKey = [clientId: ClientId, key: string];

// TODO: make different hooks for master and other accounts
const fetcher = ([clientId]: SWRKey) =>
    requestAdminery("limits", { clientId }).then((data) => {
        // for master accounts: [externalGlobalLimits, internalGlobalLimits]
        if (Array.isArray(data[0])) {
            return data.map(prepareData);
        }

        // for other accounts: [globalLimits]
        return [prepareData(data)];
    });

export const useGlobalLimitsApi = (clientId: ClientId) =>
    useSWR<GlobalLimit[], unknown, SWRKey>([clientId, "useGlobalLimitsApi"], fetcher);
