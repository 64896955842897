import { Currency, CurrencyType } from "~entities/currency";
import { NetworkName } from "~entities/network";

export const getCurrencyTypeTitle = (type: CurrencyType) => {
    switch (type) {
        case CurrencyType.Crypto:
            return "Crypto";
        case CurrencyType.Fiat:
            return "Fiat";
        case CurrencyType.Stablecoin:
            return "Stablecoin";
        default:
            return "";
    }
};

export const mutateCurrencyNetwork = (
    currencies: Currency[],
    name: string,
    networks: NetworkName[],
) => {
    const currencyIndex = currencies.findIndex((item) => item.name === name);

    return [
        ...currencies.slice(0, currencyIndex),
        { ...currencies[currencyIndex], networks },
        ...currencies.slice(currencyIndex + 1),
    ];
};
