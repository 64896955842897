import useSWR from "swr";
import { ClientId } from "~entities/client";
import { OrderId } from "~entities/order";
import { SettlementCorrectionOrder } from "~entities/settlementCorrectionOrder";
import { requestAdminery } from "~utils/api";

type RawCorrectionOrderEntity = [
    settlementOrderId: OrderId,
    currency1: string,
    currency2: string,
    size1: number | bigint,
    size2: number | bigint,
    createdAt: number,
    comment: string,
    flags: number,
    clientA: ClientId,
    clientB: ClientId,
    network1: string,
    network2: string,
];

const prepareData = ([
    settlementOrderId,
    currency1,
    currency2,
    size1,
    size2,
    createdAt,
    comment,
    flags,
    clientA,
    clientB,
    network1,
    network2,
]: RawCorrectionOrderEntity): SettlementCorrectionOrder => ({
    settlementOrderId,
    currency1,
    currency2,
    size1,
    size2,
    createdAt,
    comment,
    flags,
    clientA,
    clientB,
    network1,
    network2,
});

type SWRKey = [clientId: ClientId, key: string];

const fetcher = ([clientId]: SWRKey) =>
    requestAdminery("settlementOrders", { clientId }).then(([, data]) =>
        data
            .filter((rawSettlement: RawCorrectionOrderEntity) => {
                const flags = rawSettlement[7];

                return flags === 0;
            })
            .map(prepareData),
    );

export const useSettlementCorrectionsApi = (clientId: ClientId) =>
    useSWR<SettlementCorrectionOrder[]>([clientId, "useSettlementCorrectionsApi"], fetcher);
