import { FC } from "react";
import styled from "styled-components";
import EditAction from "~components/Table/Action/EditAction";
import RemoveAction from "~components/Table/Action/RemoveAction";
import { Network } from "~entities/network";
import { useInstrumentsApi } from "~hooks/api/useInstrumentsApi";
import useNotifications from "~hooks/useNotifications";
import { EditForm } from "~pages/AssetsAndInstruments/Networks/EditForm";
import { requestAdminery } from "~utils/api";

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 8px;
`;

type NetworkActionsCellProps = {
    network: Network;
};

export const NetworkActionsCell: FC<NetworkActionsCellProps> = ({ network }) => {
    const { data: apiData, mutate } = useInstrumentsApi();
    const { showSuccessNotification, showErrorNotification } = useNotifications();

    const handleRemove = async () => {
        const response = await requestAdminery("delNetwork", {
            name: network.name,
        });

        if (!response?.error && apiData !== undefined) {
            mutate({
                ...apiData,
                networks: apiData.networks.filter((item) => item.name !== network.name),
            });
        }

        if (!response?.error) {
            showSuccessNotification(`${network.name} network has been successfully deleted.`);
        }
        if (response?.error) {
            showErrorNotification(`An error occurred when deleting ${network.name} network.`);
        }
    };

    return (
        <Container>
            <EditAction
                renderEditForm={(closePopover) => (
                    <EditForm network={network} onDone={closePopover} />
                )}
            />
            <RemoveAction title={`Remove network "${network.name}"`} onDone={handleRemove} />
        </Container>
    );
};
