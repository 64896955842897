import { FC, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Outlet } from "react-router-dom";
import styled from "styled-components";
import { Content } from "~components/Layout/Content";
import { ErrorMessage } from "~components/Layout/ErrorMessage";
import { SwrErrorHandler } from "~components/Layout/SwrErrorHandler";
import { Media } from "~constants/dimensions";
import usePageLayout from "~hooks/usePageLayout";
import usePageTitle from "~hooks/usePageTitle";
import Logo from "./Logo";
import Menu from "./Menu";
import Profile from "./Profile";

const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
`;
const Header = styled.header`
    display: flex;
    align-items: center;
    padding: 0 12px 0 16px;
    width: 100%;
    height: 68px;
`;
const Body = styled.div`
    display: flex;
    flex: 1;
    padding: 0 12px 0 8px;
    overflow: auto;

    ${Media.Mobile} {
        padding: 0 12px;
    }
`;

const Title = styled.div`
    flex: 1;
    font-size: 24px;
`;
const MenuSwitch = styled.button<{ $shown: boolean }>`
    display: none;
    user-select: none;
    overflow: hidden;

    &:active {
        user-select: none;
    }

    ${Media.Mobile} {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        width: 40px;
        height: 40px;
        margin: 0 7px;
        padding-bottom: 5px;
        font-size: 40px;
        background: ${(p) => (p.$shown ? p.theme.colors.ui52 : p.theme.colors.ui8)};
        color: ${(p) => (p.$shown ? p.theme.colors.uiWhite100 : p.theme.colors.ui100)};
        border-radius: 8px;
    }
`;

const Layout: FC = () => {
    const title = usePageTitle();
    const layout = usePageLayout();
    const [showMenu, setShowMenu] = useState(false);
    const handleMenuSwitch = () => setShowMenu((prevState) => !prevState);

    return (
        <Container>
            <Header>
                <Logo />
                <Title>{title}</Title>
                <MenuSwitch $shown={showMenu} onClick={handleMenuSwitch}>
                    ≡
                </MenuSwitch>
                <Profile />
            </Header>
            <Body>
                <Menu shown={showMenu} />
                <ErrorBoundary FallbackComponent={ErrorMessage}>
                    <SwrErrorHandler>
                        {layout?.nakedContent ? (
                            <Outlet />
                        ) : (
                            <Content>
                                <Outlet />
                            </Content>
                        )}
                    </SwrErrorHandler>
                </ErrorBoundary>
            </Body>
        </Container>
    );
};

export default Layout;
