import { noop } from "@fm-frontend/uikit/src/const";
import { createContext, Dispatch, FC, ReactNode, SetStateAction, useMemo, useState } from "react";

type TableContextValue = {
    query: string;
    setQuery: Dispatch<SetStateAction<string>>;
};

export const TableContext = createContext<TableContextValue>({
    query: "",
    setQuery: noop,
});

export const useTableContextValue = () => {
    const [query, setQuery] = useState("");

    return useMemo(
        () => ({
            query,
            setQuery,
        }),
        [query],
    );
};

export const TableContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const tableContextValue = useTableContextValue();

    return <TableContext.Provider value={tableContextValue}>{children}</TableContext.Provider>;
};
