import { PSmall } from "@fm-frontend/uikit";
import { FC } from "react";
import styled from "styled-components";

type CommentCellProps = {
    value: string;
};

const Comment = styled(PSmall)`
    color: ${(p) => p.theme.colors.ui72};
`;

export const CommentCell: FC<CommentCellProps> = ({ value }) => {
    return <Comment>{value}</Comment>;
};
