import { IconButton, Icons } from "@fm-frontend/uikit";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { ClientId } from "~entities/client";
import { getClientUrl } from "~pages/Router";

type ActionCellProps = {
    clientId: ClientId | undefined;
};

export const ActionsCell: FC<ActionCellProps> = ({ clientId }) => {
    const navigate = useNavigate();

    if (clientId === undefined) {
        return null;
    }

    const handleClick = () => {
        navigate(getClientUrl(clientId));
    };

    return (
        <IconButton title="View" variant="plain" Icon={Icons.ArrowRight} onClick={handleClick} />
    );
};
