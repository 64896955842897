import { PBold } from "@fm-frontend/uikit";
import { createColumnHelper } from "@tanstack/react-table";
import { ClientCell } from "~components/Table/Cell/ClientCell";
import { ActionsCell } from "~pages/RisksManagement/List/ActionsCell";
import { Item, RowType, SubItem } from "~pages/RisksManagement/List/types";

const columnHelper = createColumnHelper<Item | SubItem>();

export const globalColumns = [
    columnHelper.accessor("title", {
        header: "Counterparty",
        cell: (info) => {
            if (info.row.original.type === RowType.Counterparty) {
                return <ClientCell id={info.row.original.id ?? 0} name={info.getValue()} />;
            }

            if (
                info.row.original.type === RowType.GlobalGross ||
                info.row.original.type === RowType.GlobalInstrumentsWhitelist
            ) {
                return <PBold>{info.getValue()}</PBold>;
            }

            return info.getValue() ?? null;
        },
    }),
    columnHelper.accessor("value", {
        header: "Status",
        cell: (info) => info.getValue() ?? null,
    }),
    columnHelper.display({
        id: "actions",
        cell: (info) => <ActionsCell item={info.row.original} />,
        enableSorting: false,
        meta: {
            cellStyleProps: {
                textAlign: "right",
            },
        },
    }),
];
