import { VStack } from "@fm-frontend/uikit";
import styled, { css } from "styled-components";
import { ButtonItem, LinkItem } from "~components/Layout/Menu/Item";

export const Scope = styled(VStack)<{ $type: "menu" | "submenu" | "actions" }>`
    & > & {
        & > a {
            font-size: 12px;
            font-weight: 400;
        }
    }

    ${(p) => {
        switch (p.$type) {
            case "submenu":
                return css`
                    margin-bottom: 0;

                    ${LinkItem}, ${ButtonItem} {
                        font-size: 14px;
                    }
                `;
            case "actions":
                return css`
                    ${LinkItem}, ${ButtonItem} {
                        color: ${p.theme.colors.ui52};
                        font-size: 12px;

                        :hover,
                        &.active {
                            color: ${p.theme.colors.uiWhite100};
                        }
                    }
                `;
            case "menu":
            default:
                return css`
                    margin-bottom: 12px;

                    ${LinkItem}, ${ButtonItem} {
                        font-size: 14px;
                    }
                `;
        }
    }};
`;
