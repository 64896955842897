import {
    FieldGroup,
    Form,
    FormActions,
    FormBody,
    Header as FormHeader,
    HeaderTitle,
    SimpleDropdown,
    SimpleInput,
    SimpleMultiDropdown,
} from "@fm-frontend/uikit";
import { ValueParse } from "@fm-frontend/utils";
import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import FormError from "~components/FormError";
import { PrimaryButton } from "~components/PrimaryButton";
import { ERROR_MESSAGES } from "~constants/errors";
import { ValidateErrors, ValidateRules } from "~constants/form";
import { CurrencyType } from "~entities/currency";
import { useInstrumentsApi } from "~hooks/api/useInstrumentsApi";
import useNotifications from "~hooks/useNotifications";
import { getCurrencyTypeTitle } from "~pages/AssetsAndInstruments/Assets/utils";
import { requestAdminery } from "~utils/api";
import { prepareRequestError } from "~utils/prepareRequestError";

const TYPE_OPTIONS = [
    { text: getCurrencyTypeTitle(CurrencyType.Crypto), value: CurrencyType.Crypto },
    { text: getCurrencyTypeTitle(CurrencyType.Stablecoin), value: CurrencyType.Stablecoin },
    { text: getCurrencyTypeTitle(CurrencyType.Fiat), value: CurrencyType.Fiat },
];

const BALANCE_STEP_OPTIONS = [
    { text: "0.00000001", value: "0.00000001" },
    { text: "0.0000001", value: "0.0000001" },
    { text: "0.000001", value: "0.000001" },
    { text: "0.00001", value: "0.00001" },
    { text: "0.0001", value: "0.0001" },
    { text: "0.001", value: "0.001" },
    { text: "0.01", value: "0.01" },
    { text: "0.1", value: "0.1" },
    { text: "1", value: "1" },
];

const FormContainer = styled(Form)`
    width: 100%;
    min-width: initial;
    padding: 8px;
`;

type AddCurrencyFormType = {
    name: string;
    price: string;
    networks: string[];
    balanceStep: string;
    type: CurrencyType;
};

type AddFormProps = {
    onDone: () => void;
};

const AddForm: FC<AddFormProps> = ({ onDone }) => {
    const { data: apiData, isLoading, mutate } = useInstrumentsApi();
    const { networks = [] } = apiData ?? {};
    const { showSuccessNotification } = useNotifications();
    const {
        control,
        register,
        setValue,
        setFocus,
        formState: { errors },
        handleSubmit,
    } = useForm<AddCurrencyFormType>({
        defaultValues: {
            type: TYPE_OPTIONS[0].value,
            balanceStep: BALANCE_STEP_OPTIONS[0].value,
            networks: [],
        },
    });
    const [loading, setLoading] = useState(false);
    const [submitError, setSubmitError] = useState<string>();

    const networkOptions = networks.map((network) => ({ text: network.name, value: network.name }));

    useEffect(() => {
        setFocus("name");
    }, [setFocus]);

    const handleAddAssetSubmit = handleSubmit(async (data) => {
        try {
            setSubmitError(undefined);
            setLoading(true);
            const response = await requestAdminery("addCurrency", {
                name: data.name,
                type: data.type,
                balanceStep: ValueParse.size(data.balanceStep),
                price: ValueParse.price(data.price),
            }).then(() =>
                requestAdminery("assignNetworks", {
                    currency: data.name,
                    networks: data.networks,
                }),
            );

            await mutate();

            if (!response?.error) {
                onDone();
                showSuccessNotification(`Asset ${data.name} was successfully added`);
            }
            if (response?.error) {
                setSubmitError(ERROR_MESSAGES[response.error] ?? response.error);
            }
        } catch (e) {
            setSubmitError(prepareRequestError(e));
        } finally {
            setLoading(false);
        }
    });

    return (
        <FormContainer onSubmit={handleAddAssetSubmit}>
            <FormHeader>
                <HeaderTitle title="Add new asset" />
            </FormHeader>
            <FormBody alignItems="stretch">
                <FieldGroup>
                    <SimpleDropdown
                        options={TYPE_OPTIONS}
                        control={control}
                        name="type"
                        label="Type"
                    />
                    <SimpleInput
                        label="Code"
                        placeholder="BTC"
                        error={errors.name?.message}
                        {...register("name", {
                            required: ValidateErrors.required,
                            onChange: (e) => setValue("name", e.target.value.toUpperCase()),
                        })}
                    />
                </FieldGroup>
                <FieldGroup>
                    <SimpleDropdown
                        options={BALANCE_STEP_OPTIONS}
                        control={control}
                        name="balanceStep"
                        label="Balance step"
                    />
                    <SimpleMultiDropdown
                        options={networkOptions}
                        control={control}
                        name="networks"
                        label="Networks"
                        fullWidth
                        disabled={isLoading}
                    />
                    <SimpleInput
                        label="Price, $"
                        placeholder="40000"
                        error={errors.price?.message}
                        {...register("price", {
                            required: ValidateErrors.required,
                            validate: { price: ValidateRules.price },
                        })}
                    />
                </FieldGroup>
                {submitError && <FormError message={submitError} />}
            </FormBody>
            <FormActions variant="plain">
                <PrimaryButton size="large" fullWidth loading={loading}>
                    Add asset
                </PrimaryButton>
            </FormActions>
        </FormContainer>
    );
};

export default AddForm;
