import useSWR from "swr";
import { ClientId } from "~entities/client";
import { GlobalLimit } from "~entities/globalLimit";
import { requestAdminery } from "~utils/api";

type RawGlobalLimitEntity = [
    currencyName: string,
    grossLimit: number | bigint,
    grossLimitUtilisation: number | bigint,
];

const prepareData = ([
    currencyName,
    grossLimit,
    grossLimitUtilisation,
]: RawGlobalLimitEntity): GlobalLimit => ({
    currencyName,
    grossLimit: BigInt(grossLimit),
    grossLimitUtilisation: BigInt(grossLimitUtilisation),
});

type SWRKey = [clientId: ClientId, key: string];

const fetcher = ([clientId]: SWRKey) =>
    requestAdminery("internalLimit", { clientId }).then((data) => prepareData(data));

export const useInternalLimitApi = (clientId: ClientId) =>
    useSWR<GlobalLimit, unknown, SWRKey>([clientId, "useInternalLimitApi"], fetcher);
