import useSWR from "swr";
import { ClientId } from "~entities/client";
import {
    DealAggressorSide,
    DealHistoryItem,
    DealId,
    DealTradeType,
} from "~entities/dealHistoryItem";
import {
    OrderCancelReason,
    OrderCreatedByType,
    OrderId,
    OrderSide,
    OrderType,
} from "~entities/order";
import { requestAdminery } from "~utils/api";

type RawDealHistoryEntity = [
    instrumentName: string,
    orderType: OrderType,
    side: OrderSide,
    cancelReason: OrderCancelReason,
    orderId: OrderId,
    orderClientId: number,
    orderPrice: number | bigint,
    orderInitialSizeOrVolume: number | bigint,
    orderRemainingSizeOrVolume: number | bigint,
    orderCreatedAt: number,
    dealMoment: number,
    dealId: DealId,
    dealAggressorSide: DealAggressorSide,
    dealPrice: number | bigint,
    dealSize: number | bigint,
    dealVolume: number | bigint,
    dealDelta: number | bigint,
    counterpartyId: ClientId,
    createdByType: OrderCreatedByType,
    counterpartySubaccountId: ClientId,
    linkedDealId: DealId,
    tradeType: DealTradeType,
];

const prepareData = ([
    instrumentName,
    orderType,
    side,
    cancelReason,
    orderId,
    orderClientId,
    orderPrice,
    orderInitialSizeOrVolume,
    orderRemainingSizeOrVolume,
    orderCreatedAt,
    dealMoment,
    dealId,
    dealAggressorSide,
    dealPrice,
    dealSize,
    dealVolume,
    dealDelta,
    counterpartyId,
    createdByType,
    counterpartySubaccountId,
    linkedDealId,
    tradeType,
]: RawDealHistoryEntity): DealHistoryItem => ({
    instrumentName,
    orderType,
    side,
    cancelReason,
    orderId,
    orderClientId,
    orderPrice,
    orderInitialSizeOrVolume,
    orderRemainingSizeOrVolume,
    orderCreatedAt,
    dealMoment,
    dealId,
    dealAggressorSide,
    dealPrice,
    dealSize,
    dealVolume,
    dealDelta,
    counterpartyId,
    createdByType,
    counterpartySubaccountId,
    linkedDealId,
    tradeType,
});

type RequestParams = {
    clientId: ClientId;
    counterpartyIds?: number | number[];
    instrument?: string | string[];
    orderTypes?: OrderType | OrderType[];
    filter?: string;
    till?: number;
    from?: number;
    to?: number;
    limit?: number;
};

type SWRKey = { useSWRName?: string } & RequestParams;

export const dealHistoryFetcher = ({ useSWRName, ...params }: SWRKey) =>
    requestAdminery("dealHistory", params).then((data) => data.map(prepareData));

export const useDealHistoryApi = (params: RequestParams) =>
    useSWR<DealHistoryItem[], unknown, SWRKey>(
        { useSWRName: "useDealHistoryApi", ...params },
        dealHistoryFetcher,
    );
