import { EmptySearch } from "@fm-frontend/uikit/src/components/common/EmptySearch";
import {
    GroupLabel as DropdownGroupLabel,
    OptionContainer,
} from "@fm-frontend/uikit/src/components/dropdowns/common/styled";
import styled from "styled-components";
import { ALL_CLIENTS_ID } from "~components/Layout/Menu/ClientSelect/constants";
import { Item as GroupItem } from "~components/Layout/Menu/ClientSelect/Item";
import { SelectGroups, SelectValue } from "~components/Layout/Menu/ClientSelect/types";
import { Client, ClientId } from "~entities/client";

const GroupContainer = styled(OptionContainer)`
    gap: 0;
`;
const GroupLabel = styled(DropdownGroupLabel)`
    color: ${(p) => p.theme.colors.ui52};
    font-size: 11px;
    line-height: 26px;
    padding: 12px 12px 0;
    text-transform: uppercase;
`;
const EmptyContainer = styled.div`
    padding: 15px 0;
`;

export const searchClients = (clientsGroups: SelectGroups, query: string) => {
    if (!query) {
        return clientsGroups;
    }

    return Object.entries(clientsGroups).reduce<SelectGroups>(
        (clientsGroupsAcc, [key, clients]) => {
            const groupClients = clients.reduce<Client[]>((clientsAcc, client) => {
                const normalizedQuery = query.toLowerCase();
                const match =
                    String(client.id).startsWith(query) ||
                    client.username.toLowerCase().includes(normalizedQuery) ||
                    client.email.toLowerCase().includes(normalizedQuery);

                if (match) {
                    clientsAcc.push(client);
                }

                return clientsAcc;
            }, []);

            return {
                ...clientsGroupsAcc,
                [key]: groupClients,
            };
        },
        { Makers: [], Takers: [], Master: [], " ": [] },
    );
};

export const getSelectedItem = (clientsGroups: SelectGroups, selectedClientId: SelectValue) => {
    const clientSelected = selectedClientId !== ALL_CLIENTS_ID;

    if (clientSelected) {
        return Object.values(clientsGroups)
            .flat()
            .find((client) => client.id === selectedClientId);
    }
};

export const getGroupContent = (
    groupClients: Client[],
    selectedClientId: SelectValue,
    onSelect: (clientId: ClientId) => void,
) =>
    groupClients.map((client) => (
        <GroupItem
            key={client.id}
            active={client.id === selectedClientId}
            client={client}
            onSelect={onSelect}
        />
    ));

export const getContent = (
    clientsGroups: SelectGroups,
    selectedClientId: SelectValue,
    onSelect: (clientId: ClientId) => void,
) => {
    const notEmptyGroups = Object.entries(clientsGroups).filter(
        ([, groupClients]) => groupClients.length,
    );

    if (notEmptyGroups.length !== 0) {
        return notEmptyGroups.map(
            ([label, groupClients]) =>
                groupClients.length !== 0 && (
                    <GroupContainer key={label}>
                        <GroupLabel>{label}</GroupLabel>
                        {getGroupContent(groupClients, selectedClientId, onSelect)}
                    </GroupContainer>
                ),
        );
    }

    return (
        <EmptyContainer>
            <EmptySearch description="Client not found" />
        </EmptyContainer>
    );
};
