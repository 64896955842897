import { SmallLoader } from "@fm-frontend/uikit";
import styled from "styled-components";

const Container = styled.div`
    height: 88px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Loading = () => {
    return (
        <Container>
            <SmallLoader />
        </Container>
    );
};

export default Loading;
