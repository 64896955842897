import { RootState } from "~store";
import { UserStatus } from "~store/user/types";

export const getUser = (state: RootState) => state.user;
export const getStatus = (state: RootState) => getUser(state).status;
export const isAuthorized = (state: RootState) => getStatus(state) === UserStatus.Authorized;
export const isNotAuthorized = (state: RootState) => getStatus(state) === UserStatus.NotAuthorized;
export const isAuthorizationError = (state: RootState) =>
    getStatus(state) === UserStatus.AuthorizationError;
export const getEmail = (state: RootState) => getUser(state).email;
