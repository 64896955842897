import { InfiniteTable as UIKitTable } from "@fm-frontend/uikit";
import { EmDash } from "@fm-frontend/utils";
import { getCoreRowModel, getExpandedRowModel } from "@tanstack/react-table";
import { FC, useMemo, useState } from "react";
import styled from "styled-components";
import Gap from "~components/Gap";
import OptionsContainer from "~components/Table/Options/Container";
import ExportButton from "~components/Table/Options/ExportButton";
import Search from "~components/Table/Options/Search";
import { TableContext, useTableContextValue } from "~components/Table/TableContext";
import { useClientsApi } from "~hooks/api/useClientsApi";
import { useCounterpartyLimitsApi } from "~hooks/api/useCounterpartyLimitsApi";
import useAppSelector from "~hooks/useAppSelector";
import { useClientId } from "~hooks/useClientId";
import { useLimitStatuses } from "~hooks/useLimitStatuses";
import { StatusBadge } from "~pages/RisksManagement/components/StatusBadge";
import { AddCounterpartyLimitButton } from "~pages/RisksManagement/List/AddCounterpartyLimitButton";
import { columns } from "~pages/RisksManagement/List/columns";
import {
    StatusSelect,
    StatusSelectValue,
    STATUS_SELECT_ALL_VALUE,
} from "~pages/RisksManagement/List/StatusSelect";
import { Item, RowType, SubItem } from "~pages/RisksManagement/List/types";
import { CounterpartyStatus, isMatch } from "~pages/RisksManagement/List/utils";
import { getClient, isClientsFetching } from "~store/clients/selectors";
import { getClientType } from "~utils/getClientType";
import { getClientTypeTitle } from "~utils/getClientTypeTitle";

const Table = styled(UIKitTable<Item | SubItem>)`
    thead {
        visibility: collapse;
    }

    th:first-of-type,
    td:first-of-type {
        padding-left: 12px !important;
    }
`;

export const CounterpartiesRisksManagement: FC = () => {
    useClientsApi();

    const clientsFetching = useAppSelector(isClientsFetching);
    const getClientById = useAppSelector(getClient);
    const clientId = useClientId();
    const tableContextValue = useTableContextValue();
    const { query, setQuery } = tableContextValue;
    const [status, setStatus] = useState<StatusSelectValue>(STATUS_SELECT_ALL_VALUE);
    const { data: counterpartyLimitsData, isLoading: isCounterpartyLimitsLoading } =
        useCounterpartyLimitsApi(clientId);
    const { data: limitStatuses, isLoading: isLimitStatusesLoading } = useLimitStatuses(clientId);
    const isLoading = isCounterpartyLimitsLoading || isLimitStatusesLoading || clientsFetching;

    const counterpartyLimitsRows = useMemo(
        () =>
            counterpartyLimitsData
                ?.filter((counterpartyLimit) => {
                    const { counterpartyId } = counterpartyLimit;
                    const counterparty = getClientById(counterpartyId);

                    if (!isMatch(query, counterparty)) {
                        return false;
                    }

                    if (status !== STATUS_SELECT_ALL_VALUE) {
                        return limitStatuses?.[counterpartyId] === status;
                    }

                    return true;
                })
                .sort(
                    (a, b) =>
                        (limitStatuses?.[b.counterpartyId] ?? 0) -
                        (limitStatuses?.[a.counterpartyId] ?? 0),
                )
                .map((counterpartyLimit) => {
                    const { counterpartyId } = counterpartyLimit;
                    const counterparty = getClientById(counterpartyId);

                    return {
                        type: RowType.Counterparty,
                        title: counterparty?.username ?? "",
                        id: counterpartyId,
                        value: <StatusBadge status={limitStatuses?.[counterpartyId]} />,
                        status: limitStatuses?.[counterpartyId],
                        clientType: counterparty?.type
                            ? getClientTypeTitle(getClientType(counterparty))
                            : EmDash,
                    };
                }) ?? [],
        [counterpartyLimitsData, getClientById, limitStatuses, query, status],
    );

    const data: Item[] = useMemo(() => [...counterpartyLimitsRows], [counterpartyLimitsRows]);

    const getExportData = () => [
        ...counterpartyLimitsRows.map((counterpartyLimitsRow) => ({
            Counterparty: counterpartyLimitsRow.title,
            ID: counterpartyLimitsRow.id,
            Type: counterpartyLimitsRow.clientType,
            Status:
                counterpartyLimitsRow.status !== undefined
                    ? CounterpartyStatus[counterpartyLimitsRow.status].title
                    : "",
        })),
    ];

    return (
        <TableContext.Provider value={tableContextValue}>
            <OptionsContainer>
                <Search query={query} onChange={setQuery} />
                <StatusSelect value={status} onChange={setStatus} />
                <Gap />
                <ExportButton
                    data={getExportData}
                    filename={`risks_management_${clientId}`}
                    loading={isLoading}
                />
                <AddCounterpartyLimitButton />
            </OptionsContainer>
            <Table
                tableOptions={{
                    data,
                    columns,
                    getCoreRowModel: getCoreRowModel(),
                    state: {
                        expanded: true,
                    },
                    getExpandedRowModel: getExpandedRowModel(),
                    getSubRows: (row) => {
                        return row.items;
                    },
                    getRowCanExpand: (row) => {
                        return row.original.type !== RowType.Info;
                    },
                }}
                isLoading={isLoading}
            />
        </TableContext.Provider>
    );
};
