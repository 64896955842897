import { Icon } from "@fm-frontend/uikit";
import { NotificationType } from "~components/Notifications/types";

export const getNotificationIcon = (type: NotificationType) => {
    switch (type) {
        case NotificationType.Success:
            return <Icon name="success" />;
        case NotificationType.Error:
            return <Icon name="error" />;
        case NotificationType.Copy:
            return <Icon name="copy" />;
        default:
            return null;
    }
};
