import { FC } from "react";
import Select from "~components/Table/Select";
import { CounterpartyStatusType } from "~pages/RisksManagement/utils/status";

export const STATUS_SELECT_ALL_VALUE = "All";

const OPTIONS = [
    { text: "All", value: STATUS_SELECT_ALL_VALUE },
    { text: "Awaiting CP", value: String(CounterpartyStatusType.AWAITING_CP) },
    { text: "Liquidation", value: String(CounterpartyStatusType.LIQUIDATION) },
    { text: "Stopped", value: String(CounterpartyStatusType.STOPPED) },
    { text: "Stopped by CP", value: String(CounterpartyStatusType.STOPPED_BY_CP) },
    { text: "Restricted trading", value: String(CounterpartyStatusType.RESTRICTED_TRADING) },
    { text: "Low gross free", value: String(CounterpartyStatusType.LOW_GROSS_FREE) },
    { text: "Margin call", value: String(CounterpartyStatusType.MARGIN_CALL) },
    { text: "Normal", value: String(CounterpartyStatusType.NORMAL) },
];

export type StatusSelectValue = CounterpartyStatusType | typeof STATUS_SELECT_ALL_VALUE;

type StatusSelectProps = {
    value: StatusSelectValue;
    onChange: (value: StatusSelectValue) => void;
};

export const StatusSelect: FC<StatusSelectProps> = ({ value, onChange }) => {
    const handleChange = (selectedValue: string) => {
        if (selectedValue === STATUS_SELECT_ALL_VALUE) {
            onChange(STATUS_SELECT_ALL_VALUE);
            return;
        }

        onChange(Number(selectedValue) as CounterpartyStatusType);
    };

    return (
        <Select
            label="Status"
            defaultValue={String(value)}
            options={OPTIONS}
            onChange={handleChange}
        />
    );
};
