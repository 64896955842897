import useSWR from "swr";
import { ClientId } from "~entities/client";
import { User } from "~entities/user";
import { requestAdminery } from "~utils/api";

type SWRKey = [clientId: ClientId, key: string];

const fetcher = ([clientId]: SWRKey) => requestAdminery("getUsers", { clientId });

export const useUsersApi = (clientId: ClientId) =>
    useSWR<User[], unknown, SWRKey>([clientId, "useUsersApi"], fetcher);
