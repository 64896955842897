import { P } from "@fm-frontend/uikit";
import { FC } from "react";

type TxIdCellProps = {
    value: string;
};

export const TxIdCell: FC<TxIdCellProps> = ({ value }) => {
    return <P title={value}>{value}</P>;
};
