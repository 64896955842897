import useSWR from "swr";
import { requestHrpReporting } from "~utils/api";

type HRPMarket = {
    symbol: string;
    venue: string;
    instrument: string;
};

type SWRKey = string;
const fetcher = () => requestHrpReporting("hrpMarkets", {}).then((data) => data.result);

export const useHRPMarketsApi = () =>
    useSWR<HRPMarket[], unknown, SWRKey>("useHRPMarketsApi", fetcher, {
        revalidateOnFocus: false,
        // eslint-disable-next-line no-console
        onError: (e) => console.error(e),
    });
