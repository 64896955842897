import { FC } from "react";
import styled from "styled-components";

const Container = styled.span`
    color: ${(p) => p.theme.colors.ui32};
`;

export const ClientStatus: FC<{ disabled: boolean | undefined }> = ({ disabled }) => {
    if (disabled) {
        return <Container>Disabled</Container>;
    }

    return null;
};
