import { PBold } from "@fm-frontend/uikit";
import { FC, useContext } from "react";
import MatchStartsWith from "~components/Table/Cell/MatchStartsWith";
import { TableContext } from "~components/Table/TableContext";
import { ClientId } from "~entities/client";

type IdCellProps = {
    value: ClientId | undefined;
};

export const IdCell: FC<IdCellProps> = ({ value }) => {
    const { query } = useContext(TableContext);

    if (value === undefined) {
        return <PBold>–</PBold>;
    }

    return (
        <PBold>
            <MatchStartsWith value={String(value)} query={query} />
        </PBold>
    );
};
