import { FC } from "react";
import Select from "~components/Table/Select";
import { InviteStatus } from "~entities/onboardingStatus";
import { ReferralStatus } from "~entities/referral";

type Option = {
    text: string;
    value: ReferralStatus | InviteStatus;
};

export const STATUS_SELECT_ALL_VALUE = "All";

const OPTIONS = [
    { text: "All", value: STATUS_SELECT_ALL_VALUE },
    { text: "Data filling", value: ReferralStatus.Accepted },
    { text: "Sent", value: ReferralStatus.Sent },
    { text: "Joined", value: InviteStatus.Joined },
    { text: "LP choosing", value: InviteStatus.LpChoosing },
    { text: "LP verification", value: InviteStatus.LpVerification },
    { text: "Expired", value: ReferralStatus.Expired },
] as Option[];

export type StatusSelectValue = ReferralStatus | InviteStatus | typeof STATUS_SELECT_ALL_VALUE;

type StatusSelectProps = {
    value: StatusSelectValue;
    onChange: (value: StatusSelectValue) => void;
};

export const StatusSelect: FC<StatusSelectProps> = ({ value, onChange }) => {
    return (
        <Select<StatusSelectValue>
            label="Status"
            defaultValue={value}
            options={OPTIONS}
            onChange={onChange}
        />
    );
};
