import { P } from "@fm-frontend/uikit";
import { FC } from "react";

type CommentCellProps = {
    value: string;
};

export const CommentCell: FC<CommentCellProps> = ({ value }) => {
    return <P>{value}</P>;
};
