import { createColumnHelper } from "@tanstack/react-table";
import { ClientCell } from "~components/Table/Cell/ClientCell";
import DateTimeViewer from "~components/Table/Cell/DateTimeViewer";
import { DisabledCellTheme } from "~components/Table/CellTheme/Disabled";
import { EllipsisCellTheme } from "~components/Table/CellTheme/Ellipsis";
import ClientAction from "~pages/Overview/ClientAction";
import ClientEmail from "~pages/Overview/ClientEmail";
import { TableClient } from "~pages/Overview/ClientsView/types";
import ClientTags from "~pages/Overview/ClientTags";
import ClientTypeTitle from "~pages/Overview/ClientTypeTitle";
import { HRPId } from "~pages/Overview/HRPId";
import { sortTimestamp } from "~utils/sortTimestamp";

const columnHelper = createColumnHelper<TableClient>();

export const clientsTableColumns = [
    columnHelper.accessor("username", {
        header: "Name",
        cell: (info) => (
            <DisabledCellTheme disabled={info.row.original.isDisabled}>
                <ClientCell id={info.row.original.id} name={info.getValue()} />
            </DisabledCellTheme>
        ),
    }),
    columnHelper.accessor("email", {
        header: "Email",
        cell: (info) => (
            <DisabledCellTheme disabled={info.row.original.isDisabled}>
                <EllipsisCellTheme maxWidth={250}>
                    <ClientEmail value={info.getValue()} />
                </EllipsisCellTheme>
            </DisabledCellTheme>
        ),
        meta: {
            headerStyleProps: {
                width: "25%",
            },
        },
    }),
    columnHelper.accessor("metadata", {
        header: "Tags",
        sortingFn: (a, b) =>
            (a.original.metadata.tags?.join(", ") ?? "").localeCompare(
                b.original.metadata.tags?.join(", ") ?? "",
            ),
        cell: (info) => (
            <DisabledCellTheme disabled={info.row.original.isDisabled}>
                <ClientTags value={info.getValue()} />
            </DisabledCellTheme>
        ),
        meta: {
            headerStyleProps: {
                width: "15%",
            },
        },
    }),
    columnHelper.accessor("type", {
        header: "Type",
        cell: (info) => (
            <DisabledCellTheme disabled={info.row.original.isDisabled}>
                <ClientTypeTitle value={info.getValue()} />
            </DisabledCellTheme>
        ),
        meta: {
            headerStyleProps: {
                width: "80px",
            },
        },
    }),
    columnHelper.accessor("createdAt", {
        header: "Created",
        sortingFn: sortTimestamp,
        cell: (info) => (
            <DisabledCellTheme disabled={info.row.original.isDisabled}>
                <DateTimeViewer value={info.getValue()} />
            </DisabledCellTheme>
        ),
        meta: {
            headerStyleProps: {
                width: "90px",
            },
        },
    }),
    columnHelper.accessor("signedAgreementAt", {
        header: "T&C",
        sortingFn: sortTimestamp,
        cell: (info) => (
            <DisabledCellTheme disabled={info.row.original.isDisabled}>
                <DateTimeViewer value={info.getValue()} />
            </DisabledCellTheme>
        ),
        meta: {
            headerStyleProps: {
                width: "90px",
            },
        },
    }),
    columnHelper.accessor("lastTradeAt", {
        header: "Last trade",
        sortingFn: sortTimestamp,
        cell: (info) => (
            <DisabledCellTheme disabled={info.row.original.isDisabled}>
                <DateTimeViewer value={info.getValue()} />
            </DisabledCellTheme>
        ),
        meta: {
            headerStyleProps: {
                width: "90px",
            },
        },
    }),
    columnHelper.accessor("hrpId", {
        header: "HRP ID",
        cell: (info) => (
            <DisabledCellTheme disabled={info.row.original.isDisabled}>
                <HRPId value={info.getValue()} />
            </DisabledCellTheme>
        ),
        meta: {
            headerStyleProps: {
                width: "90px",
            },
        },
    }),
    columnHelper.display({
        id: "actions",
        cell: (info) => <ClientAction clientId={info.row.original.id} />,
        enableSorting: false,
        meta: {
            headerStyleProps: {
                width: "60px",
            },
            cellStyleProps: {
                textAlign: "right",
            },
        },
    }),
];
