import { useMatches } from "react-router-dom";
import { RouteHandle } from "~pages/Router";

const usePageHandle = (): RouteHandle => {
    const matches = useMatches();
    const { handle } = matches[matches.length - 1];

    if (handle !== null && typeof handle === "object") {
        return handle;
    }

    return {};
};

export default usePageHandle;
