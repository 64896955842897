import { BasicButton, Icons } from "@fm-frontend/uikit";
import { FC } from "react";
import styled from "styled-components";
import { pulseAnalyticsUrl } from "~constants/api";
import { ClientId } from "~entities/client";

const Button = styled(BasicButton)`
    gap: 0;
    padding-right: 4px;
`;

type AnalyticsButtonProps = {
    clientId: ClientId | undefined;
};

const AnalyticsButton: FC<AnalyticsButtonProps> = ({ clientId }) => {
    const handleClick = () => {
        const url = new URL(pulseAnalyticsUrl);
        url.searchParams.set("companyId", String(clientId));

        window.open(url.href, "_blank");
    };

    return (
        <Button type="button" size="small" disabled={clientId === undefined} onClick={handleClick}>
            Open Analytics
            <Icons.ExternalLink />
        </Button>
    );
};

export default AnalyticsButton;
