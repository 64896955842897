import {
    FieldGroup,
    Form,
    FormActions,
    FormBody,
    Header as FormHeader,
    HeaderTitle,
    P,
    SimpleDropdown,
    SimpleInput,
} from "@fm-frontend/uikit";
import { FC, useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import FormError from "~components/FormError";
import { PrimaryButton } from "~components/PrimaryButton";
import { ERROR_MESSAGES } from "~constants/errors";
import { useHRPInstrumentsApi } from "~hooks/api/useHRPInstrumentsApi";
import { useHRPMarketsApi } from "~hooks/api/useHRPMarketsApi";
import useNotifications from "~hooks/useNotifications";
import { requestHrpReporting } from "~utils/api";
import { prepareRequestError } from "~utils/prepareRequestError";

const FormContainer = styled(Form)`
    width: 100%;
    min-width: initial;
    padding: 8px;
`;

type EditCurrencyFormType = {
    name: string;
    hrpSymbol?: string;
};

type EditFormProps = {
    name: string;
    hrpSymbol?: string;
    onDone: () => void;
};

const NONE_HRP_SYMBOL = "";

export const EditForm: FC<EditFormProps> = ({ name, hrpSymbol = NONE_HRP_SYMBOL, onDone }) => {
    const { data: hrpMarkets = [] } = useHRPMarketsApi();
    const { mutate: mutateHRPInstruments } = useHRPInstrumentsApi();
    const { showSuccessNotification } = useNotifications();
    const {
        control,
        handleSubmit,
        register,
        formState: { isDirty },
    } = useForm<EditCurrencyFormType>({
        defaultValues: {
            name,
            hrpSymbol,
        },
    });
    const [loading, setLoading] = useState(false);
    const [submitError, setSubmitError] = useState<string>();

    const hrpMarketsOptions = [
        {
            text: "N/A",
            value: NONE_HRP_SYMBOL,
        },
        ...hrpMarkets.map((hrpMarket) => ({
            value: hrpMarket.symbol,
            text: hrpMarket.symbol,
        })),
    ];

    const handleHRPSymbolEdit = async (data: EditCurrencyFormType) => {
        let response;
        let notificationText = "";

        if (data.hrpSymbol) {
            response = await requestHrpReporting("mapInstrument", {
                fmInstrument: data.name,
                hrpInstrument: data.hrpSymbol,
            });
            notificationText = `Instrument ${data.name} was successfully mapped to HRP symbol ${data.hrpSymbol}`;
        }
        if (!data.hrpSymbol) {
            response = await requestHrpReporting("delInstrument", {
                fmInstrument: data.name,
            });
            notificationText = `HRP symbol for ${data.name} was successfully removed`;
        }

        if (response?.error) {
            setSubmitError(ERROR_MESSAGES[response.error] ?? response.error);
        }
        if (!response?.error) {
            await mutateHRPInstruments();
            onDone();
            showSuccessNotification(notificationText);
        }
    };

    const handleInstrumentAssetSubmit = handleSubmit(async (data) => {
        try {
            setSubmitError(undefined);
            setLoading(true);

            await handleHRPSymbolEdit(data);
        } catch (e) {
            setSubmitError(prepareRequestError(e));
        } finally {
            setLoading(false);
        }
    });

    return (
        <FormContainer onSubmit={handleInstrumentAssetSubmit}>
            <FormHeader>
                <HeaderTitle title="Edit instrument" />
            </FormHeader>
            <FormBody alignItems="stretch">
                <FieldGroup>
                    <SimpleInput disabled label="Name" {...register("name")} />
                    <SimpleDropdown
                        options={hrpMarketsOptions}
                        control={control}
                        name="hrpSymbol"
                        label="HRP symbol"
                        renderText={(text, value) => (
                            <P>{value === NONE_HRP_SYMBOL ? NONE_HRP_SYMBOL : text}</P>
                        )}
                    />
                </FieldGroup>
                {submitError && <FormError message={submitError} />}
            </FormBody>
            <FormActions variant="plain">
                <PrimaryButton size="large" fullWidth loading={loading} disabled={!isDirty}>
                    Edit instrument
                </PrimaryButton>
            </FormActions>
        </FormContainer>
    );
};
