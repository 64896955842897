import styled, { css } from "styled-components";
import { Media } from "~constants/dimensions";

export const Container = styled.nav<{ $shown: boolean }>`
    display: flex;
    flex-direction: column;
    padding: 0 8px 0 0;
    width: 212px;
    overflow: auto;

    ${Media.Mobile} {
        position: absolute;
        z-index: 10;
        transform: translateX(-100%);
        width: 100vw;
        height: calc(100% - 68px);
        padding: 0 12px;
        margin: 0 -12px;
        background: linear-gradient(
                180deg,
                rgba(60, 114, 255, 0.2) calc(-12.63% - 68px),
                rgba(86, 219, 219, 0.2) 32.54%,
                rgba(254, 191, 191, 0.2) 75.95%,
                rgba(254, 254, 254, 0.2) 100%
            ),
            linear-gradient(0deg, #ffffff, #ffffff), #ffffff;

        background-position: top center;

        ${(props) =>
            props.$shown &&
            css`
                transform: translateX(0%);
            `};
    }
`;
