import { FC } from "react";
import Select from "~components/Table/Select";
import { ClientType } from "~entities/client";
import { getClientTypeTitle } from "~utils/getClientTypeTitle";

type OptionValue = ClientType | typeof ALL_TYPES_VALUE;
type Option = {
    text: string;
    value: OptionValue;
};

export const ALL_TYPES_VALUE = "all";

const TYPE_OPTIONS = [
    { text: "All", value: ALL_TYPES_VALUE },
    { text: getClientTypeTitle(ClientType.Taker), value: ClientType.Taker },
    { text: getClientTypeTitle(ClientType.Maker), value: ClientType.Maker },
    { text: getClientTypeTitle(ClientType.PrimeBroker), value: ClientType.PrimeBroker },
] as Option[];

type TypeSelectProps = {
    value: OptionValue;
    onChange: (value: OptionValue) => void;
};

const TypeSelect: FC<TypeSelectProps> = ({ value, onChange }) => {
    return (
        <Select<OptionValue>
            label="Type"
            defaultValue={value}
            options={TYPE_OPTIONS}
            onChange={onChange}
        />
    );
};

export default TypeSelect;
