import { PBold } from "@fm-frontend/uikit";
import styled from "styled-components";

export const Headline = styled(PBold)`
    background: ${(p) => p.theme.colors.ui8};
    box-shadow: 0 -1px 0 ${(p) => p.theme.colors.ui8}, inset 0 -1px 0 ${(p) => p.theme.colors.ui8};
    color: ${(p) => p.theme.colors.ui100};
    padding: 0 12px;
    line-height: 44px;
    height: 44px;
`;
