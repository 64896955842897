const SPECIAL_SYMBOL_PATTERN = /[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;
const EMAIL_PATTERN =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

const ErrorMessages = {
    FieldRequired: "Field Required",
    InvalidEmail: "Invalid Email",
    MinLength: (count: number) => `Must be at least ${count} characters`,
    HasNumber: "Must contain at least 1 digit",
    HasUpperCase: "Must contain at least 1 upper case",
    HasLowerCase: "Must contain at least 1 lower case",
    HasSpecialSymbol: "Must contain at least 1 special character",
    IsDigitCode: "Code must be 6-digit",
    IsPasswordsMatch: "Your passwords must match",
};

export const ValidationRules = {
    email: {
        required: ErrorMessages.FieldRequired,
        pattern: {
            value: EMAIL_PATTERN,
            message: ErrorMessages.InvalidEmail,
        },
    },
    password: {
        required: ErrorMessages.FieldRequired,
        minLength: {
            value: 8,
            message: ErrorMessages.MinLength(8),
        },
        validate: {
            hasNumber: (v: string) => /\d/.test(v) || ErrorMessages.HasNumber,
            hasUpperCase: (v: string) => /[A-Z]/.test(v) || ErrorMessages.HasUpperCase,
            hasLowerCase: (v: string) => /[a-z]/.test(v) || ErrorMessages.HasLowerCase,
            hasSpecialSymbol: (v: string) =>
                SPECIAL_SYMBOL_PATTERN.test(v) || ErrorMessages.HasSpecialSymbol,
        },
    },
    code: {
        required: ErrorMessages.FieldRequired,
        validate: {
            isDigit: (v: string) => /^\d{6}$/.test(v) || ErrorMessages.IsDigitCode,
        },
    },
    confirmPassword(password: string) {
        return {
            required: ErrorMessages.FieldRequired,
            validate: {
                isMatch: (v: string) => password === v || ErrorMessages.IsPasswordsMatch,
            },
        };
    },
};

export const getMfaTokenURI = (token: string): string => {
    return `otpauth://totp/FM Admin?secret=${token}`;
};
