import { InfiniteTable as UIKitTable, Tab, TabContext, TabList } from "@fm-frontend/uikit";
import { ExpandedState, getCoreRowModel, getExpandedRowModel } from "@tanstack/react-table";
import { FC, useState } from "react";
import styled from "styled-components";
import Gap from "~components/Gap";
import { PartnerSelect, PartnerSelectType, PartnerValueType } from "~components/PartnerSelect";
import OptionsContainer from "~components/Table/Options/Container";
import ExportButton from "~components/Table/Options/ExportButton";
import Search from "~components/Table/Options/Search";
import { TableContext, useTableContextValue } from "~components/Table/TableContext";
import { useClientId } from "~hooks/useClientId";
import { useIsMaster } from "~hooks/useIsMaster";
import { columns } from "~pages/OpenPositions/columns";
import { useTableData } from "~pages/OpenPositions/hooks/useTableData";
import { Item, SubItem, Tabs } from "~pages/OpenPositions/types";
import {
    EMPTY_ITEM_EXPORT_DATA,
    getItemExportData,
    getSubItemExportData,
} from "~pages/OpenPositions/utils";

const TabsContainer = styled.div`
    width: 220px;
`;

const Table = styled(UIKitTable<Item | SubItem>)`
    min-width: 1010px;

    th:first-of-type,
    td:first-of-type {
        padding-left: 12px !important;
    }

    // "Total" row styles
    tbody > tr:first-child {
        background-color: ${(p) => p.theme.colors.ui4};
        color: inherit;
    }
`;

export const OpenPositionsPage: FC = () => {
    const clientId = useClientId();
    const isMaster = useIsMaster();
    const tableContextValue = useTableContextValue();
    const { query, setQuery } = tableContextValue;
    const [tab, setTab] = useState<string>(Tabs.Counterparties);
    const [expanded, setExpanded] = useState<ExpandedState>(true);
    const [partnerType, setPartnerType] = useState<PartnerValueType>(
        PartnerSelectType.Counterparties,
    );
    const { data, isLoading } = useTableData(clientId, partnerType, tab, query);

    const getExportData = () =>
        data.reduce<Record<string, string | bigint>[]>((acc, item) => {
            acc.push(getItemExportData(item));
            Object.values(item.items).forEach((subItem) => {
                acc.push(getSubItemExportData(subItem));
            });
            acc.push(EMPTY_ITEM_EXPORT_DATA);

            return acc;
        }, []);

    return (
        <TableContext.Provider value={tableContextValue}>
            <TabContext value={tab} handleClick={setTab}>
                <OptionsContainer>
                    <Search query={query} onChange={setQuery} />
                    <TabsContainer>
                        <TabList size="small">
                            <Tab title="Counterparties" value={Tabs.Counterparties} />
                            <Tab title="Assets" value={Tabs.Assets} />
                        </TabList>
                    </TabsContainer>
                    {isMaster && <PartnerSelect value={partnerType} onChange={setPartnerType} />}
                    <Gap />
                    <ExportButton
                        data={getExportData}
                        filename={`open_positions_${clientId}`}
                        loading={isLoading}
                    />
                </OptionsContainer>
                <Table
                    selectedRows={{ 0: true }}
                    tableOptions={{
                        data,
                        columns,
                        getCoreRowModel: getCoreRowModel(),
                        state: {
                            expanded,
                        },
                        onExpandedChange: setExpanded,
                        getExpandedRowModel: getExpandedRowModel(),
                        getSubRows: (row) => {
                            if (row.items === undefined) {
                                return [];
                            }

                            return Object.values(row.items);
                        },
                    }}
                    onRowClick={(row) => {
                        const isExpandable = row.getCanExpand();

                        if (isExpandable) {
                            row.toggleExpanded();
                        }
                    }}
                    isLoading={isLoading}
                />
            </TabContext>
        </TableContext.Provider>
    );
};
