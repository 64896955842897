import { BITMASK_UTILS } from "@fm-frontend/utils";
import { FC } from "react";
import styled from "styled-components";

const Container = styled.span`
    font-weight: 400;
`;

type AccessCellProps = {
    value: number;
    bitmask: number;
};

const AccessCell: FC<AccessCellProps> = ({ value, bitmask }) => {
    const flag = BITMASK_UTILS.isBitKeyApplied(value, bitmask);

    return <Container title={flag ? "Yes" : "No"}>{flag ? "Y" : "N"}</Container>;
};

export default AccessCell;
