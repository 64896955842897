import { PBold } from "@fm-frontend/uikit";
import { FC, useContext } from "react";
import MatchStartsWith from "~components/Table/Cell/MatchStartsWith";
import { TableContext } from "~components/Table/TableContext";

type CurrencyCellProps = {
    value: string;
};

const CurrencyCell: FC<CurrencyCellProps> = ({ value }) => {
    const { query } = useContext(TableContext);

    return (
        <PBold>
            <MatchStartsWith value={value} query={query} />
        </PBold>
    );
};

export default CurrencyCell;
