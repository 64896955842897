import useSWR from "swr";
import { ClientId } from "~entities/client";
import { Order } from "~entities/order";
import { Position } from "~entities/position";
import { SettlementOrder, SettlementOrderId } from "~entities/settlementOrder";
import { requestAdminery } from "~utils/api";
import { prepareOrder, RawOrderEntity } from "~utils/prepareFeedEntity";

type RawPosition = [currency: string, value: number | bigint, counterpartyId: ClientId];

type RawSettlementOrder = [
    settlementOrderId: SettlementOrderId,
    currency1: string,
    currency2: string,
    size1: number | bigint,
    size2: number | bigint,
    createdAt: number,
    counterpartyId: ClientId,
];

type RawData = [
    dealId: number,
    positions: RawPosition[],
    orders: RawOrderEntity[],
    settlementOrders: RawSettlementOrder[],
];

type PositionsData = {
    dealId: number;
    positions: Position[];
    orders: Order[];
    settlementOrders: SettlementOrder[];
};

const prepareData = ([dealId, positions, orders, settlementOrders]: RawData): PositionsData => ({
    dealId,
    positions: positions.map(([currency, value, counterpartyId]) => ({
        currency,
        value,
        counterpartyId,
    })),
    orders: orders.map(prepareOrder),
    settlementOrders: settlementOrders.map(
        ([settlementOrderId, currency1, currency2, size1, size2, createdAt, counterpartyId]) => ({
            settlementOrderId,
            currency1,
            currency2,
            size1,
            size2,
            createdAt,
            counterpartyId,
        }),
    ),
});

type SWRKey = [clientId: ClientId, key: string];

const fetcher = ([clientId]: SWRKey) =>
    requestAdminery("positions", { clientId }).then(prepareData);

export const usePositionsApi = (clientId: ClientId) =>
    useSWR<PositionsData, unknown, SWRKey>([clientId, "usePositionsApi"], fetcher);
