import usePageHandle from "~hooks/usePageHandle";

const usePageTitle = () => {
    const handle = usePageHandle();

    if ("title" in handle) {
        return handle.title;
    }

    return "";
};

export default usePageTitle;
