import { ConfirmationModal, IconButton, Icons } from "@fm-frontend/uikit";
import { FC, useState } from "react";

type RemoveActionProps = {
    title?: string;
    loading?: boolean;
    onClick?: () => void;
    onDone?: () => void;
    onCancel?: () => void;
};

const RemoveAction: FC<RemoveActionProps> = ({
    title = "Remove item",
    loading = false,
    onClick,
    onDone,
    onCancel,
}) => {
    const [confirmationShown, setConfirmationShown] = useState(false);

    const handleButtonClick = () => {
        onClick?.();
        setConfirmationShown((prev) => !prev);
    };

    const handleConfirmationDone = () => {
        setConfirmationShown(false);
        onDone?.();
    };

    const handleConfirmationClose = () => {
        setConfirmationShown(false);
        onCancel?.();
    };

    return (
        <>
            <IconButton
                title="Remove"
                variant="basic"
                loading={loading}
                Icon={Icons.Bin}
                onClick={handleButtonClick}
            />
            {confirmationShown && (
                <ConfirmationModal
                    title={title}
                    description="This action is irreversible. Are you sure?"
                    confirmButtonTitle="Continue"
                    onConfirm={handleConfirmationDone}
                    onClose={handleConfirmationClose}
                />
            )}
        </>
    );
};

export default RemoveAction;
