export const getServerName = (prodHost: string) => {
    const { host } = window.location;

    if (host === prodHost) {
        return "PROD";
    }

    if (host.includes("test.")) {
        return "TEST";
    }

    if (host.includes("preview.")) {
        return "PRVW-1";
    }

    if (host.includes("preview2.")) {
        return "PRVW-2";
    }

    if (host.includes("preview3.")) {
        return "PRVW-3";
    }

    if (host.includes("localhost")) {
        return "LCLHST";
    }

    return "UNDEF";
};
