import { P } from "@fm-frontend/uikit";
import { FC, useContext } from "react";
import MatchContains from "~components/Table/Cell/MatchContains";
import { TableContext } from "~components/Table/TableContext";

type EmailCellProps = {
    value: string;
};

export const EmailCell: FC<EmailCellProps> = ({ value }) => {
    const { query } = useContext(TableContext);

    return (
        <P title={value}>
            <MatchContains value={value} query={query} />
        </P>
    );
};
