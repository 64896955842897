import { P } from "@fm-frontend/uikit";
import React from "react";
import styled from "styled-components";

const Whitelisted = styled(P)`
    color: ${(p) => p.theme.colors.ui72};
`;
const NotWhitelisted = styled(P)`
    color: ${(p) => p.theme.colors.negative72};
`;

export const WhitelistCell: React.FC<{ isWhitelisted?: boolean }> = ({ isWhitelisted }) => {
    return isWhitelisted ? (
        <Whitelisted>Enabled</Whitelisted>
    ) : (
        <NotWhitelisted>Disabled</NotWhitelisted>
    );
};
