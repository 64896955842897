import { FC } from "react";
import { MultiSelect } from "~components/Table/MultiSelect";
import { SettlementStatus } from "~pages/Settlements/types";

export const STATUS_SELECT_ALL_VALUE = [
    SettlementStatus.Request,
    SettlementStatus.Sent,
    SettlementStatus.Received,
    SettlementStatus.Created,
    SettlementStatus.Committed,
    SettlementStatus.Cancelled,
];

export type StatusSelectValue = SettlementStatus[];

type Options = Record<
    string,
    {
        text: string;
        value: string;
    }[]
>;

const OPTIONS = {
    Active: [
        { text: "Request", value: String(SettlementStatus.Request) },
        { text: "Sent", value: String(SettlementStatus.Sent) },
        { text: "Received", value: String(SettlementStatus.Received) },
        { text: "Created", value: String(SettlementStatus.Created) },
    ],
    History: [
        { text: "Committed", value: String(SettlementStatus.Committed) },
        { text: "Cancelled", value: String(SettlementStatus.Cancelled) },
    ],
} as Options;

type StatusSelectProps = {
    value: StatusSelectValue;
    onChange: (value: StatusSelectValue) => void;
};

export const StatusSelect: FC<StatusSelectProps> = ({ value, onChange }) => {
    const handleChange = (selectedValue: string[]) => {
        if (selectedValue.length === STATUS_SELECT_ALL_VALUE.length) {
            onChange(STATUS_SELECT_ALL_VALUE);
            return;
        }

        onChange(selectedValue.map(Number));
    };

    return (
        <MultiSelect
            label="Status"
            defaultValue={value.map(String)}
            options={OPTIONS}
            onChange={handleChange}
        />
    );
};
