import { FC, useContext } from "react";
import MatchContains from "~components/Table/Cell/MatchContains";
import { TableContext } from "~components/Table/TableContext";

type NetworkCurrenciesCellProps = {
    value: string[];
};

export const NetworkCurrenciesCell: FC<NetworkCurrenciesCellProps> = ({ value }) => {
    const { query } = useContext(TableContext);

    return <MatchContains value={value.join(", ")} query={query} />;
};
