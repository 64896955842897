import { HStack, IconButton, Icons } from "@fm-frontend/uikit";
import { FC, useMemo } from "react";
import ModalButton from "~components/ModalButton";
import { useIsSubaccount } from "~hooks/useIsSubaccount";
import { EditAssetLimitForm } from "~pages/RisksManagement/components/EditAssetLimitForm";
import { Item, RowType, SubItem } from "~pages/RisksManagement/View/AssetsControl/types";

type ActionCellProps = {
    item: Item | SubItem;
};

export const ActionsCell: FC<ActionCellProps> = ({ item, children }) => {
    const isSubaccount = useIsSubaccount();

    const content = useMemo(() => {
        switch (item.type) {
            case RowType.Currency:
                return (
                    <ModalButton
                        renderModalContent={(closePopover) => (
                            <EditAssetLimitForm currency={item.title} onDone={closePopover} />
                        )}
                        renderButton={(handleClick) => (
                            <IconButton
                                disabled={isSubaccount}
                                title="Edit"
                                variant="plain"
                                Icon={Icons.Pencil}
                                onClick={handleClick}
                            />
                        )}
                    />
                );
            default:
                return null;
        }
    }, [isSubaccount, item]);

    return (
        <HStack alignItems="center" spacing={2} justifyContent="space-between">
            {children} {content}
        </HStack>
    );
};
