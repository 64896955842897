import { EMPTY_ARRAY } from "@fm-frontend/uikit/src/const";
import { useMemo } from "react";
import { ClientId } from "~entities/client";
import { useSubaccountsApi } from "~hooks/api/useSubaccountsApi";

export const useSubaccountsIds = (clientId: ClientId) => {
    const { data = EMPTY_ARRAY } = useSubaccountsApi(clientId);

    return useMemo(
        () =>
            data
                .map((item) => item.info.clientId)
                .filter((item): item is number => item !== undefined),
        [data],
    );
};
