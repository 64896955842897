import { BadgeVariant } from "@fm-frontend/uikit";
import { ReferralStatus } from "~entities/referral";
import { SubaccountStatus } from "~entities/subaccount";

export const getSubaccountStatusTitle = (value: SubaccountStatus | ReferralStatus) => {
    switch (value) {
        case SubaccountStatus.Active:
        case ReferralStatus.Accepted:
            return "Active";
        case SubaccountStatus.Pending:
        case ReferralStatus.Sent:
            return "Pending";
        case SubaccountStatus.Disabled:
            return "Disabled";
        case ReferralStatus.Expired:
            return "Expired";
        default:
            return "";
    }
};

export const getSubaccountStatusBadgeVariant = (
    value: SubaccountStatus | ReferralStatus,
): BadgeVariant => {
    switch (value) {
        case SubaccountStatus.Active:
        case ReferralStatus.Accepted:
            return "positive";
        case SubaccountStatus.Pending:
        case ReferralStatus.Sent:
            return "process";
        case SubaccountStatus.Disabled:
            return "negative";
        case ReferralStatus.Expired:
            return "negative";
        default:
            return "info";
    }
};
