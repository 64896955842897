import useAppSelector from "~hooks/useAppSelector";
import { useClientId } from "~hooks/useClientId";
import { getClient } from "~store/clients/selectors";

export const useIsSubaccount = () => {
    const getClientById = useAppSelector(getClient);
    const clientId = useClientId();
    const client = getClientById(clientId);

    return client?.isSubaccount ?? false;
};
