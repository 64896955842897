import { EMPTY_ARRAY } from "@fm-frontend/uikit/src/const";
import useSWR from "swr";
import { ClientId } from "~entities/client";
import { InstrumentName } from "~entities/instrument";
import {
    RFQDealHistoryItem,
    RFQDealHistoryItemRaw,
    RFQDealHistoryStatus,
} from "~entities/rfqDealHistoryItem";
import { requestAdminery } from "~utils/api";

export enum RFQDealHistoryFilter {
    External = "EXTERNAL",
    Subaccounts = "SUBACCOUNTS",
}

type RequestParams = {
    clientId: ClientId;
    // filter should be set up for master account
    filter?: RFQDealHistoryFilter;
    counterpartyIds: ClientId[];
    instruments: InstrumentName[];
    statuses?: RFQDealHistoryStatus[];
    from?: number;
    to?: number;
    pageSize?: number;
    pageNumber?: number;
};

type Response = {
    content: RFQDealHistoryItemRaw[];
    pageable: {
        pageNumber: number;
        pageSize: number;
        sort: {
            empty: boolean;
            sorted: boolean;
            unsorted: boolean;
        };
        offset: 0;
        unpaged: boolean;
        paged: boolean;
    };
    last: boolean;
    totalElements: number;
    totalPages: number;
    size: number;
    number: number;
    sort: {
        empty: boolean;
        sorted: boolean;
        unsorted: boolean;
    };
    first: boolean;
    numberOfElements: number;
    empty: boolean;
};

const prepareRfqHistoryItem = (apiItem: RFQDealHistoryItemRaw): RFQDealHistoryItem => ({
    ...apiItem,
    date: apiItem.date * 1000,
    price: BigInt(apiItem.price ?? 0),
    size: BigInt(apiItem.size),
    clientDelta: BigInt(apiItem.clientDelta ?? 0),
});

type SWRKey = { useSWRName?: string } & RequestParams;

export const rfqDealHistoryFetcher = ({ useSWRName, ...params }: SWRKey): Promise<Response> =>
    requestAdminery("rfq/getSessions", params);

export const useRFQDealHistoryApi = (params: RequestParams) => {
    const { data, isLoading, error, mutate } = useSWR<Response, unknown, SWRKey>(
        { useSWRName: "rfq/getSessions", ...params },
        rfqDealHistoryFetcher,
    );

    return {
        data: (data?.content ?? (EMPTY_ARRAY as RFQDealHistoryItem[])).map(prepareRfqHistoryItem),
        totalElements: data?.totalElements,
        totalPages: data?.totalPages,
        mutate,
        isLoading,
        error,
    };
};
