import { ReactNode } from "react";

export enum RowType {
    Info,
    Currency,
}

export type SubItem = {
    type: RowType;
    title: string;
    clientValue: ReactNode;
    counterpartyValue: ReactNode;
    items?: never;
};

export type Item = {
    type: RowType;
    title: string;
    clientValue?: ReactNode;
    counterpartyValue?: ReactNode;
    items?: SubItem[];
};
