import { ReactElement } from "react";
import { PartnerValueType } from "~components/PartnerSelect";
import { ClientId } from "~entities/client";

export type CounterpartiesListPageProps = {
    onChangePartnerType: (partnerType: PartnerValueType) => void;
};

export enum RowType {
    Info,
    GlobalGross,
    Counterparty,
    GlobalInstrumentsWhitelist,
}

export enum GlobalGrossType {
    Default,
    External,
    Internal,
}

export type SubItem = {
    type: RowType;
    title: string;
    id?: never;
    value: ReactElement | string;
    items?: never;
    globalGrossType?: never;
    clientType?: string;
};

export type Item = {
    type: RowType;
    title: string;
    id?: ClientId;
    value?: ReactElement | string;
    items?: SubItem[];
    globalGrossType?: GlobalGrossType;
    clientType?: string;
};
