import { BasicButton } from "@fm-frontend/uikit";
import { FC } from "react";
import ModalButton from "~components/ModalButton";
import { EditInstrumentMarkupForm } from "~pages/RisksManagement/components/EditInstrumentMarkupForm";

type EditInstrumentMarkupActionProps = {
    instrument: string;
};

export const EditInstrumentMarkupAction: FC<EditInstrumentMarkupActionProps> = ({ instrument }) => (
    <ModalButton
        renderModalContent={(closePopover) => (
            <EditInstrumentMarkupForm instrument={instrument} onDone={closePopover} />
        )}
        renderButton={(handleClick) => (
            <BasicButton size="small" onClick={handleClick}>
                Edit markups
            </BasicButton>
        )}
    />
);
