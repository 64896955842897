import { ClientId, CounterpartyIcon, DropdownOption, HStack, P, PSmall } from "@fm-frontend/uikit";
import { FC } from "react";
import styled from "styled-components";

const Placeholder = styled(P)`
    color: ${(p) => p.theme.colors.ui52};
`;
const Container = styled(HStack)`
    align-items: center;
    gap: 6px;
    overflow: hidden;
`;
type CounterpartyDropdownOption = DropdownOption<string | number>;
type TriggerEssenceProps = {
    option: CounterpartyDropdownOption | CounterpartyDropdownOption[] | undefined;
    size: "large" | "medium" | "small";
    placeholder?: string;
    groupingOption?: boolean;
    noIcon?: boolean;
};
export const TriggerEssence: FC<TriggerEssenceProps> = ({
    option,
    size,
    placeholder,
    groupingOption,
    noIcon,
}) => {
    const firstOption = Array.isArray(option) ? option[0] : option;

    const TextElement = size === "large" ? P : PSmall;

    if (!firstOption) {
        return (
            <Container>
                <Placeholder as={TextElement} ellipsis>
                    {placeholder}
                </Placeholder>
            </Container>
        );
    }

    const rest = Array.isArray(option) ? option.length - 1 : 0;

    if (groupingOption) {
        return (
            <Container>
                <TextElement title={firstOption.text} ellipsis>
                    {firstOption.text}
                </TextElement>
            </Container>
        );
    }

    if (noIcon) {
        return (
            <Container>
                <TextElement title={firstOption.text} ellipsis>
                    {firstOption.text}
                </TextElement>
                <ClientId id={firstOption.value} tooltip={null} />
                {rest > 0 && <TextElement>+{rest}</TextElement>}
            </Container>
        );
    }

    return (
        <Container>
            <CounterpartyIcon cpId={Number(firstOption.value)} size={16} />
            <TextElement title={firstOption.text} ellipsis>
                {firstOption.text}
            </TextElement>
            <ClientId id={firstOption.value} tooltip={null} />
            {rest > 0 && <TextElement>+{rest}</TextElement>}
        </Container>
    );
};
