import { EMPTY_ARRAY } from "@fm-frontend/uikit/src/const";
import { useMemo } from "react";
import { useInstrumentsApi } from "~hooks/api/useInstrumentsApi";

export type UsdPrices = Record<string, bigint>;

export const useUsdPrices = () => {
    const { data, isLoading } = useInstrumentsApi();
    const { currencies = EMPTY_ARRAY } = data ?? {};

    const prices = useMemo<UsdPrices>(
        () =>
            Object.fromEntries(
                currencies.map((currency) => [currency.name, BigInt(currency.price)]),
            ),
        [currencies],
    );

    return { prices, isLoading };
};
