import { Modal, MODAL_TOP_OFFSET } from "@fm-frontend/uikit";
import { useModalControl } from "@fm-frontend/utils";
import { FC, ReactNode, SyntheticEvent } from "react";
import styled from "styled-components";

const ModalContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    min-height: 20px;
    max-height: calc(100% - ${MODAL_TOP_OFFSET * 2}px);
    overflow-y: auto;
    flex-direction: column;
    background: ${(p) => p.theme.colors.uiWhite100};
    box-shadow: 0 4px 16px 0 ${(p) => p.theme.colors.ui8}, 0 0 0 1px ${(p) => p.theme.colors.ui12};
    border-radius: 8px;
    width: 380px;
`;

export const FIXED_MODAL_STYLE = {
    overlay: {
        overflow: "auto",
    },
    content: {
        top: `${MODAL_TOP_OFFSET}px`,
        left: "50%",
        transform: "translate(-50%)",
        backgroundColor: "transparent",
        height: "100%",
    },
};

type ModalButtonProps = {
    renderModalContent: (closeModal: () => void) => ReactNode;
    renderButton: (handleClick: (e: SyntheticEvent) => void) => JSX.Element;
};

const ModalButton: FC<ModalButtonProps> = ({ renderModalContent, renderButton }) => {
    const { isModalOpen, openModal, closeModal } = useModalControl(false);
    const handleOpenModal = (e?: SyntheticEvent) => {
        e?.stopPropagation();
        openModal();
    };

    return (
        <>
            <Modal isOpen={isModalOpen} onClose={closeModal} style={FIXED_MODAL_STYLE}>
                <ModalContainer>{renderModalContent(closeModal)}</ModalContainer>
            </Modal>
            {renderButton(handleOpenModal)}
        </>
    );
};

export default ModalButton;
