import { useMemo } from "react";
import { ClientId } from "~entities/client";
import { useClientsApi } from "~hooks/api/useClientsApi";
import { useCounterpartyLimitsApi } from "~hooks/api/useCounterpartyLimitsApi";
import useAppSelector from "~hooks/useAppSelector";
import { useUsdPrices } from "~hooks/useUsdPrices";
import { shouldUseUserGrossLimit } from "~pages/RisksManagement/List/utils";
import {
    CounterpartyStatusType,
    getCounterpartyGrossFreeStatus,
    getCounterpartyStatus,
    getEquityStatus,
} from "~pages/RisksManagement/utils/status";
import { getClient } from "~store/clients/selectors";

export const useLimitStatuses = (clientId: ClientId) => {
    useClientsApi();

    const getClientById = useAppSelector(getClient);
    const { data: counterpartyLimits, isLoading: isCounterpartyLimitsLoading } =
        useCounterpartyLimitsApi(clientId);
    const { prices, isLoading: isPricesLoading } = useUsdPrices();
    const isLoading = isCounterpartyLimitsLoading || isPricesLoading;

    const data = useMemo(
        () =>
            counterpartyLimits?.reduce<Record<ClientId, CounterpartyStatusType>>(
                (acc, counterpartyLimit) => {
                    const { counterpartyId } = counterpartyLimit;
                    const counterparty = getClientById(counterpartyId);
                    const client = getClientById(clientId);
                    const useUserGrossLimit = shouldUseUserGrossLimit(
                        counterparty?.type,
                        client?.type,
                    );

                    const counterpartyGrossFreeStatus = getCounterpartyGrossFreeStatus(
                        counterpartyLimit,
                        prices,
                    );
                    const equityStatus = getEquityStatus(
                        counterpartyLimit,
                        prices,
                        useUserGrossLimit,
                    );
                    const status = getCounterpartyStatus(
                        counterpartyLimit,
                        counterpartyGrossFreeStatus,
                        equityStatus,
                    );

                    acc[counterpartyLimit.counterpartyId] = status;

                    return acc;
                },
                {},
            ),
        [clientId, counterpartyLimits, getClientById, prices],
    );

    return { data, isLoading };
};
