import { P } from "@fm-frontend/uikit";
import { FC, useContext } from "react";
import MatchContains from "~components/Table/Cell/MatchContains";
import { TableContext } from "~components/Table/TableContext";
import { ClientMetadata } from "~entities/client";

type ClientTagsProps = {
    value: ClientMetadata | undefined;
};

const ClientTags: FC<ClientTagsProps> = ({ value }) => {
    const { query } = useContext(TableContext);
    const tags = value?.tags ?? [];

    if (tags.length === 0) {
        return null;
    }

    return (
        <P title={tags.join(", ")}>
            {tags.map((tag, index) => [
                index !== 0 ? ", " : null,
                // eslint-disable-next-line react/no-array-index-key
                <MatchContains key={index} value={tag} query={query} />,
            ])}
        </P>
    );
};

export default ClientTags;
