import usePageHandle from "~hooks/usePageHandle";

const usePageLayout = () => {
    const handle = usePageHandle();

    if ("layout" in handle) {
        return handle.layout;
    }

    return {};
};

export default usePageLayout;
