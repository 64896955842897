import { ClientType } from "~entities/client";

export const getClientTypeTitle = (clientType: ClientType) => {
    switch (clientType) {
        case ClientType.Maker:
            return "Maker";
        case ClientType.Taker:
            return "Taker";
        case ClientType.PrimeBroker:
            return "Master";
        case ClientType.SubaccountMaker:
            return "Sub-account Maker";
        case ClientType.SubaccountTaker:
            return "Sub-account Taker";
        default:
            return "";
    }
};
