import { Auth } from "aws-amplify";

export const getToken = async () => {
    const currentSession = await Auth.currentAuthenticatedUser();
    const token = JSON.stringify({
        token: currentSession?.signInUserSession?.accessToken?.jwtToken,
    });
    const isAuthed = Boolean(currentSession?.signInUserSession?.accessToken?.jwtToken);

    return { token, isAuthed };
};
