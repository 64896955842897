import { FormAttention, Icons, P } from "@fm-frontend/uikit";
import { FC } from "react";
import styled, { useTheme } from "styled-components";

const FormErrorContainer = styled(FormAttention)`
    color: ${(p) => p.theme.colors.negative80};
`;

type FormErrorProps = {
    message: string;
};

const FormError: FC<FormErrorProps> = ({ message }) => {
    const { colors } = useTheme();

    return (
        <FormErrorContainer>
            <Icons.Attention color={colors.negative80} />
            <P>{message}</P>
        </FormErrorContainer>
    );
};

export default FormError;
