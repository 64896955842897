import { HStack, P, PSmall } from "@fm-frontend/uikit";
import { DropdownOption } from "@fm-frontend/uikit/src/components/v2/Dropdown";
import styled from "styled-components";
import { InstrumentIcon } from "../InstrumentIcon";

const Placeholder = styled(P)`
    color: ${(p) => p.theme.colors.ui52};
`;
const Container = styled(HStack)`
    align-items: center;
    gap: 6px;
    overflow: hidden;
`;

type TriggerEssenceProps<T> = {
    option: DropdownOption<T> | DropdownOption<T>[] | undefined;
    size: "large" | "small";
    placeholder?: string;
    noIcon?: boolean;
};

export const TriggerEssence = <T,>({
    option,
    size,
    placeholder,
    noIcon,
}: TriggerEssenceProps<T>) => {
    const firstOption = Array.isArray(option) ? option[0] : option;

    const TextElement = size === "large" ? P : PSmall;

    if (!firstOption) {
        return (
            <Container>
                <Placeholder as={TextElement} ellipsis>
                    {placeholder}
                </Placeholder>
            </Container>
        );
    }
    const rest = Array.isArray(option) ? option.length - 1 : 0;

    if (noIcon) {
        return (
            <Container>
                <TextElement ellipsis>{firstOption.text}</TextElement>
                {rest > 0 && <TextElement>+{rest}</TextElement>}
            </Container>
        );
    }

    return (
        <Container>
            <InstrumentIcon instrument={firstOption.text} size={16} />
            <TextElement ellipsis>{firstOption.text}</TextElement>
            {rest > 0 && <TextElement>+{rest}</TextElement>}
        </Container>
    );
};
