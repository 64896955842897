import { PBold } from "@fm-frontend/uikit";
import { FC, useContext } from "react";
import MatchContains from "~components/Table/Cell/MatchContains";
import { TableContext } from "~components/Table/TableContext";

type InstrumentCellProps = {
    value: string;
};

const InstrumentCell: FC<InstrumentCellProps> = ({ value }) => {
    const { query } = useContext(TableContext);

    return (
        <PBold>
            <MatchContains value={value} query={query} />
        </PBold>
    );
};

export default InstrumentCell;
