import { InfiniteTable as UIKitTable } from "@fm-frontend/uikit";
import { EMPTY_ARRAY } from "@fm-frontend/uikit/src/const";
import { ValueFormat } from "@fm-frontend/utils";
import {
    createColumnHelper,
    getCoreRowModel,
    getSortedRowModel,
    SortingState,
} from "@tanstack/react-table";
import { format } from "date-fns";
import { FC, useMemo, useState } from "react";
import styled from "styled-components";
import Gap from "~components/Gap";
import {
    PartnerSelect,
    PartnerSelectType,
    PartnerValueType,
    PARTNER_SELECT_ALL_VALUE,
} from "~components/PartnerSelect";
import DateTimeViewer from "~components/Table/Cell/DateTimeViewer";
import { EllipsisCellTheme } from "~components/Table/CellTheme/Ellipsis";
import OptionsContainer from "~components/Table/Options/Container";
import ExportButton from "~components/Table/Options/ExportButton";
import Search from "~components/Table/Options/Search";
import { TableContext, useTableContextValue } from "~components/Table/TableContext";
import { DATE_TIME_FORMAT } from "~constants/date";
import { ClientId } from "~entities/client";
import { OrderId } from "~entities/order";
import { useClientsApi } from "~hooks/api/useClientsApi";
import { useSettlementCorrectionsApi } from "~hooks/api/useSettlementCorrectionsApi";
import { useSettlementHistoryApi } from "~hooks/api/useSettlementHistoryApi";
import useAppSelector from "~hooks/useAppSelector";
import { useClientId } from "~hooks/useClientId";
import { useIsMaster } from "~hooks/useIsMaster";
import { useSubaccountsIds } from "~hooks/useSubaccountsIds";
import { TabList } from "~pages/History/TabList";
import { CommentCell } from "~pages/History/Technical/CommentCell";
import { CounterpartyCell } from "~pages/History/Technical/CounterpartyCell";
import CurrencyCell from "~pages/History/Technical/CurrencyCell";
import { SideCell } from "~pages/History/Technical/SideCell";
import { VolumeCell } from "~pages/History/Technical/VolumeCell";
import { getClient } from "~store/clients/selectors";
import { sortTimestamp } from "~utils/sortTimestamp";
import { CreateSettlementButton } from "./CreateSettlementButton";
import { ManualOrderActions } from "./ManualOrderActions";
import { OrderIdCell } from "./OrderIdCell";

type RowData = {
    id: OrderId;
    cpId: ClientId;
    side: "in" | "out";
    asset: string;
    size: number | bigint;
    moment: number;
    comment: string;
    isManualOrder?: boolean;
};

export const Table = styled(UIKitTable<RowData>)`
    min-width: 1010px;

    th:first-of-type,
    td:first-of-type {
        padding-left: 12px !important;
    }
`;

const columnHelper = createColumnHelper<RowData>();

const technicalHistoryTableColumns = [
    columnHelper.accessor("id", {
        header: "Order ID",
        cell: (info) => <OrderIdCell value={info.getValue()} />,
        meta: {
            headerStyleProps: {
                width: "110px",
            },
        },
    }),
    columnHelper.accessor("moment", {
        header: "Moment",
        sortingFn: sortTimestamp,
        cell: (info) => <DateTimeViewer value={info.getValue() / 1000} />,
        meta: {
            cellStyleProps: {
                width: "106px",
            },
        },
    }),
    columnHelper.accessor("side", {
        id: "side",
        header: "Side",
        cell: (info) => <SideCell value={info.getValue()} />,
        meta: {
            cellStyleProps: {
                width: "52px",
            },
        },
    }),
    columnHelper.accessor("cpId", {
        id: "counterpartyName",
        header: "Counterparty",
        sortDescFirst: false,
        // TODO: Find a way to type custom sorting functions
        // @ts-ignore
        sortingFn: "usernameSorting",
        cell: (info) => (
            <EllipsisCellTheme maxWidth={200}>
                <CounterpartyCell value={info.getValue()} />
            </EllipsisCellTheme>
        ),
        meta: {
            cellStyleProps: {
                width: "153px",
            },
        },
    }),
    columnHelper.accessor("asset", {
        header: "Currency",
        cell: (info) => <CurrencyCell value={info.getValue()} />,
        meta: {
            cellStyleProps: {
                width: "91px",
            },
        },
    }),
    columnHelper.accessor("size", {
        header: "Volume",
        cell: (info) => <VolumeCell value={info.getValue()} />,
        meta: {
            cellStyleProps: {
                width: "160px",
            },
        },
    }),
    columnHelper.accessor("comment", {
        header: "Comment",
        cell: (info) => <CommentCell value={info.getValue()} />,
    }),
];

const isMatch = ({
    id,
    cpId,
    username,
    query,
}: {
    id: OrderId;
    cpId: ClientId;
    username: string;
    query: string;
}) => {
    const normalizedQuery = query.toUpperCase();

    return (
        String(id).startsWith(normalizedQuery) ||
        String(cpId).startsWith(normalizedQuery) ||
        username.toUpperCase().includes(normalizedQuery)
    );
};

export const OvernightsHistory: FC = () => {
    useClientsApi();
    const clientId = useClientId();
    const isMaster = useIsMaster();
    const subaccountsIds = useSubaccountsIds(clientId);
    const {
        data: settlementHistoryData = EMPTY_ARRAY,
        isLoading: isSettlementHistoryLoading,
        mutate: refetchTechnicalHistory,
    } = useSettlementHistoryApi(clientId, true);
    const {
        data: manualOrdersData = EMPTY_ARRAY,
        isLoading: isManualOrdersLoading,
        mutate: refetchManualOrders,
    } = useSettlementCorrectionsApi(clientId);
    const [sorting, setSorting] = useState<SortingState>([]);
    const [partnerType, setPartnerType] = useState<PartnerValueType>(PARTNER_SELECT_ALL_VALUE);
    const tableContextValue = useTableContextValue();
    const { query, setQuery } = tableContextValue;
    const getClientById = useAppSelector(getClient);

    const technicalTransactions: RowData[] = useMemo(() => {
        const getFilteredOvernights = () => {
            if (query === "" && partnerType === PARTNER_SELECT_ALL_VALUE) {
                return settlementHistoryData;
            }

            return settlementHistoryData.filter((item) => {
                const isSubaccount = subaccountsIds.includes(item.counterpartyId);

                if (partnerType === PartnerSelectType.Counterparties && isSubaccount) {
                    return false;
                }
                if (partnerType === PartnerSelectType.SubAccounts && !isSubaccount) {
                    return false;
                }

                const counterparty = getClientById(item.counterpartyId);
                const username = counterparty?.username ?? "";

                return isMatch({ id: item.orderId, cpId: item.counterpartyId, username, query });
            });
        };

        return getFilteredOvernights().map(
            ({ counterpartyId, size1, currency1, settlementMoment, comment, orderId }) => ({
                id: orderId,
                cpId: counterpartyId,
                size: size1,
                asset: currency1,
                side: size1 < 0n ? "in" : "out",
                comment,
                moment: settlementMoment,
            }),
        );
    }, [query, partnerType, settlementHistoryData, subaccountsIds, getClientById]);
    const manualOrders: RowData[] = useMemo(() => {
        const getFilteredManualOrders = () => {
            if (query === "" && partnerType === PARTNER_SELECT_ALL_VALUE) {
                return manualOrdersData;
            }

            return manualOrdersData.filter((item) => {
                const cpId = item.clientA === clientId ? item.clientB : item.clientA;
                const isSubaccount = subaccountsIds.includes(cpId);

                if (partnerType === PartnerSelectType.Counterparties && isSubaccount) {
                    return false;
                }
                if (partnerType === PartnerSelectType.SubAccounts && !isSubaccount) {
                    return false;
                }

                const counterparty = getClientById(cpId);
                const username = counterparty?.username ?? "";

                return isMatch({ id: item.settlementOrderId, cpId, username, query });
            });
        };

        return getFilteredManualOrders().map((manualOrder) => {
            const cpId =
                manualOrder.clientA === clientId ? manualOrder.clientB : manualOrder.clientA;
            const side = manualOrder.clientA === cpId ? "in" : "out";

            return {
                id: manualOrder.settlementOrderId,
                cpId,
                size: manualOrder.size1,
                asset: manualOrder.currency1,
                side,
                comment: manualOrder.comment,
                moment: manualOrder.createdAt,
                isManualOrder: true,
            };
        });
    }, [query, partnerType, manualOrdersData, subaccountsIds, getClientById, clientId]);

    const tableData = useMemo(
        () =>
            [...technicalTransactions, ...manualOrders].sort(
                ({ moment: moment1 }, { moment: moment2 }) => moment2 - moment1,
            ),
        [technicalTransactions, manualOrders],
    );

    const columns = useMemo(
        () => [
            ...technicalHistoryTableColumns,
            columnHelper.display({
                id: "actions",
                /* eslint-disable react/no-unstable-nested-components */
                cell: (info) =>
                    info.row.original.isManualOrder ? (
                        <ManualOrderActions
                            onUpdate={() => {
                                refetchManualOrders();
                                refetchTechnicalHistory();
                            }}
                            settlementOrderId={info.row.original.id}
                        />
                    ) : null,
                enableSorting: false,
                meta: {
                    headerStyleProps: {
                        width: "50px",
                    },
                },
            }),
        ],
        [refetchManualOrders, refetchTechnicalHistory],
    );

    const getExportData = () =>
        technicalTransactions.map((overnight) => ({
            "Client ID": overnight.cpId,
            Counterparty: getClientById(overnight.cpId)?.username,
            Side: overnight.side === "in" ? "In" : "Out",
            Currency: overnight.asset,
            Volume: ValueFormat.size(overnight.size < 0n ? -overnight.size : overnight.size),
            Moment: format(overnight.moment, DATE_TIME_FORMAT),
            Comment: overnight.comment,
        }));

    const isLoading = isSettlementHistoryLoading || isManualOrdersLoading;

    return (
        <TableContext.Provider value={tableContextValue}>
            <OptionsContainer>
                <Search query={query} onChange={setQuery} />
                <TabList />
                {isMaster && (
                    <PartnerSelect allTypes value={partnerType} onChange={setPartnerType} />
                )}
                <Gap />
                <ExportButton
                    data={getExportData}
                    filename={`overnights_history_${clientId}`}
                    loading={isLoading}
                    disabled={technicalTransactions.length === 0}
                />
                <CreateSettlementButton onCreated={refetchManualOrders} />
            </OptionsContainer>
            <Table
                tableOptions={{
                    data: tableData,
                    columns,
                    state: {
                        sorting,
                    },
                    sortingFns: {
                        usernameSorting: (rowA, rowB, columnId) => {
                            const client1 = getClientById(rowA.getValue(columnId));
                            const client2 = getClientById(rowB.getValue(columnId));

                            if (
                                client1?.username === undefined ||
                                client2?.username === undefined
                            ) {
                                return 0;
                            }

                            return client1.username.localeCompare(client2.username);
                        },
                    },
                    onSortingChange: setSorting,
                    getCoreRowModel: getCoreRowModel(),
                    getSortedRowModel: getSortedRowModel(),
                }}
                isLoading={isLoading}
            />
        </TableContext.Provider>
    );
};
