import { FC, useContext } from "react";
import styled from "styled-components";
import MatchContains from "~components/Table/Cell/MatchContains";
import { TableContext } from "~components/Table/TableContext";

const Container = styled.span`
    font-weight: 600;
`;

type InstrumentNameProps = {
    value: string;
};

const InstrumentName: FC<InstrumentNameProps> = ({ value }) => {
    const { query } = useContext(TableContext);

    return (
        <Container>
            <MatchContains value={value} query={query} />
        </Container>
    );
};

export default InstrumentName;
