import { AssetLimit } from "~entities/assetLimit";
import { ClientId } from "~entities/client";
import { OvernightFee } from "~entities/overnightFee";
import { ShortSalesBan } from "~entities/shortSalesBan";
import { useAssetLimits } from "~hooks/api/useAssetLimits";
import { useOvernightFees } from "~hooks/api/useOvernightFees";
import { useShortSalesBan } from "~hooks/api/useShortSalesBan";

export const useRiskManagementAssets = (clientId: ClientId, counterpartyId: ClientId) => {
    const { data: clientAssetLimitsData, isLoading: isClientAssetLimitsLoading } =
        useAssetLimits(clientId);
    const { data: counterpartyAssetLimitsData, isLoading: isCounterpartyAssetLimitsLoading } =
        useAssetLimits(counterpartyId);
    const { data: overnightFeesData, isLoading: isOvernightFeesLoading } =
        useOvernightFees(clientId);
    const { data: shortSalesBanData, isLoading: isShortSalesBanLoading } =
        useShortSalesBan(clientId);

    const clientAssetLimits = clientAssetLimitsData?.reduce<Record<string, AssetLimit>>(
        (acc, item) => {
            if (item.counterpartyId === counterpartyId) {
                acc[item.currencyName] = item;
            }
            return acc;
        },
        {},
    );
    const counterpartyAssetLimits = counterpartyAssetLimitsData?.reduce<Record<string, AssetLimit>>(
        (acc, item) => {
            if (item.counterpartyId === clientId) {
                acc[item.currencyName] = item;
            }
            return acc;
        },
        {},
    );
    const overnightFees = overnightFeesData?.reduce<Record<string, OvernightFee>>((acc, item) => {
        if (item.counterparty === counterpartyId) {
            acc[item.currency] = item;
        }
        return acc;
    }, {});
    const shortSalesBan = shortSalesBanData?.reduce<Record<string, ShortSalesBan>>((acc, item) => {
        if (item.counterpartyId === counterpartyId) {
            acc[item.currencyName] = item;
        }
        return acc;
    }, {});

    const data = {
        clientAssetLimits,
        counterpartyAssetLimits,
        overnightFees,
        shortSalesBan,
    };

    const isLoading =
        isClientAssetLimitsLoading ||
        isCounterpartyAssetLimitsLoading ||
        isOvernightFeesLoading ||
        isShortSalesBanLoading;

    return { data, isLoading };
};
