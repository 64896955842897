import { ReactElement, useEffect } from "react";
import {
    createBrowserRouter,
    Navigate,
    RouterProvider,
    useLocation,
    useNavigate,
} from "react-router-dom";
import Layout from "~components/Layout";
import { ClientId } from "~entities/client";
import { InstrumentName } from "~entities/instrument";
import useAppSelector from "~hooks/useAppSelector";
import { AssetsAndInstrumentsPage } from "~pages/AssetsAndInstruments";
import { AuthPage } from "~pages/Auth";
import { HistoryPage } from "~pages/History";
import { MyAdminKeysPage } from "~pages/MyAdminKeys";
import { OpenPositionsPage } from "~pages/OpenPositions";
import { OrderBookPage } from "~pages/OrderBook";
import { OrdersPage } from "~pages/Orders";
import { OverviewPage } from "~pages/Overview";
import { ProfilePage } from "~pages/Profile";
import { ReferralsPage } from "~pages/Referrals";
import { RisksManagementListPage } from "~pages/RisksManagement/List";
import { RisksManagementViewPage } from "~pages/RisksManagement/View";
import { SettlementsPage } from "~pages/Settlements";
import { SubaccountsPage } from "~pages/Subaccounts";
import { UsersAndRolesPage } from "~pages/UsersAndRoles";
import { isAuthorizationError, isNotAuthorized } from "~store/user/selectors";

export type RouteHandle = {
    title?: string;
    layout?: { nakedContent?: boolean };
};

export const getAuthUrl = () => "/auth";
export const getAllClientsUrl = () => "/all";
export const getClientUrl = (clientId: ClientId) => `/${clientId}`;
export const getOrderBookUrl = (instrumentName: InstrumentName, clientId?: ClientId) => {
    if (clientId) {
        return `/${clientId}/order-book/${instrumentName}`;
    }

    return `/all/order-book/${instrumentName}`;
};
export const getRiskManagementListUrl = (clientId: ClientId) => `/${clientId}/risk-management`;
export const getRiskManagementViewUrl = (clientId: ClientId, counterpartyId: ClientId) =>
    `/${clientId}/risk-management/${counterpartyId}`;

const RequireAuth = ({ children }: { children: ReactElement }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const notAuthorized = useAppSelector(isNotAuthorized);
    const authorizationError = useAppSelector(isAuthorizationError);

    useEffect(() => {
        if (notAuthorized) {
            navigate(getAuthUrl(), { replace: true });
        }
    }, [notAuthorized, navigate]);

    useEffect(() => {
        if (authorizationError) {
            navigate(getAuthUrl(), { replace: true, state: { from: location } });
        }
    }, [authorizationError, location, navigate]);

    return children;
};

const router = createBrowserRouter([
    {
        path: "/auth",
        element: <AuthPage />,
    },
    {
        index: true,
        element: <Navigate to="/all" replace />,
    },
    {
        path: "/all",
        element: (
            <RequireAuth>
                <Layout />
            </RequireAuth>
        ),
        children: [
            {
                index: true,
                element: <Navigate to="overview" replace />,
            },
            {
                path: "overview",
                handle: { title: "Clients overview" } as RouteHandle,
                element: <OverviewPage />,
            },
            {
                path: "order-book/:instrument?",
                handle: {
                    title: "Global order book",
                    layout: { nakedContent: true },
                } as RouteHandle,
                element: <OrderBookPage />,
            },
            {
                path: "assets-and-instruments",
                handle: { title: "Assets and Instruments" } as RouteHandle,
                element: <AssetsAndInstrumentsPage />,
            },
            {
                path: "my-admin-keys",
                handle: { title: "My admin keys" } as RouteHandle,
                element: <MyAdminKeysPage />,
            },
        ],
    },
    {
        path: "/:clientId",
        element: (
            <RequireAuth>
                <Layout />
            </RequireAuth>
        ),
        children: [
            {
                index: true,
                element: <Navigate to="profile" replace />,
            },
            {
                path: "overview",
                handle: { title: "Clients overview" } as RouteHandle,
                element: <OverviewPage />,
            },
            {
                path: "order-book/:instrument?",
                handle: {
                    title: "Global order book",
                    layout: { nakedContent: true },
                } as RouteHandle,
                element: <OrderBookPage />,
            },
            {
                path: "assets-and-instruments",
                handle: { title: "Assets and Instruments" } as RouteHandle,
                element: <AssetsAndInstrumentsPage />,
            },
            {
                path: "my-admin-keys",
                handle: { title: "My admin keys" } as RouteHandle,
                element: <MyAdminKeysPage />,
            },
            {
                path: "profile",
                handle: { title: "Client overview" } as RouteHandle,
                element: <ProfilePage />,
            },
            {
                path: "users-and-roles",
                handle: { title: "Users and Roles" } as RouteHandle,
                element: <UsersAndRolesPage />,
            },
            {
                path: "sub-accounts",
                handle: { title: "Sub accounts" } as RouteHandle,
                element: <SubaccountsPage />,
            },
            {
                path: "referrals",
                handle: { title: "Referrals" } as RouteHandle,
                element: <ReferralsPage />,
            },
            {
                path: "open-positions",
                handle: { title: "Open positions" } as RouteHandle,
                element: <OpenPositionsPage />,
            },
            {
                path: "orders",
                handle: { title: "Orders" } as RouteHandle,
                element: <OrdersPage />,
            },
            {
                path: "risk-management",
                handle: { title: "Risk management", layout: { nakedContent: true } } as RouteHandle,
                element: <RisksManagementListPage />,
            },
            {
                path: "risk-management/:counterpartyId",
                handle: { title: "Risk management" } as RouteHandle,
                element: <RisksManagementViewPage />,
            },
            {
                path: "settlements",
                handle: { title: "Settlements" } as RouteHandle,
                element: <SettlementsPage />,
            },
            {
                path: "history",
                handle: { title: "History" } as RouteHandle,
                element: <HistoryPage />,
            },
        ],
    },
]);

const Router = () => <RouterProvider router={router} />;

export default Router;
