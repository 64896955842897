import { ClientId } from "~entities/client";

export enum InviteStatus {
    LpChoosing = "LP choosing",
    LpVerification = "LP verification",
    Joined = "Joined",
}

export type OnboardingStatus = {
    taker_id: ClientId;
    status: InviteStatus | null;
};
