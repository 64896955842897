import { Search as UIKitSearch } from "@fm-frontend/uikit";
import { FC } from "react";
import styled from "styled-components";

const Search = styled(UIKitSearch)`
    width: 200px;
`;

type TableSearchProps = {
    query: string;
    onChange: (value: string) => void;
};

const TableSearch: FC<TableSearchProps> = ({ query, onChange }) => (
    <Search query={query} onChange={onChange} size="small" />
);

export default TableSearch;
