import { ClientId } from "~entities/client";
import { Order } from "~entities/order";

export type DealId = number;

export enum DealAggressorSide {
    Bid,
    Ask,
}

export enum DealTradeType {
    Regular,
    Authorized,
    Liquidation,
}

export type DealHistoryItem = Order & {
    dealMoment: number;
    dealId: DealId;
    dealAggressorSide: DealAggressorSide;
    dealPrice: number | bigint;
    dealSize: number | bigint;
    dealVolume: number | bigint;
    dealDelta: number | bigint;
    counterpartyId: ClientId;
    counterpartySubaccountId: ClientId;
    linkedDealId: DealId | undefined; // available only for master accounts
    tradeType: DealTradeType;
};
