export type FeedId = string | number;

export enum Feed {
    Auth = "X",
    Clients = "U",
    Assets = "A",
    Currencies = "C",
    OrderBooks = "B",
    Orders = "O",
    Positions = "P",
    SettlementOrders = "S",
}

export enum MessageType {
    Snapshot = "S",
    Unsubscribed = "U",
    Execute = "D",
    Add = "+",
    Modify = "M",
    Remove = "-",
    NewTopLevel = "~",
    Error = "Z",
}

// TODO: Find a way to type the content of the API response.
//       Maybe we should use `never` or `unknown` and define correct type on feed hook
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type MessageContent = any;
