import { FC } from "react";
import { DisabledVisibility } from "~components/Table/Options/DisabledSelect/types";
import Select from "~components/Table/Select";

type Option = {
    text: string;
    value: DisabledVisibility;
};

const OPTIONS = [
    { text: "Hide", value: DisabledVisibility.hide },
    { text: "Show", value: DisabledVisibility.show },
] as Option[];

type DisabledSelectProps = {
    value: DisabledVisibility;
    onChange: (value: DisabledVisibility) => void;
};

const DisabledSelect: FC<DisabledSelectProps> = ({ value, onChange }) => {
    return (
        <Select<DisabledVisibility>
            label="Disabled"
            defaultValue={value}
            options={OPTIONS}
            onChange={onChange}
        />
    );
};

export default DisabledSelect;
