import { DropdownOption, MultipleDropdown } from "@fm-frontend/uikit/src/components/v2";
import { EMPTY_ARRAY } from "@fm-frontend/uikit/src/const";
import { useMemo } from "react";
import { Control, Controller, FieldValues, Path } from "react-hook-form";
import useAppSelector from "~hooks/useAppSelector";
import { getClient } from "~store/clients/selectors";
import { Sheet } from "./Sheet";
import { TriggerEssence } from "./TriggerEssence";

type CounterpartyDropdownSelectorProps<T extends FieldValues> = {
    control: Control<T>;
    name: Path<T>;
    counterparties?: number[];
};

export const CounterpartyDropdownSelector = <T extends FieldValues>({
    control,
    name,
    counterparties = EMPTY_ARRAY as number[],
}: CounterpartyDropdownSelectorProps<T>) => {
    const getClientById = useAppSelector((state) => getClient(state));
    const options = useMemo(
        () =>
            counterparties
                .map<DropdownOption<number>>((id) => ({
                    text: getClientById(id)?.username ?? "",
                    value: Number(id),
                }))
                .sort((a, b) => a.text.localeCompare(b.text)),
        [counterparties, getClientById],
    );

    return (
        <Controller
            control={control}
            render={({ field }) => (
                <MultipleDropdown
                    values={field.value}
                    onChange={field.onChange}
                    options={options}
                    renderTrigger={(trigger) => (
                        <MultipleDropdown.Trigger {...trigger} size="small" variant="simple">
                            <TriggerEssence
                                {...trigger}
                                size="small"
                                option={trigger.selectedOptions}
                                noIcon
                            />
                        </MultipleDropdown.Trigger>
                    )}
                    caption="CP"
                    asFilter
                >
                    <Sheet size="medium" options={options} Dropdown={MultipleDropdown} />
                </MultipleDropdown>
            )}
            name={name}
        />
    );
};
