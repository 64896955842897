import useSWR from "swr";
import { ClientId } from "~entities/client";
import { ShortSalesBan } from "~entities/shortSalesBan";
import { requestAdminery } from "~utils/api";

type RawShortSalesBanEntity = [counterpartyId: ClientId, currencyName: string];

const prepareData = ([counterpartyId, currencyName]: RawShortSalesBanEntity): ShortSalesBan => ({
    counterpartyId,
    currencyName,
});

type SWRKey = [clientId: ClientId, method: string];

const fetcher = ([clientId]: SWRKey) =>
    requestAdminery("cShortSalesBan", {
        clientId,
    }).then((data) => data.map(prepareData));

export const useShortSalesBan = (clientId: ClientId) =>
    useSWR<ShortSalesBan[], unknown, SWRKey>([clientId, "useShortSalesBan"], fetcher);
