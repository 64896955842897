import { useModalControl } from "@fm-frontend/utils";
import { PrimaryButton } from "~components/PrimaryButton";
import { NewSettlementOrderModal } from "./NewSettlementOrderModal";

export const CreateSettlementButton = ({ onCreated }: { onCreated: () => void }) => {
    const { isModalOpen, openModal, closeModal } = useModalControl(false);

    return (
        <>
            <PrimaryButton size="small" onClick={openModal}>
                New settlement order
            </PrimaryButton>
            {isModalOpen && (
                <NewSettlementOrderModal closeModal={closeModal} onCreated={onCreated} />
            )}
        </>
    );
};
