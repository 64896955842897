import { noop } from "@fm-frontend/uikit/src/const";
import { createContext, Dispatch, SetStateAction, useMemo, useState } from "react";
import { OnboardingStatus } from "~entities/onboardingStatus";

type ReferralsTableContextValue = {
    onboardingStatuses: OnboardingStatus[];
    setOnboardingStatuses: Dispatch<SetStateAction<OnboardingStatus[]>>;
};

export const ReferralsTableContext = createContext<ReferralsTableContextValue>({
    onboardingStatuses: [],
    setOnboardingStatuses: noop,
});

export const useReferralsTableContextValue = () => {
    const [onboardingStatuses, setOnboardingStatuses] = useState<OnboardingStatus[]>([]);

    return useMemo(
        () => ({
            onboardingStatuses,
            setOnboardingStatuses,
        }),
        [onboardingStatuses],
    );
};
