import { configureStore } from "@reduxjs/toolkit";
import assetsReducer from "./assets/slice";
import clientsReducer from "./clients/slice";
import clientsInfoReducer from "./clientsInfo/slice";
import currenciesReducer from "./currencies/slice";
import userReducer from "./user/slice";

export const store = configureStore({
    reducer: {
        assets: assetsReducer,
        clients: clientsReducer,
        clientsInfo: clientsInfoReducer,
        currencies: currenciesReducer,
        user: userReducer,
    },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
