import { createAsyncThunk } from "@reduxjs/toolkit";
import { CognitoUser } from "amazon-cognito-identity-js";
import { Auth } from "aws-amplify";

export const refreshAuthenticatedUser = createAsyncThunk(
    "users/refreshAuthenticatedUser",
    async () => {
        const user: CognitoUser = await Auth.currentAuthenticatedUser();
        const userAttributes = await new Promise<Record<string, string>>((resolve, reject) => {
            user.getUserAttributes((error, attributes) => {
                if (error) {
                    reject(error);
                }
                if (attributes) {
                    resolve(
                        attributes.reduce((acc, attribute) => {
                            acc[attribute.getName()] = attribute.getValue();
                            return acc;
                        }, {} as Record<string, string>),
                    );
                }
            });
        });

        return {
            email: userAttributes.email,
        };
    },
);

export const signOut = createAsyncThunk("users/signOut", async () => {
    await Auth.signOut();
});
