import { useMemo } from "react";
import { ClientId } from "~entities/client";
import { useCounterpartyLimitsApi } from "~hooks/api/useCounterpartyLimitsApi";
import useAppSelector from "~hooks/useAppSelector";
import { getSubaccountClients } from "~store/clients/selectors";

export const useSubaccountsLimitsApi = (clientId: ClientId) => {
    const subaccounts = useAppSelector(getSubaccountClients);
    const { data, isLoading } = useCounterpartyLimitsApi(clientId);

    return useMemo(
        () => ({
            data: data?.filter((limit) =>
                subaccounts.some((client) => client.id === limit.counterpartyId),
            ),
            isLoading,
        }),
        [data, isLoading, subaccounts],
    );
};
