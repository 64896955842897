import { IconButton, Icons } from "@fm-frontend/uikit";
import { EMPTY_ARRAY } from "@fm-frontend/uikit/src/const";
import { FC } from "react";
import styled from "styled-components";
import RemoveAction from "~components/Table/Action/RemoveAction";
import { Key } from "~entities/keys";
import { useKeysApi } from "~hooks/api/useKeysApi";
import useNotifications from "~hooks/useNotifications";
import { requestAdminery } from "~utils/api";

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 8px;
`;

type ActionCellProps = {
    data: Key;
};

const ActionsCell: FC<ActionCellProps> = ({ data }) => {
    const { showSuccessNotification, showErrorNotification } = useNotifications();
    const { data: keys = EMPTY_ARRAY, mutate } = useKeysApi();

    const handleCopy = async () => {
        navigator.clipboard.writeText(data.key);
        showSuccessNotification(`Copied key: ${data.key}`);
    };
    const handleRemove = async () => {
        const response = await requestAdminery("delKey", {
            key: data.key,
        });

        if (!response?.error) {
            const keyIndex = keys.findIndex((item) => item.key === data.key);

            await mutate([
                ...(keys?.slice(0, keyIndex) ?? []),
                ...(keys?.slice(keyIndex + 1) ?? []),
            ]);
            showSuccessNotification("The key has been successfully deleted.");
        }
        if (response?.error) {
            showErrorNotification("An error occurred when deleting key.");
        }
    };

    return (
        <Container>
            <IconButton
                title="Copy the key"
                variant="basic"
                Icon={Icons.CopyIcon}
                onClick={handleCopy}
            />
            <RemoveAction title="Remove the key" onDone={handleRemove} />
        </Container>
    );
};

export default ActionsCell;
