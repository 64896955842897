import {
    Checkbox,
    FieldGroup,
    Form,
    FormActions,
    FormBody,
    Header as FormHeader,
    HeaderTitle,
    ShowIcon,
    SimpleInput,
} from "@fm-frontend/uikit";
import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import FormError from "~components/FormError";
import { PrimaryButton } from "~components/PrimaryButton";
import { ERROR_MESSAGES } from "~constants/errors";
import { ValidateErrors } from "~constants/form";
import { NewKey } from "~entities/keys";
import { useKeysApi } from "~hooks/api/useKeysApi";
import useNotifications from "~hooks/useNotifications";
import { requestAdminery } from "~utils/api";
import { prepareRequestError } from "~utils/prepareRequestError";

const prepareNewKey = ([key, allowWrite, createdAt, tag, secret]: [
    key: string,
    allowWrite: 0 | 1,
    createdAt: number,
    tag: string,
    secret: string,
]): NewKey => ({
    key,
    allowWrite: allowWrite === 1,
    createdAt,
    tag,
    secret,
});

const FormContainer = styled(Form)`
    width: 100%;
    min-width: initial;
    padding: 8px;
`;

type AddKeyFormType = {
    tag: string;
    allowWrite: boolean;
};

type AddFormProps = {
    onDone: (newKey: NewKey) => void;
};

export const AddForm: FC<AddFormProps> = ({ onDone }) => {
    const { data: apiData, mutate } = useKeysApi();
    const { showSuccessNotification } = useNotifications();
    const {
        register,
        watch,
        setValue,
        setFocus,
        formState: { errors },
        handleSubmit,
    } = useForm<AddKeyFormType>({
        defaultValues: {
            tag: "",
            allowWrite: false,
        },
    });
    const [loading, setLoading] = useState(false);
    const [submitError, setSubmitError] = useState<string>();
    const allowWrite = watch("allowWrite");

    useEffect(() => {
        setFocus("tag");
    }, [setFocus]);

    const handleAddKeySubmit = handleSubmit(async (data) => {
        try {
            setSubmitError(undefined);
            setLoading(true);
            const response = await requestAdminery("addKey", {
                tag: data.tag,
                allowWrite: data.allowWrite,
            });
            const newKey = prepareNewKey(response);

            if (apiData !== undefined) {
                await mutate([...apiData, newKey]);
            }

            if (!response?.error) {
                showSuccessNotification(`Key ${data.tag} was successfully added`);
                onDone(newKey);
            }
            if (response?.error) {
                setSubmitError(ERROR_MESSAGES[response.error] ?? response.error);
            }
        } catch (e) {
            setSubmitError(prepareRequestError(e));
        } finally {
            setLoading(false);
        }
    });

    return (
        <FormContainer onSubmit={handleAddKeySubmit}>
            <FormHeader>
                <HeaderTitle title="Admin API Key" />
            </FormHeader>
            <FormBody alignItems="stretch">
                <FieldGroup>
                    <SimpleInput
                        label="Tag"
                        placeholder="API Key tag"
                        error={errors.tag?.message}
                        {...register("tag", {
                            required: ValidateErrors.required,
                        })}
                    />
                </FieldGroup>
                <FieldGroup>
                    <ShowIcon>
                        <Checkbox
                            id="write-access-checkbox"
                            checked={allowWrite}
                            onChange={(value: boolean) => setValue("allowWrite", value)}
                        />
                        <label htmlFor="write-access-checkbox">Allow write access</label>
                    </ShowIcon>
                </FieldGroup>
                {submitError && <FormError message={submitError} />}
            </FormBody>
            <FormActions variant="plain">
                <PrimaryButton size="large" fullWidth loading={loading}>
                    Add new
                </PrimaryButton>
            </FormActions>
        </FormContainer>
    );
};
