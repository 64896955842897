import { onHover } from "@fm-frontend/uikit/src/const";
import { FC } from "react";
import { Link as RouterLink } from "react-router-dom";
import styled from "styled-components";
import { Media } from "~constants/dimensions";
import { SERVER_NAME } from "~constants/serverName";
import { getAllClientsUrl } from "~pages/Router";

const Link = styled(RouterLink)`
    ${onHover} {
        text-decoration: none;
    }
`;

const Container = styled.div`
    width: 212px;
    background: url("/images/logo.svg") no-repeat center left;
    padding: 0 7px 0 20px;

    ${Media.Mobile} {
        width: 82px;
    }
`;
const Title = styled.span`
    font-size: 24px;

    ${Media.Mobile} {
        display: none;
    }
`;
const Server = styled.span`
    font-size: 11px;
    font-weight: 400;
    text-transform: uppercase;
    padding: 0 5px;
    vertical-align: top;
    line-height: 20px;
    color: ${(p) => p.theme.colors.ui72};
`;

const Logo: FC = () => (
    <Link to={getAllClientsUrl()}>
        <Container>
            <Title>Adminery</Title>
            <Server>{SERVER_NAME}</Server>
        </Container>
    </Link>
);

export default Logo;
