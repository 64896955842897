import { Checkbox, HStack } from "@fm-frontend/uikit";
import React from "react";
import { Control, Controller } from "react-hook-form";
import styled from "styled-components";
import MatchContains from "~components/Table/Cell/MatchContains";
import { InputsValue } from "~pages/RisksManagement/components/EditInstrumentsWhitelistForm/types";

const CheckboxLabel = styled.label`
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

type CheckItemProps = {
    control: Control<InputsValue>;
    id: string;
    name: string;
    index: number;
    disabled: boolean;
    query: string;
};

export const CheckItem: React.FC<CheckItemProps> = ({
    control,
    id,
    name,
    index,
    disabled,
    query,
}) => (
    <Controller
        control={control}
        render={({ field }) => (
            <HStack alignItems="center" spacing={8} padding={8}>
                <Checkbox
                    disabled={disabled}
                    id={id}
                    {...field}
                    size="small"
                    checked={field.value}
                    onChange={field.onChange}
                />
                <CheckboxLabel htmlFor={id}>
                    <MatchContains value={name} query={query} />
                </CheckboxLabel>
            </HStack>
        )}
        name={`instruments.${index}.isInWhitelist`}
    />
);
