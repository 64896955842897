import { BadgeVariant } from "@fm-frontend/uikit";
import { ClientId, SubsFmRelationType } from "~entities/client";
import { InviteStatus, OnboardingStatus } from "~entities/onboardingStatus";
import { ReferralStatus } from "~entities/referral";

export const getReferralStatusTitle = (value: ReferralStatus) => {
    switch (value) {
        case ReferralStatus.Accepted:
            return "Data filling";
        case ReferralStatus.Sent:
            return "Link sent";
        case ReferralStatus.Expired:
            return "Expired";
        default:
            return "";
    }
};

export const getReferralStatusBadgeVariant = (value: ReferralStatus): BadgeVariant => {
    switch (value) {
        case ReferralStatus.Accepted:
            return "process";
        case ReferralStatus.Sent:
            return "attention";
        case ReferralStatus.Expired:
            return "negative";
        default:
            return "info";
    }
};

export const findInviteStatus = (onboardingStatuses: OnboardingStatus[], clientId: ClientId) => {
    const { status: inviteStatus } = onboardingStatuses.find(
        ({ taker_id }) => taker_id === clientId,
    ) ?? {
        status: null,
    };

    return inviteStatus;
};

export const getInviteStatusTitle = (value: InviteStatus) => {
    switch (value) {
        case InviteStatus.Joined:
            return "Joined";
        case InviteStatus.LpChoosing:
            return "LP choosing";
        case InviteStatus.LpVerification:
            return "LP verification";
        default:
            return "";
    }
};

export const getInviteStatusBadgeVariant = (value: InviteStatus): BadgeVariant => {
    switch (value) {
        case InviteStatus.Joined:
            return "positive";
        case InviteStatus.LpChoosing:
            return "process";
        case InviteStatus.LpVerification:
            return "process";
        default:
            return "info";
    }
};

export const getTnCSaTitle = (value: SubsFmRelationType | undefined): string => {
    switch (value) {
        case "direct":
            return "Request";
        default:
            return "";
    }
};
