import styled from "styled-components";

const OptionsContainer = styled.div`
    padding: 2px 12px 20px;
    display: flex;
    flex-direction: row;
    gap: 5px;
`;

export default OptionsContainer;
