import useSWR from "swr";
import { ClientId } from "~entities/client";
import { SettlementRequest } from "~entities/settlementRequest";
import { requestAdminery } from "~utils/api";

type RawSettlementTransaction = [
    counterpartyId: ClientId,
    currency: string,
    flags: number,
    amount: number | bigint,
    comment: string,
    expirationTime: number,
    networkName: string,
    id: number,
    creationTimestamp: number,
];

const prepareData = ([
    counterpartyId,
    currency,
    flags,
    amount,
    comment,
    expirationTime,
    networkName,
    id,
    creationTimestamp,
]: RawSettlementTransaction): SettlementRequest => ({
    counterpartyId,
    currency,
    flags,
    amount,
    comment,
    expirationTime,
    networkName,
    id,
    creationTimestamp,
});

type SWRKey = [clientId: ClientId, key: string];

const fetcher = ([clientId]: SWRKey) =>
    requestAdminery("settlementRequests", { clientId }).then(([incoming, outgoing]) => ({
        incoming: incoming.map(prepareData),
        outgoing: outgoing.map(prepareData),
    }));

export const useSettlementRequestsApi = (clientId: ClientId) =>
    useSWR<{ incoming: SettlementRequest[]; outgoing: SettlementRequest[] }>(
        [clientId, "useSettlementRequestsApi"],
        fetcher,
    );
