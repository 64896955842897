import { FC, ReactElement, ReactNode } from "react";
import styled from "styled-components";

const Wrapper = styled.span`
    color: ${(p) => p.theme.colors.ui32};
`;

type DisabledCellThemeProps = {
    disabled: boolean;
    children: ReactNode;
};

export const DisabledCellTheme: FC<DisabledCellThemeProps> = ({ disabled, children }) => {
    if (!disabled) {
        return children as ReactElement;
    }

    return <Wrapper>{children}</Wrapper>;
};
