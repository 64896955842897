import useSWR from "swr";
import { ClientId } from "~entities/client";
import { SettlementTransactionHistoryItem } from "~entities/settlementTransactionHistoryItem";
import { requestAdminery } from "~utils/api";

type RawSettlementTransactionHistoryEntity = [
    counterpartyId: ClientId,
    currency: string,
    amount: number | bigint,
    orderId: number,
    comment: string,
    createdAt: number,
    txId: string,
    sentAt: number,
    transactionFlags: number,
    transactionMoment: number,
    transactionId: number,
    networkFee: number | bigint,
    networkName: string,
];

const prepareData = ([
    counterpartyId,
    currency,
    amount,
    orderId,
    comment,
    createdAt,
    txId,
    sentAt,
    transactionFlags,
    transactionMoment,
    transactionId,
    networkFee,
    networkName,
]: RawSettlementTransactionHistoryEntity): SettlementTransactionHistoryItem => ({
    counterpartyId,
    currency,
    amount,
    orderId,
    comment,
    createdAt,
    txId,
    sentAt,
    transactionFlags,
    transactionMoment,
    transactionId,
    networkFee,
    networkName,
});

type SWRKey = [clientId: ClientId, key: string];

const fetcher = ([clientId]: SWRKey) =>
    requestAdminery("settlementTransactionHistory", { clientId }).then((data) =>
        data.map(prepareData),
    );

export const useSettlementTransactionHistoryApi = (clientId: ClientId) =>
    useSWR<SettlementTransactionHistoryItem[]>(
        [clientId, "useSettlementTransactionHistoryApi"],
        fetcher,
    );
