import { P } from "@fm-frontend/uikit";
import { FC, ReactNode } from "react";
import styled from "styled-components";

type PropertyProps = {
    title: string;
    children: ReactNode;
};

const Container = styled.div`
    display: flex;
    box-shadow: inset 0 -1px 0 ${(p) => p.theme.colors.ui8};
`;

const Title = styled(P)`
    height: 44px;
    line-height: 44px;
    min-width: 180px;
    padding: 0 12px;
`;

const Value = styled.div`
    ${(p) => p.theme.mixins.p};

    height: 44px;
    line-height: 44px;
`;

export const Property: FC<PropertyProps> = ({ title, children }) => (
    <Container>
        <Title>{title}</Title>
        <Value>{children}</Value>
    </Container>
);
