import { wsUrl } from "~constants/api";
import { Feed } from "~constants/feeds";
import BigIntJSON from "~utils/BigIntJSON";
import { getNonce } from "~utils/getNonce";
import { getToken } from "~utils/getToken";

enum WebSocketStatus {
    NotAuthorized,
    Authorized,
    Error,
}

const authRequest = async () => {
    const { token } = await getToken();
    const nonce = getNonce();
    const timestamp = Date.now();
    const body = JSON.stringify({ nonce, timestamp });

    return JSON.stringify({ event: "auth", key: token, content: body, signature: "" });
};

const convertToMessage = (data: object | string) => {
    if (typeof data === "object") {
        return BigIntJSON.stringify(data);
    }

    return data;
};

const convertFromMessage = (message: string) => {
    return BigIntJSON.parse(message);
};

class WebSocketApi extends EventTarget {
    private static instance: WebSocketApi;

    private ws: WebSocket | null = null;

    private status = WebSocketStatus.NotAuthorized;

    private constructor() {
        super();
        this.ws = null;
        this.status = WebSocketStatus.NotAuthorized;
    }

    public static getInstance(): WebSocketApi {
        if (!WebSocketApi.instance) {
            WebSocketApi.instance = new WebSocketApi();
        }

        return WebSocketApi.instance;
    }

    private open = () => {
        this.ws = new WebSocket(wsUrl);

        this.ws.addEventListener("message", async (event) => {
            const data = convertFromMessage(event.data);

            if (Array.isArray(data)) {
                // Feed response
                const [feed, feedId, type, content] = data;

                if (feed === Feed.Auth) {
                    if (content === 0) {
                        this.ws?.send(await authRequest());
                    } else if (this.status === WebSocketStatus.Authorized) {
                        this.status = WebSocketStatus.NotAuthorized;
                    }
                } else {
                    this.dispatchEvent(new MessageEvent("message", { data }));
                }
            } else if (data.event === "auth") {
                // Auth response
                if (data.error) {
                    // eslint-disable-next-line no-console
                    console.log("Auth. Error:", data.error);
                    this.status = WebSocketStatus.Error;
                    this.dispatchEvent(new ErrorEvent("error", { message: data.error }));
                } else if (this.status === WebSocketStatus.Authorized) {
                    this.dispatchEvent(new Event("refreshToken"));
                } else {
                    this.status = WebSocketStatus.Authorized;
                    this.dispatchEvent(new Event("authorized"));
                }
            }
        });

        this.ws.addEventListener("close", this.close);
    };

    private close = () => {
        this.ws?.close();
        this.ws = null;
    };

    public start = () => {
        window.removeEventListener("blur", this.close);

        if (this.ws === null) {
            this.open();
        }
    };

    public stop = () => {
        if (typeof document !== "undefined" && document.hasFocus()) {
            // close websocket only when window is inactive
            window.addEventListener("blur", this.close, { once: true });
        } else {
            this.close();
        }
    };

    public send = (data: object | string) => {
        if (this.ws === null) {
            // throw new Error("WebSocket is not open");
            return;
        }
        if (this.ws.readyState === WebSocket.CONNECTING) {
            this.ws.addEventListener("open", () => this.send(data), { once: true });
            return;
        }
        if (this.status !== WebSocketStatus.Authorized) {
            this.addEventListener("authorized", () => this.send(data), { once: true });
            return;
        }
        if (this.status === WebSocketStatus.Authorized) {
            this.ws.send(convertToMessage(data));
        }
    };

    // TODO: Find a way to type the content of the API response.
    //       Maybe we should use `never` or `unknown` and define correct type in the highest component
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public subscribe = (cb: (data: any) => void) => {
        const messageHandler = (event: Event) => {
            if (event instanceof MessageEvent) {
                cb(event.data);
            }
        };

        this.addEventListener("message", messageHandler);

        return () => {
            this.removeEventListener("message", messageHandler);
        };
    };

    public onRefreshToken = (cb: () => void) => {
        this.addEventListener("refreshToken", cb);

        return () => {
            this.removeEventListener("refreshToken", cb);
        };
    };
}

export default WebSocketApi;
