import { ReactNode, useCallback, useContext } from "react";
import { NotificationContext } from "~components/Notifications/context";
import { NotificationType } from "~components/Notifications/types";

const useNotifications = () => {
    const { showNotification } = useContext(NotificationContext);
    const showSuccessNotification = useCallback(
        (content: ReactNode) => {
            showNotification(content, NotificationType.Success);
        },
        [showNotification],
    );
    const showErrorNotification = useCallback(
        (content: ReactNode) => {
            showNotification(content, NotificationType.Error);
        },
        [showNotification],
    );

    return { showSuccessNotification, showErrorNotification };
};

export default useNotifications;
