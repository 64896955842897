import {
    BasicButton,
    DesctructiveButton,
    FieldGroup,
    Form,
    FormActions,
    FormBody,
    Header as FormHeader,
    HeaderTitle,
    SimpleInput,
    SwitchField,
} from "@fm-frontend/uikit";
import { ValueFormat, ValueParse } from "@fm-frontend/utils";
import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import FormError from "~components/FormError";
import { PrimaryButton } from "~components/PrimaryButton";
import TagsInput from "~components/TagsInput";
import { ERROR_MESSAGES } from "~constants/errors";
import { ValidateErrors, ValidateRules } from "~constants/form";
import { Client, ClientType } from "~entities/client";
import { ClientInfo } from "~entities/clientInfo";
import { useAuthorizedTradingApi } from "~hooks/api/useAuthorizedTradingApi";
import { useHRPClientsApi } from "~hooks/api/useHRPClientsApi";
import useAppDispatch from "~hooks/useAppDispatch";
import useNotifications from "~hooks/useNotifications";
import { modifyClient } from "~store/clients/actions";
import { modifyClientInfo } from "~store/clientsInfo/actions";
import { requestAdminery, requestHrpReporting, requestRfq } from "~utils/api";
import { prepareRequestError } from "~utils/prepareRequestError";
import { isAuthorizedTradingAvailable, isRfqAvailable } from "../utils";

const FormContainer = styled(Form)`
    width: 100%;
    min-width: initial;
    padding: 8px;
`;

type ModifyProfileFormType = {
    tags: { name: string; value: string }[];
    makerFee: string;
    fineryShare: string;
    name: string;
    fullName: string;
    email: string;
    hrpId?: string;
    rfqEnabled: boolean;
    authorizedTrading: boolean;
};

type AddFormProps = {
    client: Client;
    clientInfo: ClientInfo;
    allTags: string[] | undefined;
    hrpId: string | undefined;
    authorizedTrading?: boolean;
    onDone: () => void;
};

const ModifyProfileForm: FC<AddFormProps> = ({
    client,
    clientInfo,
    allTags,
    hrpId,
    authorizedTrading = false,
    onDone,
}) => {
    const { mutate: mutateHRPClients } = useHRPClientsApi();
    const dispatch = useAppDispatch();
    const { isDisabled, metadata, id: clientId, type } = client;
    const { id, makerFee, fineryShare, rfqEnabled } = clientInfo;
    const { enableAuthorizedTrading, disableAuthorizedTrading } = useAuthorizedTradingApi(id);
    const { showSuccessNotification, showErrorNotification } = useNotifications();
    const {
        control,
        register,
        setFocus,
        formState: { isSubmitting, errors, isDirty },
        handleSubmit,
    } = useForm<ModifyProfileFormType>({
        defaultValues: {
            tags: metadata.tags?.map((tag, index) => ({ id: index, name: tag })),
            makerFee: ValueFormat.deltaRate(makerFee),
            fineryShare: ValueFormat.u32(fineryShare),
            name: client.username,
            fullName: client.fullname,
            email: client.email,
            hrpId: hrpId ?? "",
            rfqEnabled,
            authorizedTrading,
        },
    });
    const [loading, setLoading] = useState(false);
    const [submitError, setSubmitError] = useState<string>();

    useEffect(() => {
        setFocus("tags");
    }, [setFocus]);

    const handleMapClient = async (_hrpId: string) => {
        try {
            const response = await requestHrpReporting("mapClient", {
                fmClientId: clientId,
                hrpClientId: _hrpId,
            });

            if (!response?.error) {
                await mutateHRPClients();
                showSuccessNotification(`Client was successfully mapped to HRP ID`);
            }
            if (response?.error) {
                showErrorNotification(`Failed to map client to HRP ID. Reason: ${response?.error}`);
            }
        } catch (e) {
            setSubmitError(prepareRequestError(e));
        }
    };
    const handleDeleteMapClient = async () => {
        try {
            const response = await requestHrpReporting("delClient", {
                fmClientId: clientId,
            });

            if (!response?.error) {
                await mutateHRPClients();
                showSuccessNotification(`Client was successfully removed from HRP`);
            }
            if (response?.error) {
                showErrorNotification(
                    `Failed to remove client from HRP. Reason: ${response?.error}`,
                );
            }
        } catch (e) {
            setSubmitError(prepareRequestError(e));
        }
    };

    const handleModifySubmit = handleSubmit(async (data) => {
        try {
            setSubmitError(undefined);

            const newMakerFee = ValueParse.deltaRate(data.makerFee);
            const newFineryShare = ValueParse.u32(data.fineryShare);
            const newTags = data.tags?.map((tag) => tag.name) ?? [];

            const modifyRequests = [
                requestAdminery("updateClient", {
                    id,
                    makerFee: newMakerFee,
                    fineryShare: newFineryShare,
                }),
                requestAdminery("modClient", {
                    id,
                    name: data.name,
                    fullName: data.fullName,
                    email: data.email,
                }),
                requestAdminery("setClientMetadata", {
                    clientId: id,
                    tagName: "tags",
                    tagContent: newTags,
                }),
                isRfqAvailable(type)
                    ? requestRfq("setSettings", {
                          clientId: id,
                          tradingEnabled:
                              type === ClientType.SubaccountTaker || type === ClientType.Taker
                                  ? data.rfqEnabled
                                  : false,
                          viewingEnabled: data.rfqEnabled,
                      })
                    : Promise.resolve(),
            ];

            if (data.authorizedTrading !== authorizedTrading) {
                modifyRequests.push(
                    data.authorizedTrading ? enableAuthorizedTrading() : disableAuthorizedTrading(),
                );
            }

            const [clientResponse, metadataResponse] = await Promise.all(modifyRequests);
            const error = clientResponse?.error || metadataResponse?.error;

            if (!error) {
                dispatch(
                    modifyClient({
                        id,
                        data: {
                            username: data.name,
                            fullname: data.fullName,
                            email: data.email,
                            metadata: {
                                ...metadata,
                                tags: newTags,
                            },
                        },
                    }),
                );
                dispatch(
                    modifyClientInfo({
                        id,
                        data: {
                            username: data.name,
                            fullName: data.fullName,
                            email: data.email,
                            makerFee: newMakerFee,
                            fineryShare: newFineryShare,
                            rfqEnabled: data.rfqEnabled,
                        },
                    }),
                );

                if (data.hrpId && data.hrpId !== hrpId) {
                    await handleMapClient(data.hrpId);
                }
                if (!data.hrpId && hrpId) {
                    await handleDeleteMapClient();
                }

                onDone();
                showSuccessNotification(`Client was successfully modified`);
            }
            if (error) {
                setSubmitError(ERROR_MESSAGES[error] ?? error);
            }
        } catch (e) {
            setSubmitError(prepareRequestError(e));
        }
    });

    const handleEnable = async () => {
        try {
            setSubmitError(undefined);
            setLoading(true);

            const response = await requestAdminery("enableClient", {
                id,
            });

            if (!response?.error) {
                dispatch(modifyClient({ id, data: { isDisabled: false } }));
                onDone();
                showSuccessNotification("Client was enabled");
            }

            if (response?.error) {
                setSubmitError(ERROR_MESSAGES[response?.error] ?? response?.error);
            }
        } catch (e) {
            setSubmitError(prepareRequestError(e));
        } finally {
            setLoading(false);
        }
    };

    const handleDisable = async () => {
        try {
            setSubmitError(undefined);
            setLoading(true);

            const response = await requestAdminery("disableClient", {
                id,
            });

            if (!response?.error) {
                dispatch(modifyClient({ id, data: { isDisabled: true } }));
                onDone();
                showSuccessNotification("Client was disabled");
            }

            if (response?.error) {
                setSubmitError(ERROR_MESSAGES[response?.error] ?? response?.error);
            }
        } catch (e) {
            setSubmitError(prepareRequestError(e));
        } finally {
            setLoading(false);
        }
    };

    const suggestions = allTags?.map((tag, index) => ({ id: index, name: tag }));

    return (
        <FormContainer onSubmit={handleModifySubmit}>
            <FormHeader>
                <HeaderTitle title="Modify client" />
            </FormHeader>
            <FormBody alignItems="stretch">
                <FieldGroup>
                    <TagsInput
                        control={control}
                        name="tags"
                        label="Tag"
                        suggestions={suggestions}
                    />
                </FieldGroup>
                <FieldGroup>
                    <SimpleInput
                        label="License fee"
                        placeholder="0.08"
                        error={errors.makerFee?.message}
                        {...register("makerFee", {
                            required: ValidateErrors.required,
                            validate: { deltaRate: ValidateRules.deltaRate },
                        })}
                    />
                    <SimpleInput
                        label="FM markup share"
                        placeholder="10"
                        error={errors.fineryShare?.message}
                        {...register("fineryShare", {
                            required: ValidateErrors.required,
                            validate: { u32: ValidateRules.u32 },
                        })}
                    />
                </FieldGroup>
                <FieldGroup>
                    <SimpleInput
                        data-1pignore
                        label="Name"
                        error={errors.name?.message}
                        {...register("name")}
                    />
                    <SimpleInput
                        data-1pignore
                        label="Full Name"
                        error={errors.fullName?.message}
                        {...register("fullName")}
                    />
                    <SimpleInput
                        data-1pignore
                        label="Email"
                        error={errors.email?.message}
                        {...register("email")}
                    />
                </FieldGroup>
                <FieldGroup>
                    <SimpleInput
                        data-1pignore
                        label="HRP ID"
                        error={errors.hrpId?.message}
                        {...register("hrpId")}
                    />
                </FieldGroup>
                {isRfqAvailable(type) && (
                    <FieldGroup>
                        <SwitchField
                            text="RFQ"
                            variant="basic"
                            fullWidth
                            control={control}
                            {...register("rfqEnabled")}
                        />
                    </FieldGroup>
                )}
                {isAuthorizedTradingAvailable(type) && (
                    <FieldGroup>
                        <SwitchField
                            text="Authorized trading"
                            variant="basic"
                            fullWidth
                            control={control}
                            {...register("authorizedTrading")}
                        />
                    </FieldGroup>
                )}

                {submitError && <FormError message={submitError} />}
            </FormBody>
            <FormActions variant="plain">
                {isDisabled && (
                    <BasicButton
                        type="button"
                        size="large"
                        fullWidth
                        loading={isSubmitting || loading}
                        onClick={handleEnable}
                    >
                        Enable client
                    </BasicButton>
                )}
                {!isDisabled && (
                    <DesctructiveButton
                        type="button"
                        size="large"
                        fullWidth
                        loading={isSubmitting || loading}
                        onClick={handleDisable}
                    >
                        Disable client
                    </DesctructiveButton>
                )}
                <PrimaryButton
                    disabled={!isDirty}
                    size="large"
                    fullWidth
                    loading={isSubmitting || loading}
                >
                    Confirm changes
                </PrimaryButton>
            </FormActions>
        </FormContainer>
    );
};

export default ModifyProfileForm;
