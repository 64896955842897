import { BasicButton } from "@fm-frontend/uikit";
import React, { SyntheticEvent, useCallback } from "react";
import ModalButton from "~components/ModalButton";
import { useInstrumentsApi } from "~hooks/api/useInstrumentsApi";
import { useInstrumentsWhitelist } from "~hooks/api/useInstrumentsWhitelist";
import { useClientId } from "~hooks/useClientId";
import { EditInstrumentsWhitelistForm } from "~pages/RisksManagement/components/EditInstrumentsWhitelistForm";

type EditInstrumentWhitelistActionProps = {
    renderButton?: (handleClick: (e: SyntheticEvent) => void) => JSX.Element;
};

export const EditInstrumentWhitelistAction: React.FC<EditInstrumentWhitelistActionProps> = ({
    renderButton,
}) => {
    const clientId = useClientId();
    const { data: instrumentsData, isLoading: isInstrumentsDataLoading } = useInstrumentsApi();
    const { data: instrumentsWhitelistData, isLoading: isInstrumentsWhitelistDataLoading } =
        useInstrumentsWhitelist(clientId);
    const instrumentsWhiteList = instrumentsWhitelistData?.instruments ?? [];
    const enabled = instrumentsWhitelistData?.enabled ?? false;
    const isLoading = isInstrumentsDataLoading || isInstrumentsWhitelistDataLoading;

    const formData =
        instrumentsData?.instruments?.map((instrument) => {
            return {
                name: instrument.name,
                isInWhitelist: instrumentsWhiteList.includes(instrument.name),
            };
        }) ?? [];

    const defaultRenderButton = useCallback(
        (handleClick) => (
            <BasicButton size="small" onClick={handleClick}>
                Edit
            </BasicButton>
        ),
        [],
    );

    return (
        <ModalButton
            renderModalContent={(closePopover) => (
                <EditInstrumentsWhitelistForm
                    loading={isLoading}
                    onDone={closePopover}
                    formData={formData}
                    whitelistEnabled={enabled}
                />
            )}
            renderButton={renderButton ?? defaultRenderButton}
        />
    );
};
