import { useCallback, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { Content as LayoutContent } from "~components/Layout/Content";
import Loading from "~components/Table/Loading";
import { InstrumentName } from "~entities/instrument";
import { useInstrumentsApi } from "~hooks/api/useInstrumentsApi";
import { useClientId } from "~hooks/useClientId";
import Content from "~pages/OrderBook/Content";
import InstrumentSelector from "~pages/OrderBook/InstrumentSelector";
import { getOrderBookUrl } from "~pages/Router";

const Container = styled.div`
    display: flex;
    flex: 1;
    gap: 12px;
`;

const SubMenu = styled.div`
    display: flex;
    flex-direction: column;
    flex: 0;
    background: ${(p) => p.theme.colors.uiWhite100};
    box-shadow: 0 1px 2px rgba(36, 48, 52, 0.04), inset 0 -1px 0 rgba(36, 48, 52, 0.12),
        inset 0 0 0 1px rgba(36, 48, 52, 0.12);
    border-radius: 12px 12px 0 0;
`;

export const OrderBookPage = () => {
    const { instrument } = useParams();
    const clientId = useClientId();
    const navigate = useNavigate();

    const { data: apiData, isLoading: instrumentsFetching } = useInstrumentsApi();
    const { instruments = [] } = apiData ?? {};
    const instrumentsFetched = !instrumentsFetching;

    const selectInstrument = useCallback(
        (instrumentName: InstrumentName) => navigate(getOrderBookUrl(instrumentName, clientId)),
        [clientId, navigate],
    );

    useEffect(() => {
        if (instrument === undefined && instrumentsFetched) {
            selectInstrument(instruments[0].name);
        }
    }, [selectInstrument, instrument, instruments, instrumentsFetched]);

    return (
        <Container>
            <SubMenu>
                <InstrumentSelector selected={instrument} onSelect={selectInstrument} />
            </SubMenu>
            <LayoutContent>
                {instrument === undefined && <Loading />}
                {instrument !== undefined && <Content instrument={instrument} />}
            </LayoutContent>
        </Container>
    );
};
