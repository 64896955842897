export type ClientId = number;
export type WhitelabelId = number;

export type SubsFmRelationType = "direct" | "indirect";

export enum ClientType {
    Maker = "maker",
    Taker = "taker",
    PrimeBroker = "primeBroker",

    SubaccountMaker = "subaccountMaker",
    SubaccountTaker = "subaccountTaker",
}

export type ClientMetadata = {
    tags?: string[];
};

export type Client = {
    id: ClientId;
    email: string;
    fullname: string;
    username: string;
    isSubaccount: boolean;
    isDisabled: boolean;
    type: ClientType;
    metadata: ClientMetadata;
    createdAt: number;
    signedAgreementAt?: number;
    lastTradeAt?: number;
    subsFmRelationType?: SubsFmRelationType;

    // master props
    subsWhitelabelName?: string;

    // subaccount props
    whitelabelId?: WhitelabelId;
    whitelabel?: string;
};
