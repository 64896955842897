import { FC } from "react";
import ModalButton from "~components/ModalButton";
import { PrimaryButton } from "~components/PrimaryButton";
import { AddForm } from "~pages/AssetsAndInstruments/Instruments/AddForm";

const AddButton: FC = () => (
    <ModalButton
        renderModalContent={(closePopover) => <AddForm onDone={closePopover} />}
        renderButton={(handleClick) => (
            <PrimaryButton size="small" onClick={handleClick}>
                Add new
            </PrimaryButton>
        )}
    />
);

export default AddButton;
