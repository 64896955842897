import { P } from "@fm-frontend/uikit";
import { ValueFormat } from "@fm-frontend/utils";
import { FC } from "react";
import styled from "styled-components";
import { SettlementRequestFlags } from "~entities/settlementRequest";
import { TableData } from "~pages/Settlements";
import { SettlementStatus } from "~pages/Settlements/types";

const Info = styled(P)`
    color: ${(p) => p.theme.colors.ui52};
`;

type FeeCellProps = {
    data: TableData;
};

export const FeeCell: FC<FeeCellProps> = ({ data }) => {
    if (data.status === SettlementStatus.Request) {
        return data.flags === SettlementRequestFlags.FeePaidByRecipient ? (
            <Info>by Recipient</Info>
        ) : (
            <Info>by Sender</Info>
        );
    }
    if (data.networkFee !== undefined) {
        return data.networkFee > 0 ? (
            <>
                <P>{ValueFormat.size(data.networkFee)}</P>
                <br />
                <Info>by Recipient</Info>
            </>
        ) : (
            <Info>by Sender</Info>
        );
    }

    return null;
};
