import { Client } from "~entities/client";

export const getClientSubaccountsTypeTitle = (client: Client) => {
    switch (client.subsFmRelationType) {
        case "direct":
            return "Required";
        case "indirect":
            return "Not required";
        default:
            return "";
    }
};
