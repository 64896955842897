import ModalButton from "~components/ModalButton";
import { PrimaryButton } from "~components/PrimaryButton";
import { useIsSubaccount } from "~hooks/useIsSubaccount";
import { AddCounterpartyLimitForm } from "~pages/RisksManagement/components/AddCounterpartyLimitForm";

export const AddCounterpartyLimitButton = () => {
    const isSubaccount = useIsSubaccount();

    return (
        <ModalButton
            renderModalContent={(closePopover) => (
                <AddCounterpartyLimitForm onDone={closePopover} />
            )}
            renderButton={(handleClick) => (
                <PrimaryButton disabled={isSubaccount} size="small" onClick={handleClick}>
                    Add risk profile
                </PrimaryButton>
            )}
        />
    );
};
