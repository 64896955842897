import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Asset } from "~entities/asset";
import {
    AddAssetsAction,
    FetchAssetsAction,
    ModifyAssetsAction,
    RemoveAssetsAction,
    SetAssetsAction,
    SetErrorAction,
    UnsetAssetsAction,
} from "~store/assets/types";
import { EntityStatus } from "~store/types";

type AssetsState = {
    status: EntityStatus;
    items: Asset[];
    error: string;
};

const initialState: AssetsState = {
    status: EntityStatus.NotFetched,
    items: [],
    error: "",
};

export const assetsSlice = createSlice({
    name: "assets",
    initialState,
    reducers: {
        fetchAssets: (state, { payload }: PayloadAction<FetchAssetsAction>) => {
            state.status = EntityStatus.Fetching;
        },
        setAssets: (state, { payload }: PayloadAction<SetAssetsAction>) => {
            state.items = payload.assets;
            state.status = EntityStatus.Fetched;
        },
        addAssets: (state, { payload }: PayloadAction<AddAssetsAction>) => {
            const { assets } = payload;

            state.items = [...state.items, ...assets];
        },
        modifyAssets: (state, { payload }: PayloadAction<ModifyAssetsAction>) => {
            const { assets } = payload;
            const modifyAssetsMap = new Map(assets.map((asset) => [asset.id, asset]));

            state.items = state.items.map((item) => modifyAssetsMap.get(item.id) ?? item);
        },
        removeAssets: (state, { payload }: PayloadAction<RemoveAssetsAction>) => {
            const { assets } = payload;
            const removeAssetsMap = new Map(assets.map((asset) => [asset.id, asset]));

            state.items = state.items.filter((item) => removeAssetsMap.has(item.id));
        },
        unsetAssets: (state, { payload }: PayloadAction<UnsetAssetsAction>) => {
            state.items = [];
            state.status = EntityStatus.NotFetched;
        },
        setError: (state, { payload }: PayloadAction<SetErrorAction>) => {
            state.error = payload.error;
            state.status = EntityStatus.Error;
        },
    },
});

export default assetsSlice.reducer;
