import { FC, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import useAppDispatch from "~hooks/useAppDispatch";
import useAppSelector from "~hooks/useAppSelector";
import Actions from "~pages/Auth/Actions";
import Content from "~pages/Auth/Content";
import { AuthContext, useAuthContextValue } from "~pages/Auth/context";
import { Logo } from "~pages/Auth/Logo";
import { signOut } from "~store/user/actions";
import { isAuthorizationError, isAuthorized } from "~store/user/selectors";

const Container = styled.div`
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const AuthPage: FC = () => {
    const dispatch = useAppDispatch();
    const authContextValue = useAuthContextValue();
    const location = useLocation();
    const navigate = useNavigate();
    const authorized = useAppSelector(isAuthorized);
    const authorizationError = useAppSelector(isAuthorizationError);
    const redirectPath = location.state?.from?.pathname ?? "/";

    useEffect(() => {
        if (authorizationError) {
            dispatch(signOut());
        }
        if (authorized) {
            navigate(redirectPath, { replace: true });
        }
    }, [authorized, authorizationError, redirectPath, navigate, dispatch]);

    return (
        <AuthContext.Provider value={authContextValue}>
            <Container>
                <Logo />
                <Content />
                <Actions />
            </Container>
        </AuthContext.Provider>
    );
};
