import {
    DropdownOption,
    MultipleDropdown,
    OPTIONS_SEARCH_COUNT,
    SheetSize,
    SingleDropdown,
} from "@fm-frontend/uikit/src/components/v2/Dropdown";
import React, { useMemo } from "react";
import { OptionEssence } from "../OptionEssence";

export const PREFERRED_INSTRUMENTS: string[] = [];

type DropdownInstrumentOption = DropdownOption<string>;
type SheetProps = {
    size: SheetSize;
    options: DropdownInstrumentOption[];
    groupingOptions?: DropdownInstrumentOption[];
    Dropdown: typeof SingleDropdown | typeof MultipleDropdown;
};
export const Sheet: React.FC<SheetProps> = ({ size, options, groupingOptions = [], Dropdown }) => {
    const instrumentsOptions = useMemo(() => {
        return options.reduce<{
            top: DropdownInstrumentOption[];
            other: DropdownInstrumentOption[];
        }>(
            (acc, curr) => {
                acc[PREFERRED_INSTRUMENTS.includes(curr.text) ? "top" : "other"].push(curr);

                return acc;
            },
            {
                top: [],
                other: [],
            },
        );
    }, [options]);

    const showGroupingDivider = Boolean(
        groupingOptions.length &&
            (instrumentsOptions.top.length || instrumentsOptions.other.length),
    );
    const showTopDivider = Boolean(
        instrumentsOptions.top.length && instrumentsOptions.other.length,
    );

    return (
        <Dropdown.Sheet size={size}>
            {({ query, normalizedQuery, onSearch }) => (
                <>
                    {options.length > OPTIONS_SEARCH_COUNT && (
                        <Dropdown.Search query={query} onSearch={onSearch} />
                    )}
                    {query && (
                        <Dropdown.EmptySearch>
                            {options
                                .filter((o) => o.text?.toLowerCase().includes(normalizedQuery))
                                .map((option) => (
                                    <Dropdown.Option key={`${option.value}`} value={option.value}>
                                        <OptionEssence option={option} query={query} />
                                    </Dropdown.Option>
                                ))}
                        </Dropdown.EmptySearch>
                    )}
                    {!query && (
                        <Dropdown.EmptySearch>
                            {groupingOptions.map((option) => (
                                <Dropdown.Option key={`${option.value}`} value={option.value}>
                                    <OptionEssence groupingOption option={option} />
                                </Dropdown.Option>
                            ))}
                            {showGroupingDivider && <Dropdown.Divider />}
                            {instrumentsOptions.top.map((option) => (
                                <Dropdown.Option key={`${option.value}`} value={option.value}>
                                    <OptionEssence option={option} query={query} />
                                </Dropdown.Option>
                            ))}
                            {showTopDivider && <Dropdown.Divider />}
                            {instrumentsOptions.other.map((option) => (
                                <Dropdown.Option key={`${option.value}`} value={option.value}>
                                    <OptionEssence option={option} query={query} />
                                </Dropdown.Option>
                            ))}
                        </Dropdown.EmptySearch>
                    )}
                </>
            )}
        </Dropdown.Sheet>
    );
};
