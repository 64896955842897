import { CognitoUser } from "amazon-cognito-identity-js";
import { Auth } from "aws-amplify";
import { useState } from "react";
import { ChallengeName } from "~pages/Auth/consts";

const useConfirmSignIn = (userState: CognitoUser | undefined) => {
    const [loading, setLoading] = useState<boolean>(false);

    const confirmSignIn = (code: string) => {
        setLoading(true);

        return new Promise<void>((resolve, reject) => {
            Auth.confirmSignIn(userState, code, ChallengeName.SoftwareTokenMfa)
                .then(resolve)
                .catch(reject)
                .finally(() => setLoading(false));
        });
    };

    return { confirmSignIn, loading };
};

export default useConfirmSignIn;
