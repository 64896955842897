import { P } from "@fm-frontend/uikit";
import { ValueFormat } from "@fm-frontend/utils";
import { FC } from "react";

type VolumeCellProps = {
    value: number | bigint;
};

export const VolumeCell: FC<VolumeCellProps> = ({ value }) => {
    return <P>{ValueFormat.size(value)}</P>;
};
