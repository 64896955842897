import { Auth } from "aws-amplify";
import { useState } from "react";
import { AuthState } from "~pages/Auth/consts";

const useForgotPasswordSubmit = () => {
    const [loading, setLoading] = useState<boolean>(false);

    const forgotPasswordSubmit = (email: string, code: string, password: string) => {
        setLoading(true);

        return new Promise<AuthState>((resolve, reject) => {
            Auth.forgotPasswordSubmit(email, code, password)
                .then(() => resolve(AuthState.Login))
                .catch(reject)
                .finally(() => setLoading(false));
        });
    };

    return { forgotPasswordSubmit, loading };
};

export default useForgotPasswordSubmit;
