import { BasicButton } from "@fm-frontend/uikit";
import { downloadCsv, ExportData, parseToCsv } from "@fm-frontend/utils";
import { FC, useState } from "react";
import useNotifications from "~hooks/useNotifications";

type ExportButtonProps = {
    data: ExportData[] | (() => ExportData[]);
    filename?: string;
    loading?: boolean;
    disabled?: boolean;
};

const ExportButton: FC<ExportButtonProps> = ({
    data,
    filename = "data",
    loading = false,
    disabled = false,
}) => {
    const { showErrorNotification } = useNotifications();
    const [exporting, setExporting] = useState(false);
    const handleClick = () => {
        const exportData = typeof data === "function" ? data() : data;

        try {
            setExporting(true);
            const csv = parseToCsv(exportData);
            downloadCsv(csv, filename);
        } catch (e) {
            showErrorNotification("There was a problem with the export.");
        } finally {
            setExporting(false);
        }
    };

    return (
        <BasicButton
            size="small"
            loading={loading || exporting}
            disabled={disabled}
            onClick={handleClick}
        >
            Export
        </BasicButton>
    );
};

export default ExportButton;
