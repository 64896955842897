import { themes } from "@fm-frontend/uikit";
import "@fm-frontend/uikit/src/styled.d";
import { FC, useEffect } from "react";
import { Provider as ReduxProvider } from "react-redux";
import { ThemeProvider } from "styled-components";
import GlobalStyle from "~components/GlobalStyle";
import { NotificationsProvider } from "~components/Notifications/context";
import useAppDispatch from "~hooks/useAppDispatch";
import Router from "~pages/Router";
import { store } from "~store";
import { refreshAuthenticatedUser } from "~store/user/actions";

const CheckAuth: FC = () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(refreshAuthenticatedUser());
    }, [dispatch]);

    return null;
};

const App: FC = () => (
    <ReduxProvider store={store}>
        <CheckAuth />
        <ThemeProvider theme={themes.light}>
            <NotificationsProvider>
                <Router />
            </NotificationsProvider>
            <GlobalStyle />
        </ThemeProvider>
    </ReduxProvider>
);

export default App;
