import { ClientId } from "~entities/client";
import { OrderId } from "~entities/order";

/* eslint-disable no-bitwise */
export enum TransactionFlagsBitmask {
    Committed = 1 << 0, // 0 - by receiver, 1 - by admin
    Cancelled = 1 << 1,
    PaidByRecipient = 1 << 2,
}
/* eslint-enable no-bitwise */

export type SettlementTransactionHistoryItem = {
    counterpartyId: ClientId;
    currency: string;
    amount: number | bigint;
    orderId: OrderId;
    comment: string;
    createdAt: number;
    txId: string;
    sentAt: number;
    transactionFlags: number;
    transactionMoment: number;
    transactionId: number;
    networkFee: number | bigint;
    networkName: string;
};
