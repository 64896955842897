import { P } from "@fm-frontend/uikit";
import { EmDash, ValueFormat } from "@fm-frontend/utils";
import { FC } from "react";

type PriceCellProps = {
    value: number | bigint;
};

export const PriceCell: FC<PriceCellProps> = ({ value }) => {
    return <P>{value ? ValueFormat.price(value) : EmDash}</P>;
};
