import { HRPGroup } from "~pages/Profile/Groups/HRPGroup";
import ProfileGroup from "~pages/Profile/Groups/ProfileGroup";
import TermsAndConditions from "~pages/Profile/Groups/TermsAndConditionsGroup";
import useCommonExportData from "~pages/Profile/hooks/useCommonExportData";
import Options from "~pages/Profile/Options";

const MakerProfile = () => {
    const getCommonExportData = useCommonExportData();

    return (
        <>
            <Options exportData={getCommonExportData} />
            <ProfileGroup />
            <HRPGroup />
            <TermsAndConditions />
        </>
    );
};

export default MakerProfile;
