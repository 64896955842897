import { Badge } from "@fm-frontend/uikit";
import { formatPercent } from "@fm-frontend/utils";
import { FC } from "react";
import { AssetLimit } from "~entities/assetLimit";

type StatusBadgeProps = {
    limit: AssetLimit | undefined;
    exposure: number | bigint | undefined;
};

export const StatusBadge: FC<StatusBadgeProps> = ({ limit, exposure }) => {
    if (limit?.grossLimit === 0 || limit?.grossLimit === 0n) {
        return <Badge variant="info" value="Stop" />;
    }

    if (limit?.grossLimit !== undefined && exposure !== undefined && limit.grossLimit > 0) {
        const exposureAbs = exposure < 0 ? BigInt(exposure) * -1n : BigInt(exposure);
        const grossLimit = BigInt(limit.grossLimit);
        const freeLimit = grossLimit - exposureAbs;
        const percent = formatPercent((BigInt(100 * 1e4) * freeLimit) / grossLimit);

        return <Badge variant="positive" value={percent} />;
    }

    return null;
};
