import { P, PXSmall, VStack } from "@fm-frontend/uikit";
import { FC } from "react";
import styled from "styled-components";
import { DealTradeType } from "~entities/dealHistoryItem";
import { OrderType } from "~entities/order";

const TradeTypeText = styled(PXSmall)`
    color: ${(p) => p.theme.colors.ui72};
`;

export const ORDER_TYPE_TITLES = {
    [OrderType.Limit]: "Limit",
    [OrderType.PostOnly]: "Post only",
    [OrderType.LimitIOC]: "Limit IOC",
    [OrderType.LimitFOK]: "Limit FOK",
    [OrderType.MarketIOC]: "Market IOC",
    [OrderType.MarketFOK]: "Market FOK",
    [OrderType.ManualTrade]: "Voice trade",
    [OrderType.LiquidationTrade]: "Liquidation",
    [OrderType.RFQ]: "RFQ",
};

export const TRADE_TYPE_TITLES: Record<DealTradeType, string> = {
    [DealTradeType.Regular]: "",
    [DealTradeType.Liquidation]: "Liquidation",
    [DealTradeType.Authorized]: "Authorized",
};

type OrderTypeCellProps = {
    orderType: OrderType;
    tradeType?: DealTradeType;
};

export const OrderTypeCell: FC<OrderTypeCellProps> = ({
    orderType,
    tradeType = DealTradeType.Regular,
}) => {
    if (orderType === undefined) {
        return null;
    }

    return (
        <VStack>
            <P>{ORDER_TYPE_TITLES[orderType]}</P>
            {Boolean(TRADE_TYPE_TITLES[tradeType]) && (
                <TradeTypeText>{TRADE_TYPE_TITLES[tradeType]}</TradeTypeText>
            )}
        </VStack>
    );
};
