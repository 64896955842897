import { P } from "@fm-frontend/uikit";
import { createColumnHelper } from "@tanstack/react-table";
import { ClientCell } from "~components/Table/Cell/ClientCell";
import DateTimeViewer from "~components/Table/Cell/DateTimeViewer";
import { EllipsisCellTheme } from "~components/Table/CellTheme/Ellipsis";
import { Referral } from "~entities/referral";
import { InvitationAction } from "~pages/Overview/InvitationAction";
import { EmailCell } from "~pages/Referrals/EmailCell";
import { StatusCell } from "~pages/Referrals/StatusCell";
import { getTnCSaTitle } from "~pages/Referrals/utils";
import { getClientTypeTitle } from "~utils/getClientTypeTitle";
import { sortTimestamp } from "~utils/sortTimestamp";

const columnHelper = createColumnHelper<Referral>();

export const columns = [
    columnHelper.accessor("name", {
        header: "Name",
        cell: (info) => <ClientCell id={info.row.original.clientId} name={info.getValue()} />,
        meta: {
            headerStyleProps: {
                width: "300px",
            },
        },
    }),
    columnHelper.accessor("email", {
        header: "Email",
        cell: (info) => (
            <EllipsisCellTheme maxWidth={300}>
                <EmailCell value={info.getValue()} />
            </EllipsisCellTheme>
        ),
        meta: {
            headerStyleProps: {
                width: "300px",
            },
        },
    }),
    columnHelper.accessor("clientType", {
        header: "Type",
        cell: (info) => <P>{getClientTypeTitle(info.getValue())} </P>,
    }),
    columnHelper.accessor("subsFmRelationType", {
        header: "T&C SA",
        cell: (info) => (
            <EllipsisCellTheme maxWidth={110}>
                <>{getTnCSaTitle(info.getValue())}</>
            </EllipsisCellTheme>
        ),
        meta: {
            headerStyleProps: {
                width: "110px",
            },
        },
    }),
    columnHelper.accessor("status", {
        header: "Status",
        cell: (info) => <StatusCell value={info.row.original} />,
        meta: {
            headerStyleProps: {
                width: "110px",
            },
        },
    }),
    columnHelper.accessor("createdAt", {
        header: "Created",
        sortingFn: sortTimestamp,
        cell: (info) => <DateTimeViewer value={info.getValue() / 1000} />,
        meta: {
            headerStyleProps: {
                width: "90px",
            },
        },
    }),
    columnHelper.display({
        id: "actions",
        cell: (info) => (
            <InvitationAction
                clientId={info.row.original.clientId}
                status={info.row.original.status}
                referralEmail={info.row.original.email}
                referralType={info.row.original.clientType}
            />
        ),
        enableSorting: false,
        meta: {
            cellStyleProps: {
                textAlign: "right",
            },
        },
    }),
];
