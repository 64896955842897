import { EXPAND_TABLE_COLUMN_KEY, Flex, Icons } from "@fm-frontend/uikit";
import { ColumnHelper } from "@tanstack/react-table";
// eslint-disable-next-line import/no-extraneous-dependencies
import { RowData } from "@tanstack/table-core/src/types";
import styled from "styled-components";

const TableRowExpandContainer = styled(Flex)`
    cursor: pointer;
`;

export const getExpandColumn = <TData extends RowData>(columnHelper: ColumnHelper<TData>) =>
    columnHelper.display({
        id: EXPAND_TABLE_COLUMN_KEY,
        header: ({ table }) => {
            const isAllRowsExpanded = table.getIsAllRowsExpanded();

            return (
                <TableRowExpandContainer onClick={table.getToggleAllRowsExpandedHandler()}>
                    {isAllRowsExpanded ? <Icons.CollapseAll /> : <Icons.ExpandAll />}
                </TableRowExpandContainer>
            );
        },
        cell: ({ row }) => {
            if (!row.getCanExpand()) {
                return null;
            }

            return row.getIsExpanded() ? <Icons.Collapse /> : <Icons.Expand />;
        },
        meta: {
            headerStyleProps: {
                width: "32px",
                maxWidth: "32px",
            },
            cellStyleProps: {
                width: "32px",
                maxWidth: "32px",
            },
        },
    });
