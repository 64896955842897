import { Icons, Loading } from "@fm-frontend/uikit";
import { EMPTY_ARRAY } from "@fm-frontend/uikit/src/const";
import { FC } from "react";
import styled from "styled-components";
import ModalButton from "~components/ModalButton";
import { ResetCredentialsModal } from "~components/ResetCredentialsModal";
import { useUsersApi } from "~hooks/api/useUsersApi";
import { useClientId } from "~hooks/useClientId";
import { ButtonItem } from "../../Item";

const Content = styled.div`
    text-align: center;
    padding: 22px 12px;
    color: ${(p) => p.theme.colors.ui100};
    width: 360px;
`;

type ModalContentProps = {
    onClose: () => void;
};

const ModalContent: FC<ModalContentProps> = ({ onClose }) => {
    const clientId = useClientId();
    const { data = EMPTY_ARRAY, isLoading } = useUsersApi(clientId);
    const owner = data.find((item) => item.owner);

    if (isLoading) {
        return (
            <Content>
                <Loading />
            </Content>
        );
    }

    if (owner === undefined) {
        return <Content>There is no owner user</Content>;
    }

    return <ResetCredentialsModal user={owner} onClose={onClose} />;
};

export const ResetCredentials = () => (
    <ModalButton
        renderModalContent={(closePopover) => <ModalContent onClose={closePopover} />}
        renderButton={(handleClick) => (
            <ButtonItem onClick={handleClick}>
                Reset owner’s credentials <Icons.Reload />
            </ButtonItem>
        )}
    />
);
