import {
    FieldGroup,
    Form,
    FormActions,
    FormBody,
    Header as FormHeader,
    HeaderTitle,
    P,
    SimpleDropdown,
    SimpleInput,
} from "@fm-frontend/uikit";
import { FC, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import FormError from "~components/FormError";
import { PrimaryButton } from "~components/PrimaryButton";
import { ERROR_MESSAGES } from "~constants/errors";
import { ValidateErrors } from "~constants/form";
import { useHRPInstrumentsApi } from "~hooks/api/useHRPInstrumentsApi";
import { useHRPMarketsApi } from "~hooks/api/useHRPMarketsApi";
import { useInstrumentsApi } from "~hooks/api/useInstrumentsApi";
import useNotifications from "~hooks/useNotifications";
import { requestAdminery, requestHrpReporting } from "~utils/api";
import { prepareRequestError } from "~utils/prepareRequestError";

const FormContainer = styled(Form)`
    width: 100%;
    min-width: initial;
    padding: 8px;
`;

type AddInstrumentFormType = {
    name: string;
    base: string;
    quote: string;
    hrpSymbol?: string;
};

type AddFormProps = {
    onDone: () => void;
};

const NONE_HRP_SYMBOL = "";

export const AddForm: FC<AddFormProps> = ({ onDone }) => {
    const { mutate: mutateHRPInstruments } = useHRPInstrumentsApi();
    const { data: hrpMarkets = [] } = useHRPMarketsApi();
    const { data: apiData, mutate: mutateInstruments } = useInstrumentsApi();
    const { currencies = [] } = apiData ?? {};
    const sortedCurrencies = useMemo(
        () => [...currencies].sort((a, b) => a.name.localeCompare(b.name)),
        [currencies],
    );
    const { showSuccessNotification, showErrorNotification } = useNotifications();
    const {
        control,
        register,
        setValue,
        watch,
        formState: { errors },
        handleSubmit,
    } = useForm<AddInstrumentFormType>({
        defaultValues: {
            base: sortedCurrencies[0].name,
            quote: sortedCurrencies[1].name,
            name: `${sortedCurrencies[0].name}-${sortedCurrencies[1].name}`,
            hrpSymbol: NONE_HRP_SYMBOL,
        },
    });
    const [loading, setLoading] = useState(false);
    const [submitError, setSubmitError] = useState<string>();

    const [name, base, quote] = watch(["name", "base", "quote"]);

    useEffect(() => {
        if (base !== "" && quote !== "") {
            setValue("name", `${base}-${quote}`);
        }
    }, [base, quote, setValue]);

    useEffect(() => {
        const [newBase = "", newQuote = ""] = name.split("-");
        const newBaseExist = sortedCurrencies.some((currency) => currency.name === newBase);
        const newQuoteExist = sortedCurrencies.some((currency) => currency.name === newQuote);

        setValue("base", newBaseExist ? newBase : "");
        setValue("quote", newQuoteExist ? newQuote : "");
    }, [sortedCurrencies, name, setValue]);

    const handleInstrumentMappingToHRP = async (data: AddInstrumentFormType) => {
        try {
            if (!data.hrpSymbol) {
                return undefined;
            }

            const response = await requestHrpReporting("mapInstrument", {
                fmInstrument: data.name,
                hrpInstrument: data.hrpSymbol,
            });

            if (!response?.error) {
                await mutateHRPInstruments();

                showSuccessNotification(
                    `Instrument ${data.name} was successfully mapped to HRP symbol ${data.hrpSymbol}`,
                );
            }
            if (response?.error) {
                showErrorNotification(
                    `Failed to map instrument ${data.name} to  HRP symbol ${
                        data.hrpSymbol
                    }. Reason: ${ERROR_MESSAGES[response.error] ?? response.error}`,
                );
            }
        } catch (e) {
            showErrorNotification(
                `Failed to map instrument ${data.name} to HRP symbol ${
                    data.hrpSymbol
                }. Reason: ${prepareRequestError(e)}`,
            );
        }
    };

    const handleInstrumentAdding = async (data: AddInstrumentFormType) => {
        const response = await requestAdminery("addInstrument", {
            name: data.name,
        });

        await mutateInstruments();

        if (!response?.error) {
            showSuccessNotification(`Instrument ${data.name} was successfully added`);
        }
        if (response?.error) {
            setSubmitError(ERROR_MESSAGES[response.error] ?? response.error);
        }

        return response;
    };

    const handleInstrumentSubmit = handleSubmit(async (data) => {
        try {
            setSubmitError(undefined);
            setLoading(true);

            const instrumentResponse = await handleInstrumentAdding(data);

            if (!instrumentResponse?.error) {
                await handleInstrumentMappingToHRP(data);
                onDone();
            }
        } catch (e) {
            setSubmitError(prepareRequestError(e));
        } finally {
            setLoading(false);
        }
    });

    const options = sortedCurrencies.map((currency) => ({
        text: currency.name,
        value: currency.name,
    }));
    const baseOptions = options.filter((option) => option.value !== quote);
    const quoteOptions = options.filter((option) => option.value !== base);
    const hrpMarketsOptions = [
        {
            text: "N/A",
            value: NONE_HRP_SYMBOL,
        },
        ...hrpMarkets.map((hrpMarket) => ({
            value: hrpMarket.symbol,
            text: hrpMarket.symbol,
        })),
    ];

    return (
        <FormContainer onSubmit={handleInstrumentSubmit}>
            <FormHeader>
                <HeaderTitle title="Add new instruments" />
            </FormHeader>
            <FormBody alignItems="stretch">
                <FieldGroup>
                    <SimpleDropdown
                        options={baseOptions}
                        control={control}
                        label="Base"
                        name="base"
                    />
                    <SimpleDropdown
                        options={quoteOptions}
                        control={control}
                        label="Quote"
                        name="quote"
                    />
                    <SimpleInput
                        label="Name"
                        placeholder="BTC-USD"
                        error={errors.name?.message}
                        {...register("name", {
                            required: ValidateErrors.required,
                            onChange: (e) => setValue("name", e.target.value.toUpperCase()),
                        })}
                    />
                    <SimpleDropdown
                        options={hrpMarketsOptions}
                        control={control}
                        label="HRP symbol"
                        name="hrpSymbol"
                        renderText={(text, value) => (
                            <P>{value === NONE_HRP_SYMBOL ? NONE_HRP_SYMBOL : text}</P>
                        )}
                        presetSearchQuery={base}
                        key={base}
                    />
                </FieldGroup>
                {submitError && <FormError message={submitError} />}
            </FormBody>
            <FormActions variant="plain">
                <PrimaryButton size="large" fullWidth loading={loading}>
                    Add instrument
                </PrimaryButton>
            </FormActions>
        </FormContainer>
    );
};
