import { InfiniteTable as UIKitTable } from "@fm-frontend/uikit";
import { ValueFormat } from "@fm-frontend/utils";
import {
    createColumnHelper,
    getCoreRowModel,
    getSortedRowModel,
    SortingState,
} from "@tanstack/react-table";
import { useMemo, useState } from "react";
import styled from "styled-components";
import Gap from "~components/Gap";
import OptionsContainer from "~components/Table/Options/Container";
import ExportButton from "~components/Table/Options/ExportButton";
import Search from "~components/Table/Options/Search";
import { TableContext, useTableContextValue } from "~components/Table/TableContext";
import { Currency } from "~entities/currency";
import { useInstrumentsApi } from "~hooks/api/useInstrumentsApi";
import AddButton from "~pages/AssetsAndInstruments/Assets/AddButton";
import CurrencyActions from "~pages/AssetsAndInstruments/Assets/CurrencyActions";
import CurrencyBalanceStep from "~pages/AssetsAndInstruments/Assets/CurrencyBalanceStep";
import CurrencyCode from "~pages/AssetsAndInstruments/Assets/CurrencyCode";
import { CurrencyNetworksCell } from "~pages/AssetsAndInstruments/Assets/CurrencyNetworksCell";
import CurrencyPrice from "~pages/AssetsAndInstruments/Assets/CurrencyPrice";
import { getCurrencyTypeTitle } from "~pages/AssetsAndInstruments/Assets/utils";
import { TabList } from "~pages/AssetsAndInstruments/TabList";

type TableData = Currency & { networks: string[] };

export const Table = styled(UIKitTable<TableData>)`
    min-width: 1010px;

    th:first-of-type,
    td:first-of-type {
        padding-left: 12px !important;
    }
`;

const columnHelper = createColumnHelper<TableData>();

const currenciesTableColumns = [
    columnHelper.accessor("name", {
        header: "Code",
        cell: (info) => <CurrencyCode value={info.getValue()} />,
        meta: {
            headerStyleProps: {
                width: "160px",
            },
        },
    }),
    columnHelper.accessor("currencyType", {
        header: "Type",
        cell: (info) => getCurrencyTypeTitle(info.getValue()),
        meta: {
            headerStyleProps: {
                width: "200px",
            },
        },
    }),
    columnHelper.accessor("balanceStep", {
        header: "Balance step",
        cell: (info) => <CurrencyBalanceStep value={info.getValue()} />,
        meta: {
            headerStyleProps: {
                width: "150px",
            },
        },
    }),
    columnHelper.accessor("networks", {
        id: "networks",
        header: "Networks",
        cell: (info) => <CurrencyNetworksCell value={info.getValue()} />,
    }),
    columnHelper.accessor("price", {
        header: "Price, USD",
        cell: (info) => <CurrencyPrice value={info.getValue()} />,
        meta: {
            headerStyleProps: {
                width: "140px",
            },
            cellStyleProps: {
                textAlign: "end",
            },
        },
    }),
    columnHelper.display({
        id: "actions",
        cell: (info) => <CurrencyActions currency={info.row.original} />,
        enableSorting: false,
        meta: {
            headerStyleProps: {
                width: "150px",
            },
        },
    }),
];

const isMatch = (data: TableData, query: string) => {
    const { name, networks } = data;
    const normalizedQuery = query.toUpperCase();

    return (
        name.toUpperCase().startsWith(normalizedQuery) ||
        networks.some((network) => network.toUpperCase().includes(normalizedQuery))
    );
};

export const Assets = () => {
    const { data: apiData, isLoading: isApiLoading } = useInstrumentsApi();
    const { currencies = [] } = apiData ?? {};
    const [sorting, setSorting] = useState<SortingState>([]);
    const tableContextValue = useTableContextValue();
    const { query, setQuery } = tableContextValue;

    const tableData = useMemo(() => {
        if (query === "") {
            return currencies;
        }

        return currencies.filter((currency) => isMatch(currency, query));
    }, [currencies, query]);

    const getExportData = () =>
        tableData.map((item) => ({
            Code: item.name,
            "Currency ID": item.currencyId,
            "Currency Type": item.currencyType,
            "Balance step": ValueFormat.size(item.balanceStep),
            Networks: item.networks.join(", "),
            "Price, USD": ValueFormat.price(item.price, 2),
        }));

    return (
        <TableContext.Provider value={tableContextValue}>
            <OptionsContainer>
                <Search query={query} onChange={setQuery} />
                <TabList />
                <Gap />
                <ExportButton
                    data={getExportData}
                    filename="assets"
                    loading={isApiLoading}
                    disabled={tableData.length === 0}
                />
                <AddButton />
            </OptionsContainer>
            <Table
                tableOptions={{
                    data: tableData,
                    columns: currenciesTableColumns,
                    state: {
                        sorting,
                    },
                    onSortingChange: setSorting,
                    getCoreRowModel: getCoreRowModel(),
                    getSortedRowModel: getSortedRowModel(),
                }}
                onInitialize={(currenciesTable) => {
                    currenciesTable.setSorting([{ id: "name", desc: false }]);
                }}
                isLoading={isApiLoading}
            />
        </TableContext.Provider>
    );
};
