import { ValueFormat } from "@fm-frontend/utils";
import { FC } from "react";

type CurrencyBalanceStepProps = {
    value: number | bigint;
};

const CurrencyBalanceStep: FC<CurrencyBalanceStepProps> = ({ value }) => (
    <div>{ValueFormat.size(value)}</div>
);

export default CurrencyBalanceStep;
