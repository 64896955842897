import { FC, useContext } from "react";
import { AuthState } from "~pages/Auth/consts";
import { AuthContext } from "~pages/Auth/context";
import ChangePasswordContent from "./ChangePassword";
import ConfirmMfaCodeContent from "./ConfirmMfaCode";
import LoginContent from "./Login";
import ResetPasswordContent from "./ResetPassword";
import ResetPasswordSubmitContent from "./ResetPasswordSubmit";
import SetupMfaCodeContent from "./SetupMfaCode";

const Content: FC = () => {
    const { authState } = useContext(AuthContext);

    switch (authState) {
        case AuthState.Login:
            return <LoginContent />;
        case AuthState.ConfirmMfaCode:
            return <ConfirmMfaCodeContent />;
        case AuthState.SetupMfaCode:
            return <SetupMfaCodeContent />;
        case AuthState.ResetPassword:
            return <ResetPasswordContent />;
        case AuthState.ResetPasswordSubmit:
            return <ResetPasswordSubmitContent />;
        case AuthState.ChangePassword:
            return <ChangePasswordContent />;
        default:
            throw new Error(`Wrong auth state: ${authState}`);
    }
};

export default Content;
