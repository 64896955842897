import { HStack, Icons, P, Tooltip } from "@fm-frontend/uikit";
import React, { useContext } from "react";
import styled from "styled-components";
import { ProfileContext } from "~pages/Profile/Context";

const Property = styled.div`
    display: flex;
    box-shadow: inset 0 -1px 0 ${(p) => p.theme.colors.ui8};
`;
const Title = styled(P)`
    height: 44px;
    line-height: 44px;
`;
const Value = styled(P)`
    height: 44px;
    line-height: 44px;
`;

export const HRPGroup: React.VFC = () => {
    const { hrpId } = useContext(ProfileContext);

    return (
        <Property>
            <HStack paddingX={12} alignItems="center" spacing={4} minWidth="180px">
                <Title>HRP ID</Title>
                <Tooltip
                    align="center"
                    content="Check with HRP directly the ID of the counterparty"
                >
                    <Icons.TooltipQuestion />
                </Tooltip>
            </HStack>
            <Value>{hrpId}</Value>
        </Property>
    );
};
