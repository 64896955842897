import { CognitoUser } from "amazon-cognito-identity-js";
import { Auth } from "aws-amplify";
import { useState } from "react";
import { AuthenticationException, AuthState, ChallengeName } from "~pages/Auth/consts";

const getAuthState = (challengeName: string | undefined) => {
    switch (challengeName) {
        case ChallengeName.SoftwareTokenMfa:
            return AuthState.ConfirmMfaCode;
        case ChallengeName.NewPasswordRequired:
            return AuthState.ChangePassword;
        case ChallengeName.MfaSetup:
            return AuthState.SetupMfaCode;
        default:
            return AuthState.Login;
    }
};

const useSignIn = () => {
    const [loading, setLoading] = useState<boolean>(false);

    const signIn = (email: string, password: string) => {
        setLoading(true);

        return new Promise<{ nextAuthState: AuthState; userState?: CognitoUser; error?: string }>(
            (resolve) => {
                Auth.signIn(email, password)
                    .then((user: CognitoUser) => {
                        resolve({
                            nextAuthState: getAuthState(user.challengeName),
                            userState: user,
                        });
                    })
                    .catch((e) => {
                        if (e?.code === AuthenticationException.PasswordResetRequired) {
                            resolve({
                                nextAuthState: AuthState.ResetPassword,
                                error: e?.message,
                            });
                        } else {
                            resolve({
                                nextAuthState: AuthState.Login,
                                error: e?.message,
                            });
                        }
                    })
                    .finally(() => setLoading(false));
            },
        );
    };

    return { signIn, loading };
};

export default useSignIn;
