import { getMatch } from "@fm-frontend/uikit";
import { FC } from "react";

type MatchContainsProps = {
    value: string;
    query: string;
    className?: string;
};

const MatchContains: FC<MatchContainsProps> = ({ value, query, className }) => {
    return <span className={className}>{getMatch(value, query) ?? value}</span>;
};

export default MatchContains;
