import {
    FieldGroup,
    Form,
    FormActions,
    FormBody,
    Header as FormHeader,
    HeaderTitle,
    SimpleInput,
    SimpleMultiDropdown,
} from "@fm-frontend/uikit";
import { FC, useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import FormError from "~components/FormError";
import { PrimaryButton } from "~components/PrimaryButton";
import { ERROR_MESSAGES } from "~constants/errors";
import { ValidateErrors } from "~constants/form";
import { NetworkName } from "~entities/network";
import { useInstrumentsApi } from "~hooks/api/useInstrumentsApi";
import useNotifications from "~hooks/useNotifications";
import { requestAdminery } from "~utils/api";
import { prepareRequestError } from "~utils/prepareRequestError";

const FormContainer = styled(Form)`
    width: 100%;
    min-width: initial;
    padding: 8px;
`;

type AddNetworkFormType = {
    name: string;
    description: string;
    currencies?: string[];
};

type AddFormProps = {
    onDone: () => void;
};

export const AddForm: FC<AddFormProps> = ({ onDone }) => {
    const { data: apiData, isLoading: isApiLoading, mutate } = useInstrumentsApi();
    const { currencies = [] } = apiData ?? {};

    const { showSuccessNotification } = useNotifications();
    const {
        control,
        register,
        formState: { errors },
        handleSubmit,
    } = useForm<AddNetworkFormType>();
    const [loading, setLoading] = useState(false);
    const [submitError, setSubmitError] = useState<string>();

    const handleNetworkSubmit = handleSubmit(async (data) => {
        try {
            setSubmitError(undefined);
            setLoading(true);
            const response = await requestAdminery("addNetwork", {
                name: data.name,
                description: data.description,
            });

            const currencyNetworks = currencies.reduce((acc, currency) => {
                acc[currency.name] = currency.networks;

                return acc;
            }, {} as Record<string, NetworkName[]>);

            if (data.currencies !== undefined) {
                await Promise.all(
                    data.currencies.map((currency) =>
                        requestAdminery("assignNetworks", {
                            currency,
                            networks: [...new Set([...currencyNetworks[currency], data.name])],
                        }),
                    ),
                );
            }

            if (!response?.error && apiData !== undefined) {
                mutate({
                    ...apiData,
                    networks: [
                        ...apiData.networks,
                        {
                            name: data.name,
                            description: data.description,
                        },
                    ],
                });
            }

            if (!response?.error) {
                onDone();
                showSuccessNotification(`Network ${data.name} was successfully added`);
            }
            if (response?.error) {
                setSubmitError(ERROR_MESSAGES[response.error] ?? response.error);
            }
        } catch (e) {
            setSubmitError(prepareRequestError(e));
        } finally {
            setLoading(false);
        }
    });

    const currenciesOptions = currencies.map((currency) => ({
        text: currency.name,
        value: currency.name,
    }));

    return (
        <FormContainer onSubmit={handleNetworkSubmit}>
            <FormHeader>
                <HeaderTitle title="Add new network" />
            </FormHeader>
            <FormBody alignItems="stretch">
                <FieldGroup>
                    <SimpleInput
                        label="Name"
                        placeholder="ERC20"
                        error={errors.name?.message}
                        {...register("name", {
                            required: ValidateErrors.required,
                        })}
                    />
                    <SimpleInput
                        label="Description"
                        placeholder="Simple description"
                        error={errors.description?.message}
                        {...register("description", {
                            required: ValidateErrors.required,
                        })}
                    />
                </FieldGroup>
                <FieldGroup>
                    <SimpleMultiDropdown
                        options={currenciesOptions}
                        control={control}
                        name="currencies"
                        label="Assets"
                        fullWidth
                        disabled={isApiLoading}
                    />
                </FieldGroup>
                {submitError && <FormError message={submitError} />}
            </FormBody>
            <FormActions variant="plain">
                <PrimaryButton size="large" fullWidth loading={loading}>
                    Add network
                </PrimaryButton>
            </FormActions>
        </FormContainer>
    );
};
