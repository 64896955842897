import { getMatch } from "@fm-frontend/uikit";
import {
    Container as ItemContainer,
    Content as ItemContent,
} from "@fm-frontend/uikit/src/components/dropdowns/common/Item";
import { Checkmark } from "@fm-frontend/uikit/src/components/icons/styled";
import { FC, useContext } from "react";
import styled, { css, useTheme } from "styled-components";
import { ClientSelectContext } from "~components/Layout/Menu/ClientSelect/Context";
import { Client, ClientId } from "~entities/client";
import getMatchStartsWith from "~utils/getMatchStartsWith";

const Container = styled(ItemContainer)`
    border-radius: 0;
    box-shadow: inset 0px -1px 0px ${(p) => p.theme.colors.ui8};
    min-height: 44px;
    padding: 0 12px;

    ${(p) =>
        p.isActive &&
        css`
            color: ${p.theme.colors.uiWhite100};
            background: ${p.theme.colors.ui100};

            :hover {
                background: ${p.theme.colors.ui96} !important;
            }
        `}

    mark {
        background-color: ${(p) => p.theme.colors.brand32};
    }

    svg {
        position: initial;
    }

    :hover {
        background: ${(p) => p.theme.colors.ui4};
    }
`;

const Name = styled(ItemContent)`
    flex: 1;
    font-weight: 600;
    font-size: 14px;
    white-space: pre-wrap;
`;

const Id = styled.div`
    font-weight: 400;
    font-size: 14px;
`;

type ClientSelectItemProps = {
    active: boolean;
    client: Client;
    onSelect?: (clientId: ClientId) => void;
};

export const Item: FC<ClientSelectItemProps> = ({ active, client, onSelect }) => {
    const { colors } = useTheme();
    const { query } = useContext(ClientSelectContext);

    return (
        <Container isActive={active} onClick={() => onSelect?.(client.id)}>
            <Name>{getMatch(client.username, query) ?? client.username}</Name>
            <Id>{getMatchStartsWith(String(client.id), query) ?? client.id}</Id>
            {active && <Checkmark color={colors.uiWhite100} />}
        </Container>
    );
};
