import { Popover } from "@fm-frontend/uikit";
import { PopoverContent } from "@fm-frontend/uikit/src/components/dropdowns/common";
import { useModalControl } from "@fm-frontend/utils";
import { FC, ReactNode } from "react";
import styled from "styled-components";

const PopoverContainer = styled(PopoverContent)`
    max-height: initial;
`;

type PopoverButtonProps = {
    renderPopoverContent: (closePopover: () => void) => ReactNode;
    renderButton: (handleClick: () => void) => JSX.Element;
};

const PopoverButton: FC<PopoverButtonProps> = ({ renderPopoverContent, renderButton }) => {
    const { isModalOpen, openModal, closeModal } = useModalControl(false);

    return (
        <Popover
            isOpen={isModalOpen}
            padding={9}
            positions={["bottom"]}
            align="start"
            onClickOutside={closeModal}
            content={<PopoverContainer>{renderPopoverContent(closeModal)}</PopoverContainer>}
        >
            {renderButton(openModal)}
        </Popover>
    );
};

export default PopoverButton;
