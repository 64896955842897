import { PBold } from "@fm-frontend/uikit";
import { FC } from "react";

type SideCellProps = {
    value: "in" | "out";
};

export const SideCell: FC<SideCellProps> = ({ value }) => {
    const label = value === "in" ? "In" : "Out";

    return <PBold>{label}</PBold>;
};
