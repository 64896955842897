import { Badge, BadgeVariant } from "@fm-frontend/uikit";
import { FC } from "react";
import { CounterpartyStatus } from "~pages/RisksManagement/List/utils";
import { CounterpartyStatusType } from "~pages/RisksManagement/utils/status";

const getBudgeVariant = (status: CounterpartyStatusType): BadgeVariant => {
    switch (status) {
        case CounterpartyStatusType.NORMAL:
            return "positive";
        case CounterpartyStatusType.AWAITING_CP:
            return "process";
        case CounterpartyStatusType.MARGIN_CALL:
            return "attention";
        case CounterpartyStatusType.STOPPED:
        case CounterpartyStatusType.STOPPED_BY_CP:
            return "info";
        case CounterpartyStatusType.LOW_GROSS_FREE:
        case CounterpartyStatusType.RESTRICTED_TRADING:
        case CounterpartyStatusType.LIQUIDATION:
            return "negative";
        default:
            return "info";
    }
};

type StatusBadgeProps = {
    status: CounterpartyStatusType | undefined;
};

export const StatusBadge: FC<StatusBadgeProps> = ({ status }) => {
    if (status === undefined) {
        return null;
    }

    const { title } = CounterpartyStatus[status];
    const variant = getBudgeVariant(status);

    return <Badge variant={variant} value={title} />;
};
