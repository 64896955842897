import {
    BasicInput,
    Form,
    FormActions,
    FormBody,
    FormInfo,
    Header as FormHeader,
    HeaderTitle,
    ShadowCard,
} from "@fm-frontend/uikit";
import { FC, useContext, useState } from "react";
import { useForm } from "react-hook-form";
import FormError from "~components/FormError";
import { PrimaryButton } from "~components/PrimaryButton";
import { AuthContext } from "~pages/Auth/context";
import useSetupTOTP from "~pages/Auth/hooks/useSetupTOTP";
import useVerifyTotpToken from "~pages/Auth/hooks/useVerifyTotpToken";
import { ValidationRules } from "~pages/Auth/utils";
import MfaTokenSection from "./MfaTokenSection";

type SetupMfaCodeForm = {
    code: string;
};

const SetupMfaCodeContent: FC = () => {
    const { userState, setAuthState } = useContext(AuthContext);
    const { mfaToken, mfaError } = useSetupTOTP(userState);
    const { verifyTotpToken, loading } = useVerifyTotpToken(userState);
    const [error, setError] = useState<string>();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<SetupMfaCodeForm>({
        mode: "onChange",
    });

    const handleFormSubmit = handleSubmit((data) => {
        verifyTotpToken(data.code)
            .then(setAuthState)
            .catch((e) => setError(e?.message));
    });

    return (
        <Form onSubmit={handleFormSubmit}>
            <ShadowCard>
                <FormHeader>
                    <HeaderTitle title="Setup 2FA" />
                </FormHeader>
                <FormBody>
                    <MfaTokenSection mfaToken={mfaToken} />
                    <FormInfo>
                        We strongly recommend you to keep this key in a safe place to restore the
                        access in case of a device loss.
                    </FormInfo>
                    <BasicInput
                        type="number"
                        label="Code"
                        placeholder="000000"
                        error={errors.code?.message}
                        {...register("code", {
                            ...ValidationRules.code,
                        })}
                    />
                    {mfaError && <FormError message={mfaError} />}
                    {error && <FormError message={error} />}
                </FormBody>
                <FormActions variant="plain">
                    <PrimaryButton type="submit" fullWidth size="large" loading={loading}>
                        Confirm
                    </PrimaryButton>
                </FormActions>
            </ShadowCard>
        </Form>
    );
};

export default SetupMfaCodeContent;
