import { FC } from "react";
import { ClientId } from "~entities/client";
import useTakerInfoApi from "~hooks/api/useTakerInfoApi";
import { HRPGroup } from "~pages/Profile/Groups/HRPGroup";
import ProfileGroup from "~pages/Profile/Groups/ProfileGroup";
import TakerGeneralInfo from "~pages/Profile/Groups/TakerGeneralInfoGroup";
import TermsAndConditions from "~pages/Profile/Groups/TermsAndConditionsGroup";
import useCommonExportData from "~pages/Profile/hooks/useCommonExportData";
import Options from "~pages/Profile/Options";

type TakerProfileProps = {
    clientId: ClientId;
};

const TakerProfile: FC<TakerProfileProps> = ({ clientId }) => {
    const { takerInfo } = useTakerInfoApi(clientId);
    const getCommonExportData = useCommonExportData();

    const getExportData = () => [
        ...getCommonExportData(),
        { title: "" },
        { title: "General Information" },
        { title: "Business", value: takerInfo?.business_type },
        {
            title: "Exp. Turnover",
            value:
                takerInfo?.monthly_turnover &&
                `${takerInfo.monthly_turnover?.toLocaleString("en-US")} USD per month`,
        },
        { title: "How often trade", value: takerInfo?.trade_frequency },
        { title: "How often settle", value: takerInfo?.settle_frequency },
        { title: "Banks", value: takerInfo?.bank?.map((bank) => bank.name).join(", ") },
        { title: "Website", value: takerInfo?.website },
        { title: "Telegram", value: takerInfo?.telegram },
        {
            title: "Assets",
            value: takerInfo?.currency?.map((currency) => currency.name).join(", "),
        },
    ];

    return (
        <>
            <Options exportData={getExportData} />
            <ProfileGroup />
            <HRPGroup />
            <TermsAndConditions />
            <TakerGeneralInfo takerInfo={takerInfo} />
        </>
    );
};

export default TakerProfile;
