import useSWR from "swr";
import { ClientId, ClientType, SubsFmRelationType } from "~entities/client";
import { Referral, ReferralStatus } from "~entities/referral";
import { requestAdminery } from "~utils/api";

type RawReferralEntity = {
    email: string;
    name: string;
    isSubaccount: boolean;
    createdAt: number;
    expiresAt: number;
    clientId: ClientId;
    status: ReferralStatus;
    clientType: ClientType;
    subsFmRelationType: SubsFmRelationType | "";
};

const prepareData = ({
    email,
    name,
    isSubaccount,
    createdAt,
    expiresAt,
    clientId,
    status,
    clientType,
    subsFmRelationType,
}: RawReferralEntity): Referral => ({
    email,
    name,
    isSubaccount,
    createdAt,
    expiresAt,
    clientId,
    status,
    clientType,
    subsFmRelationType: subsFmRelationType === "" ? undefined : subsFmRelationType,
});

type SWRKey = [clientId: ClientId | undefined, key: string];

const fetcher = ([clientId]: SWRKey) =>
    requestAdminery("getInvitesList", {
        clientId,
    }).then((data) => data.map(prepareData));

export const useReferralsApi = (clientId: ClientId | undefined) =>
    useSWR<Referral[], unknown, SWRKey>([clientId, "useReferralsApi"], fetcher);
