import { P, PBold } from "@fm-frontend/uikit";
import { ValueFormat } from "@fm-frontend/utils";
import { FC } from "react";
import { useTheme } from "styled-components";

type VolumeCellProps = {
    value: number | bigint | undefined;
    fixed?: number;
    bold?: boolean;
};

export const VolumeCell: FC<VolumeCellProps> = ({ value, fixed, bold = false }) => {
    const theme = useTheme();

    if (value === undefined) {
        return null;
    }

    if (bold) {
        return <PBold>{ValueFormat.price(value, fixed)}</PBold>;
    }

    const color = value < 0n ? theme.colors.negative100 : "inherit";

    return <P color={color}>{ValueFormat.price(value, fixed)}</P>;
};
