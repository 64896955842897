import { P } from "@fm-frontend/uikit";
import { ValueFormat } from "@fm-frontend/utils";
import { FC } from "react";

type SizeCellProps = {
    value: number | bigint;
};

export const SizeCell: FC<SizeCellProps> = ({ value }) => {
    return <P>{ValueFormat.size(value)}</P>;
};
