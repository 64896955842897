import useSWR from "swr";
import { ClientId } from "~entities/client";
import { OnboardingStatus } from "~entities/onboardingStatus";
import { requestOnboarding } from "~utils/api";

type SWRKey = [clientId: ClientId[], key: string];

const fetcher = async ([clientId]: SWRKey): Promise<OnboardingStatus[]> => {
    return requestOnboarding("inviteStatus", { clientId });
};

export const useOnboardingStatusesApi = (clientId: ClientId[]) =>
    useSWR<OnboardingStatus[]>([clientId, "useOnboardingStatusesApi"], fetcher);
