import {
    FieldGroup,
    Form,
    FormActions,
    FormBody,
    Header as FormHeader,
    HeaderTitle,
    SimpleDropdown,
    SimpleInput,
    SwitchField,
} from "@fm-frontend/uikit";
import { FC, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import FormError from "~components/FormError";
import { PrimaryButton } from "~components/PrimaryButton";
import { ERROR_MESSAGES } from "~constants/errors";
import { ValidateErrors } from "~constants/form";
import { useInstrumentMarkups } from "~hooks/api/useInstrumentMarkups";
import { useInstrumentsApi } from "~hooks/api/useInstrumentsApi";
import useAppSelector from "~hooks/useAppSelector";
import { useClientId } from "~hooks/useClientId";
import useNotifications from "~hooks/useNotifications";
import { useCounterpartyId } from "~pages/RisksManagement/View/hooks/useCounterpartyId";
import { getClient } from "~store/clients/selectors";
import { requestAdminery } from "~utils/api";
import { prepareRequestError } from "~utils/prepareRequestError";

const INVALID_DELTA_MESSAGE = "Invalid delta";
const MIN_DELTA_MESSAGE = "Delta must be more than or equal -10%";
const MAX_DELTA_MESSAGE = "Delta must be less than or equal 10%";

const MIN_DELTA_VALIDATION = { value: -10, message: MIN_DELTA_MESSAGE };
const MAX_DELTA_VALIDATION = { value: 10, message: MAX_DELTA_MESSAGE };
const checkIsNumber = (v: number | undefined) => !Number.isNaN(v) || INVALID_DELTA_MESSAGE;
const checkMaxDecimals = (v: number | undefined) => {
    const [, decimals] = String(v).split(".");
    return (decimals?.length || 0) <= 4 || INVALID_DELTA_MESSAGE;
};

const FormContainer = styled(Form)`
    width: 100%;
    min-width: initial;
    padding: 8px;
`;

type EditAssetLimitFormType = {
    instrument: string;
    enableMarkups: boolean;
    bidMarkup?: number;
    askMarkup?: number;
};

type EditInstrumentMarkupFormProps = {
    instrument: string;
    onDone: () => void;
};

export const EditInstrumentMarkupForm: FC<EditInstrumentMarkupFormProps> = ({
    instrument,
    onDone,
}) => {
    const getClientById = useAppSelector(getClient);
    const { showSuccessNotification } = useNotifications();
    const clientId = useClientId();
    const counterpartyId = useCounterpartyId();
    const client = getClientById(clientId);
    const counterparty = getClientById(counterpartyId);

    const { data: instrumentMarkups, mutate: mutateInstrumentMarkups } =
        useInstrumentMarkups(clientId);
    const { data: instrumentsData } = useInstrumentsApi();
    const { instruments = [] } = instrumentsData ?? {};

    const [submitError, setSubmitError] = useState<string>();

    const defaultInstrumentMarkup = instrumentMarkups?.find(
        (markup) => markup.counterpartyId === counterpartyId && markup.instrument === instrument,
    );
    const defaultEnableMarkups =
        defaultInstrumentMarkup?.askMarkup !== undefined &&
        defaultInstrumentMarkup?.bidMarkup !== undefined;

    const {
        register,
        formState: { isSubmitting, errors, isDirty },
        handleSubmit,
        control,
        watch,
        setValue,
    } = useForm<EditAssetLimitFormType>({
        defaultValues: {
            instrument,
            enableMarkups: defaultEnableMarkups,
        },
    });

    const selectedInstrument = watch("instrument");
    const enableMarkups = watch("enableMarkups");

    const instrumentsOptions = useMemo(
        () =>
            instruments
                .map(({ name }) => ({ text: name, value: name }))
                .sort((a, b) => a.text.localeCompare(b.text)),
        [instruments],
    );

    useEffect(() => {
        const instrumentMarkup = instrumentMarkups?.find(
            (markup) =>
                markup.counterpartyId === counterpartyId &&
                markup.instrument === selectedInstrument,
        );
        setValue(
            "enableMarkups",
            instrumentMarkup?.askMarkup !== undefined && instrumentMarkup?.bidMarkup !== undefined,
        );
        setValue("askMarkup", (instrumentMarkup?.askMarkup ?? 0) / 1e4);
        setValue("bidMarkup", (instrumentMarkup?.bidMarkup ?? 0) / 1e4);
    }, [counterpartyId, instrumentMarkups, selectedInstrument, setValue]);

    const handleModifySubmit = handleSubmit(async (data) => {
        try {
            setSubmitError(undefined);

            const instrumentMarkupsResponse = data.enableMarkups
                ? await requestAdminery("addInstrumentMarkups", {
                      clientId,
                      counterpartyId,
                      instrument: data.instrument,
                      bidMarkup: Math.round((data.bidMarkup ?? 0) * 1e4),
                      askMarkup: Math.round((data.askMarkup ?? 0) * 1e4),
                  })
                : await requestAdminery("delInstrumentMarkups", {
                      clientId,
                      counterpartyId,
                      instrument: data.instrument,
                  });

            const { error } = instrumentMarkupsResponse;

            if (!error) {
                await mutateInstrumentMarkups();
                onDone();
                showSuccessNotification(`Instrument was successfully modified`);
            }
            if (error) {
                setSubmitError(ERROR_MESSAGES[error] ?? error);
            }
        } catch (e) {
            setSubmitError(prepareRequestError(e));
        }
    });

    return (
        <FormContainer onSubmit={handleModifySubmit} onClick={(e) => e.stopPropagation()}>
            <FormHeader>
                <HeaderTitle
                    title="Instruments"
                    label={`${client?.username} (${clientId}) setup for ${counterparty?.username} (${counterpartyId})`}
                />
            </FormHeader>
            <FormBody alignItems="stretch">
                <SimpleDropdown
                    label="Instrument"
                    name="instrument"
                    searchable
                    control={control}
                    rules={{ required: ValidateErrors.required }}
                    error={errors.instrument?.message}
                    options={instrumentsOptions}
                />
                <FieldGroup>
                    <SwitchField
                        text="Enable instrument markups"
                        variant="basic"
                        fullWidth
                        control={control}
                        {...register("enableMarkups")}
                    />
                    {enableMarkups && (
                        <>
                            <SimpleInput
                                label="Bid, %"
                                placeholder="0.00%"
                                error={errors.bidMarkup?.message}
                                {...register("bidMarkup", {
                                    valueAsNumber: true,
                                    required: ValidateErrors.required,
                                    min: MIN_DELTA_VALIDATION,
                                    max: MAX_DELTA_VALIDATION,
                                    validate: {
                                        checkIsNumber,
                                        checkMaxDecimals,
                                    },
                                })}
                            />
                            <SimpleInput
                                label="Ask, %"
                                placeholder="0.00%"
                                error={errors.askMarkup?.message}
                                {...register("askMarkup", {
                                    valueAsNumber: true,
                                    required: ValidateErrors.required,
                                    min: MIN_DELTA_VALIDATION,
                                    max: MAX_DELTA_VALIDATION,
                                    validate: {
                                        checkIsNumber,
                                        checkMaxDecimals,
                                    },
                                })}
                            />
                        </>
                    )}
                </FieldGroup>
                {submitError && <FormError message={submitError} />}
            </FormBody>
            <FormActions variant="plain">
                <PrimaryButton
                    type="submit"
                    size="large"
                    fullWidth
                    disabled={!isDirty}
                    loading={isSubmitting}
                >
                    Apply
                </PrimaryButton>
            </FormActions>
        </FormContainer>
    );
};
