import { InfiniteTable as UIKitTable } from "@fm-frontend/uikit";
import { getCoreRowModel, getExpandedRowModel } from "@tanstack/react-table";
import { ReactNode, useMemo } from "react";
import styled from "styled-components";
import OptionsContainer from "~components/Table/Options/Container";
import ExportButton from "~components/Table/Options/ExportButton";
import { useClientsApi } from "~hooks/api/useClientsApi";
import { useCounterpartyLimitsApi } from "~hooks/api/useCounterpartyLimitsApi";
import useAppSelector from "~hooks/useAppSelector";
import { useClientId } from "~hooks/useClientId";
import { useLimitStatuses } from "~hooks/useLimitStatuses";
import { EditCounterpartyLimitAction } from "~pages/RisksManagement/components/EditCounterpartyLimitAction";
import { StatusBadge } from "~pages/RisksManagement/components/StatusBadge";
import {
    CounterpartyStatus,
    getCurrency,
    getExposure,
    getGrossFree,
    getGrossLimit,
    getTradingEnabled,
    shouldUseUserGrossLimit,
} from "~pages/RisksManagement/List/utils";
import { BackButton } from "~pages/RisksManagement/View/components/BackButton";
import { HeaderCell } from "~pages/RisksManagement/View/components/HeaderCell";
import { useCounterpartyId } from "~pages/RisksManagement/View/hooks/useCounterpartyId";
import { TabList } from "~pages/RisksManagement/View/TabList";
import {
    getClientColumn,
    getCounterpartyColumn,
    getExtraColumn,
    getTitleColumn,
} from "~pages/RisksManagement/View/TradingLimits/columns";
import { MarginValue } from "~pages/RisksManagement/View/TradingLimits/MarginValue";
import { Item, RowType, SubItem } from "~pages/RisksManagement/View/TradingLimits/types";
import {
    getEquityPercent,
    getEquityValue,
    getMarginPercent,
    getMarginValue,
    getMarkup,
    isMarginActive,
} from "~pages/RisksManagement/View/TradingLimits/utils";
import { getClient, isClientsFetching } from "~store/clients/selectors";

const getInfoRow = (
    title = "",
    clientValue: ReactNode | null = null,
    counterpartyValue: ReactNode | null = null,
) => ({
    type: RowType.Info,
    title,
    clientValue,
    counterpartyValue,
});

const Table = styled(UIKitTable<Item | SubItem>)`
    min-width: 1010px;

    thead {
        th {
            //due to transparency nature of UIkit colors
            background-color: #606a6d;
            height: 32px;
            padding-right: 12px;
            padding-bottom: unset;

            span {
                font-size: 11px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
                letter-spacing: 0.44px;
                text-transform: uppercase;
                color: ${(p) => p.theme.colors.uiWhite100};
            }

            svg path {
                fill: ${(p) => p.theme.colors.uiWhite100};
            }
        }
    }

    td {
        border-right: 1px solid ${(p) => p.theme.colors.ui8};
        padding-right: 12px;
    }

    th:first-of-type,
    td:first-of-type {
        padding-left: 12px !important;
    }
`;

export const RisksManagementViewTradingLimits = () => {
    useClientsApi();

    const isClientsLoading = useAppSelector(isClientsFetching);
    const getClientById = useAppSelector(getClient);
    const clientId = useClientId();
    const counterpartyId = useCounterpartyId();
    const client = getClientById(clientId);
    const counterparty = getClientById(counterpartyId);
    const clientTitle = `${client?.username} (${clientId})`;
    const counterpartyTitle = `${counterparty?.username} (${counterpartyId})`;
    const { data: clientLimitsData, isLoading: isClientLimitsLoading } =
        useCounterpartyLimitsApi(clientId);
    const { data: counterpartyLimitsData, isLoading: isCounterpartyLimitsLoading } =
        useCounterpartyLimitsApi(counterpartyId);
    const { data: limitStatuses, isLoading: isLimitStatusesLoading } = useLimitStatuses(clientId);
    const clientLimit = clientLimitsData?.find((data) => data.counterpartyId === counterpartyId);
    const counterpartyLimit = counterpartyLimitsData?.find(
        (data) => data.counterpartyId === clientId,
    );
    const marginActive = isMarginActive(clientLimit, counterparty?.type, client?.type);
    const useUserGrossLimit = shouldUseUserGrossLimit(counterparty?.type, client?.type);

    const isLoading =
        isClientLimitsLoading ||
        isCounterpartyLimitsLoading ||
        isLimitStatusesLoading ||
        isClientsLoading;

    const data: Item[] = [
        {
            type: RowType.General,
            title: "General",
            items: [
                getInfoRow(
                    "Trading enabled",
                    getTradingEnabled(clientLimit) ? "Yes" : "No",
                    getTradingEnabled(counterpartyLimit) ? "Yes" : "No",
                ),
                getInfoRow("Markup", getMarkup(clientLimit), getMarkup(counterpartyLimit)),
                getInfoRow(
                    "Status",
                    null,
                    <StatusBadge status={limitStatuses?.[counterpartyId]} />,
                ),
            ],
        },
        {
            type: RowType.GrossLimit,
            title: "Gross limit",
            items: [
                getInfoRow(
                    "Gross limit",
                    getGrossLimit(clientLimit),
                    getGrossLimit(counterpartyLimit),
                ),
                getInfoRow("Currency", getCurrency(clientLimit), getCurrency(counterpartyLimit)),
                getInfoRow("Free", getGrossFree(clientLimit), getGrossFree(counterpartyLimit)),
                getInfoRow("Exposure", getExposure(clientLimit), getExposure(counterpartyLimit)),
            ],
        },
        {
            type: RowType.MarginRequirements,
            title: "Margin requirements",
            clientValue: marginActive ? "Enabled" : "Disable",
            ...(marginActive && {
                items: [
                    getInfoRow(
                        "Equity",
                        <MarginValue
                            percent={getEquityPercent(clientLimit, useUserGrossLimit)}
                            value={getEquityValue(clientLimit, useUserGrossLimit)}
                        />,
                    ),
                    getInfoRow(
                        "Maintenance",
                        <MarginValue
                            percent={getMarginPercent(clientLimit?.maintenanceMargin)}
                            value={getMarginValue(clientLimit?.maintenanceMargin, clientLimit)}
                        />,
                    ),
                    getInfoRow(
                        "Restricted trading",
                        <MarginValue
                            percent={getMarginPercent(clientLimit?.restrictedTrading)}
                            value={getMarginValue(clientLimit?.restrictedTrading, clientLimit)}
                        />,
                    ),
                    getInfoRow(
                        "Initial",
                        <MarginValue
                            percent={getMarginPercent(clientLimit?.initialMargin)}
                            value={getMarginValue(clientLimit?.initialMargin, clientLimit)}
                        />,
                    ),
                ],
            }),
        },
    ];

    const columns = useMemo(() => {
        return [
            getTitleColumn(),
            getClientColumn(clientTitle),
            getCounterpartyColumn(counterpartyTitle),
            getExtraColumn(),
        ];
    }, [clientTitle, counterpartyTitle]);

    const getExportRow = (title = "", clientValue = "", counterpartyValue = "") => ({
        title,
        [clientTitle]: clientValue,
        [counterpartyTitle]: counterpartyValue,
    });

    const getExportData = () => [
        getExportRow("General"),
        getExportRow(
            "Trading enabled",
            getTradingEnabled(clientLimit) ? "Yes" : "No",
            getTradingEnabled(counterpartyLimit) ? "Yes" : "No",
        ),
        getExportRow("Markup", getMarkup(clientLimit), getMarkup(counterpartyLimit)),
        getExportRow(
            "Status",
            "",
            limitStatuses?.[counterpartyId] !== undefined
                ? CounterpartyStatus[limitStatuses[counterpartyId]].title
                : "",
        ),
        getExportRow(),
        getExportRow("General"),
        getExportRow("Gross limit", getGrossLimit(clientLimit), getGrossLimit(counterpartyLimit)),
        getExportRow("Currency", getCurrency(clientLimit), getCurrency(counterpartyLimit)),
        getExportRow("Free", getGrossFree(clientLimit), getGrossFree(counterpartyLimit)),
        getExportRow("Exposure", getExposure(clientLimit), getExposure(counterpartyLimit)),
        getExportRow(),
        getExportRow("Margin requirements", marginActive ? "Enabled" : "Disable"),
        ...(marginActive
            ? [
                  getExportRow(
                      "Equity",
                      `${getEquityPercent(clientLimit, useUserGrossLimit)} ${getEquityValue(
                          clientLimit,
                          useUserGrossLimit,
                      )}`,
                  ),
                  getExportRow(
                      "Maintenance",
                      `${getMarginPercent(clientLimit?.maintenanceMargin)} ${getMarginValue(
                          clientLimit?.maintenanceMargin,
                          clientLimit,
                      )}`,
                  ),
                  getExportRow(
                      "Restricted trading",
                      `${getMarginPercent(clientLimit?.restrictedTrading)} ${getMarginValue(
                          clientLimit?.restrictedTrading,
                          clientLimit,
                      )}`,
                  ),
                  getExportRow(
                      "Initial",
                      `${getMarginPercent(clientLimit?.initialMargin)} ${getMarginValue(
                          clientLimit?.initialMargin,
                          clientLimit,
                      )}`,
                  ),
              ]
            : []),
    ];

    return (
        <>
            <OptionsContainer>
                <HeaderCell>
                    <BackButton />
                </HeaderCell>
                <TabList />
                <EditCounterpartyLimitAction />
                <ExportButton
                    data={getExportData}
                    filename={`risks_management_trading_limits_${clientId}-${counterpartyId}`}
                    loading={isLoading}
                />
            </OptionsContainer>
            <Table
                tableOptions={{
                    data,
                    columns,
                    state: {
                        expanded: true,
                    },
                    getCoreRowModel: getCoreRowModel(),
                    getExpandedRowModel: getExpandedRowModel(),
                    getSubRows: (row) => row.items,
                    getRowCanExpand: (row) => row.original.type !== RowType.Info,
                }}
                isLoading={isLoading}
            />
        </>
    );
};
