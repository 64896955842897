import { Tab, TabList as UIKitTabList } from "@fm-frontend/uikit";
import styled from "styled-components";

export enum Tabs {
    Assets = "Assets",
    Networks = "Networks",
    Instruments = "Instruments",
}

const Container = styled.div`
    width: 430px;
`;

export const TabList = () => {
    return (
        <Container>
            <UIKitTabList size="small">
                <Tab title="Assets" value={Tabs.Assets} />
                <Tab title="Networks" value={Tabs.Networks} />
                <Tab title="Instruments" value={Tabs.Instruments} />
            </UIKitTabList>
        </Container>
    );
};
