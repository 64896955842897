import { P } from "@fm-frontend/uikit";
import { EmDash } from "@fm-frontend/utils";
import { FC } from "react";

type TradeIdCellProps = {
    value: number | undefined;
};

export const TradeIdCell: FC<TradeIdCellProps> = ({ value }) => {
    if (value === undefined) {
        return <P>{EmDash}</P>;
    }

    if (value === 0) {
        return <P>{EmDash}</P>;
    }

    return <P>{value}</P>;
};
