import React, { useMemo, useState } from "react";
import { useController } from "react-hook-form";
import { useTheme } from "styled-components";
import { Checkbox } from "../../Checkbox";
import { EmptySearch } from "../../common/EmptySearch";
import { HintVariantComponents } from "../../common/HintVariantComponents";
import { Popover } from "../../Popover";
import { Search } from "../../search";
import { P, TextSmall } from "../../typography";
import { Container, DropdownGroup, GroupLabel, MatchedPart, OptionContainer, useCustomHint } from "../common";
import { DropdownOption } from "../common/types";
import { BasicTrigger, InlineTrigger, MinimumTrigger, SimpleTrigger } from "../Trigger";
import { MultiDropdownProps } from "./MultiDropdown.types";
import { Option, PopoverContent } from "./styled";
import { search } from "./utils";

const getDisplayedValue = (
    selectedValues: string[] = [],
    options: DropdownOption[] | Record<string, DropdownOption[]>,
) => {
    let optionValues: DropdownOption[] = [];

    if (Array.isArray(options)) {
        optionValues = options;
    } else {
        optionValues = Object.values(options).reduce((acc, curr) => {
            return [...acc, ...curr];
        }, []);
    }

    if (selectedValues.length === 0) {
        return "";
    }
    if (selectedValues.length === optionValues.length) {
        return "All";
    }
    const getSelectedOptionText = (selectedValue: string) =>
        optionValues.find((o) => o.value === selectedValue)?.text ?? "";
    if (selectedValues.length === 1) {
        return getSelectedOptionText(selectedValues[0]);
    }
    return `${getSelectedOptionText(selectedValues[0])}, +${selectedValues.length - 1}`;
};

export const MultiDropdown: React.VFC<MultiDropdownProps> = (props) => {
    const {
        label,
        text,
        options,
        searchable,
        placeholder,
        fullWidth,
        disabled,
        error,
        hint,
        variant,
        size = "large",
        ...rest
    } = props;
    const { colors } = useTheme();
    const [isOpen, setIsOpen] = useState(false);
    const [query, setQuery] = useState("");
    const closePopover = () => setIsOpen(false);

    const { hintVariant } = useCustomHint(error, hint);
    const HintComponent = HintVariantComponents[hintVariant.variant];

    const {
        field: { value, onChange: setSelectedValues },
    } = useController(rest);

    const selectedValues = !Array.isArray(value) ? [] : value;

    const displayValue = text ? text : getDisplayedValue(selectedValues, options);

    const handleSelect = (value: string) =>
        setSelectedValues(
            selectedValues.includes(value) ? selectedValues.filter((v) => v !== value) : [...selectedValues, value],
        );

    const mappedOptions = useMemo(() => search(query, options), [query, options]);

    const getArrayOptionsContent = (mappedOptions: DropdownOption<string>[]) =>
        mappedOptions.map(({ text, value, match }) => (
            <Option htmlFor={`${value}-${text}`} key={`${value}-${text}`}>
                <Checkbox
                    id={`${value}-${text}`}
                    size="small"
                    checked={selectedValues.includes(value)}
                    onChange={() => handleSelect(value)}
                />
                {match ? <MatchedPart>{match}</MatchedPart> : <P color={colors.ui72}>{text}</P>}
            </Option>
        ));

    const getOptionsContent = (mappedOptions: DropdownOption<string>[] | Record<string, DropdownOption<string>[]>) => {
        if (Array.isArray(mappedOptions) && mappedOptions?.length) {
            return getArrayOptionsContent(mappedOptions);
        }

        const notEmptyGroups = Object.entries(mappedOptions).filter(([, options]) => options.length);

        if (notEmptyGroups.length) {
            return notEmptyGroups.map(
                ([label, options], index) =>
                    Boolean(options.length) && (
                        <OptionContainer key={`${label}-${index}`}>
                            <GroupLabel>{label}</GroupLabel>
                            {getArrayOptionsContent(options)}
                        </OptionContainer>
                    ),
            );
        }

        return <EmptySearch description="Option not found" />;
    };

    return (
        <Container isDisabled={disabled} fullWidth={fullWidth} size={size}>
            <Popover
                isOpen={isOpen}
                padding={8}
                positions={["bottom"]}
                align="start"
                onClickOutside={closePopover}
                content={
                    <PopoverContent>
                        {searchable && <Search size="small" query={query} onChange={(query) => setQuery(query)} />}
                        <OptionContainer>{getOptionsContent(mappedOptions)}</OptionContainer>
                    </PopoverContent>
                }
            >
                <DropdownGroup>
                    {variant === "basic" && (
                        <BasicTrigger
                            isOpen={isOpen}
                            label={label}
                            text={displayValue}
                            placeholder={placeholder}
                            isError={hintVariant.variant === "error"}
                            isDisabled={Boolean(disabled)}
                            onTrigger={() => !disabled && setIsOpen(!isOpen)}
                        />
                    )}
                    {variant === "simple" && (
                        <SimpleTrigger
                            isOpen={isOpen}
                            label={label}
                            text={displayValue}
                            placeholder={placeholder}
                            isError={hintVariant.variant === "error"}
                            isDisabled={Boolean(disabled)}
                            onTrigger={() => !disabled && setIsOpen(!isOpen)}
                        />
                    )}
                    {variant === "minimum" && (
                        <MinimumTrigger
                            isOpen={isOpen}
                            text={displayValue}
                            placeholder={placeholder}
                            isError={hintVariant.variant === "error"}
                            isDisabled={Boolean(disabled)}
                            onTrigger={() => !disabled && setIsOpen(!isOpen)}
                        />
                    )}
                    {variant === "inline" && (
                        <InlineTrigger
                            isOpen={isOpen}
                            label={label}
                            text={displayValue}
                            placeholder={placeholder}
                            isError={hintVariant.variant === "error"}
                            isDisabled={Boolean(disabled)}
                            onTrigger={() => !disabled && setIsOpen(!isOpen)}
                            size={size}
                        />
                    )}
                </DropdownGroup>
            </Popover>
            {hintVariant.text && (
                <HintComponent>
                    <TextSmall>{hintVariant.text}</TextSmall>
                </HintComponent>
            )}
        </Container>
    );
};
