import { FC, useState } from "react";
import styled from "styled-components";
import EditAction from "~components/Table/Action/EditAction";
import RemoveAction from "~components/Table/Action/RemoveAction";
import { Currency } from "~entities/currency";
import { updateInstrumentsApi } from "~hooks/api/useInstrumentsApi";
import useNotifications from "~hooks/useNotifications";
import { EditForm } from "~pages/AssetsAndInstruments/Assets/EditForm";
import { requestAdminery } from "~utils/api";

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 8px;
`;

type CurrencyActionsProps = {
    currency: Currency;
};

const CurrencyActions: FC<CurrencyActionsProps> = ({ currency }) => {
    const { showSuccessNotification, showErrorNotification } = useNotifications();
    const [removing, setRemoving] = useState(false);

    const handleRemove = async () => {
        setRemoving(true);

        try {
            const response = await requestAdminery("delCurrency", {
                name: currency.name,
            });

            await updateInstrumentsApi();

            if (!response?.error) {
                showSuccessNotification(`${currency.name} currency has been successfully deleted.`);
            }
            if (response?.error) {
                showErrorNotification(`An error occurred when deleting ${currency.name} currency.`);
            }
        } catch (e) {
            showErrorNotification(`An error occurred when deleting ${currency.name} currency.`);
        } finally {
            setRemoving(false);
        }
    };

    return (
        <Container>
            <EditAction
                renderEditForm={(closePopover) => (
                    <EditForm currency={currency} onDone={closePopover} />
                )}
            />
            <RemoveAction
                title={`Remove currency "${currency.name}"`}
                loading={removing}
                onDone={handleRemove}
            />
        </Container>
    );
};

export default CurrencyActions;
