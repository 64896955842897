import { InfiniteTable as UIKitTable, VStack } from "@fm-frontend/uikit";
import { getCoreRowModel, getSortedRowModel, SortingState } from "@tanstack/react-table";
import { format } from "date-fns";
import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Gap from "~components/Gap";
import OptionsContainer from "~components/Table/Options/Container";
import DisabledSelect from "~components/Table/Options/DisabledSelect";
import { DisabledVisibility } from "~components/Table/Options/DisabledSelect/types";
import ExportButton from "~components/Table/Options/ExportButton";
import Search from "~components/Table/Options/Search";
import { TableContext, useTableContextValue } from "~components/Table/TableContext";
import { DATE_TIME_FORMAT } from "~constants/date";
import { ClientType } from "~entities/client";
import { useClientsApi } from "~hooks/api/useClientsApi";
import { useHRPClientsApi } from "~hooks/api/useHRPClientsApi";
import useAppSelector from "~hooks/useAppSelector";
import { ClientsOverviewTabs } from "~pages/Overview/ClientsOverviewTabs";
import { clientsTableColumns } from "~pages/Overview/ClientsView/columns";
import { TableClient } from "~pages/Overview/ClientsView/types";
import InviteButton from "~pages/Overview/InviteButton";
import TypeSelect, { ALL_TYPES_VALUE } from "~pages/Overview/TypeSelect";
import { getClientUrl } from "~pages/Router";
import { getClients, isClientsFetching } from "~store/clients/selectors";
import BigIntJSON from "~utils/BigIntJSON";

const Table = styled(UIKitTable<TableClient>)`
    min-width: 1010px;

    th:first-of-type,
    td:first-of-type {
        padding-left: 12px !important;
    }
`;

const OptionsWrapper = styled(VStack)`
    width: 100%;

    ${OptionsContainer} {
        :first-child {
            padding-bottom: 8px;
        }
    }
`;

const clientMatchQuery = (client: TableClient, query: string) =>
    `${client.id}`.startsWith(query) ||
    [client.username, client.email, client.hrpId, ...(client.metadata?.tags ?? [])]
        .map((str) => str?.toUpperCase())
        .some((str) => str?.includes(query.toUpperCase()));

export const ClientsView: React.FC = () => {
    useClientsApi();
    const { data: hrpClients = {}, isLoading: isHRPClientsLoading } = useHRPClientsApi();

    const navigate = useNavigate();
    const [sorting, setSorting] = useState<SortingState>([]);
    const [type, setType] = useState<ClientType | typeof ALL_TYPES_VALUE>(ALL_TYPES_VALUE);
    const [disabledVisibility, setDisabledVisibility] = useState<DisabledVisibility>(
        DisabledVisibility.hide,
    );
    const fetching = useAppSelector(isClientsFetching);
    const clients = useAppSelector(getClients);
    const tableContextValue = useTableContextValue();
    const { query, setQuery } = tableContextValue;

    const data = useMemo(
        () =>
            clients
                .map((client) => ({
                    ...client,
                    hrpId: hrpClients[client.id]?.hrpClientId,
                }))
                .filter((client) => {
                    if (query !== "" && !clientMatchQuery(client, query)) {
                        return false;
                    }

                    if (type !== ALL_TYPES_VALUE && client.type !== type) {
                        return false;
                    }

                    if (disabledVisibility === DisabledVisibility.hide && client.isDisabled) {
                        return false;
                    }

                    return true;
                }),
        [clients, disabledVisibility, query, type, hrpClients],
    );
    const getExportData = () =>
        data.map((item) => ({
            "Client ID": item.id,
            Email: item.email,
            Username: item.username,
            "Full Name": item.fullname,
            Type: item.type,
            "Is subaccount": item.isSubaccount,
            "Is disabled": item.isDisabled,
            Metadata: BigIntJSON.stringify(item.metadata),
            "Created at": format(item.createdAt * 1000, DATE_TIME_FORMAT),
            "Signed agreement at": item.signedAgreementAt
                ? format(item.signedAgreementAt * 1000, DATE_TIME_FORMAT)
                : "",
            "HRP ID": item.hrpId ?? "",
        }));

    return (
        <TableContext.Provider value={tableContextValue}>
            <OptionsWrapper>
                <OptionsContainer>
                    <ClientsOverviewTabs />
                    <Gap />
                    <ExportButton
                        data={getExportData}
                        filename="clients"
                        loading={fetching || isHRPClientsLoading}
                        disabled={data.length === 0}
                    />
                    <InviteButton />
                </OptionsContainer>
                <OptionsContainer>
                    <Search query={query} onChange={setQuery} />
                    <TypeSelect value={type} onChange={setType} />
                    <DisabledSelect value={disabledVisibility} onChange={setDisabledVisibility} />
                    <Gap />
                </OptionsContainer>
            </OptionsWrapper>
            <Table
                tableOptions={{
                    data,
                    columns: clientsTableColumns,
                    state: {
                        sorting,
                    },
                    onSortingChange: setSorting,
                    getCoreRowModel: getCoreRowModel(),
                    getSortedRowModel: getSortedRowModel(),
                }}
                onRowClick={(row) => {
                    const { id } = row.original;
                    navigate(getClientUrl(id));
                }}
                isLoading={fetching}
            />
        </TableContext.Provider>
    );
};
