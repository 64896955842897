import { ReactNode } from "react";

export type NotificationId = string;

export enum NotificationType {
    Success,
    Error,
    Copy,
}

export type Notification = {
    id: NotificationId;
    type: NotificationType;
    icon: ReactNode;
    content: ReactNode;
    timestamp: number;
    isRead: boolean;
};
