import { IconButton, Icons } from "@fm-frontend/uikit";
import { FC, SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FieldPath, FieldValues, useController, UseControllerProps } from "react-hook-form";
import ReactTags, { Tag, TagComponentProps } from "react-tag-autocomplete";
import styled from "styled-components";
import Styles from "~components/TagsInput/Styles";

const TagContainer = styled.div`
    display: inline-flex;
    cursor: pointer;
    background: ${(p) => p.theme.colors.ui8};
    color: ${(p) => p.theme.colors.ui52};
    font-size: 12px;
    font-weight: 600;
    letter-spacing: -0.005em;
    line-height: 16px;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border-radius: 12px;
    padding: 0 0 0 8px;
    width: fit-content;
    min-height: 24px;
`;
interface TagsInputProps<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends UseControllerProps<TFieldValues, TName> {
    label?: string;
    suggestions?: Tag[];
}

const TagsInput = <
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
    label = "",
    suggestions = [],
    ...controllerProps
}: TagsInputProps<TFieldValues, TName>) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const ref = useRef<any>(null);
    // HACK: force rerender `ReactTags` for correct input size calculation
    const [forceRerenderKey, setForceRerenderKey] = useState(0);
    const { field } = useController(controllerProps);
    // TODO: find a way to typing field value for input
    const currentItems: Tag[] = useMemo(() => field.value ?? [], [field.value]);

    const handleAdd = (newItem: Tag) => {
        field.onChange([...currentItems, newItem]);
    };
    const handleDelete = (index: number) => {
        field.onChange(currentItems.filter((_, i) => i !== index));
    };

    const renderTag = useCallback<FC<TagComponentProps>>(
        ({ tag, removeButtonText, onDelete }) => (
            <TagContainer className="react-tags__selected-tag">
                {tag.name}
                <IconButton
                    variant="plain"
                    type="button"
                    title={removeButtonText}
                    Icon={Icons.Clear}
                    onClick={onDelete}
                />
            </TagContainer>
        ),
        [],
    );

    const handleKeyDown = useCallback((e: KeyboardEvent) => {
        const { value } = e.target as HTMLInputElement;

        if (e.key === "Escape" && value !== "") {
            e.stopPropagation();
            ref.current?.clearInput();
        }
    }, []);
    const handleInput = useCallback(
        (e: SyntheticEvent) => {
            const { value } = e.target as HTMLInputElement;

            if (value.includes(",")) {
                const newItems = value.split(",").reduce((acc, item) => {
                    const name = item.trim();

                    if (name !== "") {
                        acc.push({ name, id: name });
                    }

                    return acc;
                }, [] as Tag[]);

                ref.current?.clearInput();
                field.onChange([...currentItems, ...newItems]);
            }
        },
        [currentItems, field],
    );

    useEffect(() => {
        setForceRerenderKey((prev) => prev + 1);
    }, []);

    useEffect(() => {
        const inputComponent = ref.current?.input.current;
        const inputField = inputComponent?.input.current;

        inputField?.addEventListener("keydown", handleKeyDown);
        inputField?.addEventListener("input", handleInput);

        return () => {
            inputField?.removeEventListener("keydown", handleKeyDown);
            inputField?.removeEventListener("input", handleInput);
        };
    }, [handleInput, handleKeyDown, ref.current?.input]);

    return (
        <Styles>
            <ReactTags
                ref={ref}
                key={forceRerenderKey}
                placeholderText={label}
                minQueryLength={1}
                maxSuggestionsLength={5}
                tags={field.value}
                allowNew
                newTagText="Press Enter to add a new tag:"
                suggestions={suggestions}
                onAddition={handleAdd}
                onDelete={handleDelete}
                tagComponent={renderTag}
            />
        </Styles>
    );
};

export default TagsInput;
