import { ClientId } from "~entities/client";

export enum SettlementRequestFlags {
    NoFlags = 0,
    FeePaidByRecipient = 1,
}

export type SettlementRequest = {
    counterpartyId: ClientId;
    currency: string;
    flags: SettlementRequestFlags;
    amount: number | bigint;
    comment: string;
    expirationTime: number;
    networkName: string;
    id: number;
    creationTimestamp: number;
};
