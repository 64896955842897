import { Client, ClientType } from "~entities/client";
import { CounterpartyLimit } from "~entities/counterpartyLimit";
import { getClientType } from "~utils/getClientType";
import { getClientTypeTitle } from "~utils/getClientTypeTitle";

export enum LimitType {
    Empty = -1,
    UserLimit = 0,
    MutualLimit = 1,
}

type Prices = Record<string, number | bigint>;

export const convertMutualLimitToGrossLimit = (
    prices: Record<string, number | bigint>,
    mutualLimit: bigint | null,
    mutualLimitCurrency: string | null,
    currency: string,
) => {
    if (prices[currency] === undefined) {
        return 0n;
    }

    const mutualCurrencyInUsd = BigInt(prices[mutualLimitCurrency ?? ""] ?? 0n);
    const grossCurrencyInUsd = BigInt(prices[currency]);

    const result = ((mutualCurrencyInUsd * BigInt(1e8)) / grossCurrencyInUsd) * (mutualLimit ?? 0n);

    return result / BigInt(1e8);
};

export const getAppliedLimitType = (counterpartyLimit: CounterpartyLimit, prices: Prices) => {
    const { currencyName, grossLimit, mutualLimitCurrencyName, mutualGrossLimit } =
        counterpartyLimit;
    const convertedMutualLimit = convertMutualLimitToGrossLimit(
        prices,
        mutualGrossLimit,
        mutualLimitCurrencyName,
        currencyName,
    );

    if (!grossLimit || !convertedMutualLimit) {
        return LimitType.Empty;
    }

    if (grossLimit <= convertedMutualLimit) {
        return LimitType.UserLimit;
    }

    return LimitType.MutualLimit;
};

export const getAppliedLimit = (counterpartyLimit: CounterpartyLimit, prices: Prices) => {
    const { currencyName, grossLimit, mutualLimitCurrencyName, mutualGrossLimit } =
        counterpartyLimit;
    const appliedLimitType = getAppliedLimitType(counterpartyLimit, prices);

    if (appliedLimitType === LimitType.Empty) {
        return 0n;
    }
    if (appliedLimitType === LimitType.UserLimit) {
        return grossLimit;
    }

    return convertMutualLimitToGrossLimit(
        prices,
        mutualGrossLimit,
        mutualLimitCurrencyName,
        currencyName,
    );
};

export const getFreeLimit = (grossLimit: number | bigint, exposure: number | bigint) => {
    return BigInt(grossLimit) - BigInt(exposure);
};

export const getFreeLimitPercent = (grossLimit: number | bigint, exposure: number | bigint) => {
    const PERCENT_SHIFT = 1e4;
    const freeLimit = getFreeLimit(grossLimit, exposure);

    if (grossLimit !== 0n && freeLimit > 0n) {
        return Number((BigInt(100 * PERCENT_SHIFT) * freeLimit) / BigInt(grossLimit));
    }

    return 0;
};

export const getMarginLimitValue = (grossLimit: number | bigint, margin: number | bigint) => {
    return (BigInt(grossLimit) * BigInt(margin)) / BigInt(1e6);
};

const markupEditableCombinations = [
    `${ClientType.PrimeBroker}-${ClientType.PrimeBroker}`,
    `${ClientType.PrimeBroker}-${ClientType.Taker}`,
    `${ClientType.PrimeBroker}-${ClientType.SubaccountMaker}`,
    `${ClientType.PrimeBroker}-${ClientType.SubaccountTaker}`,
    `${ClientType.Maker}-${ClientType.PrimeBroker}`,
    `${ClientType.Maker}-${ClientType.Taker}`,
];
export const isMarkupEditable = (client: Client | undefined, counterparty: Client | undefined) => {
    if (client === undefined || counterparty === undefined) {
        return false;
    }

    const key = `${getClientType(client)}-${getClientType(counterparty)}`;

    return markupEditableCombinations.includes(key);
};

const marginEditableCombinations = [
    `${ClientType.PrimeBroker}-${ClientType.Taker}`,
    `${ClientType.PrimeBroker}-${ClientType.SubaccountMaker}`,
    `${ClientType.PrimeBroker}-${ClientType.SubaccountTaker}`,
    `${ClientType.Maker}-${ClientType.PrimeBroker}`,
    `${ClientType.Maker}-${ClientType.Taker}`,
];
export const isMarginEditable = (client: Client | undefined, counterparty: Client | undefined) => {
    if (client === undefined || counterparty === undefined) {
        return false;
    }

    const key = `${getClientType(client)}-${getClientType(counterparty)}`;

    return marginEditableCombinations.includes(key);
};

export const combineMarkupLabel = (clientType: ClientType | undefined) => {
    if (clientType === undefined) {
        return "Counterparty markup, %";
    }

    return `${getClientTypeTitle(clientType)} markup, %`;
};
