import { HStack, P, PBold } from "@fm-frontend/uikit";
import React from "react";
import styled from "styled-components";

type InstrumentCellProps = {
    isWhitelisted: boolean | undefined;
    instrument: string | undefined;
};

const Container = styled(HStack)<{ isWhitelisted?: boolean }>`
    align-items: center;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: ${(p) => (p.isWhitelisted ? null : p.theme.colors.negative8)};
        pointer-events: none;
    }
`;

export const InstrumentCell: React.FC<InstrumentCellProps> = ({ isWhitelisted, instrument }) => {
    if (instrument === undefined) {
        return null;
    }

    const TextContainer = isWhitelisted ? PBold : P;

    return (
        <Container isWhitelisted={isWhitelisted}>
            <TextContainer>{instrument}</TextContainer>
        </Container>
    );
};
