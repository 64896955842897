import { P } from "@fm-frontend/uikit";
import { ValueFormat } from "@fm-frontend/utils";
import { FC } from "react";
import styled from "styled-components";
import { TableData } from "~pages/Settlements";
import { SettlementStatus, SettlementType } from "~pages/Settlements/types";
import { abs } from "~utils/abs";

const Info = styled(P)`
    color: ${(p) => p.theme.colors.ui52};
`;

const Positive = styled(P)`
    color: ${(p) => p.theme.colors.positive72};
`;

const Negative = styled(P)`
    color: ${(p) => p.theme.colors.negative72};
`;

type AmountCellProps = {
    amount: number | bigint;
    data: TableData;
};

export const AmountCell: FC<AmountCellProps> = ({ amount, data }) => {
    const { status, type } = data;

    if (status === SettlementStatus.Request && !amount) {
        return <Info>All available</Info>;
    }

    const formattedAmount = ValueFormat.size(abs(amount));

    if (type === SettlementType.In) {
        return <Positive>+{formattedAmount}</Positive>;
    }
    if (type === SettlementType.Out) {
        return <Negative>-{formattedAmount}</Negative>;
    }
    return <P>{formattedAmount}</P>;
};
