import { CognitoUser } from "amazon-cognito-identity-js";
import { Auth } from "aws-amplify";
import { useState } from "react";
import { AuthState } from "~pages/Auth/consts";

const useVerifyTotpToken = (userState: CognitoUser | undefined) => {
    const [loading, setLoading] = useState<boolean>(false);

    const verifyTotpToken = (code: string) => {
        setLoading(true);

        return new Promise<AuthState>((resolve, reject) => {
            Auth.verifyTotpToken(userState, code)
                .then(() => resolve(AuthState.Login))
                .catch(reject)
                .finally(() => setLoading(false));
        });
    };

    return { verifyTotpToken, loading };
};

export default useVerifyTotpToken;
