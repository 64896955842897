import { InfiniteTable as UIKitTable } from "@fm-frontend/uikit";
import { EMPTY_ARRAY } from "@fm-frontend/uikit/src/const";
import {
    createColumnHelper,
    getCoreRowModel,
    getSortedRowModel,
    SortingState,
} from "@tanstack/react-table";
import { format } from "date-fns";
import { FC, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import Gap from "~components/Gap";
import { ClientCell } from "~components/Table/Cell/ClientCell";
import DateTimeViewer from "~components/Table/Cell/DateTimeViewer";
import { EllipsisCellTheme } from "~components/Table/CellTheme/Ellipsis";
import OptionsContainer from "~components/Table/Options/Container";
import ExportButton from "~components/Table/Options/ExportButton";
import Search from "~components/Table/Options/Search";
import { TableContext, useTableContextValue } from "~components/Table/TableContext";
import { DATE_TIME_FORMAT } from "~constants/date";
import { Referral } from "~entities/referral";
import { useOnboardingStatusesApi } from "~hooks/api/useOnboardingStatusesApi";
import { useReferralsApi } from "~hooks/api/useReferralsApi";
import { useClientId } from "~hooks/useClientId";
import { ActionsCell } from "~pages/Referrals/ActionsCell";
import { EmailCell } from "~pages/Referrals/EmailCell";
import { StatusCell } from "~pages/Referrals/StatusCell";
import {
    StatusSelect,
    StatusSelectValue,
    STATUS_SELECT_ALL_VALUE,
} from "~pages/Referrals/StatusSelect";
import {
    findInviteStatus,
    getInviteStatusTitle,
    getReferralStatusTitle,
} from "~pages/Referrals/utils";
import { sortTimestamp } from "~utils/sortTimestamp";
import { ReferralsTableContext, useReferralsTableContextValue } from "./Context";

const Table = styled(UIKitTable<Referral>)`
    min-width: 1010px;

    th:first-of-type,
    td:first-of-type {
        padding-left: 12px !important;
    }
`;

const columnHelper = createColumnHelper<Referral>();

const referralsTableColumns = [
    columnHelper.accessor("name", {
        header: "Name",
        cell: (info) => (
            <EllipsisCellTheme maxWidth={300}>
                <ClientCell id={info.row.original.clientId} name={info.getValue()} />
            </EllipsisCellTheme>
        ),
        meta: {
            headerStyleProps: {
                width: "300px",
            },
        },
    }),
    columnHelper.accessor("email", {
        header: "Email",
        cell: (info) => (
            <EllipsisCellTheme maxWidth={300}>
                <EmailCell value={info.getValue()} />
            </EllipsisCellTheme>
        ),
        meta: {
            headerStyleProps: {
                width: "300px",
            },
        },
    }),
    columnHelper.accessor("status", {
        header: "Status",
        cell: (info) => <StatusCell value={info.row.original} />,
        meta: {
            headerStyleProps: {
                width: "110px",
            },
        },
    }),
    columnHelper.accessor("createdAt", {
        header: "Created",
        sortingFn: sortTimestamp,
        cell: (info) => <DateTimeViewer value={info.getValue() / 1000} />,
        meta: {
            headerStyleProps: {
                width: "90px",
            },
        },
    }),
    columnHelper.display({
        id: "actions",
        cell: (info) => <ActionsCell clientId={info.row.original.clientId} />,
        enableSorting: false,
        meta: {
            cellStyleProps: {
                textAlign: "right",
            },
        },
    }),
];

const isMatch = (referrals: Referral, query: string) => {
    const { clientId, name, email } = referrals;
    const normalizedQuery = query.toUpperCase();

    if (clientId !== 0 && String(clientId).startsWith(query)) {
        return true;
    }

    return (
        name.toUpperCase().includes(normalizedQuery) ||
        email.toUpperCase().includes(normalizedQuery)
    );
};

export const ReferralsPage: FC = () => {
    const clientId = useClientId();
    const { data: referrals = EMPTY_ARRAY, isLoading: isReferralsLoading } =
        useReferralsApi(clientId);
    const { data: onboardingStatuses = EMPTY_ARRAY, isLoading: isOnboardingStatusesLoading } =
        useOnboardingStatusesApi(
            referrals.map((referral) => referral.clientId).filter((takerId) => takerId !== 0),
        );
    const [sorting, setSorting] = useState<SortingState>([]);
    const [status, setStatus] = useState<StatusSelectValue>(STATUS_SELECT_ALL_VALUE);
    const referralsTableContextValue = useReferralsTableContextValue();
    const { setOnboardingStatuses } = referralsTableContextValue;
    const tableContextValue = useTableContextValue();
    const { query, setQuery } = tableContextValue;
    const isLoading = isReferralsLoading || isOnboardingStatusesLoading;

    useEffect(() => {
        setOnboardingStatuses(onboardingStatuses);
    }, [onboardingStatuses, setOnboardingStatuses]);

    const data = useMemo(() => {
        let items = referrals.filter((referral) => !referral.isSubaccount);

        if (status !== STATUS_SELECT_ALL_VALUE) {
            items = items.filter((item) => {
                const inviteStatus = findInviteStatus(onboardingStatuses, item.clientId);

                if (inviteStatus !== null) {
                    return inviteStatus === status;
                }

                return item.status === status;
            });
        }

        if (query !== "") {
            items = items.filter((item) => isMatch(item, query));
        }

        return items;
    }, [referrals, status, query, onboardingStatuses]);
    const getExportData = () =>
        data.map((item) => {
            const inviteStatus = findInviteStatus(onboardingStatuses, item.clientId);

            return {
                "Client ID": item.clientId,
                Name: item.name,
                Email: item.email,
                Status:
                    inviteStatus !== null
                        ? getInviteStatusTitle(inviteStatus)
                        : getReferralStatusTitle(item.status),
                Created: format(item.createdAt, DATE_TIME_FORMAT),
            };
        });

    return (
        <TableContext.Provider value={tableContextValue}>
            <ReferralsTableContext.Provider value={referralsTableContextValue}>
                <OptionsContainer>
                    <Search query={query} onChange={setQuery} />
                    <StatusSelect value={status} onChange={setStatus} />
                    <Gap />
                    <ExportButton
                        data={getExportData}
                        filename={`referrals_${clientId}`}
                        loading={isLoading}
                        disabled={data.length === 0}
                    />
                </OptionsContainer>
                <Table
                    tableOptions={{
                        data,
                        columns: referralsTableColumns,
                        state: {
                            sorting,
                        },
                        onSortingChange: setSorting,
                        getCoreRowModel: getCoreRowModel(),
                        getSortedRowModel: getSortedRowModel(),
                    }}
                    isLoading={isLoading}
                />
            </ReferralsTableContext.Provider>
        </TableContext.Provider>
    );
};
