import { InfiniteTable as UIKitTable } from "@fm-frontend/uikit";
import { EMPTY_ARRAY } from "@fm-frontend/uikit/src/const";
import { ValueFormat } from "@fm-frontend/utils";
import {
    createColumnHelper,
    getCoreRowModel,
    getSortedRowModel,
    SortingState,
} from "@tanstack/react-table";
import { format } from "date-fns";
import { FC, useMemo, useState } from "react";
import styled from "styled-components";
import Gap from "~components/Gap";
import {
    PartnerSelect,
    PartnerSelectType,
    PartnerValueType,
    PARTNER_SELECT_ALL_VALUE,
} from "~components/PartnerSelect";
import DateTimeViewer from "~components/Table/Cell/DateTimeViewer";
import { EllipsisCellTheme } from "~components/Table/CellTheme/Ellipsis";
import OptionsContainer from "~components/Table/Options/Container";
import ExportButton from "~components/Table/Options/ExportButton";
import Search from "~components/Table/Options/Search";
import { TableContext, useTableContextValue } from "~components/Table/TableContext";
import { DATE_TIME_FORMAT } from "~constants/date";
import { SettlementTransactionHistoryItem } from "~entities/settlementTransactionHistoryItem";
import { useSettlementTransactionHistoryApi } from "~hooks/api/useSettlementTransactionHistoryApi";
import useAppSelector from "~hooks/useAppSelector";
import { useClientId } from "~hooks/useClientId";
import { useIsMaster } from "~hooks/useIsMaster";
import { useSubaccountsIds } from "~hooks/useSubaccountsIds";
import { CommentCell } from "~pages/History/Settlements/CommentCell";
import { CounterpartyCell } from "~pages/History/Settlements/CounterpartyCell";
import CurrencyCell from "~pages/History/Settlements/CurrencyCell";
import { SideCell } from "~pages/History/Settlements/SideCell";
import { TxIdCell } from "~pages/History/Settlements/TxIdCell";
import { VolumeCell } from "~pages/History/Settlements/VolumeCell";
import { TabList } from "~pages/History/TabList";
import { getClient } from "~store/clients/selectors";
import { sortTimestamp } from "~utils/sortTimestamp";
import { OrderIdCell } from "./OrderIdCell";

export const Table = styled(UIKitTable<SettlementTransactionHistoryItem>)`
    min-width: 1010px;

    th:first-of-type,
    td:first-of-type {
        padding-left: 12px !important;
    }
`;

const columnHelper = createColumnHelper<SettlementTransactionHistoryItem>();

const settlementTransactionHistoryTableColumns = [
    columnHelper.accessor("orderId", {
        header: "Order ID",
        cell: (info) => <OrderIdCell value={info.getValue()} />,
        meta: {
            headerStyleProps: {
                width: "110px",
            },
        },
    }),
    columnHelper.accessor("counterpartyId", {
        id: "counterparty",
        header: "Counterparty",
        cell: (info) => (
            <EllipsisCellTheme maxWidth={200}>
                <CounterpartyCell value={info.getValue()} />
            </EllipsisCellTheme>
        ),
        sortDescFirst: false,
        // TODO: Find a way to type custom sorting functions
        // @ts-ignore
        sortingFn: "usernameSorting",
        meta: {
            headerStyleProps: {
                width: "200px",
            },
        },
    }),
    columnHelper.accessor("amount", {
        id: "side",
        header: "Side",
        sortingFn: (row) => (row.original.amount < 0n ? 1 : -1),
        cell: (info) => <SideCell value={info.getValue()} />,
        meta: {
            headerStyleProps: {
                width: "60px",
            },
        },
    }),
    columnHelper.accessor("currency", {
        header: "Currency",
        cell: (info) => <CurrencyCell value={info.getValue()} />,
        meta: {
            headerStyleProps: {
                width: "80px",
            },
        },
    }),
    columnHelper.accessor("amount", {
        header: "Volume",
        cell: (info) => <VolumeCell value={info.getValue()} />,
        meta: {
            headerStyleProps: {
                width: "200px",
            },
        },
    }),
    columnHelper.accessor("txId", {
        header: "TX ID",
        cell: (info) => (
            <EllipsisCellTheme maxWidth={200}>
                <TxIdCell value={info.getValue()} />
            </EllipsisCellTheme>
        ),
        meta: {
            headerStyleProps: {
                width: "200px",
            },
        },
    }),
    columnHelper.accessor("createdAt", {
        header: "Created",
        sortingFn: sortTimestamp,
        cell: (info) => <DateTimeViewer value={info.getValue() / 1000} />,
        meta: {
            headerStyleProps: {
                width: "90px",
            },
        },
    }),

    columnHelper.accessor("comment", {
        header: "Comment",
        cell: (info) => <CommentCell value={info.getValue()} />,
    }),
];

const isMatch = (
    historyItem: SettlementTransactionHistoryItem,
    counterpartyName: string | undefined,
    query: string,
) => {
    const { counterpartyId, currency } = historyItem;
    const normalizedQuery = query.toUpperCase();

    return (
        String(counterpartyId).startsWith(normalizedQuery) ||
        counterpartyName?.toUpperCase().includes(normalizedQuery) ||
        currency.toUpperCase().startsWith(normalizedQuery)
    );
};

export const SettlementsHistory: FC = () => {
    const clientId = useClientId();
    const isMaster = useIsMaster();
    const { data = EMPTY_ARRAY, isLoading } = useSettlementTransactionHistoryApi(clientId);
    const subaccountsIds = useSubaccountsIds(clientId);
    const [sorting, setSorting] = useState<SortingState>([]);
    const [partnerType, setPartnerType] = useState<PartnerValueType>(PARTNER_SELECT_ALL_VALUE);
    const tableContextValue = useTableContextValue();
    const { query, setQuery } = tableContextValue;
    const getClientById = useAppSelector(getClient);

    const settlementsTransactions = useMemo(() => {
        if (query === "" && partnerType === PARTNER_SELECT_ALL_VALUE) {
            return data;
        }

        return data.filter((item) => {
            const isSubaccount = subaccountsIds.includes(item.counterpartyId);

            if (partnerType === PartnerSelectType.Counterparties && isSubaccount) {
                return false;
            }
            if (partnerType === PartnerSelectType.SubAccounts && !isSubaccount) {
                return false;
            }

            const client = getClientById(item.counterpartyId);

            return isMatch(item, client?.username, query);
        });
    }, [query, partnerType, data, subaccountsIds, getClientById]);

    const getExportData = () =>
        settlementsTransactions.map((settlementsTransaction) => {
            const client = getClientById(settlementsTransaction.counterpartyId);
            const side = settlementsTransaction.amount < 0n ? "In" : "Out";
            const volume = ValueFormat.size(
                settlementsTransaction.amount < 0n
                    ? -settlementsTransaction.amount
                    : settlementsTransaction.amount,
            );

            return {
                "Client ID": settlementsTransaction.counterpartyId,
                Counterparty: client?.username,
                Side: side,
                Currency: settlementsTransaction.currency,
                Volume: volume,
                "TX ID": settlementsTransaction.txId,
                Created: format(settlementsTransaction.createdAt, DATE_TIME_FORMAT),
                Comment: settlementsTransaction.comment,
            };
        });

    return (
        <TableContext.Provider value={tableContextValue}>
            <OptionsContainer>
                <Search query={query} onChange={setQuery} />
                <TabList />
                {isMaster && (
                    <PartnerSelect allTypes value={partnerType} onChange={setPartnerType} />
                )}
                <Gap />
                <ExportButton
                    data={getExportData}
                    filename={`settlements_history_${clientId}`}
                    loading={isLoading}
                    disabled={settlementsTransactions.length === 0}
                />
            </OptionsContainer>
            <Table
                tableOptions={{
                    data: settlementsTransactions,
                    columns: settlementTransactionHistoryTableColumns,
                    state: {
                        sorting,
                    },
                    sortingFns: {
                        usernameSorting: (rowA, rowB, columnId) => {
                            const client1 = getClientById(rowA.getValue(columnId));
                            const client2 = getClientById(rowB.getValue(columnId));

                            if (
                                client1?.username === undefined ||
                                client2?.username === undefined
                            ) {
                                return 0;
                            }

                            return client1.username.localeCompare(client2.username);
                        },
                    },
                    onSortingChange: setSorting,
                    getCoreRowModel: getCoreRowModel(),
                    getSortedRowModel: getSortedRowModel(),
                }}
                isLoading={isLoading}
            />
        </TableContext.Provider>
    );
};
