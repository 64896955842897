import { ValueFormat } from "@fm-frontend/utils";
import { Item, ItemType, SubItem } from "~pages/OpenPositions/types";

export const isCounterpartyMatch = (id: number, name: string | undefined, query: string) => {
    return (
        query === "" ||
        String(id).startsWith(query) ||
        name?.toUpperCase().includes(query.toUpperCase())
    );
};
export const isAssetMatch = (name: string | undefined, query: string) => {
    return query === "" || name?.toUpperCase().includes(query.toUpperCase());
};

export const EMPTY_ITEM_EXPORT_DATA = { Subject: "" };

const getSubject = (item: Item | SubItem) => {
    switch (item.type) {
        case ItemType.Counterparty:
            return `${item.subject.name} (${item.subject.id})`;
        case ItemType.Currency:
            return item.subject.name;
        case ItemType.Total:
            return "Global total";
        default:
            return "";
    }
};

export const getItemExportData = (item: Item) => ({
    Subject: getSubject(item),
    Position: "",
    "Position, $": ValueFormat.price(item.positionUSD, 2),
    "Planned position": "",
    "Planned position, $": ValueFormat.price(item.plannedPositionUSD, 2),
});

export const getSubItemExportData = (subItem: SubItem) => ({
    Subject: getSubject(subItem),
    Position: ValueFormat.price(subItem.position),
    "Position, $": ValueFormat.price(subItem.positionUSD, 2),
    "Planned position": ValueFormat.price(subItem.plannedPosition),
    "Planned position, $": ValueFormat.price(subItem.plannedPositionUSD, 2),
});
