import { useEffect } from "react";
import { useErrorBoundary } from "react-error-boundary";
import { Client, ClientId } from "~entities/client";
import { ClientInfo } from "~entities/clientInfo";
import useAppDispatch from "~hooks/useAppDispatch";
import { isRfqAvailable } from "~pages/Profile/utils";
import { fetchClientInfo, setClientInfo } from "~store/clientsInfo/actions";
import { requestAdminery, requestRfq } from "~utils/api";

type RawClientInfoEntity = [
    id: ClientId,
    username: string,
    takerFeeMarkup: number,
    flags: number,
    createdAt: number,
    fullName: string,
    email: string,
    companyName: string,
    registrationNumber: string,
    address: string,
    city: string,
    zipCode: string,
    country: string,
    makerFee: number,
    fineryShare: number,
    agreementNr: string,
    primeBrokerId: ClientId,
    signatory: string,
    signatoryPosition: string,
    signedAt: number,
];

type RawRfqSettingsEntity = {
    clientId: number;
    tradingEnabled: boolean;
    viewingEnabled: boolean;
    id: number;
};

const prepareClientInfo = (
    [
        id,
        username,
        takerFeeMarkup,
        flags,
        createdAt,
        fullName,
        email,
        companyName,
        registrationNumber,
        address,
        city,
        zipCode,
        country,
        makerFee,
        fineryShare,
        agreementNr,
        primeBrokerId,
        signatory,
        signatoryPosition,
        signedAt,
    ]: RawClientInfoEntity,
    rfqSettings: RawRfqSettingsEntity | undefined,
): ClientInfo => ({
    id,
    username,
    takerFeeMarkup,
    flags,
    createdAt,
    fullName,
    email,
    companyName,
    registrationNumber,
    address,
    city,
    zipCode,
    country,
    makerFee,
    fineryShare,
    agreementNr,
    primeBrokerId,
    signatory,
    signatoryPosition,
    signedAt,
    rfqEnabled: rfqSettings ? rfqSettings.tradingEnabled || rfqSettings.viewingEnabled : false,
});

const useClientInfoApi = (client: Client | undefined) => {
    const { showBoundary } = useErrorBoundary();
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (client === undefined) {
            return;
        }

        (async () => {
            const clientId = client.id;

            dispatch(fetchClientInfo({ id: clientId }));

            try {
                const clientInfoPromise = requestAdminery("clientInfo", {
                    id: clientId,
                });
                const rfqSettingsPromise = isRfqAvailable(client.type)
                    ? requestRfq("getSettings", {
                          clientId,
                      })
                    : Promise.resolve(undefined);

                const [data, rfqSettings]: [RawClientInfoEntity, RawRfqSettingsEntity | undefined] =
                    await Promise.all([clientInfoPromise, rfqSettingsPromise]);

                dispatch(setClientInfo({ clientInfo: prepareClientInfo(data, rfqSettings) }));
            } catch (error) {
                showBoundary(error);
            }
        })();
    }, [client, dispatch, showBoundary]);
};

export default useClientInfoApi;
