import { createSelector } from "@reduxjs/toolkit";
import { ClientId } from "~entities/client";
import { RootState } from "~store";
import { EntityStatus } from "~store/types";

export const getRootState = (state: RootState) => state.clients;
export const getStatus = (state: RootState) => getRootState(state).status;
export const isClientsNotFetched = (state: RootState) =>
    getStatus(state) === EntityStatus.NotFetched;
export const isClientsFetching = (state: RootState) => getStatus(state) === EntityStatus.Fetching;
export const isClientsFetched = (state: RootState) => getStatus(state) === EntityStatus.Fetched;
export const isClientsFetchError = (state: RootState) => getStatus(state) === EntityStatus.Error;
export const getClients = (state: RootState) => getRootState(state).items;
export const getClientsTags = createSelector(getClients, (clients) => {
    const tagsSet = clients.reduce((acc, { metadata }) => {
        if (Array.isArray(metadata.tags)) {
            metadata.tags.forEach((tag) => acc.add(`${tag}`));
        }

        return acc;
    }, new Set<string>());

    return [...tagsSet].sort();
});
export const getClient = (state: RootState) => (clientId: ClientId) =>
    getClients(state).find((item) => item.id === clientId);

export const getSubaccountClients = (state: RootState) =>
    getRootState(state).items.filter((client) => client.isSubaccount);
