import { getEnv } from "@fm-frontend/utils";

const awsExports = {
    Auth: {
        region: getEnv().REACT_APP_REGION,
        identityPoolRegion: getEnv().REACT_APP_IDENTITY_POOL_REGION,
        userPoolId: getEnv().REACT_APP_USER_POOL_ID,
        userPoolWebClientId: getEnv().REACT_APP_USER_POOL_WEBCLIENT_ID,
        mandatorySignIn: true,
    },
};

export default awsExports;
