import { Input } from "@fm-frontend/uikit";
import QRCode from "qrcode.react";
import { FC } from "react";
import styled from "styled-components";
import { getMfaTokenURI } from "~pages/Auth/utils";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
`;

type MfaTokenSectionProps = {
    mfaToken: string | undefined;
};

const MfaTokenSection: FC<MfaTokenSectionProps> = ({ mfaToken }) => {
    if (mfaToken === undefined) {
        return null;
    }

    return (
        <Container>
            <QRCode value={getMfaTokenURI(mfaToken)} />
            <Input
                label="Alternatively, input the secret key manually"
                required
                readOnly
                autoComplete="off"
                value={mfaToken}
            />
        </Container>
    );
};

export default MfaTokenSection;
