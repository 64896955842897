import { P } from "@fm-frontend/uikit";
import { FC } from "react";
import { ClientType } from "~entities/client";
import { getSubaccountTypeTitle } from "~utils/getSubaccountTypeTitle";

type TypeCellProps = {
    value: ClientType;
};

export const TypeCell: FC<TypeCellProps> = ({ value }) => {
    return <P>{getSubaccountTypeTitle(value)}</P>;
};
