import { Notifications as UikitNotifications } from "@fm-frontend/uikit";
import { useContext } from "react";
import { NotificationContext } from "~components/Notifications/context";

const Notifications = () => {
    const { notifications } = useContext(NotificationContext);

    return <UikitNotifications notifications={notifications} />;
};

export default Notifications;
