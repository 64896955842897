import { NetworkName } from "~entities/network";

export type CurrencyId = number;

export enum CurrencyType {
    Crypto = "crypto",
    Stablecoin = "stablecoin",
    Fiat = "fiat",
}

export type Currency = {
    currencyId: CurrencyId;
    name: string;
    balanceStep: number | bigint;
    price: number | bigint;
    currencyType: CurrencyType;
    networks: NetworkName[];
};
