import { useEffect, useState } from "react";
import { ClientId } from "~entities/client";
import { TakerInfo } from "~entities/takerInfo";
import { requestOnboarding } from "~utils/api";

const useTakerInfoApi = (clientId: ClientId) => {
    const [loading, setLoading] = useState(false);
    const [takerInfo, setTakerInfo] = useState<TakerInfo>();

    useEffect(() => {
        let isMounted = true;

        (async () => {
            setLoading(true);
            const data = await requestOnboarding("takerInfo", { clientId: `${clientId}` });

            if (isMounted) {
                setTakerInfo(data);
                setLoading(false);
            }
        })();

        return () => {
            isMounted = false;
        };
    }, [clientId]);

    return { takerInfo, loading };
};

export default useTakerInfoApi;
