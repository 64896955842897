import { useEffect, useRef, useState } from "react";

export const DEFAULT_DELAY = 300;
export const DEFAULT_MAX_DELAY = 2000;

export function useDebouncedValue<T>(
    value: T,
    delay = DEFAULT_DELAY,
    maxDelay = DEFAULT_MAX_DELAY,
): T {
    const [debouncedValue, setDebouncedValue] = useState<T>(value);
    const lastDebounce = useRef(Date.now());

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            lastDebounce.current = Date.now();
            setDebouncedValue(value);
        }, delay || DEFAULT_DELAY);

        return () => clearTimeout(timeoutId);
    }, [value, delay]);

    useEffect(() => {
        const lastDebounceDiff = Date.now() - lastDebounce.current;
        const maxDelayDiff = maxDelay - lastDebounceDiff;

        if (maxDelayDiff > 0) {
            const timeoutId = setTimeout(() => {
                lastDebounce.current = Date.now();
                setDebouncedValue(value);
            }, maxDelayDiff);

            return () => clearTimeout(timeoutId);
        }

        lastDebounce.current = Date.now();
        setDebouncedValue(value);
    }, [value, maxDelay]);

    return debouncedValue;
}
