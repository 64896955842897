import { ValueFormat } from "@fm-frontend/utils";
import { FC } from "react";

type CurrencyPriceProps = {
    value: number | bigint;
};

const CurrencyPrice: FC<CurrencyPriceProps> = ({ value }) => <div>{ValueFormat.price(value)}</div>;

export default CurrencyPrice;
