import useSWR from "swr";
import { requestHrpReporting } from "~utils/api";

type HRPInstrument = {
    fmInstrument: string;
    hrpInstrument: string;
};
type HRPInstruments = Record<
    string,
    {
        hrpInstrument: string;
    }
>;
type SWRKey = string;

const prepareHRPInstruments = (hrpResult: HRPInstrument[]): HRPInstruments =>
    hrpResult.reduce<HRPInstruments>((acc, curr) => {
        acc[curr.fmInstrument] = {
            hrpInstrument: curr.hrpInstrument,
        };

        return acc;
    }, {});

const fetcher = () =>
    requestHrpReporting("getInstruments", {}).then((data) => prepareHRPInstruments(data.result));

export const useHRPInstrumentsApi = () =>
    useSWR<HRPInstruments, unknown, SWRKey>("useHRPInstrumentsApi", fetcher, {
        revalidateOnFocus: false,
        // eslint-disable-next-line no-console
        onError: (e) => console.error(e),
    });
