export const enum AuthState {
    ConfirmMfaCode,
    Login,
    ChangePassword,
    SetupMfaCode,
    ResetPassword,
    ResetPasswordSubmit,
}

export enum AuthenticationException {
    UserNotFound = "UserNotFoundException",
    UserNotConfirmed = "UserNotConfirmedException",
    PasswordResetRequired = "PasswordResetRequiredException",
}

export enum ChallengeName {
    SoftwareTokenMfa = "SOFTWARE_TOKEN_MFA",
    UserSrpAuth = "USER_SRP_AUTH",
    MfaSetup = "MFA_SETUP",
    ResetRequired = "RESET_REQUIRED",
    NewPasswordRequired = "NEW_PASSWORD_REQUIRED",
    UserPasswordAuth = "USER_PASSWORD_AUTH",
}
