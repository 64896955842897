import { BITMASK_UTILS } from "@fm-frontend/utils";
import { useMemo } from "react";
import { ClientId } from "~entities/client";
import { TransactionFlagsBitmask } from "~entities/settlementTransactionHistoryItem";
import { useSettlementRequestsApi } from "~hooks/api/useSettlementRequestsApi";
import { useSettlementTransactionHistoryApi } from "~hooks/api/useSettlementTransactionHistoryApi";
import { useSettlementTransactionsApi } from "~hooks/api/useSettlementTransactionsApi";
import useAppSelector from "~hooks/useAppSelector";
import { TableData } from "~pages/Settlements";
import { SettlementStatus, SettlementType } from "~pages/Settlements/types";
import { getClient } from "~store/clients/selectors";

export const useTableData = (clientId: ClientId) => {
    const getClientById = useAppSelector(getClient);

    const { data: settlementRequests, isLoading: isSettlementRequestsLoading } =
        useSettlementRequestsApi(clientId);
    const { data: settlementTransactions, isLoading: isSettlementTransactionsLoading } =
        useSettlementTransactionsApi(clientId);
    const { data: settlementTransactionHistory, isLoading: isSettlementTransactionHistoryLoading } =
        useSettlementTransactionHistoryApi(clientId);

    const data = useMemo(() => {
        const { incoming: incomingRequest, outgoing: outgoingRequest } = settlementRequests ?? {};
        const { incoming: incomingTransactions, outgoing: outgoingTransactions } =
            settlementTransactions ?? {};

        return [
            // requests
            ...(incomingRequest?.map<TableData>((settlementRequest) => ({
                ...settlementRequest,
                status: SettlementStatus.Request,
                type: SettlementType.In,
                name: getClientById(settlementRequest.counterpartyId)?.username ?? "",
                txId: "",
                createdAt: settlementRequest.creationTimestamp,
            })) ?? []),
            ...(outgoingRequest?.map<TableData>((settlementRequest) => ({
                ...settlementRequest,
                status: SettlementStatus.Request,
                type: SettlementType.Out,
                name: getClientById(settlementRequest.counterpartyId)?.username ?? "",
                txId: "",
                createdAt: settlementRequest.creationTimestamp,
            })) ?? []),

            // transactions
            ...(incomingTransactions?.map<TableData>((settlementTransaction) => ({
                ...settlementTransaction,
                status:
                    settlementTransaction.sentAt > 0
                        ? SettlementStatus.Received
                        : SettlementStatus.Created,
                type: SettlementType.In,
                name: getClientById(settlementTransaction.counterpartyId)?.username ?? "",
            })) ?? []),
            ...(outgoingTransactions?.map<TableData>((settlementTransaction) => ({
                ...settlementTransaction,
                status:
                    settlementTransaction.sentAt > 0
                        ? SettlementStatus.Sent
                        : SettlementStatus.Created,
                type: SettlementType.Out,
                name: getClientById(settlementTransaction.counterpartyId)?.username ?? "",
            })) ?? []),

            // history
            ...(settlementTransactionHistory?.map<TableData>(
                (settlementTransactionHistoryItem) => ({
                    ...settlementTransactionHistoryItem,
                    status: BITMASK_UTILS.isBitKeyApplied(
                        settlementTransactionHistoryItem.transactionFlags,
                        TransactionFlagsBitmask.Cancelled,
                    )
                        ? SettlementStatus.Cancelled
                        : SettlementStatus.Committed,
                    type:
                        settlementTransactionHistoryItem.amount < 0
                            ? SettlementType.In
                            : SettlementType.Out,
                    name:
                        getClientById(settlementTransactionHistoryItem.counterpartyId)?.username ??
                        "",
                }),
            ) ?? []),
        ];
    }, [getClientById, settlementRequests, settlementTransactions, settlementTransactionHistory]);

    const isLoading =
        isSettlementRequestsLoading ||
        isSettlementTransactionsLoading ||
        isSettlementTransactionHistoryLoading;

    return { data, isLoading };
};
