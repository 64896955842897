import { InfiniteTable as UIKitTable, VStack } from "@fm-frontend/uikit";
import { EMPTY_ARRAY } from "@fm-frontend/uikit/src/const";
import { getCoreRowModel, getSortedRowModel, SortingState } from "@tanstack/react-table";
import { format } from "date-fns";
import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import Gap from "~components/Gap";
import OptionsContainer from "~components/Table/Options/Container";
import ExportButton from "~components/Table/Options/ExportButton";
import Search from "~components/Table/Options/Search";
import { TableContext, useTableContextValue } from "~components/Table/TableContext";
import { DATE_TIME_FORMAT } from "~constants/date";
import { Referral } from "~entities/referral";
import { useOnboardingStatusesApi } from "~hooks/api/useOnboardingStatusesApi";
import { useReferralsApi } from "~hooks/api/useReferralsApi";
import { ClientsOverviewTabs } from "~pages/Overview/ClientsOverviewTabs";
import { columns } from "~pages/Overview/InvitationsView/columns";
import InviteButton from "~pages/Overview/InviteButton";
import { ReferralsTableContext, useReferralsTableContextValue } from "~pages/Referrals/Context";
import {
    StatusSelect,
    StatusSelectValue,
    STATUS_SELECT_ALL_VALUE,
} from "~pages/Referrals/StatusSelect";
import {
    findInviteStatus,
    getInviteStatusTitle,
    getReferralStatusTitle,
    getTnCSaTitle,
} from "~pages/Referrals/utils";

const Table = styled(UIKitTable<Referral>)`
    min-width: 1010px;

    th:first-of-type,
    td:first-of-type {
        padding-left: 12px !important;
    }
`;

const OptionsWrapper = styled(VStack)`
    width: 100%;

    ${OptionsContainer} {
        :first-child {
            padding-bottom: 8px;
        }
    }
`;

const isMatch = (referrals: Referral, query: string) => {
    const { clientId, name, email } = referrals;
    const normalizedQuery = query.toUpperCase();

    if (clientId !== 0 && String(clientId).startsWith(query)) {
        return true;
    }

    return (
        name.toUpperCase().includes(normalizedQuery) ||
        email.toUpperCase().includes(normalizedQuery)
    );
};

export const InvitationsView: React.FC = () => {
    const { data: referrals = EMPTY_ARRAY, isLoading: isReferralsLoading } =
        useReferralsApi(undefined);
    const { data: onboardingStatuses = EMPTY_ARRAY, isLoading: isOnboardingStatusesLoading } =
        useOnboardingStatusesApi(
            referrals.map((referral) => referral.clientId).filter((takerId) => takerId !== 0),
        );

    const [sorting, setSorting] = useState<SortingState>([
        {
            id: "createdAt",
            desc: true,
        },
    ]);
    const [status, setStatus] = useState<StatusSelectValue>(STATUS_SELECT_ALL_VALUE);
    const referralsTableContextValue = useReferralsTableContextValue();
    const { setOnboardingStatuses } = referralsTableContextValue;
    const tableContextValue = useTableContextValue();
    const { query, setQuery } = tableContextValue;
    const isLoading = isReferralsLoading || isOnboardingStatusesLoading;

    useEffect(() => {
        setOnboardingStatuses(onboardingStatuses);
    }, [onboardingStatuses, setOnboardingStatuses]);

    const data = useMemo(() => {
        let items = referrals;

        if (status !== STATUS_SELECT_ALL_VALUE) {
            items = items.filter((item) => {
                const inviteStatus = findInviteStatus(onboardingStatuses, item.clientId);

                if (inviteStatus !== null) {
                    return inviteStatus === status;
                }

                return item.status === status;
            });
        }

        if (query !== "") {
            items = items.filter((item) => isMatch(item, query));
        }

        return items;
    }, [referrals, status, query, onboardingStatuses]);

    const getExportData = () =>
        data.map((item) => {
            const inviteStatus = findInviteStatus(onboardingStatuses, item.clientId);

            return {
                "Client ID": item.clientId,
                Name: item.name,
                Email: item.email,
                Type: item.clientType,
                "T&C SA": getTnCSaTitle(item.subsFmRelationType),
                Status:
                    inviteStatus !== null
                        ? getInviteStatusTitle(inviteStatus)
                        : getReferralStatusTitle(item.status),
                Created: format(item.createdAt, DATE_TIME_FORMAT),
            };
        });

    return (
        <TableContext.Provider value={tableContextValue}>
            <ReferralsTableContext.Provider value={referralsTableContextValue}>
                <OptionsWrapper>
                    <OptionsContainer>
                        <ClientsOverviewTabs />
                        <Gap />
                        <ExportButton
                            data={getExportData}
                            filename="clients"
                            loading={isLoading}
                            disabled={data.length === 0}
                        />
                        <InviteButton />
                    </OptionsContainer>
                    <OptionsContainer>
                        <Search query={query} onChange={setQuery} />
                        <StatusSelect value={status} onChange={setStatus} />
                        <Gap />
                    </OptionsContainer>
                </OptionsWrapper>
                <Table
                    tableOptions={{
                        data,
                        columns,
                        state: {
                            sorting,
                        },
                        onSortingChange: setSorting,
                        getCoreRowModel: getCoreRowModel(),
                        getSortedRowModel: getSortedRowModel(),
                    }}
                    isLoading={isLoading}
                />
            </ReferralsTableContext.Provider>
        </TableContext.Provider>
    );
};
