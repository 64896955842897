import { ButtonItem } from "~components/Layout/Menu/Item";
import { pulseAnalyticsUrl } from "~constants/api";
import { useClientId } from "~hooks/useClientId";
import { ReactComponent as PulseIcon } from "./icon.svg";

export const ViewInPulse = () => {
    const clientId = useClientId();
    const handleClick = () => {
        const url = new URL(pulseAnalyticsUrl);
        url.searchParams.set("companyId", String(clientId));

        window.open(url.href, "_blank");
    };

    return (
        <ButtonItem onClick={handleClick}>
            View in Pulse Analytics
            <PulseIcon />
        </ButtonItem>
    );
};
