import {
    FieldGroup,
    Form,
    FormActions,
    FormBody,
    Header,
    HeaderTitle,
    Modal,
    ShadowCard,
    SimpleDropdown,
    SimpleInput,
    Tab,
    TabContext,
    TabList,
} from "@fm-frontend/uikit";
import { ValueParse } from "@fm-frontend/utils";
import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import styled, { css } from "styled-components";
import FormError from "~components/FormError";
import { PrimaryButton } from "~components/PrimaryButton";
import { ValidateErrors, ValidateRules } from "~constants/form";
import { useCounterpartyLimitsApi } from "~hooks/api/useCounterpartyLimitsApi";
import { useInstrumentsApi } from "~hooks/api/useInstrumentsApi";
import useAppSelector from "~hooks/useAppSelector";
import { useClientId } from "~hooks/useClientId";
import { getClient } from "~store/clients/selectors";
import { AddSettlementOrderParams, requestAdminery } from "~utils/api";
import { prepareRequestError } from "~utils/prepareRequestError";

export const TextArea = styled.textarea<{ invalid?: boolean }>`
    padding: 12px;
    border-radius: 8px;
    background: ${(p) => p.theme.colors.smallButtonBG};
    resize: none;
    width: 100%;

    &::placeholder {
        font-size: 12px;
    }
    :enabled:active,
    :enabled:focus {
        background-color: ${(p) => p.theme.colors.uiWhite100};
        box-shadow: 0 0 0 3px ${(p) => p.theme.colors.brand12},
            inset 0 0 0 1px ${(p) => p.theme.colors.brand100};
    }
    ${(p) =>
        p.invalid &&
        css`
            box-shadow: 0 0 0 3px ${p.theme.colors.negative16},
                inset 0 0 0 1px ${p.theme.colors.negative100} !important;
        `}
`;

type Inputs = {
    clientId: number;
    asset: string;
    hasNetworks: boolean;
    network?: string;
    amount: string;
    comment: string;
};

interface CreateSettlementOrderModalProps {
    closeModal: () => void;
    onCreated: () => void;
}

type TransactionDirection = "in" | "out";

export const NewSettlementOrderModal = ({
    closeModal,
    onCreated,
}: CreateSettlementOrderModalProps) => {
    const [type, setType] = useState<TransactionDirection>("in");
    const {
        control,
        formState: { errors, isSubmitting },
        handleSubmit,
        register,
    } = useForm<Inputs>({
        defaultValues: {
            asset: "",
            amount: "",
            comment: "",
            hasNetworks: false,
        },
        mode: "onSubmit",
        reValidateMode: "onChange",
    });
    const [apiError, setApiError] = useState<string | null>(null);

    const { data: { currencies = [] } = {} } = useInstrumentsApi();
    const currencyOptions = useMemo(
        () =>
            currencies
                .map(({ name }) => ({ text: name, value: name }))
                .sort(({ text: text1 }, { text: text2 }) => text1.localeCompare(text2)),
        [currencies],
    );

    const clientId = useClientId();
    const { data: counterpartyLimitsData } = useCounterpartyLimitsApi(clientId);
    const getClientById = useAppSelector(getClient);

    const cpOptions = useMemo(
        () =>
            counterpartyLimitsData
                ?.map((counterpartyLimit) => {
                    const { counterpartyId } = counterpartyLimit;
                    const counterparty = getClientById(counterpartyId);

                    return {
                        value: counterpartyId,
                        text: `${counterparty?.username} (${counterpartyId})`,
                    };
                })
                .sort(({ text: text1 }, { text: text2 }) => text1.localeCompare(text2)) ?? [],
        [counterpartyLimitsData, getClientById],
    );

    const onSubmit = async (formValues: Inputs) => {
        try {
            setApiError(null);

            let clientIdA = clientId;
            let clientIdB = formValues.clientId;

            if (type === "in") {
                [clientIdA, clientIdB] = [clientIdB, clientIdA];
            }

            const requestBody: AddSettlementOrderParams = {
                clientIdA,
                clientIdB,
                currency1: formValues.asset,
                size1: ValueParse.size(formValues.amount),
                comment: formValues.comment,
            };
            if (formValues.network) {
                requestBody.network = formValues.network;
            }

            await requestAdminery("addSettlementOrder", requestBody);

            onCreated();
            closeModal();
        } catch (err) {
            setApiError(prepareRequestError(err));
        }
    };

    return (
        <Modal isOpen onClose={closeModal}>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <ShadowCard>
                    <Header>
                        <HeaderTitle title="New settlement order" />
                    </Header>
                    <FormBody spacing={12} alignItems="stretch">
                        <TabContext
                            value={type}
                            handleClick={(value) => setType(value as TransactionDirection)}
                        >
                            <TabList>
                                <Tab title="Incoming" value="in" />
                                <Tab title="Outgoing" value="out" />
                            </TabList>
                        </TabContext>

                        <FieldGroup>
                            <SimpleDropdown
                                label={type === "in" ? "From" : "To"}
                                options={cpOptions}
                                control={control}
                                rules={{ required: ValidateErrors.required }}
                                name="clientId"
                                placeholder="Select"
                                error={errors.clientId?.message}
                            />
                            <SimpleDropdown
                                label="Asset"
                                options={currencyOptions}
                                control={control}
                                rules={{ required: ValidateErrors.required }}
                                name="asset"
                                placeholder="Select"
                                error={errors.asset?.message}
                            />
                            <SimpleInput
                                label="Amount"
                                placeholder="0.00000000"
                                error={errors.amount?.message}
                                {...register("amount", {
                                    required: ValidateErrors.required,
                                    validate: {
                                        size: ValidateRules.size,
                                        greaterThan0: ValidateRules.greaterThan0,
                                    },
                                })}
                            />
                        </FieldGroup>

                        <TextArea
                            rows={4}
                            placeholder="Comment"
                            autoFocus
                            {...register("comment")}
                        />
                        {apiError && <FormError message={apiError} />}
                    </FormBody>
                    <FormActions variant="plain">
                        <PrimaryButton type="submit" fullWidth loading={isSubmitting} size="large">
                            Create order
                        </PrimaryButton>
                    </FormActions>
                </ShadowCard>
            </Form>
        </Modal>
    );
};
