import { PBold } from "@fm-frontend/uikit";
import { createColumnHelper } from "@tanstack/react-table";
import { getExpandColumn } from "~components/Table/Column/expand";
import { CounterpartyCell } from "~pages/OpenPositions/CounterpartyCell";
import { CurrencyCell } from "~pages/OpenPositions/CurrencyCell";
import { Item, ItemType, SubItem } from "~pages/OpenPositions/types";
import { VolumeCell } from "~pages/OpenPositions/VolumeCell";

const columnHelper = createColumnHelper<Item | SubItem>();

export const columns = [
    getExpandColumn(columnHelper),
    columnHelper.display({
        id: "subject",
        header: "Subject",
        cell: (info) => {
            const { type, subject } = info.row.original;
            const isExpandable = info.row.getCanExpand();

            if (type === ItemType.Counterparty) {
                return (
                    <CounterpartyCell
                        id={subject.id}
                        name={subject.name}
                        expandable={isExpandable}
                    />
                );
            }
            if (type === ItemType.Currency) {
                return <CurrencyCell currency={subject.name} expandable={isExpandable} />;
            }
            if (type === ItemType.Total) {
                return <PBold>Global total</PBold>;
            }

            return null;
        },
    }),
    columnHelper.accessor("position", {
        header: "Position",
        cell: (info) => <VolumeCell value={info.getValue()} />,
        enableSorting: false,
        meta: {
            headerStyleProps: {
                width: "200px",
                textAlign: "right",
            },
            cellStyleProps: {
                textAlign: "right",
            },
        },
    }),
    columnHelper.accessor("positionUSD", {
        header: "Position, $",
        cell: (info) => {
            const isExpandable = info.row.getCanExpand();
            const { type } = info.row.original;
            const value = info.getValue();

            return (
                <VolumeCell
                    value={value}
                    bold={isExpandable || type === ItemType.Total}
                    fixed={2}
                />
            );
        },
        enableSorting: false,
        meta: {
            headerStyleProps: {
                width: "200px",
                textAlign: "right",
            },
            cellStyleProps: {
                textAlign: "right",
            },
        },
    }),
    columnHelper.accessor("plannedPosition", {
        header: "Planned position",
        cell: (info) => <VolumeCell value={info.getValue()} />,
        enableSorting: false,
        meta: {
            headerStyleProps: {
                width: "200px",
                textAlign: "right",
            },
            cellStyleProps: {
                textAlign: "right",
            },
        },
    }),
    columnHelper.accessor("plannedPositionUSD", {
        header: "Planned position, $",
        cell: (info) => {
            const isExpandable = info.row.getCanExpand();
            const { type } = info.row.original;
            const value = info.getValue();

            return (
                <VolumeCell
                    value={value}
                    bold={isExpandable || type === ItemType.Total}
                    fixed={2}
                />
            );
        },
        enableSorting: false,
        meta: {
            headerStyleProps: {
                width: "200px",
                textAlign: "right",
            },
            cellStyleProps: {
                textAlign: "right",
            },
        },
    }),
];
