import { Icons } from "@fm-frontend/uikit";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useClientId } from "~hooks/useClientId";
import { getRiskManagementListUrl } from "~pages/Router";

const Container = styled.button`
    display: flex;
    align-items: center;
    background: transparent;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
`;

export const BackButton = () => {
    const navigate = useNavigate();
    const clientId = useClientId();

    const handleClick = () => navigate(getRiskManagementListUrl(clientId));

    return (
        <Container onClick={handleClick}>
            <Icons.FormBackAction />
            All partners
        </Container>
    );
};
