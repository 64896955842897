import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Client } from "~entities/client";
import {
    FetchClientsAction,
    ModifyClientAction,
    SetClientsAction,
    SetErrorAction,
    UnsetClientsAction,
} from "~store/clients/types";
import { EntityStatus } from "~store/types";
import { signOut } from "~store/user/actions";

type ClientsState = {
    status: EntityStatus;
    items: Client[];
    error: string;
};

const initialState: ClientsState = {
    status: EntityStatus.NotFetched,
    items: [],
    error: "",
};

export const clientsSlice = createSlice({
    name: "clients",
    initialState,
    reducers: {
        fetchClients: (state, { payload }: PayloadAction<FetchClientsAction>) => {
            state.status = EntityStatus.Fetching;
        },
        setClients: (state, { payload }: PayloadAction<SetClientsAction>) => {
            state.items = payload.clients;
            state.status = EntityStatus.Fetched;
        },
        unsetClients: (state, { payload }: PayloadAction<UnsetClientsAction>) => {
            state.items = [];
            state.status = EntityStatus.NotFetched;
        },
        modifyClient: (state, { payload }: PayloadAction<ModifyClientAction>) => {
            const { id, data } = payload;
            const index = state.items.findIndex((item) => item.id === id);

            state.items[index] = {
                ...state.items[index],
                ...data,
            };
        },
        setError: (state, { payload }: PayloadAction<SetErrorAction>) => {
            state.error = payload.error;
            state.status = EntityStatus.Error;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(signOut.fulfilled, () => initialState);
        builder.addCase(signOut.rejected, () => initialState);
    },
});

export default clientsSlice.reducer;
