import { ReactNode } from "react";

export enum RowType {
    Info,
    Instrument,
}

export type SubItem = {
    type: RowType;
    instrument: string;
    clientData: ReactNode;
    counterpartyData: ReactNode;
    items?: never;
};

export type Item = {
    type: RowType;
    instrument: string;
    clientData: ReactNode;
    counterpartyData: ReactNode;
    items: SubItem[];
};
