import { IconButton, Icons } from "@fm-frontend/uikit";
import { format } from "date-fns";
import { FC, useContext } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Headline, Property } from "~components/Specification";
import { Tag } from "~components/Tag";
import { DATE_TIME_FORMAT } from "~constants/date";
import { Client, ClientId } from "~entities/client";
import useAppSelector from "~hooks/useAppSelector";
import { ProfileContext } from "~pages/Profile/Context";
import { isAuthorizedTradingAvailable, isRfqAvailable } from "~pages/Profile/utils";
import { getClientUrl } from "~pages/Router";
import { getClient } from "~store/clients/selectors";
import { getClientSubaccountsTypeTitle } from "~utils/getClientSubaccountsTncRequested";
import { getClientTypeTitle } from "~utils/getClientTypeTitle";

const ViewMasterButtom = styled(IconButton)`
    margin-left: 15px;
`;

const MasterRow: FC<{ masterId: ClientId }> = ({ masterId }) => {
    const navigate = useNavigate();
    const getClientById = useAppSelector(getClient);

    const handleViewMasterClick = () => {
        navigate(getClientUrl(masterId));
    };

    return (
        <Property title="Master">
            {getClientById(masterId)?.username} ({masterId})
            <ViewMasterButtom
                title="View"
                variant="plain"
                Icon={Icons.ArrowRight}
                onClick={handleViewMasterClick}
            />
        </Property>
    );
};

const TagsContainer = styled.div`
    display: inline-flex;
    gap: 8px;
`;

const displayClientTags = (client: Client) => {
    return (
        <TagsContainer>
            {client.metadata?.tags?.map((tag) => (
                <Tag key={tag}>{tag}</Tag>
            ))}
        </TagsContainer>
    );
};

const ProfileGroup: FC = () => {
    const { client, clientInfo, authorizedTrading } = useContext(ProfileContext);
    const { primeBrokerId } = clientInfo ?? {};
    const hasMaster = primeBrokerId !== undefined && primeBrokerId !== 0;
    const whitelabel = client?.whitelabel || client?.subsWhitelabelName;

    return (
        <>
            <Headline>Profile</Headline>
            <Property title="Display name">{clientInfo?.username}</Property>
            <Property title="Legal name">{clientInfo?.companyName}</Property>
            <Property title="Email">{client?.email}</Property>
            <Property title="FM ID">{client?.id}</Property>
            {hasMaster && <MasterRow masterId={primeBrokerId} />}
            {whitelabel && (
                <Property title="Whitelabel">
                    <Tag>{whitelabel}</Tag>
                </Property>
            )}
            <Property title="Tags">{client && displayClientTags(client)}</Property>
            <Property title="Type">{client && getClientTypeTitle(client.type)}</Property>
            <Property title="T&C for sub-accounts">
                {client && getClientSubaccountsTypeTitle(client)}
            </Property>
            <Property title="Last trade">
                {client?.lastTradeAt ? format(client.lastTradeAt * 1000, DATE_TIME_FORMAT) : ""}
            </Property>
            {isRfqAvailable(client?.type) && (
                <Property title="RFQ">{clientInfo?.rfqEnabled ? "Enabled" : "Off"}</Property>
            )}
            {isAuthorizedTradingAvailable(client?.type) && (
                <Property title="Authorized trades">
                    {authorizedTrading ? "Enabled" : "Off"}
                </Property>
            )}
        </>
    );
};

export default ProfileGroup;
