import { PBold } from "@fm-frontend/uikit";
import { createColumnHelper } from "@tanstack/react-table";
import { getExpandColumn as getGenericExpandColumn } from "~components/Table/Column/expand";
import { ActionsCell } from "~pages/RisksManagement/View/AssetsControl/ActionsCell";
import { Item, RowType, SubItem } from "~pages/RisksManagement/View/AssetsControl/types";

const columnHelper = createColumnHelper<Item | SubItem>();

export const getExpandColumn = () => getGenericExpandColumn(columnHelper);

export const getTitleColumn = () =>
    columnHelper.accessor("title", {
        header: "",
        cell: (info) => {
            const { type } = info.row.original;

            if (type === RowType.Currency) {
                return (
                    <ActionsCell item={info.row.original}>
                        <PBold>{info.getValue()}</PBold>
                    </ActionsCell>
                );
            }

            return info.getValue() ?? null;
        },
        meta: {
            headerStyleProps: {
                width: "285px",
                minWidth: "285px",
            },
        },
        enableSorting: false,
    });

export const getClientColumn = (header = "clientValue") =>
    columnHelper.accessor("clientValue", {
        header,
        cell: (info) => info.getValue() ?? null,
        meta: {
            headerStyleProps: {
                width: "285px",
                minWidth: "285px",
                textAlign: "right",
            },
            cellStyleProps: {
                textAlign: "right",
            },
        },
        enableSorting: false,
    });

export const getCounterpartyColumn = (header = "counterpartyValue") =>
    columnHelper.accessor("counterpartyValue", {
        header,
        cell: (info) => info.getValue() ?? null,
        meta: {
            headerStyleProps: {
                width: "285px",
                minWidth: "285px",
                textAlign: "right",
            },
            cellStyleProps: {
                textAlign: "right",
            },
        },
        enableSorting: false,
    });

export const getExtraColumn = () =>
    columnHelper.display({
        id: "extraColumn",
        header: "",
        meta: {
            headerStyleProps: {
                width: "100%",
            },
        },
    });
