export enum Tabs {
    Counterparties = "Counterparties",
    Assets = "Assets",
}

export enum ItemType {
    Counterparty,
    Currency,
    Total,
}

type Subject =
    | {
          type: ItemType.Counterparty;
          subject: { id: number; name: string | undefined };
      }
    | {
          type: ItemType.Currency;
          subject: { name: string };
      }
    | {
          type: ItemType.Total;
          subject: never;
      };

export type SubItem = Subject & {
    position: bigint;
    positionUSD: bigint;
    plannedPosition: bigint;
    plannedPositionUSD: bigint;
    items: Record<string, never>;
};

export type Item = Subject & {
    position?: undefined;
    positionUSD: bigint;
    plannedPosition?: undefined;
    plannedPositionUSD: bigint;
    items: Record<string | number, SubItem>;
};
