import { BITMASK_UTILS } from "@fm-frontend/utils";
import { CounterpartyLimit, TradingDisabledFlagsBitmask } from "~entities/counterpartyLimit";
import {
    getAppliedLimit,
    getFreeLimitPercent,
    getMarginLimitValue,
} from "~pages/RisksManagement/utils/limit";

export enum LimitTradingStatus {
    ENABLED,
    DISABLED_BY_CLIENT,
    DISABLED_BY_CP,
    DISABLED_BY_BOTH,
}

/* eslint-disable no-bitwise */
export enum CounterpartyStatusType {
    NORMAL = 1 << 0,
    MARGIN_CALL = 1 << 1,
    LOW_GROSS_FREE = 1 << 2,
    RESTRICTED_TRADING = 1 << 3,
    STOPPED_BY_CP = 1 << 4,
    STOPPED = 1 << 5,
    LIQUIDATION = 1 << 6,
    AWAITING_CP = 1 << 7,
}
/* eslint-enable no-bitwise */

type Prices = Record<string, number | bigint>;

export const getGrossFreeStatus = (freeLimitPercent: number): CounterpartyStatusType => {
    if (freeLimitPercent >= 10e4) {
        return CounterpartyStatusType.NORMAL;
    }

    return CounterpartyStatusType.LOW_GROSS_FREE;
};

export const getCounterpartyGrossFreeStatus = (
    counterpartyLimit: CounterpartyLimit,
    prices: Prices,
): CounterpartyStatusType => {
    const { grossLimitUtilisation } = counterpartyLimit;
    const appliedLimit = getAppliedLimit(counterpartyLimit, prices);
    const freeLimitPercent = getFreeLimitPercent(appliedLimit, grossLimitUtilisation);

    return getGrossFreeStatus(freeLimitPercent);
};

export const getEquityStatus = (
    counterpartyLimit: CounterpartyLimit,
    prices: Prices,
    shouldTakeUserGrossLimit: boolean,
): CounterpartyStatusType => {
    const {
        grossLimit,
        mutualGrossLimit,
        equity,
        initialMargin,
        restrictedTrading,
        maintenanceMargin,
    } = counterpartyLimit;

    const isMarginEnabled = Boolean(initialMargin || restrictedTrading || maintenanceMargin);
    if (!isMarginEnabled) {
        return CounterpartyStatusType.NORMAL;
    }

    const makerGrossLimit = shouldTakeUserGrossLimit ? grossLimit : mutualGrossLimit ?? 0n;
    const takerEquity = shouldTakeUserGrossLimit ? BigInt(equity) * -1n : BigInt(equity);

    const maintenanceLimit = getMarginLimitValue(makerGrossLimit, maintenanceMargin);
    if (takerEquity <= maintenanceLimit) {
        return CounterpartyStatusType.LIQUIDATION;
    }

    const restrictedTradingLimit = getMarginLimitValue(makerGrossLimit, restrictedTrading);
    if (takerEquity <= restrictedTradingLimit) {
        return CounterpartyStatusType.RESTRICTED_TRADING;
    }

    const initialMarginLimit = getMarginLimitValue(makerGrossLimit, initialMargin);
    if (takerEquity <= initialMarginLimit) {
        return CounterpartyStatusType.MARGIN_CALL;
    }

    return CounterpartyStatusType.NORMAL;
};

export const bitmaskToTradingStatus = (bitMask: number) => {
    const disabledByCounterparty = BITMASK_UTILS.isBitKeyApplied(
        bitMask,
        TradingDisabledFlagsBitmask.DisabledByCounterparty,
    );
    const disabledByClient = BITMASK_UTILS.isBitKeyApplied(
        bitMask,
        TradingDisabledFlagsBitmask.DisabledByClient,
    );

    if (disabledByCounterparty && disabledByClient) {
        return LimitTradingStatus.DISABLED_BY_BOTH;
    }
    if (disabledByCounterparty) {
        return LimitTradingStatus.DISABLED_BY_CP;
    }
    if (disabledByClient) {
        return LimitTradingStatus.DISABLED_BY_CLIENT;
    }

    return LimitTradingStatus.ENABLED;
};

const checkStatus = (combinedStatus: number) => {
    if (BITMASK_UTILS.isBitKeyApplied(combinedStatus, CounterpartyStatusType.AWAITING_CP)) {
        return CounterpartyStatusType.AWAITING_CP;
    }
    if (BITMASK_UTILS.isBitKeyApplied(combinedStatus, CounterpartyStatusType.LIQUIDATION)) {
        return CounterpartyStatusType.LIQUIDATION;
    }
    if (BITMASK_UTILS.isBitKeyApplied(combinedStatus, CounterpartyStatusType.STOPPED)) {
        return CounterpartyStatusType.STOPPED;
    }
    if (BITMASK_UTILS.isBitKeyApplied(combinedStatus, CounterpartyStatusType.STOPPED_BY_CP)) {
        return CounterpartyStatusType.STOPPED_BY_CP;
    }
    if (BITMASK_UTILS.isBitKeyApplied(combinedStatus, CounterpartyStatusType.RESTRICTED_TRADING)) {
        return CounterpartyStatusType.RESTRICTED_TRADING;
    }
    if (BITMASK_UTILS.isBitKeyApplied(combinedStatus, CounterpartyStatusType.LOW_GROSS_FREE)) {
        return CounterpartyStatusType.LOW_GROSS_FREE;
    }
    if (BITMASK_UTILS.isBitKeyApplied(combinedStatus, CounterpartyStatusType.MARGIN_CALL)) {
        return CounterpartyStatusType.MARGIN_CALL;
    }

    return CounterpartyStatusType.NORMAL;
};

export const getCounterpartyStatus = (
    counterpartyLimit: CounterpartyLimit,
    grossFreeStatus: CounterpartyStatusType,
    marginStatus: CounterpartyStatusType,
): CounterpartyStatusType => {
    const { mutualGrossLimit, tradingDisabledFlags } = counterpartyLimit;
    const tradingStatus = bitmaskToTradingStatus(tradingDisabledFlags);

    let combinedStatus = BITMASK_UTILS.combineFlags(
        CounterpartyStatusType.NORMAL,
        grossFreeStatus,
        marginStatus,
    );

    if (mutualGrossLimit === null) {
        combinedStatus = BITMASK_UTILS.combineFlags(
            combinedStatus,
            CounterpartyStatusType.AWAITING_CP,
        );
    }

    if (
        tradingStatus === LimitTradingStatus.DISABLED_BY_BOTH ||
        tradingStatus === LimitTradingStatus.DISABLED_BY_CLIENT
    ) {
        combinedStatus = BITMASK_UTILS.combineFlags(combinedStatus, CounterpartyStatusType.STOPPED);
    }

    if (tradingStatus === LimitTradingStatus.DISABLED_BY_CP) {
        combinedStatus = BITMASK_UTILS.combineFlags(
            combinedStatus,
            CounterpartyStatusType.STOPPED_BY_CP,
        );
    }

    return checkStatus(combinedStatus);
};
