import { FC, useMemo } from "react";
import styled from "styled-components";
import ClientSelect from "~components/Layout/Menu/ClientSelect";
import { ALL_CLIENTS_ID } from "~components/Layout/Menu/ClientSelect/constants";
import { Client, ClientType } from "~entities/client";
import { useClientsApi } from "~hooks/api/useClientsApi";
import useAppSelector from "~hooks/useAppSelector";
import { useClientId } from "~hooks/useClientId";
import { useIsMaker } from "~hooks/useIsMaker";
import { useIsMaster } from "~hooks/useIsMaster";
import { getClients, isClientsFetched } from "~store/clients/selectors";
import { MigrateToCognito } from "./Actions/MigrateToCognito";
import { ResetCredentials } from "./Actions/ResetCredentials";
import { ViewInPulse } from "./Actions/ViewInPulse";
import { Container } from "./Container";
import { LinkItem } from "./Item";
import { Scope } from "./Scope";

const Title = styled.div`
    color: ${(p) => p.theme.colors.ui52};
    font-size: 11px;
    text-transform: uppercase;
    line-height: 16px;
    letter-spacing: 0.44px;
    padding: 0 4px 2px 8px;
`;

const getGroupName = (clientType: ClientType) => {
    switch (clientType) {
        case ClientType.Taker:
            return "Takers";
        case ClientType.Maker:
            return "Makers";
        case ClientType.PrimeBroker:
            return "Master";
        default:
            return " ";
    }
};

type GroupedClients = {
    Makers: Client[];
    Takers: Client[];
    Master: Client[];
    " ": Client[];
};

type MenuProps = {
    shown: boolean;
};

const Menu: FC<MenuProps> = ({ shown }) => {
    useClientsApi();

    const clientId = useClientId();
    const isMaker = useIsMaker();
    const isMaster = useIsMaster();
    const clientsFetched = useAppSelector(isClientsFetched);
    const clients = useAppSelector(getClients);
    const clientSelected = clientId > 0;

    const groupedClients = useMemo(() => {
        const list = [...clients];
        list.sort((a, b) => a.username.localeCompare(b.username));

        return list.reduce<GroupedClients>(
            (acc, client) => {
                acc[getGroupName(client.type)].push(client);
                return acc;
            },
            { Makers: [], Takers: [], Master: [], " ": [] },
        );
    }, [clients]);

    return (
        <Container $shown={shown}>
            <Title>Platform</Title>
            <Scope $type="menu">
                <LinkItem to="overview">Overview</LinkItem>
                <LinkItem to="order-book">Order Book</LinkItem>
                <LinkItem to="assets-and-instruments">Assets & Instruments</LinkItem>
                <LinkItem to="my-admin-keys">My admin keys</LinkItem>
            </Scope>
            <Title>Client</Title>
            <ClientSelect
                clients={groupedClients}
                selectedClientId={clientSelected ? clientId : ALL_CLIENTS_ID}
                clientsFetched={clientsFetched}
            />
            {clientSelected && clientsFetched && (
                <>
                    <Scope $type="submenu">
                        <LinkItem to="profile">Profile</LinkItem>
                        <LinkItem to="open-positions">Open positions</LinkItem>
                        {(isMaster || isMaker) && <LinkItem to="orders">Orders</LinkItem>}
                        <LinkItem to="risk-management">Risk management</LinkItem>
                        <LinkItem to="settlements">Settlements</LinkItem>
                        <LinkItem to="history">History</LinkItem>
                        <LinkItem to="users-and-roles">Users & Roles</LinkItem>
                        {isMaster && <LinkItem to="sub-accounts">Sub-accounts</LinkItem>}
                        <LinkItem to="referrals">Referrals</LinkItem>
                    </Scope>
                    <Scope $type="actions">
                        <ResetCredentials />
                        {/* <NewSettlementOrder /> */}
                        <MigrateToCognito />
                        <ViewInPulse />
                    </Scope>
                </>
            )}
        </Container>
    );
};

export default Menu;
