import { FC } from "react";
import styled from "styled-components";
import MarketDepthItem from "~components/MarketDepth/Item";

const Container = styled.div`
    flex: 1;
`;

type ListProps = {
    type: "bid" | "ask";
    list: [price: number | bigint, size: number | bigint][];
};

const List: FC<ListProps> = ({ type, list }) => {
    const { sum, items } = list.reduce(
        (acc, [price, size]) => {
            acc.sum += BigInt(size);
            acc.items.push(acc.sum);
            return acc;
        },
        { sum: BigInt(0), items: [] as bigint[] },
    );

    return (
        <Container>
            {list.map(([price, size], index) => (
                <MarketDepthItem
                    key={`${price}`}
                    price={price}
                    size={size}
                    percent={Number((BigInt(items[index]) * 100n) / sum)}
                    type={type}
                    first={index === 0}
                />
            ))}
        </Container>
    );
};

export default List;
