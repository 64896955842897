import { EMPTY_ARRAY } from "@fm-frontend/uikit/src/const";
import { useState } from "react";
import { Feed, MessageType } from "~constants/feeds";
import { ClientId } from "~entities/client";
import { Order } from "~entities/order";
import useFeed from "~hooks/useFeed";
import { formatMessageError } from "~utils/formatMessageError";
import { prepareOrder } from "~utils/prepareFeedEntity";

export const usePositionsFeed = (clientId: ClientId) => {
    const feed = useFeed(
        Feed.Positions,
        [Feed.Positions, Feed.Orders, Feed.SettlementOrders],
        clientId,
    );
    const [orders, setOrders] = useState<Order[]>();
    const [error, setError] = useState<string>();
    const isLoading = orders === undefined;

    feed.onUnbind(() => {
        setOrders(undefined);
    });

    feed.onReceive((messageType, messageContent, messageFeed) => {
        switch (messageType) {
            case MessageType.Snapshot: {
                const [id, rawPositions, rawOrders, rawSettlementOrders] = messageContent;

                setOrders(rawOrders.map(prepareOrder));
                break;
            }
            case MessageType.Unsubscribed:
                setOrders([]);
                break;
            case MessageType.Add:
                if (messageFeed === Feed.Orders) {
                    setOrders((items) => [...(items ?? []), prepareOrder(messageContent)]);
                }
                break;
            case MessageType.Remove:
                if (messageFeed === Feed.Orders) {
                    const { orderId } = prepareOrder(messageContent);
                    setOrders((items) => items?.filter((item) => item.orderId !== orderId));
                }
                break;
            case MessageType.Error: {
                setError(
                    typeof messageContent === "string"
                        ? messageContent
                        : formatMessageError(messageContent),
                );
                break;
            }
            default:
                throw new Error(`Unhandled message type: ${messageType}`);
        }
    });

    return {
        orders: orders ?? EMPTY_ARRAY,
        isLoading,
        error,
    };
};
