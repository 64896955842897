import {
    DropdownOption,
    MultipleDropdown,
    OPTIONS_SEARCH_COUNT,
    SheetSize,
    SingleDropdown,
} from "@fm-frontend/uikit/src/components/v2/Dropdown";
import React from "react";
import { OptionEssence } from "../OptionEssence";

type DropdownCounterpartiesOption = DropdownOption<number | string>;
type SheetProps = {
    size: SheetSize;
    options: DropdownCounterpartiesOption[];
    groupingOptions?: DropdownCounterpartiesOption[];
    Dropdown: typeof SingleDropdown | typeof MultipleDropdown;
};
export const Sheet: React.FC<SheetProps> = ({ size, options, groupingOptions = [], Dropdown }) => {
    return (
        <Dropdown.Sheet size={size}>
            {({ query, normalizedQuery, onSearch }) => (
                <>
                    {options.length > OPTIONS_SEARCH_COUNT && (
                        <Dropdown.Search query={query} onSearch={onSearch} />
                    )}
                    {!query && (
                        <Dropdown.EmptySearch>
                            {groupingOptions.map((option) => (
                                <Dropdown.Option key={`${option.value}`} value={option.value}>
                                    <OptionEssence groupingOption option={option} query={query} />
                                </Dropdown.Option>
                            ))}
                            {Boolean(groupingOptions.length && options.length) && (
                                <Dropdown.Divider />
                            )}
                            {options.map((option) => (
                                <Dropdown.Option key={`${option.value}`} value={option.value}>
                                    <OptionEssence option={option} query={query} />
                                </Dropdown.Option>
                            ))}
                        </Dropdown.EmptySearch>
                    )}
                    {query && (
                        <Dropdown.EmptySearch>
                            {options
                                .filter(
                                    (opt) =>
                                        opt.text?.toLowerCase().includes(normalizedQuery) ||
                                        `${opt.value}`?.toLowerCase().includes(normalizedQuery),
                                )
                                .map((option) => (
                                    <Dropdown.Option key={`${option.value}`} value={option.value}>
                                        <OptionEssence option={option} query={query} />
                                    </Dropdown.Option>
                                ))}
                        </Dropdown.EmptySearch>
                    )}
                </>
            )}
        </Dropdown.Sheet>
    );
};
