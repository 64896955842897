import { P, PBold } from "@fm-frontend/uikit";
import { FC, useContext } from "react";
import MatchContains from "~components/Table/Cell/MatchContains";
import { TableContext } from "~components/Table/TableContext";

type CurrencyCellProps = {
    currency: string;
    expandable?: boolean;
};

export const CurrencyCell: FC<CurrencyCellProps> = ({ currency, expandable = false }) => {
    const { query } = useContext(TableContext);

    if (expandable) {
        return (
            <PBold>
                <MatchContains value={currency} query={query} />
            </PBold>
        );
    }

    return <P>{currency}</P>;
};
