import {
    calculateRange,
    DatePicker,
    ExportCSV,
    IconButton,
    Icons,
    InfiniteTable as UIKitTable,
    Tooltip,
} from "@fm-frontend/uikit";
import { EMPTY_ARRAY } from "@fm-frontend/uikit/src/const";
import { EmDash, ValueFormat } from "@fm-frontend/utils";
import {
    createColumnHelper,
    getCoreRowModel,
    getSortedRowModel,
    SortingState,
} from "@tanstack/react-table";
import { format, startOfDay, subMonths } from "date-fns";
import { FC, useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import Gap from "~components/Gap";
import {
    PartnerSelect,
    PartnerSelectType,
    PartnerValueType,
    PARTNER_SELECT_ALL_VALUE,
} from "~components/PartnerSelect";
import { CounterpartyDropdownSelector } from "~components/Selectors/CounterpartyDropdown";
import { InstrumentDropdownSelector } from "~components/Selectors/InstrumentDropdown";
import DateTimeViewer from "~components/Table/Cell/DateTimeViewer";
import OptionsContainer from "~components/Table/Options/Container";
import Search from "~components/Table/Options/Search";
import { TablePaginator } from "~components/Table/Paginator";
import { TableContext, useTableContextValue } from "~components/Table/TableContext";
import { DATE_TIME_FORMAT } from "~constants/date";
import { OrderType } from "~entities/order";
import { RFQDealHistoryItem } from "~entities/rfqDealHistoryItem";
import { useInstrumentsApi } from "~hooks/api/useInstrumentsApi";
import {
    rfqDealHistoryFetcher,
    RFQDealHistoryFilter,
    useRFQDealHistoryApi,
} from "~hooks/api/useRFQDealHistoryApi";
import useAppSelector from "~hooks/useAppSelector";
import { useClientId } from "~hooks/useClientId";
import { useIsMaster } from "~hooks/useIsMaster";
import { useSubaccountsIds } from "~hooks/useSubaccountsIds";
import { CounterpartyCell } from "~pages/History/RFQ/CounterpartyCell";
import { DeltaCell } from "~pages/History/RFQ/DeltaCell";
import InstrumentCell from "~pages/History/RFQ/InstrumentCell";
import { OrderIdCell } from "~pages/History/RFQ/OrderIdCell";
import { OrderTypeCell } from "~pages/History/RFQ/OrderTypeCell";
import { PriceCell } from "~pages/History/RFQ/PriceCell";
import { SideCell } from "~pages/History/RFQ/SideCell";
import { SizeCell } from "~pages/History/RFQ/SizeCell";
import { getStatusTitle, StatusCell } from "~pages/History/RFQ/StatusCell";
import { TradeIdCell } from "~pages/History/RFQ/TradeIdCell";
import { TabList } from "~pages/History/TabList";
import { getClient, getClients, isClientsFetched } from "~store/clients/selectors";
import { sortTimestamp } from "~utils/sortTimestamp";

const PAGE_SIZE = 250;

export type Range = { startDate?: Date | null; endDate?: Date | null };

export const Table = styled(UIKitTable<RFQDealHistoryItem>)`
    min-width: 1010px;

    th:first-of-type,
    td:first-of-type {
        padding-left: 12px !important;
    }
`;

const columnHelper = createColumnHelper<RFQDealHistoryItem>();

const dealsTableColumns = [
    columnHelper.accessor("id", {
        header: "Order ID",
        cell: (info) => <OrderIdCell value={info.getValue()} />,
        meta: {
            headerStyleProps: {
                width: "110px",
            },
        },
    }),
    columnHelper.display({
        header: "Order type",
        cell: () => <OrderTypeCell orderType={OrderType.RFQ} />,
        meta: {
            headerStyleProps: {
                width: "110px",
            },
        },
    }),
    columnHelper.accessor("counterpartyId", {
        id: "name",
        header: "Name",
        cell: (info) => {
            const value = info.getValue();

            return value === undefined ? null : <CounterpartyCell value={value} />;
        },
        // TODO: Find a way to type custom sorting functions
        // @ts-ignore
        sortingFn: "usernameSorting",
    }),
    columnHelper.accessor("instrumentName", {
        header: "Instrument",
        cell: (info) => <InstrumentCell value={info.getValue()} />,
        meta: {
            headerStyleProps: {
                width: "90px",
            },
        },
    }),
    columnHelper.accessor("side", {
        header: "Side",
        cell: (info) => <SideCell value={info.getValue()} />,
        meta: {
            headerStyleProps: {
                width: "50px",
            },
        },
    }),
    columnHelper.accessor("price", {
        header: "Price",
        cell: (info) => <PriceCell value={info.getValue()} />,
        meta: {
            headerStyleProps: {
                width: "150px",
            },
        },
    }),
    columnHelper.accessor("size", {
        header: "Size",
        cell: (info) => <SizeCell value={info.getValue()} />,
        meta: {
            headerStyleProps: {
                width: "150px",
            },
        },
    }),
    columnHelper.accessor("status", {
        header: "Status",
        cell: (info) => <StatusCell value={info.getValue()} />,
        meta: {
            headerStyleProps: {
                width: "150px",
            },
        },
    }),
    columnHelper.accessor("clientDelta", {
        header: "Delta",
        cell: (info) => <DeltaCell value={info.getValue()} />,
        meta: {
            headerStyleProps: {
                width: "150px",
            },
        },
    }),
    columnHelper.accessor((row) => row.tradeId, {
        header: "Trade ID",
        cell: (info) => <TradeIdCell value={info.getValue()} />,
        meta: {
            headerStyleProps: {
                width: "80px",
            },
        },
    }),
    columnHelper.accessor((row) => row.tradeId, {
        header: "Linked trade ID",
        cell: (info) => <TradeIdCell value={info.getValue()} />,
        meta: {
            headerStyleProps: {
                width: "80px",
            },
        },
    }),
    columnHelper.accessor((row) => row.tradeId, {
        header: "Client order ID",
        cell: (info) => <TradeIdCell value={info.getValue()} />,
        meta: {
            headerStyleProps: {
                width: "80px",
            },
        },
    }),
    columnHelper.accessor("date", {
        header: "Date",
        sortingFn: sortTimestamp,
        cell: (info) => <DateTimeViewer value={info.getValue() / 1000} />,
        meta: {
            headerStyleProps: {
                width: "100px",
            },
        },
    }),
];

const isMatch = (historyItem: RFQDealHistoryItem, query: string) => {
    const { id, counterpartyId, counterpartyName, instrumentName } = historyItem;
    const normalizedQuery = query.toUpperCase();

    return (
        String(id).startsWith(normalizedQuery) ||
        (counterpartyId !== undefined && String(counterpartyId).startsWith(normalizedQuery)) ||
        (counterpartyName !== undefined &&
            counterpartyName.toUpperCase().includes(normalizedQuery)) ||
        instrumentName.toUpperCase().includes(normalizedQuery)
    );
};

const getDefaultRange = () => ({
    startDate: startOfDay(subMonths(new Date(), 1)),
    endDate: new Date(),
});

const getFilter = (filter: PartnerValueType) => {
    return filter === PartnerSelectType.Counterparties
        ? RFQDealHistoryFilter.External
        : RFQDealHistoryFilter.Subaccounts;
};

export const RFQHistory: FC = () => {
    const clientsFetched = useAppSelector(isClientsFetched);
    const clientId = useClientId();
    const isMaster = useIsMaster();
    const subaccountsIds = useSubaccountsIds(clientId);
    const [pageNumber, setPageNumber] = useState<number>(0);
    const [range, setRange] = useState<{ startDate?: Date | null; endDate?: Date | null }>(
        getDefaultRange(),
    );
    const [sorting, setSorting] = useState<SortingState>([]);
    const [partnerType, setPartnerType] = useState<PartnerValueType>(
        PartnerSelectType.Counterparties,
    );
    const tableContextValue = useTableContextValue();
    const { query, setQuery } = tableContextValue;
    const clients = useAppSelector(getClients);
    const getClientById = useAppSelector((state) => getClient(state));
    const counterpartyIds = clients.map((client) => client.id);

    const { data: instrumentsApiData } = useInstrumentsApi();
    const { instruments = [] } = instrumentsApiData ?? {};
    const instrumentNames = instruments.map((instrument) => instrument.name);

    const {
        control,
        watch,
        reset,
        formState: { isDirty },
    } = useForm({
        defaultValues: {
            selectedCounterparties: [] as number[],
            selectedInstruments: [] as string[],
        },
    });
    const selectedCounterparties = watch("selectedCounterparties");
    const selectedInstruments = watch("selectedInstruments");

    const {
        data = EMPTY_ARRAY,
        isLoading: isRFQDealHistoryLoading = false,
        totalPages = 0,
    } = useRFQDealHistoryApi({
        clientId,
        filter: isMaster ? getFilter(partnerType) : undefined,
        counterpartyIds: selectedCounterparties,
        instruments: selectedInstruments,
        from: range.startDate?.getTime(),
        to: range.endDate?.getTime(),
        pageSize: PAGE_SIZE,
        pageNumber,
    });

    const isLoading = isRFQDealHistoryLoading || !clientsFetched;

    const deals = useMemo(() => {
        if (query === "" && partnerType === PARTNER_SELECT_ALL_VALUE) {
            return data;
        }

        return data.filter((item) => {
            if (item.counterpartyId === undefined) {
                return false;
            }

            const isSubaccount = subaccountsIds.includes(item.counterpartyId);

            if (partnerType === PartnerSelectType.Counterparties && isSubaccount) {
                return false;
            }
            if (partnerType === PartnerSelectType.SubAccounts && !isSubaccount) {
                return false;
            }

            return isMatch(item, query);
        });
    }, [data, partnerType, query, subaccountsIds]);

    const hasPrevPage = pageNumber > 0;
    const hasNextPage = pageNumber + 1 < totalPages;

    const getExportData = async ({ startDate, endDate }: Range) => {
        let exportPageNumber = 0;
        let exportData: Record<string, unknown>[] = [];

        // eslint-disable-next-line no-constant-condition
        while (true) {
            // Next iteration depends on the previous one
            // eslint-disable-next-line no-await-in-loop
            const response = await rfqDealHistoryFetcher({
                clientId,
                filter: isMaster ? getFilter(partnerType) : undefined,
                counterpartyIds: selectedCounterparties,
                instruments: selectedInstruments,
                pageSize: PAGE_SIZE,
                pageNumber: exportPageNumber,
                from: startDate?.getTime(),
                to: endDate?.getTime(),
            });

            const exportPageData = response.content.map((item) => ({
                "Order ID": item.id,
                "Order type": "RFQ",
                "Client ID": item.counterpartyId,
                Name: item.counterpartyName,
                Instrument: item.instrumentName,
                Side: item.side,
                Price: item.price === undefined ? EmDash : ValueFormat.price(item.price),
                Size: ValueFormat.size(item.size),
                Status: getStatusTitle(item.status),
                Delta: item.clientDelta === undefined ? EmDash : ValueFormat.size(item.clientDelta),
                "Trade ID": item.tradeId,
                "Linked trade ID": item.tradeId,
                "Client order ID": item.tradeId,
                Date: format(item.date * 1000, DATE_TIME_FORMAT),
            }));
            exportData = exportData.concat(exportPageData);

            if (response.pageable.pageNumber + 1 < response.totalPages) {
                exportPageNumber += 1;
            } else {
                break;
            }
        }

        return exportData;
    };

    const handleRangeChange = useCallback(({ startDate, endDate }: Range) => {
        setPageNumber(0);
        setRange({ startDate, endDate });
    }, []);

    const handleRangeReset = useCallback(() => {
        setPageNumber(0);
        setRange(getDefaultRange());
    }, []);

    const handlePrevClick = useCallback(() => {
        setPageNumber((prevPageNumber) => prevPageNumber - 1);
    }, []);

    const handleNextClick = useCallback(() => {
        setPageNumber((prevPageNumber) => prevPageNumber + 1);
    }, []);

    const handleResetFilters = useCallback(() => {
        setPageNumber(0);
        setRange(getDefaultRange());
        reset();
    }, [reset]);

    return (
        <TableContext.Provider value={tableContextValue}>
            <OptionsContainer>
                <Search query={query} onChange={setQuery} />
                <TabList />
                {isMaster && <PartnerSelect value={partnerType} onChange={setPartnerType} />}
                <DatePicker
                    key={`${range.startDate}-${range.endDate}`}
                    startDate={range.startDate}
                    endDate={range.endDate}
                    onConfirm={handleRangeChange}
                    onReset={handleRangeReset}
                />
                <CounterpartyDropdownSelector
                    name="selectedCounterparties"
                    control={control}
                    counterparties={counterpartyIds}
                />
                <InstrumentDropdownSelector
                    name="selectedInstruments"
                    control={control}
                    instruments={instrumentNames}
                />
                {isDirty && (
                    <Tooltip content="Reset filter" align="center">
                        <IconButton
                            variant="plain"
                            type="button"
                            Icon={Icons.Recent}
                            onClick={handleResetFilters}
                        />
                    </Tooltip>
                )}
                <Gap />
                <ExportCSV
                    getData={getExportData}
                    defaultStartDate={startOfDay(subMonths(new Date(), 1))}
                    defaultEndDate={new Date()}
                    size="small"
                    defaultFileName={`trades_history_${clientId}`}
                />
            </OptionsContainer>
            <Table
                tableOptions={{
                    data: deals,
                    columns: dealsTableColumns,
                    state: {
                        sorting,
                    },
                    sortingFns: {
                        usernameSorting: (rowA, rowB, columnId) => {
                            const client1 = getClientById(rowA.getValue(columnId));
                            const client2 = getClientById(rowB.getValue(columnId));

                            if (
                                client1?.username === undefined ||
                                client2?.username === undefined
                            ) {
                                return 0;
                            }

                            return client1.username.localeCompare(client2.username);
                        },
                    },
                    onSortingChange: setSorting,
                    getCoreRowModel: getCoreRowModel(),
                    getSortedRowModel: getSortedRowModel(),
                }}
                isLoading={isLoading}
            />
            <TablePaginator
                range={calculateRange(deals, "date")}
                pageItemsCount={deals.length}
                allItemsCount={pageNumber * PAGE_SIZE + deals.length}
                isLoading={isLoading}
                hasPrevPage={hasPrevPage}
                hasNextPage={hasNextPage}
                onPrevClick={handlePrevClick}
                onNextClick={handleNextClick}
            />
        </TableContext.Provider>
    );
};
