import {
    DesctructiveButton,
    H2 as Title,
    P as Description,
    PlainButton,
    PSmall,
} from "@fm-frontend/uikit";
import { FC } from "react";
import styled from "styled-components";
import { ERROR_MESSAGES } from "~constants/errors";
import { User } from "~entities/user";
import useNotifications from "~hooks/useNotifications";
import { requestAdminery } from "~utils/api";

const Content = styled.div`
    padding: 12px 12px 0 12px;
    color: ${(p) => p.theme.colors.ui100};
    width: 360px;
`;

const Subtitle = styled(PSmall)`
    display: block;
    color: ${(p) => p.theme.colors.ui52};
    padding-bottom: 12px;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const Actions = styled.div`
    display: flex;
    gap: 8px;
    padding: 16px 12px;
    width: 100%;
    & > * {
        flex: 1;
    }
`;

type ResetCredentialsModalType = {
    user: User;
    onClose?: () => void;
};

export const ResetCredentialsModal: FC<ResetCredentialsModalType> = ({ user, onClose }) => {
    const { showSuccessNotification, showErrorNotification } = useNotifications();
    const handleResetCredentials = async () => {
        onClose?.();

        try {
            const response = await requestAdminery("resetMFA", {
                email: user.email,
            });

            if (!response?.error) {
                showSuccessNotification("MFA settings have been successfully reset.");
            }

            if (response?.error) {
                showErrorNotification(ERROR_MESSAGES[response?.error] ?? response?.error);
            }
        } catch (e) {
            showErrorNotification(`${e}`);
        }
    };
    const handleClose = () => onClose?.();

    return (
        <>
            <Content>
                <Title>Reset credentials</Title>
                <Subtitle>
                    {user.name} ({user.clientId}), {user.email}
                </Subtitle>
                <Description>
                    Reset Owner’s credentials for {user.name} ({user.clientId})?
                </Description>
            </Content>
            <Actions>
                <PlainButton size="large" onClick={handleClose}>
                    Cancel
                </PlainButton>
                <DesctructiveButton size="large" onClick={handleResetCredentials}>
                    Yes, reset
                </DesctructiveButton>
            </Actions>
        </>
    );
};
