import { Form, FormActions, FormBody, H2, P } from "@fm-frontend/uikit";
import { FC } from "react";
import styled from "styled-components";
import { PrimaryButton } from "~components/PrimaryButton";
import { NewKey } from "~entities/keys";

const FormContainer = styled(Form)`
    width: 100%;
    min-width: initial;
    padding: 8px;
`;

const KeyBlock = styled(P)`
    word-wrap: break-word;
`;

type NewKeyModalProps = {
    newKey: NewKey;
    onClose: () => void;
};

export const NewKeyModal: FC<NewKeyModalProps> = ({ newKey, onClose }) => {
    return (
        <FormContainer>
            <FormBody alignItems="stretch">
                <div>
                    <H2>New key:</H2>
                    <KeyBlock>{newKey.key}</KeyBlock>
                </div>
                <div>
                    <H2>Allow write:</H2>
                    <P>{newKey.allowWrite ? "Y" : "N"}</P>
                </div>
                <div>
                    <H2>Secret:</H2>
                    <KeyBlock>{newKey.secret}</KeyBlock>
                </div>
            </FormBody>
            <FormActions variant="plain">
                <PrimaryButton type="button" size="large" fullWidth onClick={onClose}>
                    Close
                </PrimaryButton>
            </FormActions>
        </FormContainer>
    );
};
