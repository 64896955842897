import useSWR from "swr";
import { ClientId } from "~entities/client";
import { Subaccount } from "~entities/subaccount";
import { requestAdminery } from "~utils/api";

type SWRKey = [clientId: ClientId, key: string];

const fetcher = ([clientId]: SWRKey) => requestAdminery("getSubaccounts", { clientId });

export const useSubaccountsApi = (clientId: ClientId) =>
    useSWR<Subaccount[], unknown, SWRKey>([clientId, "useSubaccountsApi"], fetcher);
