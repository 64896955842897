import { P } from "@fm-frontend/uikit";
import { createColumnHelper } from "@tanstack/react-table";
import { ClientCell } from "~components/Table/Cell/ClientCell";
import DateTimeViewer from "~components/Table/Cell/DateTimeViewer";
import { EllipsisCellTheme } from "~components/Table/CellTheme/Ellipsis";
import { AmountCell } from "~pages/Settlements/AmountCell";
import { AssetCell } from "~pages/Settlements/AssetCell";
import { FeeCell } from "~pages/Settlements/FeeCell";
import { TableData } from "~pages/Settlements/index";
import { StatusCell } from "~pages/Settlements/StatusCell";
import { TxIdCell } from "~pages/Settlements/TxIdCell";
import { sortTimestamp } from "~utils/sortTimestamp";

const columnHelper = createColumnHelper<TableData>();

export const settlementTransactionColumns = [
    columnHelper.accessor("status", {
        header: "Status",
        cell: (info) => <StatusCell value={info.getValue()} />,
        meta: {
            headerStyleProps: {
                width: "90px",
            },
        },
    }),
    columnHelper.accessor("type", {
        header: "Type",
        cell: (info) => info.getValue(),
        meta: {
            headerStyleProps: {
                width: "50px",
            },
        },
    }),
    columnHelper.accessor("name", {
        header: "Name",
        cell: (info) => (
            <EllipsisCellTheme maxWidth={240}>
                <ClientCell id={info.row.original.counterpartyId} name={info.getValue()} />
            </EllipsisCellTheme>
        ),
        meta: {
            headerStyleProps: {
                width: "240px",
            },
        },
    }),
    columnHelper.accessor("currency", {
        header: "Asset",
        cell: (info) => (
            <AssetCell currency={info.getValue()} networkName={info.row.original.networkName} />
        ),
        meta: {
            headerStyleProps: {
                width: "100px",
            },
        },
    }),
    columnHelper.accessor("amount", {
        header: "Amount",
        cell: (info) => <AmountCell amount={info.getValue()} data={info.row.original} />,
        meta: {
            headerStyleProps: {
                width: "160px",
                textAlign: "right",
            },
            cellStyleProps: {
                textAlign: "right",
            },
        },
    }),
    columnHelper.accessor("networkFee", {
        id: "feePaid",
        header: "Fee paid",
        cell: (info) => <FeeCell data={info.row.original} />,
        meta: {
            headerStyleProps: {
                width: "160px",
                textAlign: "right",
            },
            cellStyleProps: {
                textAlign: "right",
            },
        },
    }),
    columnHelper.accessor("txId", {
        header: "TX ID",
        cell: (info) => (
            <EllipsisCellTheme maxWidth={90}>
                <TxIdCell value={info.getValue()} />
            </EllipsisCellTheme>
        ),
        meta: {
            headerStyleProps: {
                textAlign: "right",
                width: "90px",
            },
            cellStyleProps: {
                textAlign: "right",
            },
        },
    }),
    columnHelper.accessor("comment", {
        header: "Comment",
        cell: (info) => <P title={info.getValue()}>{info.getValue()}</P>,
        meta: {
            headerStyleProps: {
                textAlign: "right",
            },
            cellStyleProps: {
                textAlign: "right",
            },
        },
    }),
    columnHelper.accessor("createdAt", {
        header: "Created",
        sortingFn: sortTimestamp,
        cell: (info) => <DateTimeViewer value={info.getValue() / 1000} />,
        meta: {
            headerStyleProps: {
                width: "100px",
            },
        },
    }),
];
