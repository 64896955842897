import { FC } from "react";
import Select from "~components/Table/Select";
import { Order } from "~entities/order";

export const INSTRUMENT_SELECT_ALL_VALUE = "All";

export type InstrumentSelectValue = string | typeof INSTRUMENT_SELECT_ALL_VALUE;

type InstrumentSelectProps = {
    orders: Order[];
    value: InstrumentSelectValue;
    onChange: (value: InstrumentSelectValue) => void;
};

export const InstrumentSelect: FC<InstrumentSelectProps> = ({ orders, value, onChange }) => {
    const instrumentNames = orders.reduce((acc, order) => {
        acc.add(order.instrumentName);
        return acc;
    }, new Set<string>());
    const instrumentOptions = [...instrumentNames]
        .sort()
        .map((instrument) => ({ text: instrument, value: instrument }));
    const options = [{ text: "All", value: INSTRUMENT_SELECT_ALL_VALUE }, ...instrumentOptions];

    return (
        <Select<InstrumentSelectValue>
            label="Instrument"
            defaultValue={value}
            options={options}
            onChange={onChange}
        />
    );
};
