import { InfiniteTable as UIKitTable } from "@fm-frontend/uikit";
import { getCoreRowModel, getExpandedRowModel } from "@tanstack/react-table";
import React, { useMemo } from "react";
import styled from "styled-components";
import { useClientsApi } from "~hooks/api/useClientsApi";
import { useInstrumentsApi } from "~hooks/api/useInstrumentsApi";
import { useInstrumentsWhitelist } from "~hooks/api/useInstrumentsWhitelist";
import { useClientId } from "~hooks/useClientId";
import { globalColumns } from "~pages/RisksManagement/List/globalsColumns";
import { Item, RowType, SubItem } from "~pages/RisksManagement/List/types";

const Table = styled(UIKitTable<Item | SubItem>)`
    min-width: 505px;

    thead {
        visibility: collapse;
    }

    th:first-of-type,
    td:first-of-type {
        padding-left: 12px !important;
    }
`;

export const GlobalInstrumentsWhitelist: React.FC = () => {
    useClientsApi();
    const clientId = useClientId();

    const { data: instrumentsWhitelist, isLoading: isInstrumentsWhitelistLoading } =
        useInstrumentsWhitelist(clientId);
    const { data: instrumentsData, isLoading: isInstrumentsLoading } = useInstrumentsApi();

    const globalInstrumentsWhitelistTitle = "Global instruments whitelist";
    const isLoading = isInstrumentsWhitelistLoading || isInstrumentsLoading;

    const data: Item[] = useMemo(
        () => [
            {
                type: RowType.GlobalInstrumentsWhitelist,
                title: globalInstrumentsWhitelistTitle,
                items: [
                    {
                        type: RowType.Info,
                        title: "Status",
                        value: instrumentsWhitelist?.enabled ? "Enabled" : "Disabled",
                    },
                    {
                        type: RowType.Info,
                        title: "Instruments",
                        value: `Enabled ${instrumentsWhitelist?.instruments?.length ?? 0} out of ${
                            instrumentsData?.instruments?.length ?? 0
                        }`,
                    },
                ],
            },
        ],
        [instrumentsWhitelist, instrumentsData, globalInstrumentsWhitelistTitle],
    );

    return (
        <Table
            tableOptions={{
                data,
                columns: globalColumns,
                getCoreRowModel: getCoreRowModel(),
                state: {
                    expanded: true,
                },
                getExpandedRowModel: getExpandedRowModel(),
                getSubRows: (row) => {
                    return row.items;
                },
                getRowCanExpand: (row) => {
                    return row.original.type !== RowType.Info;
                },
            }}
            isLoading={isLoading}
        />
    );
};
