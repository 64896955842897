import { BasicInput, Form, FormActions, FormBody, ShadowCard } from "@fm-frontend/uikit";
import { FC, useContext, useState } from "react";
import { useForm } from "react-hook-form";
import FormError from "~components/FormError";
import { PrimaryButton } from "~components/PrimaryButton";
import useAppDispatch from "~hooks/useAppDispatch";
import { AuthContext } from "~pages/Auth/context";
import useSignIn from "~pages/Auth/hooks/useSignIn";
import { ValidationRules } from "~pages/Auth/utils";
import { refreshAuthenticatedUser } from "~store/user/actions";

type LoginFrom = {
    email: string;
    password: string;
};

const LoginContent: FC = () => {
    const { setAuthState, setUserState } = useContext(AuthContext);
    const { signIn, loading } = useSignIn();
    const dispatch = useAppDispatch();
    const [error, setError] = useState<string>();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<LoginFrom>({
        mode: "onChange",
    });

    const handleFormSubmit = handleSubmit(({ email, password }) => {
        signIn(email, password)
            .then(({ nextAuthState, userState, error: signInError }) => {
                setError(signInError);
                setUserState(userState);
                setAuthState(nextAuthState);

                if (userState?.getSignInUserSession()?.isValid()) {
                    // TODO: Check this case:
                    dispatch(refreshAuthenticatedUser());
                }
            })
            .catch((e) => setError(e?.message));
    });

    return (
        <Form onSubmit={handleFormSubmit}>
            <ShadowCard>
                <FormBody>
                    <BasicInput
                        type="email"
                        label="Email"
                        placeholder="admin@finerymarkets.com"
                        error={errors.email?.message}
                        {...register("email", {
                            ...ValidationRules.email,
                        })}
                    />
                    <BasicInput
                        type="password"
                        label="Password"
                        placeholder="Password"
                        error={errors.password?.message}
                        {...register("password", {
                            ...ValidationRules.password,
                        })}
                    />
                    {error && <FormError message={error} />}
                </FormBody>
                <FormActions variant="plain">
                    <PrimaryButton type="submit" fullWidth size="large" loading={loading}>
                        Login
                    </PrimaryButton>
                </FormActions>
            </ShadowCard>
        </Form>
    );
};

export default LoginContent;
