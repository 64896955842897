export type RFQDealId = number;

export enum RFQDealHistorySide {
    Buy = "BUY",
    Sell = "SELL",
}

export enum RFQDealHistoryStatus {
    Created = "CREATED",
    Canceled = "CANCELED",
    Committed = "COMMITTED",
    Expired = "EXPIRED",
}

export type RFQDealHistoryItemRaw = {
    id: RFQDealId;
    counterpartyId?: number;
    counterpartyName?: string;
    instrumentName: string;
    side: RFQDealHistorySide;
    price?: bigint | number;
    size: bigint | number;
    status: RFQDealHistoryStatus;
    date: number;
    tradeId?: number;
    clientDelta?: bigint | number;
};

export type RFQDealHistoryItem = {
    id: RFQDealId;
    counterpartyId?: number;
    counterpartyName?: string;
    instrumentName: string;
    side: RFQDealHistorySide;
    price: bigint | number;
    size: bigint | number;
    status: RFQDealHistoryStatus;
    date: number;
    tradeId?: number;
    clientDelta: bigint | number;
};
