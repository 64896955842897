import {
    DesctructiveButton,
    H2 as Title,
    Loading,
    P as Description,
    PlainButton,
    PSmall,
} from "@fm-frontend/uikit";
import { FC } from "react";
import styled from "styled-components";
import { ButtonItem } from "~components/Layout/Menu/Item";
import ModalButton from "~components/ModalButton";
import useAppSelector from "~hooks/useAppSelector";
import { useClientId } from "~hooks/useClientId";
import useNotifications from "~hooks/useNotifications";
import { getClient } from "~store/clients/selectors";
import { requestAdminery } from "~utils/api";
import { prepareRequestError } from "~utils/prepareRequestError";
import { ReactComponent as CognitoIcon } from "./icon.svg";

const Content = styled.div`
    padding: 12px 12px 0 12px;
    color: ${(p) => p.theme.colors.ui100};
    width: 360px;
    justify-content: center;
`;

const Subtitle = styled(PSmall)`
    display: block;
    color: ${(p) => p.theme.colors.ui52};
    padding-bottom: 12px;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const Actions = styled.div`
    display: flex;
    gap: 8px;
    padding: 16px 12px;
    width: 100%;
    & > * {
        flex: 1;
    }
`;

type ModalContentProps = {
    onClose: () => void;
};

const ModalContent: FC<ModalContentProps> = ({ onClose }) => {
    const { showSuccessNotification, showErrorNotification } = useNotifications();
    const clientId = useClientId();
    const client = useAppSelector((state) => getClient(state)(clientId));

    if (client === undefined) {
        return (
            <Content>
                <Title>Migrate client to Cognito</Title>
                <Actions>
                    <Loading />
                </Actions>
            </Content>
        );
    }

    const handleMigrate = async () => {
        try {
            await requestAdminery("migrateUser", { email: client.email });
            showSuccessNotification("The user has successfully migrated.");
        } catch (e) {
            showErrorNotification(prepareRequestError(e));
        }
        onClose?.();
    };

    return (
        <>
            <Content>
                <Title>Migrate client to Cognito</Title>
                <Subtitle>
                    {client.username} ({client.id}), {client.email}
                </Subtitle>
                <Description>Migrate client data to Cognito Cloud Services?</Description>
            </Content>
            <Actions>
                <PlainButton size="large" onClick={onClose}>
                    Cancel
                </PlainButton>
                <DesctructiveButton size="large" onClick={handleMigrate}>
                    Yes, migrate
                </DesctructiveButton>
            </Actions>
        </>
    );
};

export const MigrateToCognito = () => (
    <ModalButton
        renderModalContent={(closePopover) => <ModalContent onClose={closePopover} />}
        renderButton={(handleClick) => (
            <ButtonItem onClick={handleClick}>
                Migrate to Cognito
                <CognitoIcon />
            </ButtonItem>
        )}
    />
);
