import { ValueFormat } from "@fm-frontend/utils";
import { TableData } from "~pages/Settlements/index";
import { SettlementStatus, SettlementType } from "~pages/Settlements/types";
import { abs } from "~utils/abs";

export const getExportFeePayed = (data: TableData) => {
    const { networkFee } = data;

    if (networkFee === undefined) {
        return "";
    }

    if (networkFee > 0) {
        return `${ValueFormat.size(networkFee)} by Recipient`;
    }

    return "by Sender";
};

export const getExportAmount = (data: TableData) => {
    const { amount, status, type } = data;

    if (status === SettlementStatus.Request) {
        return "All available";
    }

    const formattedAmount = ValueFormat.size(abs(amount));

    if (type === SettlementType.In) {
        return `+${formattedAmount}`;
    }
    if (type === SettlementType.Out) {
        return `-${formattedAmount}`;
    }
    return formattedAmount;
};
