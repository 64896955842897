import { ClientId } from "~entities/client";

export enum OrderType {
    Limit = 0,
    PostOnly = 1,
    LimitIOC = 2,
    LimitFOK = 3,
    MarketIOC = 4,
    MarketFOK = 5,
    ManualTrade = 6,
    LiquidationTrade = 7,

    RFQ = 9,
}

export enum OrderSide {
    Bid,
    Ask,
}

export enum OrderCancelReason {
    InPlaceOrFilled,
    ByClient,
    AsNonBookOrder,
    BySelfTradePrevention,
    CancelOnDisconnect,
}

export enum OrderCreatedByType {
    BySize,
    ByVolume,
}

export type OrderId = number;

export type Order = {
    instrumentName: string;
    orderType: OrderType;
    side: OrderSide;
    cancelReason: OrderCancelReason;
    orderId: OrderId;
    orderClientId: ClientId;
    orderPrice: number | bigint;
    orderInitialSizeOrVolume: number | bigint;
    orderRemainingSizeOrVolume: number | bigint;
    orderCreatedAt: number;
    createdByType: OrderCreatedByType;
};
