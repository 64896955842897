export enum ErrorType {
    NotAuthorized = 3,
    InvalidNonceOrSignature = 6,
}

export const ERROR_MESSAGES = [
    "", // 0
    "Not implemented", // 1
    "Not connected", // 2
    "Not authorized", // 3
    "Already authorized", // 4
    "Invalid password", // 5
    "Invalid nonce", // 6
    "Invalid timestamp", // 7
    "API method not available", // 8
    "API method parameter is invalid", // 9
    "Internal error", // 10
    "Invalid MFA code", // 11
    "MFA not enabled", // 12
    null, // 13
    "Invalid signature", // 14,
    "The link has expired", // 15
    null, // 16
    null, // 17
    null, // 18
    null, // 19
    "Invalid currency flags", // 20
    "Invalid currency price", // 21
    "Invalid currency balance step", // 22
    "Invalid currency name", // 23
    "Currency name cannot be changed", // 24
    "Currency balance step cannot be changed", // 25
    "Currency not found", // 26
    "Currency cannot be removed", // 27
    null, // 28
    null, // 29
    "Invalid instrument flags", // 30
    "Invalid instrument name", // 31
    "Instrument asset currency cannot be changed", // 32
    "Instrument balance currency cannot be changed", // 33
    "Instrument not found", // 34
    "Instrument cannot be removed", // 35
    null, // 36
    null, // 37
    null, // 38
    null, // 39
    "Invalid client flags", // 40
    "Invalid client taker delta ratio", // 41
    "Invalid name", // 42
    "Client type cannot be changed", // 43
    "Client already exists", // 44
    "Client not found", // 45
    "Username already exists", // 46
    null, // 47
    null, // 48
    null, // 49
    "Invalid limit flags", // 50
    null, // 51
    "Invalid limit gross limit", // 52
    "Limit not found", // 53
    "Identical clients not allowed", // 54
    "Identical client types not allowed", // 55
    "Invalid counterparty", // 56
    null, // 57
    null, // 58
    null, // 59
    "Invalid settlement order flags", // 60
    "Invalid settlement order size", // 61
    "Invalid settlement order comment", // 62
    "Identical settlement clients", // 63
    "Settlement order not found", // 64
    "Settlement order is from transaction", // 65
    null, // 66
    null, // 67
    null, // 68
    null, // 69
    "Invalid order size", // 70
    "Invalid order price", // 71
    "Invalid order flags", // 72
    "Order type not allowed", // 73
    "Client order id already in use", // 74
    "Add failed - Post-Only", // 75
    "Add failed - IOC: no orders to match", // 76
    "Add failed - FOK: not enough liquidity", // 77
    "Add failed - SMP (self-trade prevention)", // 78
    "Add failed - limits", // 79
    "Del failed - not found", // 80
    "Either volume or size should be specified", // 81
    "Orders by volume not supported for makers", // 82
    "Invalid order volume", // 83
    "Trading not allowed", // 84
    "No open positions in order currencies", // 85
    null, // 86
    null, // 87
    null, // 88
    null, // 89
    "Mod failed - no size after decrement", // 90
    "Mod failed - side mismatch", // 91
    null, // 92
    null, // 93
    null, // 94
    null, // 95
    null, // 96
    null, // 97
    null, // 98
    null, // 99
    "Binding already exists", // 100
    "Binding not found", // 101
    "Invalid feed name", // 102
    "Invalid feed id", // 103
    "Database out-of-sync", // 104
    null, // 105
    null, // 106
    null, // 107
    null, // 108
    null, // 109
    "Field Required", // 110
    "Field Invalid", // 111
    "Poor Username", // 112
    "Poor Password", // 113
    "Password Change Required", // 114
    null, // 115
    null, // 116
    null, // 117
    null, // 118
    null, // 119
    "Maximum number of keys reached", // 120
    "Key not found", // 121
    null, // 122
    null, // 123
    null, // 124
    null, // 125
    null, // 126
    null, // 127
    null, // 128
    null, // 129
    "Settlement request already exists", // 130
    "Settlement request not found", // 131
    "Invalid settlement request flags", // 132
    "Invalid settlement counterparty", // 133
    "Invalid settlment request comment", // 134
    "Invalid settlment request amount", // 135
    null, // 136
    null, // 137
    null, // 138
    null, // 139
    "Invalid settlement transaction flags", // 140
    "Invalid settlement transaction amount", // 141
    "Invalid settlement transaction txId", // 142
    "Identical clients not allowed", // 143
    "Settlement transaction not found", // 144
    null, // 145
    null, // 146
    null, // 147
    null, // 148
    null, // 149
    null, // 150
    null, // 151
    null, // 152
    null, // 153
    null, // 154
    null, // 155
    null, // 156
    null, // 157
    null, // 158
    null, // 159
    "Taker limit by asset reached", // 160
    "Taker global gross limit breached",
    "Maker limit by asset reached", // 162
    "Maker global gross limit breached", // 163
    "Not enough equity", // 164
    "Taker counterparty gross limit breached", // 165
    "Master counterparty gross limit breached", // 166
    "Maker counterparty gross limit breached", // 167
    "Master limit by asset reached", // 168
    "Master global gross limit breached", // 169
    "Short position forbidden", // 170
    "Master limit breached", // 171
    null, // 172
    null, // 173
    null, // 174
    null, // 175
    null, // 176
    null, // 177
    null, // 178
    null, // 179
    "Network not found", // 180
    "Network already exists", // 181
    "Network invalid name", // 182
    "Network invalid description", // 183
    null, // 184
    null, // 185
    null, // 186
    null, // 187
    null, // 188
    null, // 189
    null, // 190
    null, // 191
    null, // 192
    null, // 193
    null, // 194
    null, // 195
    null, // 196
    null, // 197
    null, // 198
    null, // 199
    "User not found", // 200
    "Invalid email", // 201
    "Invalid access rights", // 202
    "Invalid username", // 203
    "Invalid user fullname", // 204
    "Invite not found", // 205
    "Token not found", // 206
    "Token expired", // 207
    "Token revoked", // 208
    "Invalid key", // 209
    "Invalid content", // 210
    "Network error", // 211
    "Refresh token", // 212
    "Invalid invite id", // 213
    "User already exists", // 214
    "Migration in progress", // 215
    "Invite already used", // 216
    "Not enough permissions for the action", // 217
    "An account with the given email already exists", // 218
    "Client cannot change master", // 219
    "Invalid master id", // 220
    "Subaccount not found", // 221
    "Invalid margins", // 222
    "Invalid markup", // 223
    "Invalid client type", // 224
    "Invalid subaccount id", // 225
    "Feed not available", // 226
    "Email is already invited", // 227
    "Disable subaccount open position exists", // 228
    "Client is deactivated", // 229,
    null, // 230,
    null, // 231,
    null, // 232,
    null, // 233,
    null, // 234,
    null, // 235,
    null, // 236,
    null, // 237,
    null, // 238,
    null, // 239,
    null, // 240,
    null, // 241,
    null, // 242,
    null, // 243,
    null, // 244,
    null, // 245,
    null, // 246,
    null, // 247,
    null, // 248,
    null, // 249,
    null, // 250,
    null, // 251,
    null, // 252,
    null, // 253,
    null, // 254,
    null, // 255,
    null, // 256,
    null, // 257,
    null, // 258,
    null, // 259,
    null, // 260,
    null, // 261,
    null, // 262,
    null, // 263,
    null, // 264,
    null, // 265,
    null, // 266,
    null, // 267,
    null, // 268,
    null, // 269,
    null, // 270,
    null, // 271,
    null, // 272,
    null, // 273,
    null, // 274,
    null, // 275,
    null, // 276,
    null, // 277,
    null, // 278,
    null, // 279,
    null, // 280,
    null, // 281,
    null, // 282,
    null, // 283,
    null, // 284,
    null, // 285,
    null, // 286,
    null, // 287,
    null, // 288,
    null, // 289,
    null, // 290,
    null, // 291,
    null, // 292,
    null, // 293,
    null, // 294,
    null, // 295,
    null, // 296,
    null, // 297,
    null, // 298,
    null, // 299,
    null, // 300,
    null, // 301,
    null, // 302,
    null, // 303,
    null, // 304,
    null, // 305,
    null, // 306,
    null, // 307,
    null, // 308,
    null, // 309,
    null, // 310,
    null, // 311,
    null, // 312,
    null, // 313,
    null, // 314,
    null, // 315,
    null, // 316,
    null, // 317,
    null, // 318,
    null, // 319,
    null, // 320,
    null, // 321,
    null, // 322,
    null, // 323,
    null, // 324,
    null, // 325,
    null, // 326,
    null, // 327,
    null, // 328,
    null, // 329,
    null, // 330,
    null, // 331,
    null, // 332,
    null, // 333,
    null, // 334,
    null, // 335,
    null, // 336,
    null, // 337,
    null, // 338,
    null, // 339,
    null, // 340,
    null, // 341,
    null, // 342,
    null, // 343,
    null, // 344,
    null, // 345,
    null, // 346,
    null, // 347,
    null, // 348,
    null, // 349,
    null, // 350,
    null, // 351,
    null, // 352,
    null, // 353,
    null, // 354,
    null, // 355,
    null, // 356,
    null, // 357,
    null, // 358,
    null, // 359,
    null, // 360,
    null, // 361,
    null, // 362,
    null, // 363,
    null, // 364,
    null, // 365,
    null, // 366,
    null, // 367,
    null, // 368,
    null, // 369,
    null, // 370,
    null, // 371,
    null, // 372,
    null, // 373,
    null, // 374,
    null, // 375,
    null, // 376,
    null, // 377,
    null, // 378,
    null, // 379,
    null, // 380,
    null, // 381,
    null, // 382,
    null, // 383,
    null, // 384,
    null, // 385,
    null, // 386,
    null, // 387,
    null, // 388,
    null, // 389,
    null, // 390,
    null, // 391,
    null, // 392,
    null, // 393,
    null, // 394,
    null, // 395,
    null, // 396,
    null, // 397,
    null, // 398,
    null, // 399,
    null, // 400,
    null, // 401,
    null, // 402,
    null, // 403,
    null, // 404,
    null, // 405,
    null, // 406,
    null, // 407,
    null, // 408,
    null, // 409,
    null, // 410,
    null, // 411,
    null, // 412,
    null, // 413,
    null, // 414,
    null, // 415,
    null, // 416,
    null, // 417,
    null, // 418,
    null, // 419,
    null, // 420,
    null, // 421,
    null, // 422,
    null, // 423,
    null, // 424,
    null, // 425,
    null, // 426,
    null, // 427,
    null, // 428,
    null, // 429,
    null, // 430,
    null, // 431,
    null, // 432,
    null, // 433,
    null, // 434,
    null, // 435,
    null, // 436,
    null, // 437,
    null, // 438,
    null, // 439,
    null, // 440,
    null, // 441,
    null, // 442,
    null, // 443,
    null, // 444,
    null, // 445,
    null, // 446,
    null, // 447,
    null, // 448,
    null, // 449,
    null, // 450,
    null, // 451,
    null, // 452,
    null, // 453,
    null, // 454,
    null, // 455,
    null, // 456,
    null, // 457,
    null, // 458,
    null, // 459,
    null, // 460,
    null, // 461,
    null, // 462,
    null, // 463,
    null, // 464,
    null, // 465,
    null, // 466,
    null, // 467,
    null, // 468,
    null, // 469,
    null, // 470,
    null, // 471,
    null, // 472,
    null, // 473,
    null, // 474,
    null, // 475,
    null, // 476,
    null, // 477,
    null, // 478,
    null, // 479,
    null, // 480,
    null, // 481,
    null, // 482,
    null, // 483,
    null, // 484,
    null, // 485,
    null, // 486,
    null, // 487,
    null, // 488,
    null, // 489,
    null, // 490,
    null, // 491,
    null, // 492,
    null, // 493,
    null, // 494,
    null, // 495,
    null, // 496,
    null, // 497,
    null, // 498,
    null, // 499,
    null, // 500,
    "Invalid FM client ID", // 501,
    "Invalid HRP client ID", // 502,
    "Client mapping not found", // 503,
    "Invalid FM instrument", // 504,
    "Invalid HRP instrument", // 505,
    "Instrument mapping not found", // 506,
    "Invalid cutoff time", // 507,
    "Invalid cutoff window", // 508,
    "Master ID not set", // 509,
    "Trade report failed", // 510,
    "Invalid deal ID", // 511,
];
