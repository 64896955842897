import { FC } from "react";
import { Headline, Property } from "~components/Specification";
import { TakerInfo } from "~entities/takerInfo";

type TakerGeneralInfoGroupProps = {
    takerInfo: TakerInfo | undefined;
};

const TakerGeneralInfoGroup: FC<TakerGeneralInfoGroupProps> = ({ takerInfo }) => (
    <>
        <Headline>General Information</Headline>
        <Property title="Business type">{takerInfo?.business_type}</Property>
        <Property title="Exp. Turnover">
            {takerInfo?.monthly_turnover &&
                `${takerInfo?.monthly_turnover?.toLocaleString("en-US")} USD per month`}
        </Property>
        <Property title="How often trade">{takerInfo?.trade_frequency}</Property>
        <Property title="How often settle">{takerInfo?.settle_frequency}</Property>
        <Property title="Banks">{takerInfo?.bank?.map((bank) => bank.name).join(", ")}</Property>
        <Property title="Website">{takerInfo?.website}</Property>
        <Property title="Telegram">{takerInfo?.telegram}</Property>
        <Property title="Assets">
            {takerInfo?.currency?.map((currency) => currency.name).join(", ")}
        </Property>
    </>
);

export default TakerGeneralInfoGroup;
