import { HStack, Icons, InfiniteTable as UIKitTable, Tooltip } from "@fm-frontend/uikit";
import {
    createColumnHelper,
    getCoreRowModel,
    getSortedRowModel,
    SortingState,
} from "@tanstack/react-table";
import { useMemo, useState } from "react";
import styled from "styled-components";
import Gap from "~components/Gap";
import OptionsContainer from "~components/Table/Options/Container";
import ExportButton from "~components/Table/Options/ExportButton";
import Search from "~components/Table/Options/Search";
import { TableContext, useTableContextValue } from "~components/Table/TableContext";
import { Instrument } from "~entities/instrument";
import { useHRPInstrumentsApi } from "~hooks/api/useHRPInstrumentsApi";
import { useInstrumentsApi } from "~hooks/api/useInstrumentsApi";
import AddButton from "~pages/AssetsAndInstruments/Instruments/AddButton";
import { HRPSymbol } from "~pages/AssetsAndInstruments/Instruments/HRPSymbol";
import InstrumentActions from "~pages/AssetsAndInstruments/Instruments/InstrumentActions";
import InstrumentCurrency from "~pages/AssetsAndInstruments/Instruments/InstrumentCurrency";
import InstrumentName from "~pages/AssetsAndInstruments/Instruments/InstrumentName";
import { TabList } from "~pages/AssetsAndInstruments/TabList";

const Table = styled(UIKitTable<Instrument>)`
    min-width: 1010px;

    th:first-of-type,
    td:first-of-type {
        padding-left: 12px !important;
    }
`;

const columnHelper = createColumnHelper<Instrument>();

const instrumentsTableColumns = [
    columnHelper.accessor("name", {
        header: "Name",
        cell: (info) => <InstrumentName value={info.getValue()} />,
        meta: {
            headerStyleProps: {
                width: "220px",
            },
        },
    }),
    columnHelper.accessor("assetCurrency", {
        header: "Base",
        cell: (info) => <InstrumentCurrency value={info.getValue()} />,
        meta: {
            headerStyleProps: {
                width: "160px",
            },
        },
    }),
    columnHelper.accessor("balanceCurrency", {
        header: "Quote",
        cell: (info) => <InstrumentCurrency value={info.getValue()} />,
    }),
    columnHelper.accessor("hrpSymbol", {
        header: () => (
            <HStack spacing={4}>
                HRP symbol
                <Tooltip
                    align="center"
                    content="Check HRP specification to correctly input the instrument name or set that it is N/A"
                >
                    <Icons.TooltipQuestion />
                </Tooltip>
            </HStack>
        ),
        cell: (info) => <HRPSymbol value={info.getValue()} />,
    }),
    columnHelper.display({
        id: "actions",
        cell: (info) => <InstrumentActions instrument={info.row.original} />,
        enableSorting: false,
        meta: {
            headerStyleProps: {
                width: "100px",
            },
        },
    }),
];

const isMatch = (instrument: Instrument, query: string) => {
    const { name, assetCurrency, balanceCurrency } = instrument;

    return (
        name.toUpperCase().includes(query.toUpperCase()) ||
        assetCurrency.toUpperCase().includes(query.toUpperCase()) ||
        balanceCurrency.toUpperCase().includes(query.toUpperCase())
    );
};

export const Instruments = () => {
    const { data: apiData, isLoading: instrumentsFetching } = useInstrumentsApi();
    const { instruments = [] } = apiData ?? {};
    const { data: hrpInstruments = {}, isLoading: isHRPInstrumentsLoading } =
        useHRPInstrumentsApi();
    const [sorting, setSorting] = useState<SortingState>([]);
    const tableContextValue = useTableContextValue();
    const { query, setQuery } = tableContextValue;

    const data = useMemo(
        () =>
            (query === ""
                ? instruments
                : instruments.filter((instrument) => isMatch(instrument, query))
            ).map((instrument) => ({
                ...instrument,
                hrpSymbol: hrpInstruments[instrument.name]?.hrpInstrument,
            })),
        [instruments, hrpInstruments, query],
    );

    const getExportData = () =>
        data.map((item) => ({
            "Instrument ID": item.id,
            Name: item.name,
            "Asset currency": item.assetCurrency,
            "Balance currency": item.balanceCurrency,
            "HRP symbol": item.hrpSymbol ?? "",
        }));

    return (
        <TableContext.Provider value={tableContextValue}>
            <OptionsContainer>
                <Search query={query} onChange={setQuery} />
                <TabList />
                <Gap />
                <ExportButton
                    data={getExportData}
                    filename="instruments"
                    loading={instrumentsFetching || isHRPInstrumentsLoading}
                    disabled={data.length === 0}
                />
                <AddButton />
            </OptionsContainer>
            <Table
                tableOptions={{
                    data,
                    columns: instrumentsTableColumns,
                    state: {
                        sorting,
                    },
                    onSortingChange: setSorting,
                    getCoreRowModel: getCoreRowModel(),
                    getSortedRowModel: getSortedRowModel(),
                }}
                onInitialize={(instrumentsTable) => {
                    instrumentsTable.setSorting([{ id: "name", desc: false }]);
                }}
                isLoading={instrumentsFetching}
            />
        </TableContext.Provider>
    );
};
