import { Icons, Modal, SmallLoader } from "@fm-frontend/uikit";
import { PopoverContent } from "@fm-frontend/uikit/src/components/dropdowns/common";
import { EmDash, useModalControl } from "@fm-frontend/utils";
import { FC, memo } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import AllClients from "~components/Layout/Menu/ClientSelect/AllClients";
import { ALL_CLIENTS_ID, ALL_CLIENTS_VALUE } from "~components/Layout/Menu/ClientSelect/constants";
import {
    ClientSelectContext,
    useClientSelectContextValue,
} from "~components/Layout/Menu/ClientSelect/Context";
import Search from "~components/Layout/Menu/ClientSelect/Search";
import { SelectGroups, SelectValue } from "~components/Layout/Menu/ClientSelect/types";
import {
    getContent,
    getSelectedItem,
    searchClients,
} from "~components/Layout/Menu/ClientSelect/utils";
import { ClientId } from "~entities/client";
import { getAllClientsUrl, getClientUrl } from "~pages/Router";

const Container = styled.div`
    margin-bottom: 7px;
`;

const Dropdown = styled.div`
    display: flex;
    border: 1px solid ${({ theme }) => theme.colors.ui32};
    border-radius: 6px;
    height: 36px;
    align-items: center;
    cursor: pointer;
    padding: 0 4px 0 8px;
`;

const DropdownLoader = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    height: 36px;
`;

const DropdownLabel = styled.div`
    font-size: 12px;
    font-weight: 600;
    flex: 1;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const DropdownIcon = styled.div`
    width: 24px;
    text-align: center;
`;

const ModalContent = styled(PopoverContent)`
    padding: 0;
    width: 359px;
    height: 522px;
    max-height: initial;
`;

const ScrollableContent = styled.div`
    overflow: auto;
    width: 100%;
    margin-top: 10px;
`;

type ClientSelectProps = {
    clients: SelectGroups;
    selectedClientId: SelectValue;
    clientsFetched: boolean;
};

const ClientSelect: FC<ClientSelectProps> = ({ clients, selectedClientId, clientsFetched }) => {
    const navigate = useNavigate();
    const { isModalOpen, openModal, closeModal } = useModalControl(false);
    const clientSelectContextValue = useClientSelectContextValue();
    const { query, setQuery } = clientSelectContextValue;
    const toggleClientSelect = () => {
        if (isModalOpen) {
            setQuery("");
            closeModal();
        }
        if (!isModalOpen) {
            openModal();
        }
    };
    const closeClientSelect = () => {
        setQuery("");
        closeModal();
    };
    const handleAllClientsSelect = () => {
        closeClientSelect();
        navigate(getAllClientsUrl());
    };
    const handleClientSelect = (clientId: ClientId) => {
        closeClientSelect();
        navigate(getClientUrl(clientId));
    };
    const selectedItem = getSelectedItem(clients, selectedClientId);
    const filteredClients = searchClients(clients, query);
    const isAllClients = selectedClientId === ALL_CLIENTS_ID;

    return (
        <ClientSelectContext.Provider value={clientSelectContextValue}>
            <Container>
                <Modal isOpen={isModalOpen} onClose={closeClientSelect}>
                    <ModalContent>
                        <Search value={query} onChange={(value) => setQuery(value)} />
                        <AllClients selected={isAllClients} onSelected={handleAllClientsSelect} />
                        <ScrollableContent>
                            {getContent(filteredClients, selectedClientId, handleClientSelect)}
                        </ScrollableContent>
                    </ModalContent>
                </Modal>
                <Dropdown onClick={toggleClientSelect}>
                    {!clientsFetched && (
                        <DropdownLoader>
                            <SmallLoader />
                        </DropdownLoader>
                    )}
                    {clientsFetched && isAllClients && (
                        <DropdownLabel>{ALL_CLIENTS_VALUE}</DropdownLabel>
                    )}
                    {clientsFetched && !isAllClients && (
                        <DropdownLabel>
                            {selectedItem === undefined
                                ? EmDash
                                : `${selectedItem.username} (${selectedItem.id})`}
                        </DropdownLabel>
                    )}
                    <DropdownIcon>
                        <Icons.Dropdown />
                    </DropdownIcon>
                </Dropdown>
            </Container>
        </ClientSelectContext.Provider>
    );
};

export default memo(ClientSelect);
