import { ModalButton } from "@fm-frontend/uikit";
import { FC } from "react";
import { PrimaryButton } from "~components/PrimaryButton";
import { useReferralsApi } from "~hooks/api/useReferralsApi";
import InviteForm from "~pages/Overview/InviteForm";

const InviteButton: FC = () => {
    const { mutate: mutateReferralsData } = useReferralsApi(undefined);

    return (
        <ModalButton
            beforeModalClose={mutateReferralsData}
            renderModalContent={(closePopover) => <InviteForm onSent={closePopover} />}
            renderButton={(handleClick) => (
                <PrimaryButton size="small" onClick={handleClick}>
                    Invite client
                </PrimaryButton>
            )}
        />
    );
};

export default InviteButton;
