import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Currency } from "~entities/currency";
import {
    AddCurrencyAction,
    FetchCurrenciesAction,
    ModifyCurrencyAction,
    RemoveCurrencyAction,
    SetCurrenciesAction,
    SetErrorAction,
    UnsetCurrenciesAction,
} from "~store/currencies/types";
import { EntityStatus } from "~store/types";

type CurrenciesState = {
    status: EntityStatus;
    items: Currency[];
    error: string;
};

const initialState: CurrenciesState = {
    status: EntityStatus.NotFetched,
    items: [],
    error: "",
};

export const currenciesSlice = createSlice({
    name: "currencies",
    initialState,
    reducers: {
        fetchCurrencies: (state, { payload }: PayloadAction<FetchCurrenciesAction>) => {
            state.status = EntityStatus.Fetching;
        },
        setCurrencies: (state, { payload }: PayloadAction<SetCurrenciesAction>) => {
            const { currencies } = payload;

            state.items = currencies;
            state.status = EntityStatus.Fetched;
        },
        addCurrency: (state, { payload }: PayloadAction<AddCurrencyAction>) => {
            const { currency } = payload;

            state.items.push(currency);
        },
        modifyCurrency: (state, { payload }: PayloadAction<ModifyCurrencyAction>) => {
            const { currency } = payload;
            const index = state.items.findIndex((item) => item.name === currency.name);

            state.items[index] = currency;
        },
        removeCurrency: (state, { payload }: PayloadAction<RemoveCurrencyAction>) => {
            const { currency } = payload;

            state.items = state.items.filter((item) => item.name !== currency.name);
        },
        unsetCurrencies: (state, { payload }: PayloadAction<UnsetCurrenciesAction>) => {
            state.items = [];
            state.status = EntityStatus.NotFetched;
        },
        setError: (state, { payload }: PayloadAction<SetErrorAction>) => {
            state.error = payload.error;
            state.status = EntityStatus.Error;
        },
    },
});

export default currenciesSlice.reducer;
