/* eslint-disable no-bitwise */
export enum AccessFlagBitmask {
    ReadOnly = 0,
    AllowTrading = 1 << 0,
    AllowApiKeyManagement = 1 << 1,
    AllowSettlements = 1 << 2,
    AllowUserManagement = 1 << 3,
    AllowLimitsManagement = 1 << 4,
    AllowSubaccountsManagement = 1 << 5,
}
/* eslint-enable no-bitwise */

export type User = {
    accessFlags: number;
    active: boolean;
    clientId: number;
    createdAt: number;
    email: string;
    fullname: string;
    name: string;
    owner: boolean;
};
