import { DropdownOption, InlineMultiDropdown } from "@fm-frontend/uikit";
import { ReactElement, useEffect } from "react";
import { useForm } from "react-hook-form";

type SelectProps<OptionValue extends string = string> = {
    options: DropdownOption<OptionValue>[] | Record<string, DropdownOption<OptionValue>[]>;
    onChange: (value: OptionValue[]) => void;
    label?: string;
    defaultValue?: OptionValue[];
};

export const MultiSelect = <OptionValue extends string = string>({
    options,
    onChange,
    label = "",
    defaultValue = [],
}: SelectProps<OptionValue>): ReactElement => {
    const { control, watch } = useForm<{ dropdown: string[] }>({
        defaultValues: { dropdown: defaultValue },
    });

    useEffect(() => {
        const subscription = watch((value, { name }) => {
            if (name) {
                onChange(value[name as "dropdown"] as OptionValue[]);
            }
        });
        return () => subscription.unsubscribe();
    }, [onChange, watch]);

    return (
        <InlineMultiDropdown
            size="small"
            options={options}
            control={control}
            name="dropdown"
            label={label}
        />
    );
};
