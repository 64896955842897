import { ClientId as ClientIdComponent, PBold } from "@fm-frontend/uikit";
import { FC, useContext } from "react";
import styled from "styled-components";
import MatchContains from "~components/Table/Cell/MatchContains";
import { TableContext } from "~components/Table/TableContext";
import { ClientId } from "~entities/client";

const Container = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;
`;

const Name = styled(PBold)`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

type ClientCellProps = {
    id: ClientId;
    name: string;
};

export const ClientCell: FC<ClientCellProps> = ({ id, name }) => {
    const { query } = useContext(TableContext);

    return (
        <Container>
            {name !== undefined && (
                <Name>
                    <MatchContains value={name} query={query} />
                </Name>
            )}
            {id !== 0 && (
                <ClientIdComponent id={<MatchContains value={String(id)} query={query} />} />
            )}
        </Container>
    );
};
