import useSWR from "swr";
import { AssetLimit } from "~entities/assetLimit";
import { ClientId } from "~entities/client";
import { requestAdminery } from "~utils/api";

type RawAssetLimitEntity = [
    counterpartyId: ClientId,
    currencyName: string,
    grossLimit: number | bigint,
    limitCurrency: string,
];

const prepareData = ([
    counterpartyId,
    currencyName,
    grossLimit,
    limitCurrency,
]: RawAssetLimitEntity): AssetLimit => ({
    counterpartyId,
    currencyName,
    grossLimit,
    limitCurrency,
});

type SWRKey = [clientId: ClientId, key: string];

const fetcher = ([clientId]: SWRKey) =>
    requestAdminery("cAssetLimits", {
        clientId,
    }).then((data) =>
        data.map(prepareData).filter(({ grossLimit }: AssetLimit) => grossLimit !== null),
    );

export const useAssetLimits = (clientId: ClientId) =>
    useSWR<AssetLimit[], unknown, SWRKey>([clientId, "useAssetLimits"], fetcher);
