import useSWR from "swr";
import { ClientId } from "~entities/client";
import { CounterpartyLimit } from "~entities/counterpartyLimit";
import { requestAdminery } from "~utils/api";

type RawCounterpartyLimitEntity = [
    counterpartyId: ClientId,
    currencyName: string,
    grossLimit: number | bigint,
    mutualLimitCurrencyName: string | null,
    mutualGrossLimit: number | bigint | null,
    grossLimitUtilisation: number | bigint,
    maintenanceMargin: number,
    restrictedTrading: number,
    initialMargin: number,
    equity: number | bigint,
    takerMarkup: number,
    tradingDisabledFlags: number,
];

const prepareData = ([
    counterpartyId,
    currencyName,
    grossLimit,
    mutualLimitCurrencyName,
    mutualGrossLimit,
    grossLimitUtilisation,
    maintenanceMargin,
    restrictedTrading,
    initialMargin,
    equity,
    takerMarkup,
    tradingDisabledFlags,
]: RawCounterpartyLimitEntity): CounterpartyLimit => ({
    counterpartyId,
    currencyName,
    grossLimit: BigInt(grossLimit),
    mutualLimitCurrencyName,
    mutualGrossLimit: mutualGrossLimit !== null ? BigInt(mutualGrossLimit) : null,
    grossLimitUtilisation: BigInt(grossLimitUtilisation),
    maintenanceMargin,
    restrictedTrading,
    initialMargin,
    equity: BigInt(equity),
    takerMarkup,
    tradingDisabledFlags,
});

export type CpLimitFilterType = "all" | "external" | "subaccounts";
type SWRKey = [clientId: ClientId, method: string, filter?: CpLimitFilterType];

const fetcher = ([clientId, _, filter]: SWRKey) =>
    requestAdminery("climits", { clientId, filter }).then((data) => data.map(prepareData));

export const useCounterpartyLimitsApi = (clientId: ClientId, filter?: CpLimitFilterType) =>
    useSWR<CounterpartyLimit[], unknown, SWRKey>(
        [clientId, "useCounterpartyLimitsApi", filter],
        fetcher,
    );
