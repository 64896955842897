import { getMatch, HStack, P as Currency, VStack } from "@fm-frontend/uikit";
import { DropdownOption } from "@fm-frontend/uikit/src/components/v2";
import { FC } from "react";
import styled from "styled-components";
import { InstrumentIcon } from "../InstrumentIcon";

const Container = styled(HStack)`
    align-items: center;
    gap: 8px;
    overflow: hidden;
`;

type OptionEssenceProps = {
    option: DropdownOption<string> | undefined;
    query?: string;
    groupingOption?: boolean;
};

export const OptionEssence: FC<OptionEssenceProps> = ({ option, query = "", groupingOption }) => {
    if (!option) {
        return null;
    }

    return (
        <Container>
            {!groupingOption && <InstrumentIcon instrument={option.text} size={20} />}
            <VStack overflow="hidden">
                <HStack spacing={8} alignItems="center">
                    <Currency title={option.text} ellipsis>
                        {getMatch(option.text, query) ?? option.text}
                    </Currency>
                </HStack>
            </VStack>
        </Container>
    );
};
