import { useState } from "react";
import { Feed, MessageType } from "~constants/feeds";
import { InstrumentId, InstrumentName } from "~entities/instrument";
import useFeed from "~hooks/useFeed";
import { formatMessageError } from "~utils/formatMessageError";

type BookItem = [price: number | bigint, size: number | bigint];

type BookItemChange = [type: MessageType, price: number, size: number];

const changeList = (items: BookItem[], changes: BookItemChange[], desc: boolean) => {
    if (changes.length === 0) {
        return items;
    }

    const changedItems = [...items];

    changes.forEach(([type, price, size]) => {
        switch (type) {
            case MessageType.Add: {
                const insertIndex = desc
                    ? changedItems.findIndex((item) => item[0] < price)
                    : changedItems.findIndex((item) => item[0] > price);
                if (insertIndex !== -1) {
                    changedItems.splice(insertIndex, 0, [price, size]);
                }
                if (insertIndex === -1) {
                    changedItems.push([price, size]);
                }
                break;
            }
            case MessageType.Modify: {
                const modifyIndex = changedItems.findIndex((item) => item[0] === price);
                if (modifyIndex !== -1) {
                    changedItems[modifyIndex] = [changedItems[modifyIndex][0], size];
                }
                break;
            }
            case MessageType.Remove: {
                const removeIndex = changedItems.findIndex((item) => item[0] === price);
                if (removeIndex !== -1) {
                    changedItems.splice(removeIndex, 1);
                }
                break;
            }
            case MessageType.NewTopLevel: {
                if (price) {
                    const index = changedItems.findIndex((item) => item[0] === price);
                    const [oldPrice] = changedItems[index];

                    changedItems[index] = [oldPrice, size];

                    if (index > 0) {
                        changedItems.splice(0, index);
                    }
                } else {
                    changedItems.length = 0;
                }
                break;
            }
            default:
                throw new Error("Unhandled `Order book` message");
        }
    });

    return changedItems;
};

export const useOrderBooksFeed = (feedId: InstrumentId | InstrumentName) => {
    const feed = useFeed(Feed.OrderBooks, [Feed.OrderBooks], feedId);
    const [bidList, setBidList] = useState<BookItem[]>([]);
    const [askList, setAskList] = useState<BookItem[]>([]);
    const [error, setError] = useState<string>();

    feed.onReceive((messageType, messageContent) => {
        switch (messageType) {
            case MessageType.Snapshot: {
                const [newBidList, newAskList] = messageContent;

                setBidList(newBidList);
                setAskList(newAskList);
                break;
            }
            case MessageType.Unsubscribed:
                setBidList([]);
                setAskList([]);
                break;
            case MessageType.Modify: {
                const [bidChanges, askChanges] = messageContent;
                setBidList((prevBidList) => changeList(prevBidList, bidChanges, true));
                setAskList((prevAskList) => changeList(prevAskList, askChanges, false));
                break;
            }
            case MessageType.Error: {
                const newError =
                    typeof messageContent === "string"
                        ? messageContent
                        : formatMessageError(messageContent);

                setError(newError);
                break;
            }
            default:
                throw new Error(`Unhandled message type: ${messageType}`);
        }
    });

    return {
        bidList,
        askList,
        error,
    };
};
