import { MouseEventHandler, useState } from "react";
import styled, { css, useTheme } from "styled-components";
import { BasicButton, IconButton } from "../buttons";
import { Icons } from "../icons";
import { Flex, HStack } from "../layout";

const expandButtonHoverCss = css`
    background-color: ${(p) => p.theme.colors.ui12};
`;

const ExpandIconContainer = styled(Flex)`
    width: 24px;
    height: 24px;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    background-color: ${(p) => p.theme.colors.ui8};

    &:hover {
        ${expandButtonHoverCss}
    }
`;
const ExpandIcon = styled(Icons.Expand)<{ $isExpanded: boolean }>`
    transform: rotate(${(p) => (p.$isExpanded ? 0 : -90)}deg);
    transition: transform linear 100ms;
`;
const ExpandedIndicator = ({ isExpanded }: { isExpanded: boolean }) => (
    <ExpandIconContainer>
        <ExpandIcon $isExpanded={isExpanded} />
    </ExpandIconContainer>
);

const Layout = styled.div`
    overflow: hidden;
    border-radius: 12px;
    background-color: ${(p) => p.theme.colors.uiWhite100};
    box-shadow: 0px -1px 0px 0px ${(p) => p.theme.colors.ui12} inset,
        0px 0px 0px 1px ${(p) => p.theme.colors.ui12} inset,
        0px 1px 2px 0px ${(p) => p.theme.colors.ui4};
`;

const Header = styled(HStack)`
    padding: 8px;
    padding-right: 12px;
    gap: 8px;
    align-items: center;
    cursor: pointer;

    &:hover ${ExpandIconContainer} {
        ${expandButtonHoverCss}
    }
`;

const HeaderContent = styled.div`
    flex: 1;
`;

const Title = styled.span<{ upperCase?: boolean }>`
    color: ${(p) => p.theme.colors.ui100};
    font-size: 11px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.44px;

    ${(p) =>
        p.upperCase &&
        css`
            text-transform: uppercase;
        `}
`;

const EmptyLabel = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 24px;
    padding: 0px 8px;
    border-radius: 8px;
    border: 1px solid ${(p) => p.theme.colors.ui12};

    color: ${(p) => p.theme.colors.ui52};
    font-size: 10px;
    font-weight: 600;
    line-height: 12px;
`;

const MoveToDetailsButton = ({
    onClick,
    title,
}: {
    onClick: MouseEventHandler<HTMLButtonElement>;
    title?: string;
}) => {
    const theme = useTheme();

    if (title === undefined) {
        return <IconButton variant="plain" Icon={Icons.Greater} onClick={onClick} />;
    }

    return (
        <BasicButton size="small" onClick={onClick}>
            {title} <Icons.Greater color={theme.colors.ui32} />
        </BasicButton>
    );
};

export const Accordion = ({
    header,
    content,
    defaultExpanded,
    onExpandedChange,
    moveToDetailsButtonTitle,
    onMoveToDetailsButtonClick,
}: {
    header: JSX.Element | string;
    content: JSX.Element | string;
    defaultExpanded?: boolean;
    onExpandedChange?: (expanded: boolean) => void;
    moveToDetailsButtonTitle?: string;
    onMoveToDetailsButtonClick?: MouseEventHandler<HTMLButtonElement>;
}) => {
    const [isExpanded, setIsExpanded] = useState(defaultExpanded);
    const handleExpandedChange = () => {
        const expanded = !isExpanded;
        onExpandedChange?.(expanded);
        setIsExpanded(expanded);
    };

    return (
        <Layout>
            <Header onClick={handleExpandedChange}>
                <ExpandedIndicator isExpanded={Boolean(isExpanded)} />
                <HeaderContent>{header}</HeaderContent>
                {onMoveToDetailsButtonClick && (
                    <MoveToDetailsButton
                        onClick={onMoveToDetailsButtonClick}
                        title={moveToDetailsButtonTitle}
                    />
                )}
            </Header>
            {isExpanded && content}
        </Layout>
    );
};

Accordion.Title = Title;
Accordion.EmptyLabel = EmptyLabel;
