import { useCallback, useMemo } from "react";
import useSWR, { mutate } from "swr";
import { ClientId } from "~entities/client";
import { requestAuthorized } from "~utils/api";

const getAuthorizedTradingKey = (clientId: ClientId) => ["isAuthorizedTradingEnabled", clientId];

export const useAuthorizedTradingApi = (clientId: ClientId) => {
    const key = useMemo(() => getAuthorizedTradingKey(clientId), [clientId]);

    return {
        ...useSWR(key, () => requestAuthorized("isEnabled", { clientId }), {
            revalidateOnFocus: false,
            fallback: {},
        }),
        enableAuthorizedTrading: useCallback(async () => {
            await requestAuthorized("enableTrading", {
                clientId,
            });
            await mutate(key);
        }, [clientId, key]),
        disableAuthorizedTrading: useCallback(async () => {
            await requestAuthorized("disableTrading", {
                clientId,
            });
            await mutate(key);
        }, [clientId, key]),
    };
};
