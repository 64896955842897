import useSWR from "swr";
import { ClientId } from "~entities/client";
import { requestAdminery } from "~utils/api";

type RequestParams = {
    clientId: ClientId;
};

type Response = {
    clientId: number;
    tradingEnabled: boolean;
    viewingEnabled: boolean;
};

type SWRKey = { useSWRName?: string } & RequestParams;

export const rfqSettingsFetcher = ({ useSWRName, ...params }: SWRKey): Promise<Response> =>
    requestAdminery("rfq/admin/getSettings", params);

export const useRFQSettingsApi = (params: RequestParams) => {
    return useSWR<Response, unknown, SWRKey>(
        { useSWRName: "rfq/admin/getSettings", ...params },
        rfqSettingsFetcher,
    );
};
