import React, { useState } from "react";
import {
    PartnerSelectType,
    PartnerValueType,
    PARTNER_SELECT_ALL_VALUE,
} from "~components/PartnerSelect";
import { RisksManagementExternalListPage } from "~pages/RisksManagement/List/PrimeBrokerRisksManagement/External";
import { RisksManagementExternalAndInternalListPage } from "~pages/RisksManagement/List/PrimeBrokerRisksManagement/ExternalAndInternal";
import { RisksManagementInternalListPage } from "~pages/RisksManagement/List/PrimeBrokerRisksManagement/Internal";

export const PrimeBrokerRisksManagement: React.FC = () => {
    const [partnerType, setPartnerType] = useState<PartnerValueType>(PARTNER_SELECT_ALL_VALUE);

    switch (partnerType) {
        case PartnerSelectType.Counterparties:
            return <RisksManagementExternalListPage onChangePartnerType={setPartnerType} />;
        case PartnerSelectType.SubAccounts:
            return <RisksManagementInternalListPage onChangePartnerType={setPartnerType} />;
        case PARTNER_SELECT_ALL_VALUE:
            return (
                <RisksManagementExternalAndInternalListPage onChangePartnerType={setPartnerType} />
            );
        default:
            return null;
    }
};
