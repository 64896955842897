import { IconButton, Icons, ModalButton } from "@fm-frontend/uikit";
import { CancelInvitationForm } from "pages/Overview/CancelInvitationForm";
import React from "react";
import { useReferralsApi } from "~hooks/api/useReferralsApi";

type CancelInvitationActionProps = {
    referralEmail: string;
};

export const CancelInvitationAction: React.FC<CancelInvitationActionProps> = ({
    referralEmail,
}) => {
    const { mutate: mutateReferralsData } = useReferralsApi(undefined);

    return (
        <ModalButton
            beforeModalClose={mutateReferralsData}
            renderModalContent={(closePopover) => (
                <CancelInvitationForm referralEmail={referralEmail} onDone={closePopover} />
            )}
            renderButton={(handleClick) => (
                <IconButton title="Cancel" variant="plain" Icon={Icons.X} onClick={handleClick} />
            )}
        />
    );
};
