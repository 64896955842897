import { noop } from "@fm-frontend/uikit/src/const";
import { uniqueId } from "lodash";
import { createContext, FC, ReactNode, useCallback, useMemo, useState } from "react";
import Notifications from "~components/Notifications";
import { Notification, NotificationType } from "~components/Notifications/types";
import { getNotificationIcon } from "~components/Notifications/utils";

type NotificationContextValue = {
    notifications: Notification[];
    showNotification: (content: ReactNode, type: NotificationType) => void;
};

export const NotificationContext = createContext<NotificationContextValue>({
    notifications: [],
    showNotification: noop,
});

const DISPLAY_TIME = 1000 * 5;

export const useNotificationContextValue = () => {
    const [notifications, setNotifications] = useState<Notification[]>([]);
    const showNotification = useCallback((content: ReactNode, type: NotificationType) => {
        const notification: Notification = {
            id: uniqueId("notification_"),
            type,
            icon: getNotificationIcon(type),
            content,
            timestamp: Date.now(),
            isRead: false,
        };
        setNotifications((prevNotifications) => [...prevNotifications, notification]);
        setTimeout(() => {
            setNotifications((prevNotifications) =>
                prevNotifications.filter((item) => item.id !== notification.id),
            );
        }, DISPLAY_TIME);
    }, []);

    return useMemo(
        () => ({
            notifications,
            showNotification,
        }),
        [notifications, showNotification],
    );
};

export const NotificationsProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const value = useNotificationContextValue();

    return (
        <NotificationContext.Provider value={value}>
            {children}
            <Notifications />
        </NotificationContext.Provider>
    );
};
