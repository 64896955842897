import { DropdownOption, InlineDropdown } from "@fm-frontend/uikit";
import { ReactElement, useEffect } from "react";
import { useForm } from "react-hook-form";

type SelectProps<OptionValue extends string = string> = {
    options: DropdownOption<OptionValue>[];
    onChange: (value: OptionValue) => void;
    label?: string;
    defaultValue?: OptionValue;
};

const Select = <OptionValue extends string = string>({
    options,
    onChange,
    label = "",
    defaultValue,
}: SelectProps<OptionValue>): ReactElement => {
    const { control, watch } = useForm<{ dropdown: string }>({
        defaultValues: { dropdown: defaultValue },
    });

    useEffect(() => {
        const subscription = watch((value, { name }) => {
            if (name) {
                onChange(value[name] as OptionValue);
            }
        });
        return () => subscription.unsubscribe();
    }, [onChange, watch]);

    return (
        <InlineDropdown
            size="small"
            options={options}
            control={control}
            name="dropdown"
            label={label}
        />
    );
};

export default Select;
