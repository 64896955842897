import {
    FieldGroup,
    Form,
    FormActions,
    FormBody,
    Header as FormHeader,
    HeaderTitle,
    SimpleDropdown,
    SimpleInput,
    SimpleMultiDropdown,
} from "@fm-frontend/uikit";
import { ValueFormat, ValueParse } from "@fm-frontend/utils";
import { FC, useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import FormError from "~components/FormError";
import { PrimaryButton } from "~components/PrimaryButton";
import { ERROR_MESSAGES } from "~constants/errors";
import { ValidateErrors, ValidateRules } from "~constants/form";
import { Currency, CurrencyType } from "~entities/currency";
import { useInstrumentsApi } from "~hooks/api/useInstrumentsApi";
import useNotifications from "~hooks/useNotifications";
import {
    getCurrencyTypeTitle,
    mutateCurrencyNetwork,
} from "~pages/AssetsAndInstruments/Assets/utils";
import { requestAdminery } from "~utils/api";
import { prepareRequestError } from "~utils/prepareRequestError";

const TYPE_OPTIONS = [
    { text: getCurrencyTypeTitle(CurrencyType.Crypto), value: CurrencyType.Crypto },
    { text: getCurrencyTypeTitle(CurrencyType.Stablecoin), value: CurrencyType.Stablecoin },
    { text: getCurrencyTypeTitle(CurrencyType.Fiat), value: CurrencyType.Fiat },
];

const BALANCE_STEP_OPTIONS = [
    { text: "0.00000001", value: "0.00000001" },
    { text: "0.0000001", value: "0.00000010" },
    { text: "0.000001", value: "0.00000100" },
    { text: "0.00001", value: "0.00001000" },
    { text: "0.0001", value: "0.00010000" },
    { text: "0.001", value: "0.00100000" },
    { text: "0.01", value: "0.01000000" },
    { text: "0.1", value: "0.10000000" },
    { text: "1", value: "100000000" },
];

const FormContainer = styled(Form)`
    width: 100%;
    min-width: initial;
    padding: 8px;
`;

type EditCurrencyFormType = {
    name: string;
    price: string;
    networks: string[];
    balanceStep: string;
    type: CurrencyType;
};

type EditFormProps = {
    currency: Currency;
    onDone: () => void;
};

export const EditForm: FC<EditFormProps> = ({ currency, onDone }) => {
    const { data: apiData, isLoading, mutate } = useInstrumentsApi();
    const { networks = [] } = apiData ?? {};
    const { showSuccessNotification } = useNotifications();
    const {
        control,
        register,
        setValue,
        formState: { errors },
        handleSubmit,
    } = useForm<EditCurrencyFormType>({
        defaultValues: {
            name: currency.name,
            price: ValueFormat.formPrice(currency.price),
            networks: currency.networks ?? [],
            balanceStep: ValueFormat.formSize(currency.balanceStep),
            type: currency.currencyType,
        },
    });
    const [loading, setLoading] = useState(false);
    const [submitError, setSubmitError] = useState<string>();

    const networkOptions = networks.map((network) => ({ text: network.name, value: network.name }));

    const handleEditAssetSubmit = handleSubmit(async (data) => {
        try {
            setSubmitError(undefined);
            setLoading(true);
            const [setPriceResponse, setTypeResponse, assignNetworksResponse] = await Promise.all([
                requestAdminery("setCurrencyPrice", {
                    name: currency.name,
                    price: ValueParse.price(data.price),
                }),
                requestAdminery("setCurrencyType", {
                    name: currency.name,
                    type: data.type,
                }),
                requestAdminery("assignNetworks", {
                    currency: currency.name,
                    networks: data.networks,
                }),
            ]);

            const requestSuccessful =
                !setPriceResponse?.error &&
                !setTypeResponse?.error &&
                !assignNetworksResponse?.error;

            if (apiData !== undefined && requestSuccessful) {
                await mutate({
                    ...apiData,
                    currencies: mutateCurrencyNetwork(
                        apiData.currencies,
                        currency.name,
                        data.networks,
                    ),
                });
            }

            if (requestSuccessful) {
                onDone();
                showSuccessNotification("Asset was successfully edited");
            }
            if (setPriceResponse?.error) {
                setSubmitError(ERROR_MESSAGES[setPriceResponse.error] ?? setPriceResponse.error);
            }
            if (setTypeResponse?.error) {
                setSubmitError(ERROR_MESSAGES[setTypeResponse.error] ?? setTypeResponse.error);
            }
            if (assignNetworksResponse?.error) {
                setSubmitError(
                    ERROR_MESSAGES[assignNetworksResponse.error] ?? assignNetworksResponse.error,
                );
            }
        } catch (e) {
            setSubmitError(prepareRequestError(e));
        } finally {
            setLoading(false);
        }
    });

    return (
        <FormContainer onSubmit={handleEditAssetSubmit}>
            <FormHeader>
                <HeaderTitle title="Edit asset" />
            </FormHeader>
            <FormBody alignItems="stretch">
                <FieldGroup>
                    <SimpleDropdown
                        options={TYPE_OPTIONS}
                        control={control}
                        name="type"
                        label="Type"
                    />
                    <SimpleInput
                        disabled
                        label="Code"
                        placeholder="BTC"
                        error={errors.name?.message}
                        {...register("name", {
                            required: ValidateErrors.required,
                            onChange: (e) => setValue("name", e.target.value.toUpperCase()),
                        })}
                    />
                </FieldGroup>
                <FieldGroup>
                    <SimpleDropdown
                        disabled
                        options={BALANCE_STEP_OPTIONS}
                        control={control}
                        name="balanceStep"
                        label="Balance step"
                    />
                    <SimpleMultiDropdown
                        options={networkOptions}
                        control={control}
                        name="networks"
                        label="Networks"
                        fullWidth
                        disabled={isLoading}
                    />
                    <SimpleInput
                        label="Price, $"
                        placeholder="40000"
                        error={errors.price?.message}
                        {...register("price", {
                            required: ValidateErrors.required,
                            validate: { price: ValidateRules.price },
                        })}
                    />
                </FieldGroup>
                {submitError && <FormError message={submitError} />}
            </FormBody>
            <FormActions variant="plain">
                <PrimaryButton size="large" fullWidth loading={loading}>
                    Edit asset
                </PrimaryButton>
            </FormActions>
        </FormContainer>
    );
};
