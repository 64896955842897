import { ClientId } from "~entities/client";
import { usePositionsApi } from "~hooks/api/usePositionsApi";

type Exposures = Record<string, number | bigint>;

export const useExposures = (clientId: ClientId, counterpartyId: ClientId) => {
    const { data: positionsData, isLoading } = usePositionsApi(clientId);
    const { positions = [] } = positionsData ?? {};

    const data = positions.reduce<Exposures>((acc, position) => {
        if (position.counterpartyId === counterpartyId) {
            acc[position.currency] = position.value;
        }

        return acc;
    }, {});

    return { data, isLoading };
};
