import { BasicButton } from "@fm-frontend/uikit";
import { FC } from "react";
import ModalButton from "~components/ModalButton";
import { useIsSubaccount } from "~hooks/useIsSubaccount";
import { EditCounterpartyLimitForm } from "~pages/RisksManagement/components/EditCounterpartyLimitForm";

export const EditCounterpartyLimitAction: FC = () => {
    const isSubaccount = useIsSubaccount();

    return (
        <ModalButton
            renderModalContent={(closePopover) => (
                <EditCounterpartyLimitForm onDone={closePopover} />
            )}
            renderButton={(handleClick) => (
                <BasicButton disabled={isSubaccount} size="small" onClick={handleClick}>
                    Edit
                </BasicButton>
            )}
        />
    );
};
