import { FC } from "react";
import getMatchStartsWith from "~utils/getMatchStartsWith";

type MatchStartWithProps = {
    value: string;
    query: string;
    className?: string;
};

const MatchStartsWith: FC<MatchStartWithProps> = ({ value, query, className }) => {
    return <span className={className}>{getMatchStartsWith(value, query) ?? value}</span>;
};

export default MatchStartsWith;
