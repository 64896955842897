import { IconButton, Icons } from "@fm-frontend/uikit";
import { FC, useState } from "react";
import styled from "styled-components";
import RemoveAction from "~components/Table/Action/RemoveAction";
import { OrderId } from "~entities/order";
import useNotifications from "~hooks/useNotifications";
import { requestAdminery } from "~utils/api";

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 8px;
`;

type ManualOrderActionsProps = {
    settlementOrderId: OrderId;
    onUpdate: () => void;
};

export const ManualOrderActions: FC<ManualOrderActionsProps> = ({
    settlementOrderId,
    onUpdate,
}) => {
    const { showSuccessNotification, showErrorNotification } = useNotifications();
    const [executeButtonLoading, setExecuteButtonLoading] = useState(false);

    const handleRemove = async () => {
        const response = await requestAdminery("delSettlementOrder", {
            settlementOrderId,
        });

        if (!response?.error) {
            showSuccessNotification(`The order has been successfully deleted.`);
            onUpdate();
        }
        if (response?.error) {
            showErrorNotification(`An error occurred when deleting order.`);
        }
    };

    const handleExecute = async () => {
        try {
            setExecuteButtonLoading(true);

            const response = await requestAdminery("expSettlementOrder", {
                settlementOrderId,
            });

            if (!response?.error) {
                showSuccessNotification(`The order has been successfully executed.`);
                onUpdate();
            }
            if (response?.error) {
                showErrorNotification(`An error occurred when executing order.`);
            }
        } finally {
            setExecuteButtonLoading(false);
        }
    };

    return (
        <Container>
            <RemoveAction title="Remove order" onDone={handleRemove} />
            <IconButton
                loading={executeButtonLoading}
                title="Execute"
                variant="basic"
                Icon={Icons.Check}
                onClick={handleExecute}
            />
        </Container>
    );
};
