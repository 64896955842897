import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ClientInfo } from "~entities/clientInfo";
import {
    FetchClientInfoAction,
    ModifyClientInfoAction,
    SetClientInfoAction,
    SetErrorAction,
    UnsetClientInfoAction,
} from "~store/clientsInfo/types";
import { EntityStatus } from "~store/types";

type ClientsInfoState = {
    status: EntityStatus;
    items: ClientInfo[];
    error: string;
};

const initialState: ClientsInfoState = {
    status: EntityStatus.NotFetched,
    items: [],
    error: "",
};

export const clientsInfoSlice = createSlice({
    name: "clientsInfo",
    initialState,
    reducers: {
        fetchClientInfo: (state, { payload }: PayloadAction<FetchClientInfoAction>) => {
            state.status = EntityStatus.Fetching;
        },
        setClientInfo: (state, { payload }: PayloadAction<SetClientInfoAction>) => {
            const { clientInfo } = payload;
            const index = state.items.findIndex((item) => item.id === clientInfo.id);

            if (index === -1) {
                state.items.push(clientInfo);
            }
            if (index !== -1) {
                state.items[index] = clientInfo;
            }

            state.status = EntityStatus.Fetched;
        },
        unsetClientInfo: (state, { payload }: PayloadAction<UnsetClientInfoAction>) => {
            const { id } = payload;

            state.items = state.items.filter((item) => item.id === id);
        },
        modifyClientInfo: (state, { payload }: PayloadAction<ModifyClientInfoAction>) => {
            const { id, data } = payload;
            const index = state.items.findIndex((item) => item.id === id);

            state.items[index] = {
                ...state.items[index],
                ...data,
            };
        },
        setError: (state, { payload }: PayloadAction<SetErrorAction>) => {
            state.error = payload.error;
            state.status = EntityStatus.Error;
        },
    },
});

export default clientsInfoSlice.reducer;
