import {
    DECIMAL_PERCENT_PATTERN,
    DELTA_RATE_PATTERN,
    EMAIL_PATTERN,
    PRICE_PATTERN,
    SIZE_PATTERN,
    U32_PATTERN,
    USERNAME_REGEX,
} from "~constants/patterns";

export const ValidateErrors = {
    required: "Field Required",
    max: (value: number) => `Max valid value: ${value}`,
    min: (value: number) => `Min valid value: ${value}`,
    email: "Invalid email",
    price: "Invalid price",
    decimalPercent: "Invalid percent",
    size: "Invalid size",
    u32: "Invalid u32 value",
    deltaRate: (value: string) => `Invalid delta ratio (${value})`,
    greaterThan0: "Must be greater than 0",
    invalidClientNameLength: "Name must be from 3 to 32 characters",
    invalidClientNameSymbols: "Only digits, letters and . - & _ are allowed",
};

export const ValidateRules = {
    price: (value: string) => value === "" || !!value.match(PRICE_PATTERN) || ValidateErrors.price,
    size: (value: string) => value === "" || !!value.match(SIZE_PATTERN) || ValidateErrors.size,
    u32: (value: string) => value === "" || !!value.match(U32_PATTERN) || ValidateErrors.u32,
    deltaRate: (value: string) =>
        value === "" || !!value.match(DELTA_RATE_PATTERN) || ValidateErrors.deltaRate(value),
    decimalPercent: (value: string) =>
        value === "" || !!value.match(DECIMAL_PERCENT_PATTERN) || ValidateErrors.decimalPercent,
    email: (value: string) => value === "" || !!value.match(EMAIL_PATTERN) || ValidateErrors.email,
    greaterThan0: (value: string) => Number(value) > 0 || ValidateErrors.greaterThan0,
    clientName: (value: string) =>
        value.length < 3 || value.length > 32
            ? ValidateErrors.invalidClientNameLength
            : !!value.match(USERNAME_REGEX) || ValidateErrors.invalidClientNameSymbols,
};
