import { InfiniteTable as UIKitTable } from "@fm-frontend/uikit";
import { getCoreRowModel, getSortedRowModel, SortingState } from "@tanstack/react-table";
import { format } from "date-fns";
import { FC, useMemo, useState } from "react";
import styled from "styled-components";
import Gap from "~components/Gap";
import {
    PartnerSelect,
    PartnerSelectType,
    PartnerValueType,
    PARTNER_SELECT_ALL_VALUE,
} from "~components/PartnerSelect";
import OptionsContainer from "~components/Table/Options/Container";
import ExportButton from "~components/Table/Options/ExportButton";
import Search from "~components/Table/Options/Search";
import { TableContext, useTableContextValue } from "~components/Table/TableContext";
import { DATE_TIME_FORMAT } from "~constants/date";
import { ClientId } from "~entities/client";
import { SettlementRequestFlags } from "~entities/settlementRequest";
import { useClientId } from "~hooks/useClientId";
import { useIsMaster } from "~hooks/useIsMaster";
import { useSubaccountsIds } from "~hooks/useSubaccountsIds";
import { settlementTransactionColumns } from "~pages/Settlements/columns";
import { useTableData } from "~pages/Settlements/hooks/useTableData";
import { getStatusTitle } from "~pages/Settlements/StatusCell";
import {
    StatusSelect,
    StatusSelectValue,
    STATUS_SELECT_ALL_VALUE,
} from "~pages/Settlements/StatusSelect";
import { SettlementStatus, SettlementType } from "~pages/Settlements/types";
import { TypeSelect, TypeSelectValue, TYPE_SELECT_ALL_VALUE } from "~pages/Settlements/TypeSelect";
import { getExportAmount, getExportFeePayed } from "~pages/Settlements/utils";

export type TableData = {
    status: SettlementStatus;
    type: SettlementType;
    name: string;
    counterpartyId: ClientId;
    currency: string;
    networkName: string;
    amount: number | bigint;
    networkFee?: number | bigint;
    txId: string;
    comment: string;
    createdAt: number;
    flags?: SettlementRequestFlags;
};

const Table = styled(UIKitTable<TableData>)`
    min-width: 1070px;

    th:first-of-type,
    td:first-of-type {
        padding-left: 12px !important;
    }
`;

const isMatch = (data: TableData, query: string) => {
    const { counterpartyId, name, currency, txId } = data;
    const normalizedQuery = query.toUpperCase();

    return (
        name.toUpperCase().includes(normalizedQuery) ||
        currency.toUpperCase().includes(normalizedQuery) ||
        txId.toUpperCase().includes(normalizedQuery) ||
        String(counterpartyId).startsWith(normalizedQuery)
    );
};

export const SettlementsPage: FC = () => {
    const clientId = useClientId();
    const isMaster = useIsMaster();
    const subaccountsIds = useSubaccountsIds(clientId);
    const { data, isLoading } = useTableData(clientId);
    const [sorting, setSorting] = useState<SortingState>([]);
    const [type, setType] = useState<TypeSelectValue>(TYPE_SELECT_ALL_VALUE);
    const [statuses, setStatuses] = useState<StatusSelectValue>(STATUS_SELECT_ALL_VALUE);
    const [partnerType, setPartnerType] = useState<PartnerValueType>(PARTNER_SELECT_ALL_VALUE);
    const tableContextValue = useTableContextValue();
    const { query, setQuery } = tableContextValue;

    const filteredData = useMemo(() => {
        let items = data;

        if (type !== TYPE_SELECT_ALL_VALUE) {
            items = items.filter((item) => item.type === type);
        }

        if (statuses !== STATUS_SELECT_ALL_VALUE) {
            items = items.filter((item) => statuses.includes(item.status));
        }

        if (partnerType !== PARTNER_SELECT_ALL_VALUE) {
            items = items.filter((item) => {
                const isSubaccount = subaccountsIds.includes(item.counterpartyId);

                if (partnerType === PartnerSelectType.Counterparties && isSubaccount) {
                    return false;
                }
                if (partnerType === PartnerSelectType.SubAccounts && !isSubaccount) {
                    return false;
                }

                return true;
            });
        }

        if (query !== "") {
            items = items.filter((item) => isMatch(item, query));
        }

        return items;
    }, [data, partnerType, statuses, subaccountsIds, type, query]);

    const getExportData = () =>
        filteredData.map((item) => {
            return {
                Status: getStatusTitle(item.status),
                Type: item.type,
                ID: item.counterpartyId,
                Name: item.name,
                Asset: `${item.currency} ${item.networkName}`,
                Amount: getExportAmount(item),
                "Fee payed": getExportFeePayed(item),
                "TX ID": item.txId,
                Comment: item.comment,
                Created: item.createdAt ? format(item.createdAt, DATE_TIME_FORMAT) : "",
            };
        });

    return (
        <TableContext.Provider value={tableContextValue}>
            <OptionsContainer>
                <Search query={query} onChange={setQuery} />
                <TypeSelect value={type} onChange={setType} />
                <StatusSelect value={statuses} onChange={setStatuses} />
                {isMaster && (
                    <PartnerSelect allTypes value={partnerType} onChange={setPartnerType} />
                )}
                <Gap />
                <ExportButton
                    data={getExportData}
                    filename={`settlements_${clientId}`}
                    loading={isLoading}
                    disabled={filteredData.length === 0}
                />
            </OptionsContainer>
            <Table
                tableOptions={{
                    data: filteredData,
                    columns: settlementTransactionColumns,
                    state: {
                        sorting,
                    },
                    onSortingChange: setSorting,
                    getCoreRowModel: getCoreRowModel(),
                    getSortedRowModel: getSortedRowModel(),
                }}
                isLoading={isLoading}
            />
        </TableContext.Provider>
    );
};
