import { useEffect } from "react";
import useAppDispatch from "~hooks/useAppDispatch";
import useAppSelector from "~hooks/useAppSelector";
import { fetchClients, setClients, setError } from "~store/clients/actions";
import { isClientsFetched, isClientsFetchError, isClientsFetching } from "~store/clients/selectors";
import { isAuthorized } from "~store/user/selectors";
import { requestAdminery } from "~utils/api";

let processing = false;

export const useClientsApi = () => {
    const dispatch = useAppDispatch();
    const authorized = useAppSelector(isAuthorized);
    const fetching = useAppSelector(isClientsFetching);
    const fetched = useAppSelector(isClientsFetched);
    const fetchError = useAppSelector(isClientsFetchError);

    useEffect(() => {
        if (processing || fetching || fetched || fetchError || !authorized) {
            return;
        }

        (async () => {
            // Prevent parallel fetching
            processing = true;

            try {
                dispatch(fetchClients());
                const clients = await requestAdminery("getClients", { activityDays: 21 });

                if (!clients.error) {
                    dispatch(setClients({ clients }));
                }

                if (clients.error) {
                    dispatch(setError({ error: clients.error }));
                }
            } catch (e) {
                dispatch(setError({ error: `${e}` }));
            } finally {
                processing = false;
            }
        })();

        return () => {
            processing = false;
        };
    }, [dispatch, fetchError, fetched, fetching, authorized]);
};
