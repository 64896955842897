import { P, PBold } from "@fm-frontend/uikit";
import { FC, useContext } from "react";
import styled from "styled-components";
import MatchContains from "~components/Table/Cell/MatchContains";
import { TableContext } from "~components/Table/TableContext";

const NetworkName = styled(P)`
    color: ${(p) => p.theme.colors.ui52};
`;

type AssetCellProps = {
    currency: string;
    networkName: string;
};

export const AssetCell: FC<AssetCellProps> = ({ currency, networkName }) => {
    const { query } = useContext(TableContext);

    return (
        <>
            <PBold>
                <MatchContains value={currency} query={query} />
            </PBold>{" "}
            <NetworkName>{networkName}</NetworkName>
        </>
    );
};
