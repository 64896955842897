import { ClientId } from "~entities/client";
import { RootState } from "~store";
import { EntityStatus } from "~store/types";

export const getRootState = (state: RootState) => state.clientsInfo;
export const getStatus = (state: RootState) => getRootState(state).status;
export const isClientsInfoNotFetched = (state: RootState) =>
    getStatus(state) === EntityStatus.NotFetched;
export const isClientsInfoFetching = (state: RootState) =>
    getStatus(state) === EntityStatus.Fetching;
export const isClientsInfoFetched = (state: RootState) => getStatus(state) === EntityStatus.Fetched;
export const isClientsInfoFetchError = (state: RootState) =>
    getStatus(state) === EntityStatus.Error;
export const getClientsInfo = (state: RootState) => getRootState(state).items;
export const getClientInfo = (state: RootState) => (clientId: ClientId) =>
    getClientsInfo(state).find((item) => item.id === clientId);
