import { formatPercent, ValueFormat } from "@fm-frontend/utils";
import { ClientType } from "~entities/client";
import { CounterpartyLimit } from "~entities/counterpartyLimit";

export const getEquityPercent = (
    limit: CounterpartyLimit | undefined,
    shouldTakeUserGrossLimit: boolean,
) => {
    if (limit === undefined) {
        return "";
    }

    const { grossLimit, equity, mutualGrossLimit } = limit;
    const makerLimit = shouldTakeUserGrossLimit ? grossLimit : mutualGrossLimit ?? 0n;
    const takerEquity = shouldTakeUserGrossLimit ? equity * -1n : equity;
    const freeLimitPercent =
        makerLimit && takerEquity >= 0n ? (BigInt(100 * 1e4) * takerEquity) / makerLimit : 0n;

    return formatPercent(freeLimitPercent);
};

export const getEquityValue = (
    limit: CounterpartyLimit | undefined,
    shouldTakeUserGrossLimit: boolean,
) => {
    if (limit === undefined) {
        return "";
    }

    const { currencyName, equity, mutualLimitCurrencyName } = limit;
    const makerLimitCurrency = shouldTakeUserGrossLimit
        ? currencyName
        : mutualLimitCurrencyName ?? "USD";
    const takerEquity = shouldTakeUserGrossLimit ? equity * -1n : equity;

    return `${ValueFormat.price(takerEquity)} ${makerLimitCurrency}`;
};

export const getMarkup = (counterpartyLimit: CounterpartyLimit | undefined) => {
    const { takerMarkup } = counterpartyLimit ?? {};

    if (takerMarkup === undefined) {
        return "–";
    }

    return formatPercent(takerMarkup);
};

export const isMarginActive = (
    counterpartyLimit: CounterpartyLimit | undefined,
    counterpartyType: ClientType | undefined,
    clientType: ClientType | undefined,
) => {
    const { maintenanceMargin, restrictedTrading, initialMargin } = counterpartyLimit ?? {};
    const isMarginRequirementEnabled = Boolean(
        maintenanceMargin || restrictedTrading || initialMargin,
    );
    const userHasMakerRoleInLimit =
        clientType === ClientType.Maker ||
        (clientType === ClientType.PrimeBroker &&
            counterpartyType !== undefined &&
            [ClientType.Taker, ClientType.SubaccountMaker, ClientType.SubaccountTaker].includes(
                counterpartyType,
            ));

    return isMarginRequirementEnabled && userHasMakerRoleInLimit;
};

export const getMarginPercent = (value: number | bigint | undefined) => {
    if (value === undefined) {
        return "–";
    }

    return formatPercent(value);
};

export const getMarginValue = (
    value: number | bigint | undefined,
    counterpartyLimit: CounterpartyLimit | undefined,
) => {
    if (value === undefined || counterpartyLimit === undefined) {
        return "–";
    }

    const { currencyName, grossLimit } = counterpartyLimit;

    return `${ValueFormat.price((grossLimit * BigInt(value)) / BigInt(1e6))} ${currencyName}`;
};
