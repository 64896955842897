export enum SettlementType {
    In = "In",
    Out = "Out",
}

export enum SettlementStatus {
    Request,
    Created,
    Received,
    Sent,
    Committed,
    Cancelled,
}
