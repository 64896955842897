import { Client, ClientType } from "~entities/client";

export const getClientType = (client: Client) => {
    if (client.isSubaccount) {
        switch (client.type) {
            case ClientType.Maker:
                return ClientType.SubaccountMaker;
            case ClientType.Taker:
                return ClientType.SubaccountTaker;
            default:
                return client.type;
        }
    }

    return client.type;
};
