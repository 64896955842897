import { noop } from "@fm-frontend/uikit/src/const";
import { CognitoUser } from "amazon-cognito-identity-js";
import { createContext, useMemo, useState } from "react";
import { AuthState } from "~pages/Auth/consts";

type AuthContextValue = {
    authState: AuthState;
    setAuthState: (authState: AuthState) => void;
    userState: CognitoUser | undefined;
    setUserState: (userState: CognitoUser | undefined) => void;
    email: string | undefined;
    setEmail: (email: string | undefined) => void;
};

export const AuthContext = createContext<AuthContextValue>({
    authState: AuthState.Login,
    setAuthState: noop,
    userState: undefined,
    setUserState: noop,
    email: undefined,
    setEmail: noop,
});

export const useAuthContextValue = () => {
    const [authState, setAuthState] = useState<AuthState>(AuthState.Login);
    const [userState, setUserState] = useState<CognitoUser>();
    const [email, setEmail] = useState<string>();

    return useMemo(
        () => ({
            authState,
            setAuthState,
            userState,
            setUserState,
            email,
            setEmail,
        }),
        [email, authState, userState],
    );
};
