import { Badge, BadgeVariant } from "@fm-frontend/uikit";
import { FC } from "react";
import { SettlementStatus } from "~pages/Settlements/types";

export const getStatusBadgeVariant = (value: SettlementStatus): BadgeVariant => {
    switch (value) {
        case SettlementStatus.Request:
            return "info";
        case SettlementStatus.Created:
            return "attention";
        case SettlementStatus.Received:
        case SettlementStatus.Sent:
            return "process";
        case SettlementStatus.Committed:
            return "positive";
        case SettlementStatus.Cancelled:
            return "negative";
        default:
            return "info";
    }
};

export const getStatusTitle = (value: SettlementStatus) => {
    return SettlementStatus[value];
};

type StatusCellProps = {
    value: SettlementStatus;
};

export const StatusCell: FC<StatusCellProps> = ({ value }) => (
    <Badge variant={getStatusBadgeVariant(value)} value={getStatusTitle(value)} />
);
