import styled from "styled-components";

export const Tag = styled.div`
    ${(p) => p.theme.mixins.pSmall};

    display: inline-flex;
    border: 1px solid ${(p) => p.theme.colors.ui20};
    border-radius: 12px;
    padding: 4px 8px;
`;
