import { P } from "@fm-frontend/uikit";
import { FC } from "react";
import { useTheme } from "styled-components";
import { OrderSide } from "~entities/order";

type SideCellProps = {
    value: number;
};

export const SideCell: FC<SideCellProps> = ({ value }) => {
    const theme = useTheme();
    const color = value === OrderSide.Bid ? theme.colors.positive80 : theme.colors.negative80;
    const label = value === OrderSide.Bid ? "Buy" : "Sell";

    return <P color={color}>{label}</P>;
};
