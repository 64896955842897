import { Skeleton, Tab, TabList as UIKitTabList } from "@fm-frontend/uikit";
import styled from "styled-components";
import { useRFQSettingsApi } from "~hooks/api/useRFQSettingsApi";
import { useClientId } from "~hooks/useClientId";

export enum Tabs {
    Trades = "Trades",
    RFQ = "RFQ",
    Settlements = "Settlements",
    Technical = "Technical",
}

const Container = styled.div`
    width: 330px;
`;

export const TabList = () => {
    const clientId = useClientId();
    const { data, isLoading } = useRFQSettingsApi({ clientId });
    const rfqViewingEnabled = data?.viewingEnabled ?? false;

    return (
        <Container>
            {isLoading && <Skeleton width="330px" height="24px" />}
            {!isLoading && (
                <UIKitTabList size="small">
                    <Tab title="Trades" value={Tabs.Trades} />
                    {rfqViewingEnabled && <Tab title="RFQ" value={Tabs.RFQ} />}
                    <Tab title="Settlements" value={Tabs.Settlements} />
                    <Tab title="Technical" value={Tabs.Technical} />
                </UIKitTabList>
            )}
        </Container>
    );
};
