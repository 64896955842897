import useSWR, { mutate } from "swr";
import { Currency } from "~entities/currency";
import { Instrument } from "~entities/instrument";
import { Network } from "~entities/network";
import { requestAdminery } from "~utils/api";
import {
    prepareCurrency,
    prepareInstrument,
    prepareNetwork,
    RawCurrencyEntity,
    RawInstrumentEntity,
    RawNetworkEntity,
} from "~utils/prepareFeedEntity";

type RawData = [
    currencies: RawCurrencyEntity[],
    instruments: RawInstrumentEntity[],
    networks: RawNetworkEntity[],
];

type ResultData = {
    currencies: Currency[];
    instruments: Instrument[];
    networks: Network[];
};

type SWRKey = string;

const fetcher = () =>
    requestAdminery("instruments", {}).then(([currencies, instruments, networks]: RawData) => ({
        currencies: currencies.map(prepareCurrency),
        instruments: instruments.map(prepareInstrument),
        networks: networks.map(prepareNetwork),
    }));

export const updateInstrumentsApi = () => mutate("useInstrumentsApi");

export const useInstrumentsApi = () =>
    useSWR<ResultData, unknown, SWRKey>("useInstrumentsApi", fetcher);
