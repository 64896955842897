import {
    ConfirmationModal,
    DesctructiveButton,
    FieldGroup,
    Form,
    FormActions,
    FormBody,
    Header as FormHeader,
    HeaderTitle,
    SimpleDropdown,
    SimpleInput,
} from "@fm-frontend/uikit";
import { EMPTY_ARRAY } from "@fm-frontend/uikit/src/const";
import { ValueFormat, ValueParse } from "@fm-frontend/utils";
import { FC, SyntheticEvent, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import FormError from "~components/FormError";
import { PrimaryButton } from "~components/PrimaryButton";
import { ERROR_MESSAGES } from "~constants/errors";
import { ValidateErrors, ValidateRules } from "~constants/form";
import { useAssetLimits } from "~hooks/api/useAssetLimits";
import { useInstrumentsApi } from "~hooks/api/useInstrumentsApi";
import useAppSelector from "~hooks/useAppSelector";
import { useClientId } from "~hooks/useClientId";
import useNotifications from "~hooks/useNotifications";
import { useCounterpartyId } from "~pages/RisksManagement/View/hooks/useCounterpartyId";
import { getClient } from "~store/clients/selectors";
import { requestAdminery } from "~utils/api";
import { prepareRequestError } from "~utils/prepareRequestError";

const FormContainer = styled(Form)`
    width: 100%;
    min-width: initial;
    padding: 8px;
`;

type EditAssetLimitFormType = {
    currency: string;
    grossLimit: string;
    limitCurrency: string;
};

type EditAssetLimitFormProps = {
    currency?: string;
    onDone: () => void;
};

export const EditAssetLimitForm: FC<EditAssetLimitFormProps> = ({ currency, onDone }) => {
    const getClientById = useAppSelector(getClient);
    const { showSuccessNotification } = useNotifications();
    const clientId = useClientId();
    const counterpartyId = useCounterpartyId();
    const client = getClientById(clientId);
    const counterparty = getClientById(counterpartyId);

    const {
        data: clientAssetLimitsData,
        isLoading: isClientAssetLimitsLoading,
        mutate,
    } = useAssetLimits(clientId);
    const { data: instrumentsData, isLoading: isInstrumentsLoading } = useInstrumentsApi();
    const { currencies = EMPTY_ARRAY } = instrumentsData ?? {};

    const {
        register,
        setFocus,
        formState: { isSubmitting, errors, isDirty },
        handleSubmit,
        control,
        watch,
        reset,
    } = useForm<EditAssetLimitFormType>({
        defaultValues: {
            currency,
        },
    });

    const currencyWatch = watch("currency");

    const assetLimitsIndex =
        clientAssetLimitsData?.findIndex(
            (assetLimit) =>
                assetLimit.counterpartyId === counterpartyId &&
                assetLimit.currencyName === currency,
        ) ?? 0;
    const assetLimit = clientAssetLimitsData?.[assetLimitsIndex];
    const { grossLimit, limitCurrency } = assetLimit ?? {};
    const isLimitSet = grossLimit !== undefined;

    const [submitError, setSubmitError] = useState<string>();
    const [confirmationShown, setConfirmationShown] = useState(false);
    const [removing, setRemoving] = useState<boolean>(false);

    const currenciesOptions = useMemo(
        () =>
            currencies
                .map(({ name }) => ({ text: name, value: name }))
                .sort((a, b) => a.text.localeCompare(b.text)),
        [currencies],
    );

    useEffect(() => {
        reset({
            currency: currencyWatch,
            grossLimit: ValueFormat.formSize(grossLimit ?? 0),
            limitCurrency: limitCurrency ?? currencyWatch,
        });
    }, [reset, currencyWatch, grossLimit, limitCurrency]);

    useEffect(() => {
        setFocus("grossLimit");
    }, [setFocus]);

    const handleSubmitClick = (e: SyntheticEvent) => {
        e.stopPropagation();
    };
    const handleModifySubmit = handleSubmit(async (data) => {
        try {
            setSubmitError(undefined);

            const clientResponse = await requestAdminery("setCAssetLimit", {
                clientId,
                counterpartyId,
                asset: data.currency,
                grossLimit: ValueParse.size(data.grossLimit),
                limitCurrency: data.limitCurrency,
            });
            const error = clientResponse?.error;

            if (!error) {
                await mutate();
                onDone();
                showSuccessNotification(`Asset Limit was successfully modified`);
            }
            if (error) {
                setSubmitError(ERROR_MESSAGES[error] ?? error);
            }
        } catch (e) {
            setSubmitError(prepareRequestError(e));
        }
    });

    const handleRemove = async () => {
        if (!currency) {
            return;
        }

        try {
            setSubmitError(undefined);
            setRemoving(true);

            const response = await requestAdminery("delCAssetLimit", {
                clientId,
                counterpartyId,
                asset: currency,
            });

            if (!response?.error) {
                await mutate();
                onDone();
                showSuccessNotification("Asset Limit was removed");
            }

            if (response?.error) {
                setSubmitError(ERROR_MESSAGES[response?.error] ?? response?.error);
            }
        } catch (e) {
            setSubmitError(prepareRequestError(e));
        } finally {
            setRemoving(false);
        }
    };

    const handleConfirmationDone = () => {
        setConfirmationShown(false);
        handleRemove();
    };
    const handleConfirmationClose = () => {
        setConfirmationShown(false);
    };

    return (
        <FormContainer onSubmit={handleModifySubmit} onClick={(e) => e.stopPropagation()}>
            <FormHeader>
                <HeaderTitle
                    title="Asset control"
                    label={`${client?.username} (${clientId}) setup for ${counterparty?.username} (${counterpartyId})`}
                />
            </FormHeader>
            <FormBody alignItems="stretch">
                <SimpleDropdown
                    label="Currency"
                    name="currency"
                    disabled={isInstrumentsLoading}
                    control={control}
                    rules={{ required: ValidateErrors.required }}
                    error={errors.currency?.message}
                    options={currenciesOptions}
                    searchable
                />
                <FieldGroup>
                    <SimpleInput
                        label="Limit per asset"
                        placeholder="0.00000000"
                        disabled={isClientAssetLimitsLoading}
                        error={errors.grossLimit?.message}
                        {...register("grossLimit", {
                            required: ValidateErrors.required,
                            validate: { size: ValidateRules.size },
                        })}
                    />
                    <SimpleDropdown
                        label="Limit currency"
                        name="limitCurrency"
                        control={control}
                        rules={{ required: ValidateErrors.required }}
                        error={errors.limitCurrency?.message}
                        options={currenciesOptions}
                        placeholder="Select"
                        searchable
                    />
                </FieldGroup>
                {submitError && <FormError message={submitError} />}
            </FormBody>
            <FormActions variant="plain">
                <DesctructiveButton
                    disabled={!isLimitSet}
                    type="button"
                    size="large"
                    fullWidth
                    loading={isSubmitting || removing}
                    onClick={() => setConfirmationShown(true)}
                >
                    Remove Limit
                </DesctructiveButton>
                {confirmationShown && (
                    <ConfirmationModal
                        title={`Remove ${currency}, ${counterparty?.username} (${counterpartyId}) limit?`}
                        description="This action is irreversible. Are you sure?"
                        confirmButtonTitle="Yes, delete"
                        onConfirm={handleConfirmationDone}
                        onClose={handleConfirmationClose}
                    />
                )}
                <PrimaryButton
                    disabled={!isDirty}
                    size="large"
                    fullWidth
                    loading={isSubmitting}
                    onClick={handleSubmitClick}
                >
                    Modify
                </PrimaryButton>
            </FormActions>
        </FormContainer>
    );
};
