import { FC } from "react";
import Select from "~components/Table/Select";

export const PARTNER_SELECT_ALL_VALUE = "All";

export enum PartnerSelectType {
    Counterparties = "Counterparties",
    SubAccounts = "Sub-accounts",
}

export type PartnerValueType = typeof PARTNER_SELECT_ALL_VALUE | PartnerSelectType;
type Option = { text: string; value: PartnerValueType };

const OPTION_ALL = { text: "All", value: PARTNER_SELECT_ALL_VALUE } as Option;

const OPTIONS = [
    { text: "Counterparties", value: PartnerSelectType.Counterparties },
    { text: "Sub-accounts", value: PartnerSelectType.SubAccounts },
] as Option[];

type PartnerSelectProps = {
    allTypes?: boolean;
    value: PartnerValueType;
    onChange: (value: PartnerValueType) => void;
};

export const PartnerSelect: FC<PartnerSelectProps> = ({ allTypes = false, value, onChange }) => (
    <Select<PartnerValueType>
        label="Partner"
        defaultValue={value}
        options={[...(allTypes ? [OPTION_ALL] : []), ...OPTIONS]}
        onChange={onChange}
    />
);
