import { FC } from "react";
import { ClientCell } from "~components/Table/Cell/ClientCell";
import { ClientId } from "~entities/client";
import useAppSelector from "~hooks/useAppSelector";
import { getClient } from "~store/clients/selectors";

type CounterpartyCellProps = {
    value: ClientId;
};

export const CounterpartyCell: FC<CounterpartyCellProps> = ({ value }) => {
    const client = useAppSelector((state) => getClient(state)(value));

    if (client === undefined) {
        return null;
    }

    return <ClientCell id={value} name={client.username} />;
};
