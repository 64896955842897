import useSWR from "swr";
import { ClientId } from "~entities/client";
import { requestHrpReporting } from "~utils/api";

type HRPClient = {
    fmClientId: ClientId;
    hrpClientId: string;
};
type HRPClients = Record<
    ClientId,
    {
        hrpClientId: string;
    }
>;

type SWRKey = string;

const prepareHRPClients = (hrpResult: HRPClient[]) =>
    hrpResult.reduce<HRPClients>((acc, curr) => {
        acc[curr.fmClientId] = {
            hrpClientId: curr.hrpClientId,
        };

        return acc;
    }, {});

const fetcher = () =>
    requestHrpReporting("getClients", {}).then((data) => prepareHRPClients(data.result));
export const useHRPClientsApi = () =>
    useSWR<HRPClients, unknown, SWRKey>("useHRPClientsApi", fetcher, {
        revalidateOnFocus: false,
        // eslint-disable-next-line no-console
        onError: (e) => console.error(e),
    });
