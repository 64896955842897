import { Auth } from "aws-amplify";
import { useState } from "react";
import { AuthState } from "~pages/Auth/consts";

const useForgotPassword = () => {
    const [loading, setLoading] = useState<boolean>(false);

    const forgotPassword = (email: string) => {
        setLoading(true);

        return new Promise<AuthState>((resolve, reject) => {
            Auth.forgotPassword(email)
                .then(() => resolve(AuthState.ResetPasswordSubmit))
                .catch(reject)
                .finally(() => setLoading(false));
        });
    };

    return { forgotPassword, loading };
};

export default useForgotPassword;
