import { ValueFormat } from "@fm-frontend/utils";
import { Client, ClientType } from "~entities/client";
import { CounterpartyLimit } from "~entities/counterpartyLimit";
import { GlobalLimit } from "~entities/globalLimit";
import {
    bitmaskToTradingStatus,
    CounterpartyStatusType,
    LimitTradingStatus,
} from "~pages/RisksManagement/utils/status";

export const CounterpartyStatus = {
    [CounterpartyStatusType.NORMAL]: {
        title: "Normal",
        titleShort: "Normal",
        actions: [],
    },
    [CounterpartyStatusType.MARGIN_CALL]: {
        title: "Margin Call",
        titleShort: "Margin Call",
        actions: [],
    },
    [CounterpartyStatusType.LOW_GROSS_FREE]: {
        title: "Low Gross Free",
        titleShort: "Low Gross",
        actions: ["Update Gross Limit", "Request a settlement"],
    },
    [CounterpartyStatusType.RESTRICTED_TRADING]: {
        title: "Restricted Trading",
        titleShort: "Restricted",
        actions: [],
    },
    [CounterpartyStatusType.STOPPED_BY_CP]: {
        title: "Stopped by CP",
        titleShort: "Stopped",
        actions: [],
    },
    [CounterpartyStatusType.STOPPED]: {
        title: "Stopped",
        titleShort: "Stopped",
        actions: [],
    },
    [CounterpartyStatusType.LIQUIDATION]: {
        title: "Liquidation",
        titleShort: "Liquidation",
        actions: ["Increase Gross limit", "Settle open positions"],
    },
    [CounterpartyStatusType.AWAITING_CP]: {
        title: "Awaiting CP",
        titleShort: "Awaiting",
        actions: [],
    },
};

export const shouldUseUserGrossLimit = (counterpartyType?: ClientType, clientType?: ClientType) =>
    counterpartyType === ClientType.SubaccountMaker ||
    counterpartyType === ClientType.SubaccountTaker ||
    counterpartyType === ClientType.Taker ||
    clientType === ClientType.Maker;

export const getGlobalTradingEnabled = (globalLimit: GlobalLimit | undefined) => {
    const { grossLimit } = globalLimit ?? {};

    if (grossLimit === undefined) {
        return false;
    }

    return grossLimit > 0n;
};

/**
 * Determines whether trading is enabled based on the counterparty's limit.
 * Trading is considered enabled if it is explicitly marked as enabled or
 * not explicitly disabled by the client or both the client and system.
 *
 * @param {CounterpartyLimit | undefined} limit - The trading limit information for a counterparty, if available.
 * @returns {boolean} - True if trading is enabled; otherwise, false.
 */
export const getTradingEnabled = (limit: CounterpartyLimit | undefined) => {
    const { tradingDisabledFlags } = limit ?? {};

    if (tradingDisabledFlags === undefined) {
        return false;
    }

    const tradingStatus = bitmaskToTradingStatus(tradingDisabledFlags);

    const tradingEnabled = tradingStatus === LimitTradingStatus.ENABLED;
    const notDisabledByClient =
        tradingStatus !== LimitTradingStatus.DISABLED_BY_CLIENT &&
        tradingStatus !== LimitTradingStatus.DISABLED_BY_BOTH;

    return tradingEnabled || notDisabledByClient;
};

export const getGrossLimit = (limit: GlobalLimit | CounterpartyLimit | undefined) => {
    const { grossLimit } = limit ?? {};

    if (grossLimit === undefined) {
        return "";
    }

    return ValueFormat.size(grossLimit);
};

export const getGrossFreePercent = (limit: GlobalLimit | CounterpartyLimit | undefined) => {
    const PERCENT_SHIFT = 1e8;
    const { grossLimit = 0n, grossLimitUtilisation = 0n } = limit ?? {};
    const freeLimit = BigInt(grossLimit) - BigInt(grossLimitUtilisation);

    if (grossLimit !== 0n && freeLimit > 0n) {
        const percent = (BigInt(100 * PERCENT_SHIFT) * freeLimit) / BigInt(grossLimit);
        const quotient = Number(percent / BigInt(1e8));
        const remainder = Number(percent % BigInt(1e8));

        return (quotient + remainder * 1e-8).toFixed(2);
    }

    return 0;
};

export const getGrossFree = (limit: GlobalLimit | CounterpartyLimit | undefined) => {
    const { grossLimit = 0n, grossLimitUtilisation = 0n } = limit ?? {};
    const grossFree = ValueFormat.size(BigInt(grossLimit) - BigInt(grossLimitUtilisation));
    const grossFreePercent = getGrossFreePercent(limit);

    return `${grossFree} (${grossFreePercent}%)`;
};

export const getExposure = (limit: GlobalLimit | CounterpartyLimit | undefined) => {
    const { grossLimitUtilisation = 0n } = limit ?? {};

    return ValueFormat.size(grossLimitUtilisation);
};

export const getCurrency = (limit: GlobalLimit | CounterpartyLimit | undefined) => {
    const { currencyName = "" } = limit ?? {};

    return currencyName;
};

export const isMatch = (query: string, counterparty: Client | undefined) => {
    return (
        query === "" ||
        counterparty?.username.toUpperCase().includes(query.toUpperCase()) ||
        String(counterparty?.id).startsWith(query)
    );
};
