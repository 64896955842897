import useSWR from "swr";
import { ClientId } from "~entities/client";
import { requestAdminery } from "~utils/api";

type ResultData = {
    enabled: boolean;
    instruments: string[];
};
type SWRKey = [clientId: ClientId, key: string];

const fetcher = ([clientId]: SWRKey) => requestAdminery("instrumentsWhitelist", { clientId });

export const useInstrumentsWhitelist = (clientId: ClientId) =>
    useSWR<ResultData, unknown, SWRKey>([clientId, "useInstrumentsWhitelist"], fetcher);
