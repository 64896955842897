import { CognitoUser } from "amazon-cognito-identity-js";
import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";

const useSetupTOTP = (userState: CognitoUser | undefined) => {
    const [mfaToken, setMfaToken] = useState<string>();
    const [mfaError, setMfaError] = useState<string>();

    useEffect(() => {
        Auth.setupTOTP(userState)
            .then((token: string) => setMfaToken(token))
            .catch((error) => setMfaError(error.message));
    }, [userState]);

    return { mfaToken, mfaError };
};

export default useSetupTOTP;
