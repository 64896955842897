import { Badge, BadgeVariant } from "@fm-frontend/uikit";
import { FC } from "react";
import { RFQDealHistoryStatus } from "~entities/rfqDealHistoryItem";

export const getStatusBadgeVariant = (value: RFQDealHistoryStatus): BadgeVariant => {
    switch (value) {
        case RFQDealHistoryStatus.Committed:
            return "positive";
        case RFQDealHistoryStatus.Created:
        case RFQDealHistoryStatus.Canceled:
        case RFQDealHistoryStatus.Expired:
        default:
            return "info";
    }
};

export const getStatusTitle = (value: RFQDealHistoryStatus) => {
    switch (value) {
        case RFQDealHistoryStatus.Created:
            return "Created";
        case RFQDealHistoryStatus.Committed:
            return "Executed";
        case RFQDealHistoryStatus.Canceled:
            return "Canceled";
        case RFQDealHistoryStatus.Expired:
            return "Expired";
        default:
            return "-";
    }
};

type StatusCellProps = {
    value: RFQDealHistoryStatus;
};

export const StatusCell: FC<StatusCellProps> = ({ value }) => (
    <Badge variant={getStatusBadgeVariant(value)} value={getStatusTitle(value)} />
);
