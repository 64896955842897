import { InfiniteTable as UIKitTable } from "@fm-frontend/uikit";
import { EMPTY_ARRAY } from "@fm-frontend/uikit/src/const";
import { ExpandedState, getCoreRowModel, getExpandedRowModel } from "@tanstack/react-table";
import React, { useMemo, useState } from "react";
import styled from "styled-components";
import OptionsContainer from "~components/Table/Options/Container";
import ExportButton from "~components/Table/Options/ExportButton";
import { useInstrumentMarkups } from "~hooks/api/useInstrumentMarkups";
import { useInstrumentsApi } from "~hooks/api/useInstrumentsApi";
import { useInstrumentsWhitelist } from "~hooks/api/useInstrumentsWhitelist";
import useAppSelector from "~hooks/useAppSelector";
import { useClientId } from "~hooks/useClientId";
import { isInstrumentWhitelisted, isWhitelisted } from "~pages/RisksManagement/utils/whitelisted";
import { BackButton } from "~pages/RisksManagement/View/components/BackButton";
import { HeaderCell } from "~pages/RisksManagement/View/components/HeaderCell";
import { useCounterpartyId } from "~pages/RisksManagement/View/hooks/useCounterpartyId";
import {
    getClientColumn,
    getCounterpartyColumn,
    getExpandColumn,
    getExtraColumn,
    getTitleColumn,
} from "~pages/RisksManagement/View/InstrumentsList/columns";
import { Item, RowType, SubItem } from "~pages/RisksManagement/View/InstrumentsList/types";
import { TabList } from "~pages/RisksManagement/View/TabList";
import { getClient } from "~store/clients/selectors";

const formatMarkup = (markup: number | undefined) =>
    markup !== undefined ? `${markup / 1e4}%` : "—";

const getInfoRow = (instrument = "", clientData = "", counterpartyData = "") => ({
    type: RowType.Info,
    instrument,
    clientData,
    counterpartyData,
});

const Table = styled(UIKitTable<Item | SubItem>)`
    min-width: 1010px;

    thead {
        th {
            //due to transparency nature of UIkit colors
            background-color: #606a6d;
            height: 32px;
            padding-right: 12px;
            padding-bottom: unset;

            span {
                font-size: 11px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
                letter-spacing: 0.44px;
                text-transform: uppercase;
                color: ${(p) => p.theme.colors.uiWhite100};
            }
        }
    }

    td {
        border-right: 1px solid ${(p) => p.theme.colors.ui8};
        padding-right: 12px;
    }

    th:first-of-type,
    td:first-of-type {
        padding-left: 12px !important;
        border-right: unset;
    }
`;

const ActionGap = styled.div`
    min-width: 38px;
`;

export const RiskManagementViewInstrumentsList: React.FC = () => {
    const getClientById = useAppSelector(getClient);
    const clientId = useClientId();
    const client = getClientById(clientId);
    const counterpartyId = useCounterpartyId();
    const counterparty = getClientById(counterpartyId);
    const clientTitle = `${client?.username} (${clientId})`;
    const counterpartyTitle = `${counterparty?.username} (${counterpartyId})`;

    const { data: clientInstrumentMarkups, isLoading: isClientInstrumentMarkupsLoading } =
        useInstrumentMarkups(clientId);
    const { data: counterpartyInstrumentMarkups, isLoading: isCounterpartyInstrumentMarkups } =
        useInstrumentMarkups(counterpartyId);
    const { data: clientWLData, isLoading: isClientWLLoading } = useInstrumentsWhitelist(clientId);
    const { data: counterpartyWLData, isLoading: isCounterpartyWLLoading } =
        useInstrumentsWhitelist(counterpartyId);
    const { data: instrumentsData, isLoading: isInstrumentsLoading } = useInstrumentsApi();
    const isLoading =
        isInstrumentsLoading ||
        isClientWLLoading ||
        isCounterpartyWLLoading ||
        isClientInstrumentMarkupsLoading ||
        isCounterpartyInstrumentMarkups;
    const { instruments = EMPTY_ARRAY } = instrumentsData ?? {};

    const [expanded, setExpanded] = useState<ExpandedState>({});

    const data = useMemo(() => {
        return instruments
            .sort((a, b) => a.name.localeCompare(b.name))
            .map(({ name }) => {
                const isClientInstrumentWhitelisted = isWhitelisted(
                    clientWLData?.enabled,
                    isInstrumentWhitelisted(name, clientWLData?.instruments),
                );
                const isCounterpartyInstrumentWhitelisted = isWhitelisted(
                    counterpartyWLData?.enabled,
                    isInstrumentWhitelisted(name, counterpartyWLData?.instruments),
                );
                const clientInstrumentMarkup = clientInstrumentMarkups?.find(
                    (markup) =>
                        markup.instrument === name && markup.counterpartyId === counterpartyId,
                );
                const counterpartyInstrumentMarkup = counterpartyInstrumentMarkups?.find(
                    (markup) => markup.instrument === name && markup.counterpartyId === clientId,
                );
                const { bidMarkup: clientBidMarkup, askMarkup: clientAskMarkup } =
                    clientInstrumentMarkup ?? {};
                const { bidMarkup: counterpartyBidMarkup, askMarkup: counterpartyAskMarkup } =
                    counterpartyInstrumentMarkup ?? {};

                return {
                    type: RowType.Instrument,
                    instrument: name,
                    clientData: isClientInstrumentWhitelisted,
                    counterpartyData: isCounterpartyInstrumentWhitelisted,
                    items: [
                        getInfoRow(
                            "Bid",
                            formatMarkup(clientBidMarkup),
                            formatMarkup(counterpartyBidMarkup),
                        ),
                        getInfoRow(
                            "Offer",
                            formatMarkup(clientAskMarkup),
                            formatMarkup(counterpartyAskMarkup),
                        ),
                    ],
                };
            });
    }, [
        counterpartyId,
        counterpartyInstrumentMarkups,
        counterpartyWLData,
        clientId,
        clientInstrumentMarkups,
        clientWLData,
        instruments,
    ]);

    const columns = useMemo(() => {
        return [
            getExpandColumn(),
            getTitleColumn(),
            getClientColumn(clientTitle),
            getCounterpartyColumn(counterpartyTitle),
            getExtraColumn(),
        ];
    }, [clientTitle, counterpartyTitle]);

    const getExportData = () =>
        data
            .map((dataItem) => [
                {
                    Instrument: dataItem.instrument,
                    [clientTitle]: dataItem.clientData ? "Enabled" : "Disabled",
                    [counterpartyTitle]: dataItem.counterpartyData ? "Enabled" : "Disabled",
                },
                ...dataItem.items.map((i) => ({
                    Instrument: i.instrument,
                    [clientTitle]: i.clientData,
                    [counterpartyTitle]: i.counterpartyData,
                })),
            ])
            .flat();

    return (
        <>
            <OptionsContainer>
                <HeaderCell>
                    <BackButton />
                </HeaderCell>
                <TabList />
                <ActionGap />
                <ExportButton
                    data={getExportData}
                    filename={`risks_management_instrument_list_${clientId}-${counterpartyId}`}
                    loading={isLoading}
                />
            </OptionsContainer>
            <Table
                tableOptions={{
                    data,
                    columns,
                    state: {
                        expanded,
                    },
                    getCoreRowModel: getCoreRowModel(),
                    getExpandedRowModel: getExpandedRowModel(),
                    onExpandedChange: setExpanded,
                    getSubRows: (row) => row.items,
                    getRowCanExpand: (row) => row.original.type !== RowType.Info,
                }}
                onRowClick={(row) => {
                    if (row.getCanExpand()) {
                        row.toggleExpanded();
                    }
                }}
                isLoading={isLoading}
            />
        </>
    );
};
