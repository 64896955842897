import { ERROR_MESSAGES } from "~constants/errors";

export const prepareRequestError = (error: unknown) => {
    if (error instanceof Error) {
        const errorMassage = error.message;
        return ERROR_MESSAGES[parseInt(errorMassage, 10)] ?? errorMassage;
    }

    return String(error);
};
