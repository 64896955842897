import { FC, ReactNode } from "react";
import styled from "styled-components";

const Container = styled.main`
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: auto;
`;

const Head = styled.div`
    display: flex;
    background: ${(p) => p.theme.colors.uiWhite100};
    border: 1px solid ${(p) => p.theme.colors.ui12};
    border-bottom: none;
    border-radius: 12px 12px 0 0;
    height: 12px;
`;

const Body = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: auto;
    background: ${(p) => p.theme.colors.uiWhite100};
    border-left: 1px solid ${(p) => p.theme.colors.ui12};
    border-right: 1px solid ${(p) => p.theme.colors.ui12};
`;

export const Content: FC<{ children: ReactNode }> = ({ children }) => (
    <Container>
        {/* Extract the head with `border-radius` styles from scroll hierarchy to reduce render layers. */}
        {/* These render layers cause laggy scroll for Max environment. */}
        <Head />
        <Body>{children}</Body>
    </Container>
);
