// eslint-disable-next-line import/no-extraneous-dependencies
import { Row, RowData } from "@tanstack/table-core/build/lib";

export const sortTimestamp = <TData extends RowData>(
    rowA: Row<TData>,
    rowB: Row<TData>,
    columnId: string,
): number => {
    const a = rowA.getValue(columnId);
    const b = rowB.getValue(columnId);

    if (a === undefined && b === undefined) {
        return 0;
    }

    if (a === undefined) {
        return -1;
    }

    if (b === undefined) {
        return 1;
    }

    return Number(a) - Number(b);
};
