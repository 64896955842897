import { ClientId as ClientIdComponent, P, PBold } from "@fm-frontend/uikit";
import { FC, useContext } from "react";
import styled from "styled-components";
import MatchContains from "~components/Table/Cell/MatchContains";
import { TableContext } from "~components/Table/TableContext";
import { ClientId } from "~entities/client";

const Container = styled.div`
    display: flex;
    gap: 8px;
`;

type CounterpartyCellProps = {
    id: ClientId;
    name: string | undefined;
    expandable?: boolean;
};

export const CounterpartyCell: FC<CounterpartyCellProps> = ({ id, name, expandable = false }) => {
    const { query } = useContext(TableContext);

    if (name === undefined) {
        return null;
    }

    if (expandable) {
        return (
            <Container>
                <PBold>
                    <MatchContains value={name} query={query} />
                </PBold>
                <ClientIdComponent id={<MatchContains value={String(id)} query={query} />} />
            </Container>
        );
    }

    return (
        <Container>
            <P>{name}</P>
            <ClientIdComponent id={id} />
        </Container>
    );
};
