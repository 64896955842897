import { noop } from "@fm-frontend/uikit/src/const";
import { createContext, Dispatch, SetStateAction, useMemo, useState } from "react";

type ClientSelectContextValue = {
    query: string;
    setQuery: Dispatch<SetStateAction<string>>;
};

export const ClientSelectContext = createContext<ClientSelectContextValue>({
    query: "",
    setQuery: noop,
});

export const useClientSelectContextValue = () => {
    const [query, setQuery] = useState("");

    return useMemo(
        () => ({
            query,
            setQuery,
        }),
        [query],
    );
};
