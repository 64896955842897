import { IconButton, Icons } from "@fm-frontend/uikit";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { getClientUrl } from "~pages/Router";

type ClientActionType = {
    clientId: number;
};

const ClientAction: FC<ClientActionType> = ({ clientId }) => {
    const navigate = useNavigate();
    const handleClick = () => {
        navigate(getClientUrl(clientId));
    };

    return (
        <IconButton title="View" variant="plain" Icon={Icons.ArrowRight} onClick={handleClick} />
    );
};

export default ClientAction;
