import { FC, useContext } from "react";
import styled from "styled-components";
import { PlainButton } from "~components/PlainButton";
import { AuthState } from "~pages/Auth/consts";
import { AuthContext } from "~pages/Auth/context";

const Container = styled.section`
    display: flex;
    justify-content: center;
    gap: 30px;
    padding: 16px 0;
`;

const Actions: FC = () => {
    const { authState, setAuthState } = useContext(AuthContext);
    const login = authState === AuthState.Login;

    const handleResetPasswordClick = () => {
        setAuthState(AuthState.ResetPassword);
    };

    const handleBackClick = () => {
        setAuthState(AuthState.Login);
    };

    return (
        <Container>
            {login && (
                <PlainButton size="small" onClick={handleResetPasswordClick}>
                    Reset password
                </PlainButton>
            )}
            {!login && (
                <PlainButton size="small" onClick={handleBackClick}>
                    Back
                </PlainButton>
            )}
        </Container>
    );
};

export default Actions;
