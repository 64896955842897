import useSWR from "swr";
import { ClientId } from "~entities/client";
import { OrderId } from "~entities/order";
import { SettlementHistoryItem } from "~entities/settlementHistoryItem";
import { requestAdminery } from "~utils/api";

type RawSettlementHistoryEntity = [
    orderId: OrderId,
    currency1: string,
    currency2: string,
    size1: number | bigint,
    size2: number | bigint,
    createdAt: number,
    counterpartyId: ClientId,
    comment: string,
    flags: number,
    settlementMoment: number,
    settlementId: number,
];

const prepareData = ([
    orderId,
    currency1,
    currency2,
    size1,
    size2,
    createdAt,
    counterpartyId,
    comment,
    flags,
    settlementMoment,
    settlementId,
]: RawSettlementHistoryEntity): SettlementHistoryItem => ({
    orderId,
    currency1,
    currency2,
    size1,
    size2,
    createdAt,
    counterpartyId,
    comment,
    flags,
    settlementMoment,
    settlementId,
});

type SWRKey = [clientId: ClientId, filterTransactions: boolean, key: string];

const fetcher = ([clientId, filterTransactions]: SWRKey) =>
    requestAdminery("settlementHistory", { clientId, filterTransactions }).then((data) =>
        data.map(prepareData),
    );

export const useSettlementHistoryApi = (clientId: ClientId, filterTransactions = false) =>
    useSWR<SettlementHistoryItem[]>(
        [clientId, filterTransactions, "useSettlementHistoryApi"],
        fetcher,
    );
