import { FC } from "react";
import Select from "~components/Table/Select";
import { SettlementType } from "~pages/Settlements/types";

export const TYPE_SELECT_ALL_VALUE = "All";

export type TypeSelectValue = SettlementType | typeof TYPE_SELECT_ALL_VALUE;

type Option = {
    text: string;
    value: TypeSelectValue;
};

const OPTIONS = [
    { text: "All", value: TYPE_SELECT_ALL_VALUE },
    { text: "In", value: SettlementType.In },
    { text: "Out", value: SettlementType.Out },
] as Option[];

type StatusSelectProps = {
    value: TypeSelectValue;
    onChange: (value: TypeSelectValue) => void;
};

export const TypeSelect: FC<StatusSelectProps> = ({ value, onChange }) => (
    <Select<TypeSelectValue>
        label="Type"
        defaultValue={value}
        options={OPTIONS}
        onChange={onChange}
    />
);
