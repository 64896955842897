import styled from "styled-components";

const Styles = styled.div`
    .react-tags {
        position: relative;

        padding: 6px 0 0 6px;
        min-height: 44px;
        font-size: 14px;
        line-height: 18px;

        background-color: ${(p) => p.theme.colors.ui8};
        border-radius: 8px;

        :hover {
            background-color: ${(p) => p.theme.colors.ui12};
        }

        ::placeholder {
            color: ${(p) => p.theme.colors.ui32};
        }

        :disabled {
            cursor: not-allowed;
            background-color: ${(p) => p.theme.colors.ui4};
            color: ${(p) => p.theme.colors.ui20};
        }

        /* clicking anywhere will focus the input */
        cursor: text;

        &.is-focused {
            background-color: ${(p) => p.theme.colors.uiWhite100};
            box-shadow: 0 0 0 3px ${(p) => p.theme.colors.brand12},
                inset 0 0 0 1px ${(p) => p.theme.colors.brand100};
        }
    }

    .react-tags__selected {
        display: inline;
        padding: 6px 0;

        & > :not(:last-child) {
            margin-right: 4px;
        }
    }

    .react-tags__selected-tag {
        margin: 0 6px 6px 0;
    }

    .react-tags__search {
        display: inline-block;
        padding: 7px 2px;
        margin-bottom: 6px;
        max-width: 100%;
    }

    .react-tags__search-input {
        transition: none !important;
        background-color: transparent;
    }

    .react-tags__search-input {
        /* prevent autoresize overflowing the container */
        max-width: 100%;

        /* remove styles and layout from this element */
        margin: 0;
        padding: 0;
        border: 0;
        outline: none;

        /* match the font styles */
        font-size: 12px;
        line-height: inherit;
    }

    .react-tags__search-input::-ms-clear {
        display: none;
    }

    .react-tags__suggestions {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        z-index: 10;
    }

    .react-tags__suggestions ul {
        display: flex;
        min-height: 20px;
        max-height: 296px;
        overflow-y: auto;
        flex-direction: column;
        background: ${(p) => p.theme.colors.uiWhite100};
        padding: 12px 0;
        box-shadow: 0 4px 16px ${(p) => p.theme.colors.ui8},
            inset 0 0 0 1px ${(p) => p.theme.colors.ui12};
        border-radius: 8px;
    }

    .react-tags__suggestions li {
        padding: 8px 12px;
        list-style: none;
        width: 100%;
    }

    .react-tags__suggestions li:hover {
        cursor: pointer;
        background: #eee;
    }

    .react-tags__suggestions li.is-active {
        background: #b7cfe0;
    }

    .react-tags__suggestions li.is-disabled {
        opacity: 0.5;
        cursor: auto;
    }
`;

export default Styles;
