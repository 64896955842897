import {
    BasicInput,
    Form,
    FormActions,
    FormBody,
    Header as FormHeader,
    HeaderTitle,
    ShadowCard,
} from "@fm-frontend/uikit";
import { FC, useContext, useState } from "react";
import { useForm } from "react-hook-form";
import FormError from "~components/FormError";
import { PrimaryButton } from "~components/PrimaryButton";
import { AuthContext } from "~pages/Auth/context";
import useForgotPassword from "~pages/Auth/hooks/useForgotPassword";
import { ValidationRules } from "~pages/Auth/utils";

type ResetPasswordFrom = {
    email: string;
};

const ResetPasswordContent: FC = () => {
    const { setAuthState, setEmail } = useContext(AuthContext);
    const { forgotPassword, loading } = useForgotPassword();
    const [error, setError] = useState<string>();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<ResetPasswordFrom>({
        mode: "onChange",
    });

    const handleFormSubmit = handleSubmit(({ email }) => {
        forgotPassword(email)
            .then((nextAuthState) => {
                setEmail(email);
                setError(undefined);
                setAuthState(nextAuthState);
            })
            .catch((e) => setError(e?.message));
    });

    return (
        <Form onSubmit={handleFormSubmit}>
            <ShadowCard>
                <FormHeader>
                    <HeaderTitle title="Reset password" />
                </FormHeader>
                <FormBody>
                    <BasicInput
                        type="email"
                        label="Email"
                        placeholder="trader@example.com"
                        error={errors.email?.message}
                        {...register("email", {
                            ...ValidationRules.email,
                        })}
                    />
                    {error && <FormError message={error} />}
                </FormBody>
                <FormActions variant="plain">
                    <PrimaryButton type="submit" fullWidth size="large" loading={loading}>
                        Send Code
                    </PrimaryButton>
                </FormActions>
            </ShadowCard>
        </Form>
    );
};

export default ResetPasswordContent;
