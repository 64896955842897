import {
    Container as ItemContainer,
    Content as ItemContent,
} from "@fm-frontend/uikit/src/components/dropdowns/common/Item";
import { Checkmark } from "@fm-frontend/uikit/src/components/icons/styled";
import { FC } from "react";
import styled, { css, useTheme } from "styled-components";
import { ALL_CLIENTS_VALUE } from "~components/Layout/Menu/ClientSelect/constants";

const OverviewContainer = styled.div`
    padding: 0 12px;
    width: 100%;
`;

const OverviewButton = styled.button`
    background: ${(p) => p.theme.colors.ui8};
    border-radius: 8px;
    cursor: pointer;
    font-weight: 600;
    font-size: 14px;
    line-height: 32px;
    width: 100%;
`;

const SelectedItem = styled(ItemContainer)`
    border-radius: 0;
    box-shadow: inset 0px -1px 0px ${(p) => p.theme.colors.ui8};
    min-height: 44px;
    padding: 0 12px;

    ${(p) =>
        p.isActive &&
        css`
            color: ${p.theme.colors.uiWhite100};
            background: ${p.theme.colors.ui100};

            :hover {
                background: ${p.theme.colors.ui96} !important;
            }
        `}
`;

const Name = styled(ItemContent)`
    flex: 1;
    font-weight: 600;
    font-size: 14px;
    white-space: pre-wrap;
`;

type AllClientsProps = {
    selected: boolean;
    onSelected: () => void;
};

const AllClients: FC<AllClientsProps> = ({ selected, onSelected }) => {
    const { colors } = useTheme();

    if (selected) {
        return (
            <SelectedItem isActive>
                <Name>{ALL_CLIENTS_VALUE}</Name>
                <Checkmark color={colors.uiWhite100} />
            </SelectedItem>
        );
    }

    return (
        <OverviewContainer>
            <OverviewButton onClick={onSelected}>Overview “{ALL_CLIENTS_VALUE}”</OverviewButton>
        </OverviewContainer>
    );
};

export default AllClients;
