import {
    FieldGroup,
    Form,
    FormActions,
    FormBody,
    Header as FormHeader,
    HeaderTitle,
    SimpleInput,
} from "@fm-frontend/uikit";
import { SingleDropdown, Switch } from "@fm-frontend/uikit/src/components/v2";
import { ValueParse } from "@fm-frontend/utils";
import { FC, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import styled from "styled-components";
import FormError from "~components/FormError";
import { PrimaryButton } from "~components/PrimaryButton";
import { ERROR_MESSAGES } from "~constants/errors";
import { ValidateErrors, ValidateRules } from "~constants/form";
import { ClientType } from "~entities/client";
import useNotifications from "~hooks/useNotifications";
import { requestAdminery } from "~utils/api";
import { getClientTypeTitle } from "~utils/getClientTypeTitle";
import { prepareRequestError } from "~utils/prepareRequestError";

const TYPE_OPTIONS = [
    { text: getClientTypeTitle(ClientType.Taker), value: ClientType.Taker },
    { text: getClientTypeTitle(ClientType.Maker), value: ClientType.Maker },
    { text: getClientTypeTitle(ClientType.PrimeBroker), value: ClientType.PrimeBroker },
];

const FormContainer = styled(Form)`
    width: 100%;
    min-width: initial;
    padding: 8px;
`;

type InviteFormType = {
    name: string;
    email: string;
    type: string;
    takerMarkup: string;
    makerFee: string;
    fineryShare: string;
    tncForSubaccounts: boolean;
};

type InviteFormProps = {
    referralEmail?: string;
    referralType?: ClientType;
    onSent: () => void;
};

const InviteForm: FC<InviteFormProps> = ({ onSent, referralEmail, referralType }) => {
    const { showSuccessNotification } = useNotifications();
    const {
        control,
        register,
        watch,
        setFocus,
        resetField,
        formState: { errors },
        handleSubmit,
    } = useForm<InviteFormType>({
        defaultValues: {
            type: referralType ?? ClientType.Taker,
            email: referralEmail,
            makerFee: "0.0",
            fineryShare: "0.0",
            takerMarkup: "0.0",
            tncForSubaccounts: true,
        },
    });
    const type = watch("type");
    const [loading, setLoading] = useState(false);
    const [submitError, setSubmitError] = useState<string>();

    useEffect(() => {
        setFocus("name");
        if (type === ClientType.Taker) {
            resetField("makerFee");
            resetField("fineryShare");
        }
        if (type === ClientType.Maker || type === ClientType.PrimeBroker) {
            resetField("takerMarkup");
        }
    }, [type, resetField, setFocus]);

    const handleInviteSubmit = handleSubmit(async (data) => {
        try {
            setSubmitError(undefined);
            setLoading(true);

            const response = await requestAdminery("sendInviteLink", {
                name: data.name,
                email: data.email,
                type: data.type,
                takerMarkup: data.takerMarkup ? ValueParse.deltaRate(data.takerMarkup) : 0,
                makerFee: data.makerFee ? ValueParse.deltaRate(data.makerFee) : undefined,
                fineryShare: data.fineryShare ? ValueParse.u32(data.fineryShare) : undefined,
                ...(data.type === ClientType.PrimeBroker && {
                    subsFmRelationType: data.tncForSubaccounts ? "direct" : "indirect",
                }),
            });

            if (!response?.error) {
                onSent();
                showSuccessNotification("Invite was successfully sent");
            }
            if (response?.error) {
                setSubmitError(ERROR_MESSAGES[response.error] ?? response.error);
            }
        } catch (e) {
            setSubmitError(prepareRequestError(e));
        } finally {
            setLoading(false);
        }
    });

    return (
        <FormContainer onSubmit={handleInviteSubmit}>
            <FormHeader>
                <HeaderTitle title="Invite new client" />
            </FormHeader>
            <FormBody alignItems="stretch">
                <FieldGroup>
                    <Controller
                        control={control}
                        render={({ field }) => (
                            <SingleDropdown
                                value={field.value}
                                onChange={field.onChange}
                                renderTrigger={(trigger) => (
                                    <SingleDropdown.Trigger
                                        {...trigger}
                                        size="large"
                                        variant="simple"
                                    >
                                        <SingleDropdown.TriggerEssence
                                            {...trigger}
                                            option={trigger.selectedOption}
                                            size="large"
                                        />
                                    </SingleDropdown.Trigger>
                                )}
                                options={TYPE_OPTIONS}
                                caption="Type"
                                fullWidth
                                align="end"
                            >
                                <SingleDropdown.BasicSheet size="large" options={TYPE_OPTIONS} />
                            </SingleDropdown>
                        )}
                        name="type"
                    />
                </FieldGroup>
                <FieldGroup>
                    <SimpleInput
                        data-1pignore
                        label="Client name"
                        placeholder="Finch Trade"
                        error={errors.name?.message}
                        {...register("name", {
                            required: ValidateErrors.required,
                            validate: { name: ValidateRules.clientName },
                        })}
                    />
                    <SimpleInput
                        data-1pignore
                        label="Email"
                        placeholder="hello@finchtrade.com"
                        error={errors.email?.message}
                        {...register("email", {
                            required: ValidateErrors.required,
                            validate: { email: ValidateRules.email },
                        })}
                    />
                </FieldGroup>
                {type === ClientType.Taker && (
                    <FieldGroup>
                        <SimpleInput
                            label="Taker Markup"
                            placeholder="0.100000"
                            error={errors.takerMarkup?.message}
                            {...register("takerMarkup", {
                                required: ValidateErrors.required,
                                max: {
                                    value: 0.1,
                                    message: ValidateErrors.max(0.1),
                                },
                                min: {
                                    value: -0.1,
                                    message: ValidateErrors.min(-0.1),
                                },
                                validate: { deltaRate: ValidateRules.deltaRate },
                            })}
                        />
                    </FieldGroup>
                )}
                {(type === ClientType.Maker || type === ClientType.PrimeBroker) && (
                    <FieldGroup>
                        <SimpleInput
                            label="License fee"
                            placeholder="0.100000"
                            error={errors.makerFee?.message}
                            {...register("makerFee", {
                                required: ValidateErrors.required,
                                validate: { deltaRate: ValidateRules.deltaRate },
                            })}
                        />
                        <SimpleInput
                            label="FM markup share"
                            placeholder="100000.00"
                            error={errors.fineryShare?.message}
                            {...register("fineryShare", {
                                required: ValidateErrors.required,
                                validate: { u32: ValidateRules.u32 },
                            })}
                        />
                    </FieldGroup>
                )}

                {type === ClientType.PrimeBroker && (
                    <Controller
                        control={control}
                        render={({ field }) => (
                            <Switch
                                size="large"
                                variant="simple"
                                checked={field.value}
                                onChange={field.onChange}
                                fullWidth
                                innerCaption="T&C for sub-accounts"
                            />
                        )}
                        name="tncForSubaccounts"
                    />
                )}
                {submitError && <FormError message={submitError} />}
            </FormBody>
            <FormActions variant="plain">
                <PrimaryButton size="large" fullWidth loading={loading}>
                    Send invitation
                </PrimaryButton>
            </FormActions>
        </FormContainer>
    );
};

export default InviteForm;
