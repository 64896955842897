import { CognitoUser } from "amazon-cognito-identity-js";
import { Auth } from "aws-amplify";
import { useState } from "react";
import { AuthState, ChallengeName } from "~pages/Auth/consts";

const useCompleteNewPassword = (userState: CognitoUser | undefined) => {
    const [loading, setLoading] = useState<boolean>(false);

    const completeNewPassword = (password: string) => {
        setLoading(true);

        return new Promise<AuthState>((resolve, reject) => {
            Auth.completeNewPassword(userState, password)
                .then((user: CognitoUser) => {
                    if (user.challengeName === ChallengeName.MfaSetup) {
                        resolve(AuthState.SetupMfaCode);
                    } else {
                        resolve(AuthState.Login);
                    }
                })
                .catch(reject)
                .finally(() => setLoading(false));
        });
    };

    return { completeNewPassword, loading };
};

export default useCompleteNewPassword;
