import {
    BasicButton,
    DesctructiveButton,
    Form,
    Header,
    HeaderTitle,
    HStack,
    LinkButton,
    PSmallBold,
    Search,
    VStack,
} from "@fm-frontend/uikit";
import { EmptySearch } from "@fm-frontend/uikit/src/components/common/EmptySearch";
import { useToggle } from "@fm-frontend/utils";
import React, { useCallback, useMemo, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import styled from "styled-components";
import { PlainButton } from "~components/PlainButton";
import { PrimaryButton } from "~components/PrimaryButton";
import { useInstrumentsWhitelist } from "~hooks/api/useInstrumentsWhitelist";
import useAppSelector from "~hooks/useAppSelector";
import { useClientId } from "~hooks/useClientId";
import { CheckItem } from "~pages/RisksManagement/components/EditInstrumentsWhitelistForm/CheckItem";
import {
    CheckItemValue,
    InputsValue,
} from "~pages/RisksManagement/components/EditInstrumentsWhitelistForm/types";
import { getClient } from "~store/clients/selectors";
import { requestAdminery } from "~utils/api";

const FormContainer = styled(Form)`
    overflow: auto;
    width: 100%;
    min-width: initial;
    max-width: unset;
    height: 100%;
    gap: unset;
`;

const FormHeader = styled(Header)`
    align-items: center;
    padding: 12px;
`;

const FormCheckItemListSection = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    overflow: auto;
    padding: 4px 8px;
    border-top: 1px solid ${(p) => p.theme.colors.ui8};

    > * {
        width: 50%;
    }
`;

const EmptySearchSection = styled.div`
    width: 100%;
    padding: 4px 8px;
`;

const ActionContainer = styled(HStack)`
    gap: 10px;

    button {
        color: ${(p) => p.theme.colors.ui32};

        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: -0.06px;
    }
`;

const FormActionsContainer = styled(VStack)`
    width: 100%;
    gap: 8px;
    padding: 16px 12px;

    border-top: 1px solid ${(p) => p.theme.colors.ui8};
`;

const FormSearchSection = styled(VStack)`
    padding: 0 12px 8px;
`;

const FormInfoSection = styled(HStack)`
    justify-content: space-between;
    align-items: center;
    padding: 8px 12px;
`;

const SelectedItemsCounter = styled(PSmallBold)`
    color: ${(p) => p.theme.colors.brand100};
`;

const generateCheckedValueList = (items: CheckItemValue[], isInWhitelist: boolean) =>
    items.map((value) => ({
        ...value,
        isInWhitelist,
    }));
const countSelectedInstruments = (instrumentWatch: CheckItemValue[]) =>
    instrumentWatch.reduce((acc, curr) => acc + (curr.isInWhitelist ? 1 : 0), 0);

const filterCallback = (value: string, query: string) =>
    value.toUpperCase().includes(query.toUpperCase());

type EditInstrumentsWhitelistFormProps = {
    onDone: () => void;
    loading: boolean;
    formData: CheckItemValue[];
    whitelistEnabled: boolean;
};

export const EditInstrumentsWhitelistForm: React.FC<EditInstrumentsWhitelistFormProps> = ({
    onDone,
    loading,
    formData,
    whitelistEnabled,
}) => {
    const getClientById = useAppSelector(getClient);
    const clientId = useClientId();
    const client = getClientById(clientId);
    const { mutate: mutateInstrumentWhitelistData } = useInstrumentsWhitelist(clientId);
    const {
        isActive: isActionSubmitting,
        activate: activateActionSubmitting,
        deactivate: deactivateActionSubmitting,
    } = useToggle(false);
    const [query, setQuery] = useState("");

    const {
        control,
        watch,
        handleSubmit,
        formState: { isSubmitting, isDirty },
    } = useForm<InputsValue>({
        defaultValues: {
            instruments: formData,
        },
    });
    const { fields, replace } = useFieldArray<InputsValue>({
        control,
        name: "instruments",
    });

    const filteredInstruments = fields
        .map((field, index) => ({ instrument: field, index }))
        .filter((item) => filterCallback(item.instrument.name, query))
        .sort((itemA, itemB) => itemA.instrument.name.localeCompare(itemB.instrument.name));
    const empty = filteredInstruments.length === 0;

    const isLoading = isSubmitting || loading || isActionSubmitting;
    const instrumentsWatch = watch();

    const selectedCount = useMemo(
        () => countSelectedInstruments(instrumentsWatch.instruments),
        [instrumentsWatch],
    );

    const handleSelectAll = useCallback(() => {
        const allSelected = generateCheckedValueList(formData, true);
        replace(allSelected);
    }, [formData, replace]);

    const handleDeselectAll = useCallback(() => {
        const allDeselected = generateCheckedValueList(formData, false);
        replace(allDeselected);
    }, [formData, replace]);

    const handleEnableWhitelist = async () => {
        activateActionSubmitting();

        await requestAdminery("enableInstrumentsWhitelist", {
            clientId,
        });
        await mutateInstrumentWhitelistData();

        deactivateActionSubmitting();
    };
    const handleDisableWhitelist = async () => {
        activateActionSubmitting();

        await requestAdminery("disableInstrumentsWhitelist", {
            clientId,
        });
        await mutateInstrumentWhitelistData();

        deactivateActionSubmitting();
        onDone();
    };
    const handleEditInstrumentWhitelistSubmit = handleSubmit(
        async ({ instruments: newInstrumentsWhitelist }) => {
            const whitelist = newInstrumentsWhitelist
                .filter((i) => i.isInWhitelist)
                .map((i) => i.name);

            await requestAdminery("setInstrumentsWhitelist", {
                clientId,
                instruments: whitelist,
            });
            await mutateInstrumentWhitelistData();
            onDone();
        },
    );

    return (
        <FormContainer onSubmit={handleEditInstrumentWhitelistSubmit}>
            <FormHeader>
                <HeaderTitle
                    title={
                        whitelistEnabled
                            ? "Instruments whitelist enabled"
                            : "Instruments whitelist disabled"
                    }
                    label={`${client?.username} (${clientId}) setup for Everyone`}
                />
            </FormHeader>
            {whitelistEnabled && (
                <>
                    <FormSearchSection>
                        <Search
                            placeholder="Search instrument"
                            query={query}
                            onChange={setQuery}
                            size="large"
                        />
                    </FormSearchSection>
                    <FormInfoSection>
                        <SelectedItemsCounter>{selectedCount} selected</SelectedItemsCounter>
                        <ActionContainer>
                            <LinkButton
                                disabled={isLoading}
                                type="button"
                                size="small"
                                onClick={handleSelectAll}
                            >
                                Select all
                            </LinkButton>
                            <LinkButton
                                disabled={isLoading}
                                type="button"
                                size="small"
                                onClick={handleDeselectAll}
                            >
                                Deselect all
                            </LinkButton>
                        </ActionContainer>
                    </FormInfoSection>
                    {!empty && (
                        <FormCheckItemListSection>
                            {filteredInstruments.map(({ instrument, index }) => (
                                <CheckItem
                                    disabled={isLoading}
                                    key={instrument.id}
                                    name={instrument.name}
                                    id={instrument.id}
                                    control={control}
                                    index={index}
                                    query={query}
                                />
                            ))}
                        </FormCheckItemListSection>
                    )}
                    {empty && (
                        <EmptySearchSection>
                            <EmptySearch />
                        </EmptySearchSection>
                    )}
                    <FormActionsContainer>
                        <PrimaryButton
                            disabled={!isDirty}
                            loading={isLoading}
                            type="submit"
                            fullWidth
                            size="large"
                        >
                            Apply whitelist
                        </PrimaryButton>
                        <DesctructiveButton
                            loading={isLoading}
                            type="button"
                            fullWidth
                            size="large"
                            onClick={handleDisableWhitelist}
                        >
                            Disable whitelist
                        </DesctructiveButton>
                    </FormActionsContainer>
                </>
            )}
            {!whitelistEnabled && (
                <FormActionsContainer>
                    <BasicButton
                        loading={isLoading}
                        type="button"
                        fullWidth
                        size="large"
                        onClick={handleEnableWhitelist}
                    >
                        Enable whitelist
                    </BasicButton>
                    <PlainButton
                        loading={isLoading}
                        type="button"
                        fullWidth
                        size="large"
                        onClick={onDone}
                    >
                        Cancel
                    </PlainButton>
                </FormActionsContainer>
            )}
        </FormContainer>
    );
};
