import { FC } from "react";
import { ClientType } from "~entities/client";
import { getClientTypeTitle } from "~utils/getClientTypeTitle";

type ClientTypeProps = {
    value: ClientType;
};

const ClientTypeTitle: FC<ClientTypeProps> = ({ value }) => {
    return <>{getClientTypeTitle(value)}</>;
};

export default ClientTypeTitle;
