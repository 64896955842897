import { IconButton, Icons } from "@fm-frontend/uikit";
import { FC } from "react";
import ModalButton from "~components/ModalButton";
import { ResetCredentialsModal } from "~components/ResetCredentialsModal";
import { User } from "~entities/user";

type ActionCellProps = {
    user: User;
};

const ActionsCell: FC<ActionCellProps> = ({ user }) => {
    if (!user.owner) {
        return null;
    }

    return (
        <ModalButton
            renderModalContent={(closeModal) => (
                <ResetCredentialsModal user={user} onClose={closeModal} />
            )}
            renderButton={(handleClick) => (
                <IconButton
                    title="Reset credentials"
                    variant="basic"
                    Icon={Icons.Reload}
                    onClick={handleClick}
                />
            )}
        />
    );
};

export default ActionsCell;
