import {
    BasicInput,
    Form,
    FormActions,
    FormBody,
    Header as FormHeader,
    HeaderTitle,
    ShadowCard,
} from "@fm-frontend/uikit";
import { FC, useContext, useState } from "react";
import { useForm } from "react-hook-form";
import FormError from "~components/FormError";
import { PrimaryButton } from "~components/PrimaryButton";
import useAppDispatch from "~hooks/useAppDispatch";
import { AuthContext } from "~pages/Auth/context";
import useConfirmSignIn from "~pages/Auth/hooks/useConfirmSignIn";
import { ValidationRules } from "~pages/Auth/utils";
import { refreshAuthenticatedUser } from "~store/user/actions";

type ConfirmMfaCodeForm = {
    code: string;
};

const ConfirmMfaCodeContent: FC = () => {
    const { userState } = useContext(AuthContext);
    const { confirmSignIn, loading } = useConfirmSignIn(userState);
    const dispatch = useAppDispatch();
    const [error, setError] = useState<string>();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<ConfirmMfaCodeForm>({
        mode: "onChange",
    });

    const handleFormSubmit = handleSubmit(({ code }) => {
        confirmSignIn(code)
            .then(() => dispatch(refreshAuthenticatedUser()))
            .catch((e) => setError(e?.message));
    });

    return (
        <Form onSubmit={handleFormSubmit}>
            <ShadowCard>
                <FormHeader>
                    <HeaderTitle title="Confirm 2FA Code" />
                </FormHeader>
                <FormBody>
                    <BasicInput
                        autoFocus
                        type="number"
                        label="Code"
                        placeholder="000000"
                        error={errors.code?.message}
                        {...register("code", {
                            ...ValidationRules.code,
                        })}
                    />
                    {error && <FormError message={error} />}
                </FormBody>
                <FormActions variant="plain">
                    <PrimaryButton type="submit" fullWidth size="large" loading={loading}>
                        Confirm
                    </PrimaryButton>
                </FormActions>
            </ShadowCard>
        </Form>
    );
};

export default ConfirmMfaCodeContent;
