import { EmptySearch } from "@fm-frontend/uikit/src/components/common/EmptySearch";
import { FC, memo, useState } from "react";
import styled from "styled-components";
import Loading from "~components/Table/Loading";
import { Instrument, InstrumentName } from "~entities/instrument";
import { useInstrumentsApi } from "~hooks/api/useInstrumentsApi";
import { Item } from "~pages/OrderBook/InstrumentSelector/Item";
import Search from "~pages/OrderBook/InstrumentSelector/Search";

const Content = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: auto;
    width: 270px;
`;

const ScrollArea = styled.div`
    overflow: scroll;
`;

const EmptyContainer = styled.div`
    padding: 15px 0;
`;

const isMatch = (instrument: Instrument, query: string) => {
    const { name } = instrument;

    return name.toUpperCase().includes(query.toUpperCase());
};

type SelectorProps = {
    selected: InstrumentName | undefined;
    onSelect: (instrumentName: InstrumentName) => void;
};

const InstrumentSelector: FC<SelectorProps> = ({ selected, onSelect }) => {
    const [query, setQuery] = useState("");
    const { data: apiData, isLoading: instrumentsFetching } = useInstrumentsApi();
    const { instruments = [] } = apiData ?? {};

    const handleSearch = (value: string) => {
        setQuery(value);
    };

    const filteredInstruments =
        query === "" ? instruments : instruments.filter((instrument) => isMatch(instrument, query));

    return (
        <Content>
            <Search value={query} onChange={handleSearch} />
            <ScrollArea>
                {filteredInstruments.map((instrument) => (
                    <Item
                        key={instrument.id}
                        instrument={instrument}
                        active={instrument.name === selected}
                        query={query}
                        onSelect={onSelect}
                    />
                ))}
                {!instrumentsFetching && filteredInstruments.length === 0 && (
                    <EmptyContainer>
                        <EmptySearch description="Instrument not found" />
                    </EmptyContainer>
                )}
                {instrumentsFetching && <Loading />}
            </ScrollArea>
        </Content>
    );
};

export default memo(InstrumentSelector);
