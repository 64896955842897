import { ClientId } from "~entities/client";
import { Currency } from "~entities/currency";
import { Instrument } from "~entities/instrument";
import { Network } from "~entities/network";
import {
    Order,
    OrderCancelReason,
    OrderCreatedByType,
    OrderId,
    OrderSide,
    OrderType,
} from "~entities/order";

export type RawCurrencyEntity = [
    name: string,
    clientId: number,
    balanceStep: bigint | number,
    price: bigint | number,
    currencyType: string,
    networks: string[],
];

export const prepareCurrency = ([
    name,
    currencyId,
    balanceStep,
    price,
    currencyType,
    networks,
]: RawCurrencyEntity) =>
    ({
        name,
        currencyId,
        balanceStep,
        price,
        currencyType,
        networks,
    } as Currency);

export type RawInstrumentEntity = [
    name: string,
    id: number,
    assetCurrency: string,
    balanceCurrency: string,
];

export const prepareInstrument = ([
    name,
    id,
    assetCurrency,
    balanceCurrency,
]: RawInstrumentEntity) =>
    ({
        name,
        id,
        assetCurrency,
        balanceCurrency,
    } as Instrument);

export type RawNetworkEntity = [name: string, description: string];

export const prepareNetwork = ([name, description]: RawNetworkEntity) =>
    ({
        name,
        description,
    } as Network);

export type RawOrderEntity = [
    instrumentName: string,
    orderType: OrderType,
    side: OrderSide,
    cancelReason: OrderCancelReason,
    orderId: OrderId,
    orderClientId: ClientId,
    orderPrice: number | bigint,
    orderInitialSizeOrVolume: number | bigint,
    orderRemainingSizeOrVolume: number | bigint,
    orderCreatedAt: number,
    createdByType: OrderCreatedByType,
];

export const prepareOrder = ([
    instrumentName,
    orderType,
    side,
    cancelReason,
    orderId,
    orderClientId,
    orderPrice,
    orderInitialSizeOrVolume,
    orderRemainingSizeOrVolume,
    orderCreatedAt,
    createdByType,
]: RawOrderEntity) =>
    ({
        instrumentName,
        orderType,
        side,
        cancelReason,
        orderId,
        orderClientId,
        orderPrice,
        orderInitialSizeOrVolume,
        orderRemainingSizeOrVolume,
        orderCreatedAt,
        createdByType,
    } as Order);
