import { FC, useContext } from "react";
import MatchContains from "~components/Table/Cell/MatchContains";
import { TableContext } from "~components/Table/TableContext";

type InstrumentCurrencyProps = {
    value: string;
};

const InstrumentCurrency: FC<InstrumentCurrencyProps> = ({ value }) => {
    const { query } = useContext(TableContext);

    return <MatchContains value={value} query={query} />;
};

export default InstrumentCurrency;
