import { CurrencyIcon } from "@fm-frontend/uikit";
import { FC } from "react";
import styled from "styled-components";

const ICON_DISTANCE = 5;

const IconsContainer = styled.div<{ $size: number }>`
    position: relative;
    height: ${(p) => p.$size}px;
    width: ${(p) => p.$size + ICON_DISTANCE * 2}px;

    img {
        position: absolute;

        :last-of-type {
            left: ${(p) => p.$size - ICON_DISTANCE}px;
        }
    }
`;

type InstrumentIconProps = {
    instrument: string;
    size: number;
};

export const InstrumentIcon: FC<InstrumentIconProps> = ({ instrument, size }) => {
    const [leftCurrency, rightCurrency] = instrument.split("-");

    return (
        <IconsContainer $size={size}>
            <CurrencyIcon name={leftCurrency} size={size} />
            <CurrencyIcon name={rightCurrency} size={size} />
        </IconsContainer>
    );
};
