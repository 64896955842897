export const Size = {
    Mobile: "640px",
    Tablet: "1064px",
};

export const Media = {
    Mobile: `@media (width <= ${Size.Mobile})`,
    Tablet: `@media (${Size.Mobile} < width <= ${Size.Tablet})`,
    Desktop: `@media (width > ${Size.Tablet})`,
};
