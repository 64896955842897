import useSWR from "swr";
import { ClientId } from "~entities/client";
import { SettlementTransaction } from "~entities/settlementTransaction";
import { requestAdminery } from "~utils/api";

type RawSettlementTransaction = [
    counterpartyId: ClientId,
    currency: string,
    amount: number | bigint,
    orderId: number,
    comment: string,
    createdAt: number,
    txId: string,
    sentAt: number,
    transactionFlags: number,
    transactionMoment: number,
    transactionId: number,
    networkFee: number | bigint,
    networkName: string,
];

const prepareData = ([
    counterpartyId,
    currency,
    amount,
    orderId,
    comment,
    createdAt,
    txId,
    sentAt,
    transactionFlags,
    transactionMoment,
    transactionId,
    networkFee,
    networkName,
]: RawSettlementTransaction): SettlementTransaction => ({
    counterpartyId,
    currency,
    amount,
    orderId,
    comment,
    createdAt,
    txId,
    sentAt,
    transactionFlags,
    transactionMoment,
    transactionId,
    networkFee,
    networkName,
});

type SWRKey = [clientId: ClientId, key: string];

const fetcher = ([clientId]: SWRKey) =>
    requestAdminery("settlementTransactions", { clientId }).then(([incoming, outgoing]) => ({
        incoming: incoming.map(prepareData),
        outgoing: outgoing.map(prepareData),
    }));

export const useSettlementTransactionsApi = (clientId: ClientId) =>
    useSWR<{ incoming: SettlementTransaction[]; outgoing: SettlementTransaction[] }>(
        [clientId, "useSettlementTransactionsApi"],
        fetcher,
    );
