import { useMemo } from "react";
import { PartnerSelectType, PartnerValueType } from "~components/PartnerSelect";
import { ClientId } from "~entities/client";
import { usePositionsApi } from "~hooks/api/usePositionsApi";
import useAppSelector from "~hooks/useAppSelector";
import { useSubaccountsIds } from "~hooks/useSubaccountsIds";
import { useUsdPrices } from "~hooks/useUsdPrices";
import { Item, ItemType, Tabs } from "~pages/OpenPositions/types";
import { isAssetMatch, isCounterpartyMatch } from "~pages/OpenPositions/utils";
import { getClient } from "~store/clients/selectors";

export const useTableData = (
    clientId: ClientId,
    partnerType: PartnerValueType,
    tab: string,
    query: string,
) => {
    const { data, isLoading } = usePositionsApi(clientId);
    const subaccountsIds = useSubaccountsIds(clientId);
    const { positions, settlementOrders } = data ?? {};
    const { prices } = useUsdPrices();
    const getClientById = useAppSelector(getClient);
    const groupedByCounterparties = tab === Tabs.Counterparties;

    const tableData = useMemo(() => {
        const groups = {} as Record<string, Item>;
        let totalPosition = 0n;
        let totalPlannedPosition = 0n;

        positions?.forEach((position) => {
            const { counterpartyId, currency, value } = position;
            const isSubaccount = subaccountsIds.includes(counterpartyId);

            if (partnerType === PartnerSelectType.Counterparties && isSubaccount) {
                return;
            }
            if (partnerType === PartnerSelectType.SubAccounts && !isSubaccount) {
                return;
            }

            const client = getClientById(counterpartyId);
            const { username: counterpartyName } = client ?? {};

            if (
                groupedByCounterparties &&
                !isCounterpartyMatch(counterpartyId, counterpartyName, query)
            ) {
                return;
            }
            if (!groupedByCounterparties && !isAssetMatch(currency, query)) {
                return;
            }

            const size = BigInt(value);
            const groupId = groupedByCounterparties ? counterpartyId : currency;
            const entryId = groupedByCounterparties ? currency : counterpartyId;

            if (groups[groupId] === undefined && groupedByCounterparties) {
                groups[groupId] = {
                    type: ItemType.Counterparty,
                    subject: { id: counterpartyId, name: counterpartyName },
                    positionUSD: 0n,
                    plannedPositionUSD: 0n,
                    items: {},
                };
            }
            if (groups[groupId] === undefined && !groupedByCounterparties) {
                groups[groupId] = {
                    type: ItemType.Currency,
                    subject: { name: currency },
                    positionUSD: 0n,
                    plannedPositionUSD: 0n,
                    items: {},
                };
            }

            const currencyPrice = prices[currency];

            if (!currencyPrice) {
                return;
            }

            if (groups[groupId].items[entryId] === undefined && groupedByCounterparties) {
                groups[groupId].items[entryId] = {
                    type: ItemType.Currency,
                    subject: { name: currency },
                    position: 0n,
                    positionUSD: 0n,
                    plannedPosition: 0n,
                    plannedPositionUSD: 0n,
                    items: {},
                };
            }
            if (groups[groupId].items[entryId] === undefined && !groupedByCounterparties) {
                groups[groupId].items[entryId] = {
                    type: ItemType.Counterparty,
                    subject: { id: counterpartyId, name: counterpartyName },
                    position: 0n,
                    positionUSD: 0n,
                    plannedPosition: 0n,
                    plannedPositionUSD: 0n,
                    items: {},
                };
            }

            groups[groupId].items[entryId].position += size;
            groups[groupId].items[entryId].plannedPosition += size;
            const valueUSD = (size * currencyPrice) / BigInt(1e8) || 0n;
            groups[groupId].items[entryId].positionUSD += valueUSD;
            groups[groupId].items[entryId].plannedPositionUSD += valueUSD;
            groups[groupId].positionUSD += valueUSD;
            groups[groupId].plannedPositionUSD += valueUSD;

            totalPosition += valueUSD;
            totalPlannedPosition += valueUSD;
        });

        settlementOrders?.forEach((settlementOrder) => {
            const { counterpartyId, currency1, currency2, size1, size2 } = settlementOrder;
            const isSubaccount = subaccountsIds.includes(counterpartyId);

            if (partnerType === PartnerSelectType.Counterparties && isSubaccount) {
                return;
            }
            if (partnerType === PartnerSelectType.SubAccounts && !isSubaccount) {
                return;
            }

            const currency = currency1 || currency2;
            const size = BigInt(size1 || size2);
            const currencyPrice = prices[currency];

            if (!currencyPrice) {
                return;
            }

            const client = getClientById(counterpartyId);
            const { username: counterpartyName } = client ?? {};
            const groupId = groupedByCounterparties ? counterpartyId : currency;
            const entryId = groupedByCounterparties ? currency : counterpartyId;

            if (
                groupedByCounterparties &&
                !isCounterpartyMatch(counterpartyId, counterpartyName, query)
            ) {
                return;
            }
            if (!groupedByCounterparties && !isAssetMatch(currency, query)) {
                return;
            }

            if (groups[groupId] === undefined && groupedByCounterparties) {
                groups[groupId] = {
                    type: ItemType.Counterparty,
                    subject: { id: counterpartyId, name: counterpartyName },
                    positionUSD: 0n,
                    plannedPositionUSD: 0n,
                    items: {},
                };
            }
            if (groups[groupId] === undefined && !groupedByCounterparties) {
                groups[groupId] = {
                    type: ItemType.Currency,
                    subject: { name: currency },
                    positionUSD: 0n,
                    plannedPositionUSD: 0n,
                    items: {},
                };
            }

            if (groups[groupId].items[entryId] === undefined && groupedByCounterparties) {
                groups[groupId].items[entryId] = {
                    type: ItemType.Currency,
                    subject: { name: currency },
                    position: 0n,
                    positionUSD: 0n,
                    plannedPosition: 0n,
                    plannedPositionUSD: 0n,
                    items: {},
                };
            }
            if (groups[groupId].items[entryId] === undefined && !groupedByCounterparties) {
                groups[groupId].items[entryId] = {
                    type: ItemType.Counterparty,
                    subject: { id: counterpartyId, name: counterpartyName },
                    position: 0n,
                    positionUSD: 0n,
                    plannedPosition: 0n,
                    plannedPositionUSD: 0n,
                    items: {},
                };
            }

            groups[groupId].items[entryId].plannedPosition += size;
            const valueUSD = (size * currencyPrice) / BigInt(1e8) || 0n;
            groups[groupId].items[entryId].plannedPositionUSD += valueUSD;
            groups[groupId].plannedPositionUSD += valueUSD;

            totalPlannedPosition += valueUSD;
        });

        return [
            {
                type: ItemType.Total,
                positionUSD: totalPosition,
                plannedPositionUSD: totalPlannedPosition,
                items: {},
            } as Item,
            ...Object.values(groups),
        ];
    }, [
        positions,
        settlementOrders,
        getClientById,
        groupedByCounterparties,
        subaccountsIds,
        partnerType,
        query,
        prices,
    ]);

    return {
        data: tableData,
        isLoading,
    };
};
