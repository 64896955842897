import { IconButton, Icons, ModalButton } from "@fm-frontend/uikit";
import { FC } from "react";
import { ClientType } from "~entities/client";
import { useReferralsApi } from "~hooks/api/useReferralsApi";
import InviteForm from "~pages/Overview/InviteForm";

type InviteActionProps = {
    referralEmail: string;
    referralType: ClientType;
};

export const InviteAction: FC<InviteActionProps> = ({ referralEmail, referralType }) => {
    const { mutate: mutateReferralsData } = useReferralsApi(undefined);

    return (
        <ModalButton
            beforeModalClose={mutateReferralsData}
            renderModalContent={(closePopover) => (
                <InviteForm
                    referralEmail={referralEmail}
                    referralType={referralType}
                    onSent={closePopover}
                />
            )}
            renderButton={(handleClick) => (
                <IconButton
                    title="Resend"
                    variant="plain"
                    Icon={Icons.Reload}
                    onClick={handleClick}
                />
            )}
        />
    );
};
