import { Icon, IconButton, Icons } from "@fm-frontend/uikit";
import { FC } from "react";
import { FallbackProps } from "react-error-boundary";
import styled from "styled-components";
import { Content } from "~components/Layout/Content";
import { ERROR_MESSAGES } from "~constants/errors";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px;
    gap: 16px;
`;

const Block = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;

const getErrorMessage = (error: unknown) => {
    if (error instanceof Error) {
        return error.message;
    }

    return error;
};

export const ErrorMessage: FC<FallbackProps> = ({ error, resetErrorBoundary }) => {
    const errorMessage = getErrorMessage(error);

    return (
        <Content>
            <Container>
                <Block>
                    <Icon name="error" size={16} />
                    Error: {ERROR_MESSAGES[Number(errorMessage)] ?? errorMessage}
                </Block>
                <Block>
                    <IconButton variant="plain" Icon={Icons.Reload} onClick={resetErrorBoundary} />
                </Block>
            </Container>
        </Content>
    );
};
