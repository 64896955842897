import { FC, ReactElement } from "react";
import styled from "styled-components";

const Wrapper = styled.div<{ $maxWidth: number }>`
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: ${(p) => p.$maxWidth}px;
`;

type EllipsisCellThemeProps = {
    children: ReactElement;
    maxWidth: number;
};

export const EllipsisCellTheme: FC<EllipsisCellThemeProps> = ({ children, maxWidth }) => (
    <Wrapper $maxWidth={maxWidth}>{children}</Wrapper>
);
