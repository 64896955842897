import { P } from "@fm-frontend/uikit";
import { EmDash, ValueFormat } from "@fm-frontend/utils";
import { FC } from "react";
import styled from "styled-components";

const PositiveIndicator = styled(P)`
    color: ${(p) => p.theme.colors.positive100};
`;
const NegativeIndicator = styled(P)`
    color: ${(p) => p.theme.colors.negative100};
`;

type DeltaCellProps = {
    value: undefined | number | bigint;
};

export const DeltaCell: FC<DeltaCellProps> = ({ value }) => {
    if (value === undefined) {
        return null;
    }

    const delta = BigInt(value);

    if (delta === 0n) {
        return <P>{EmDash}</P>;
    }

    if (delta < 0n) {
        return <NegativeIndicator>{ValueFormat.size(value)}</NegativeIndicator>;
    }

    return <PositiveIndicator>{ValueFormat.size(value)}</PositiveIndicator>;
};
