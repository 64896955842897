import { Search as BaseSearch } from "@fm-frontend/uikit/src/components/search";
import { FC, useEffect, useRef } from "react";
import styled from "styled-components";

const SearchContainer = styled.div`
    padding: 16px 12px;
    width: 100%;
`;

type SearchProps = {
    value: string;
    onChange: (value: string) => void;
};

const Search: FC<SearchProps> = ({ value, onChange }) => {
    const searchRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        searchRef.current?.focus();
    }, []);

    return (
        <SearchContainer>
            <BaseSearch ref={searchRef} query={value} onChange={onChange} size="large" />
        </SearchContainer>
    );
};

export default Search;
