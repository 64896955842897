import { FC, useMemo } from "react";
import styled from "styled-components";
import { Content as LayoutContent } from "~components/Layout/Content";
import { ClientType } from "~entities/client";
import useAppSelector from "~hooks/useAppSelector";
import { useClientId } from "~hooks/useClientId";
import { CounterpartiesRisksManagement } from "~pages/RisksManagement/List/CounterpartiesRisksManagement";
import { PrimeBrokerRisksManagement } from "~pages/RisksManagement/List/PrimeBrokerRisksManagement";
import { GlobalInstrumentsWhitelist } from "~pages/RisksManagement/View/GlobalInstrumentsWhitelist";
import { getClient } from "~store/clients/selectors";

const Container = styled.div`
    display: flex;
    flex: 1;
    gap: 12px;
`;

const SubMenu = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    background: ${(p) => p.theme.colors.uiWhite100};
    border-radius: 12px 12px 0 0;
    overflow: hidden;

    &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        box-shadow: 0 1px 2px rgba(36, 48, 52, 0.04), inset 0 -1px 0 rgba(36, 48, 52, 0.12),
            inset 0 0 0 1px rgba(36, 48, 52, 0.12);
        border-radius: inherit;
        pointer-events: none;
    }
`;

export const RisksManagementListPage: FC = () => {
    const getClientById = useAppSelector(getClient);
    const clientId = useClientId();
    const client = getClientById(clientId);

    const partnersLimitsContent = useMemo(() => {
        switch (client?.type) {
            case ClientType.PrimeBroker:
                return <PrimeBrokerRisksManagement />;
            case ClientType.Maker:
            case ClientType.Taker:
            case ClientType.SubaccountMaker:
            case ClientType.SubaccountTaker:
                return <CounterpartiesRisksManagement />;
            default:
                return null;
        }
    }, [client?.type]);

    return (
        <Container>
            <SubMenu>
                <GlobalInstrumentsWhitelist />
            </SubMenu>
            <LayoutContent>{partnersLimitsContent}</LayoutContent>
        </Container>
    );
};
