import { P } from "@fm-frontend/uikit";
import { FC, useContext } from "react";
import MatchContains from "~components/Table/Cell/MatchContains";
import { TableContext } from "~components/Table/TableContext";

type TxIdCellProps = {
    value: string | undefined;
};

export const TxIdCell: FC<TxIdCellProps> = ({ value }) => {
    const { query } = useContext(TableContext);

    return (
        <P title={value}>
            <MatchContains value={value ?? ""} query={query} />
        </P>
    );
};
