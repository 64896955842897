import { P } from "@fm-frontend/uikit";
import { createColumnHelper } from "@tanstack/react-table";
import styled from "styled-components";
import { getExpandColumn as getGenericExpandColumn } from "~components/Table/Column/expand";
import { ActionsCell } from "~pages/RisksManagement/View/InstrumentsList/ActionsCell";
import { InstrumentCell } from "~pages/RisksManagement/View/InstrumentsList/InstrumentCell";
import { Item, RowType, SubItem } from "~pages/RisksManagement/View/InstrumentsList/types";
import { WhitelistCell } from "~pages/RisksManagement/View/InstrumentsList/WhitelistCell";

const InfoTitle = styled(P)`
    color: ${(p) => p.theme.colors.ui100};
`;

const InfoValue = styled(P)`
    color: ${(p) => p.theme.colors.ui72};
`;

const columnHelper = createColumnHelper<Item | SubItem>();

export const getExpandColumn = () => getGenericExpandColumn(columnHelper);

export const getTitleColumn = () =>
    columnHelper.accessor("instrument", {
        header: "",
        cell: (info) => {
            const { type, instrument, clientData, counterpartyData } = info.row.original;

            return type === RowType.Instrument ? (
                <InstrumentCell
                    instrument={instrument}
                    isWhitelisted={Boolean(clientData && counterpartyData)}
                />
            ) : (
                <InfoTitle>{info.getValue()}</InfoTitle>
            );
        },
        meta: {
            headerStyleProps: {
                width: "213px",
            },
        },
    });

export const getClientColumn = (header = "clientValue") =>
    columnHelper.accessor("clientData", {
        header,
        cell: (info) =>
            info.row.original.type === RowType.Instrument ? (
                <WhitelistCell isWhitelisted={Boolean(info.getValue())} />
            ) : (
                <InfoValue>{info.getValue()}</InfoValue>
            ),
        meta: {
            headerStyleProps: {
                width: "285px",
                textAlign: "right",
            },
            cellStyleProps: {
                textAlign: "right",
            },
        },
    });

export const getCounterpartyColumn = (header = "counterpartyValue") =>
    columnHelper.accessor("counterpartyData", {
        header,
        cell: (info) =>
            info.row.original.type === RowType.Instrument ? (
                <WhitelistCell isWhitelisted={Boolean(info.getValue())} />
            ) : (
                <InfoValue>{info.getValue()}</InfoValue>
            ),
        meta: {
            headerStyleProps: {
                width: "285px",
                textAlign: "right",
            },
            cellStyleProps: {
                textAlign: "right",
            },
        },
    });

export const getExtraColumn = () =>
    columnHelper.display({
        id: "extraColumn",
        header: "",
        cell: (info) => <ActionsCell item={info.row.original} />,
    });
