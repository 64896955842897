import { Badge } from "@fm-frontend/uikit";
import { FC, useContext } from "react";
import { Referral } from "~entities/referral";
import { ReferralsTableContext } from "~pages/Referrals/Context";
import {
    findInviteStatus,
    getInviteStatusBadgeVariant,
    getInviteStatusTitle,
    getReferralStatusBadgeVariant,
    getReferralStatusTitle,
} from "~pages/Referrals/utils";

type StatusCellProps = {
    value: Referral;
};

export const StatusCell: FC<StatusCellProps> = ({ value }) => {
    const { onboardingStatuses } = useContext(ReferralsTableContext);
    const { clientId, status: referralStatus } = value;
    const inviteStatus = findInviteStatus(onboardingStatuses, clientId);

    if (inviteStatus !== null) {
        return (
            <Badge
                variant={getInviteStatusBadgeVariant(inviteStatus)}
                value={getInviteStatusTitle(inviteStatus)}
            />
        );
    }

    return (
        <Badge
            variant={getReferralStatusBadgeVariant(referralStatus)}
            value={getReferralStatusTitle(referralStatus)}
        />
    );
};
