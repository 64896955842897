import { ClientType } from "~entities/client";

export const getSubaccountTypeTitle = (type: ClientType) => {
    switch (type) {
        case ClientType.Maker:
            return "Maker";
        case ClientType.Taker:
            return "Taker";
        case ClientType.PrimeBroker:
            return "Master";
        default:
            return "";
    }
};
