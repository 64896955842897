import { FC } from "react";
import styled from "styled-components";

const Container = styled.div`
    height: 24px;
    background: url("/images/swap.svg") no-repeat center left 8px;
    border: 1px solid ${(p) => p.theme.colors.ui8};
    border-radius: 12px;
    padding: 0 10px 0 30px;
    font-size: 12px;
    line-height: 22px;
    white-space: nowrap;
`;

const SpreadValue = styled.span`
    font-weight: 600;
`;

const FRACTIONAL_RATIO = 10000000;

type SpreadProps = {
    bidPrice: number | bigint | undefined;
    askPrice: number | bigint | undefined;
};

export const Spread: FC<SpreadProps> = ({ bidPrice, askPrice }) => {
    if (askPrice === undefined || bidPrice === undefined) {
        return (
            <Container>
                Spread: <SpreadValue>–</SpreadValue>
            </Container>
        );
    }

    const midpoint = (BigInt(askPrice) + BigInt(bidPrice)) / 2n;
    const diff = BigInt(askPrice) - BigInt(bidPrice);
    const quotedSpread =
        (Number((diff * BigInt(FRACTIONAL_RATIO)) / midpoint) / FRACTIONAL_RATIO) * 100;

    return (
        <Container>
            Spread: <SpreadValue>{Number(quotedSpread).toFixed(4)}%</SpreadValue>
        </Container>
    );
};
