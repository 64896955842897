import { FC, useContext } from "react";
import MatchContains from "~components/Table/Cell/MatchContains";
import { TableContext } from "~components/Table/TableContext";

type HRPSymbolProps = {
    value?: string;
};

export const HRPSymbol: FC<HRPSymbolProps> = ({ value = "" }) => {
    const { query } = useContext(TableContext);

    return <MatchContains value={value} query={query} />;
};
