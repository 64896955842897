import { TabContext, TabPanel } from "@fm-frontend/uikit";
import { FC, useState } from "react";
import { Assets } from "~pages/AssetsAndInstruments/Assets";
import { Instruments } from "~pages/AssetsAndInstruments/Instruments";
import { Networks } from "~pages/AssetsAndInstruments/Networks";
import { Tabs } from "~pages/AssetsAndInstruments/TabList";

export const AssetsAndInstrumentsPage: FC = () => {
    const [tab, setTab] = useState<string>(Tabs.Assets);

    return (
        <TabContext value={tab} handleClick={setTab}>
            <TabPanel value={Tabs.Assets} overflow>
                <Assets />
            </TabPanel>
            <TabPanel value={Tabs.Networks} overflow>
                <Networks />
            </TabPanel>
            <TabPanel value={Tabs.Instruments} overflow>
                <Instruments />
            </TabPanel>
        </TabContext>
    );
};
