import { ValueFormat } from "@fm-frontend/utils";
import { format } from "date-fns";
import { useContext } from "react";
import { DATE_TIME_FORMAT } from "~constants/date";
import { Client } from "~entities/client";
import useAppSelector from "~hooks/useAppSelector";
import { ProfileContext } from "~pages/Profile/Context";
import { isSigned } from "~pages/Profile/utils";
import { getClient } from "~store/clients/selectors";
import { getClientTypeTitle } from "~utils/getClientTypeTitle";

const displayClientTags = (client: Client) => {
    return client.metadata?.tags?.join(", ");
};

const useCommonExportData = () => {
    const { client, clientInfo, hrpId, authorizedTrading } = useContext(ProfileContext);
    const getClientById = useAppSelector(getClient);
    const { primeBrokerId } = clientInfo ?? {};
    const hasMaster = primeBrokerId !== undefined && primeBrokerId !== 0;
    const whitelabel = client?.whitelabel || client?.subsWhitelabelName;

    return () => [
        { title: "", value: "" },
        { title: "Profile", value: "" },
        { title: "Display name", value: clientInfo?.username },
        { title: "Legal name", value: clientInfo?.companyName },
        { title: "Email", value: client?.email },
        { title: "Client ID", value: client?.id },
        ...(hasMaster
            ? [
                  {
                      title: "Master",
                      value: `${getClientById(primeBrokerId)?.username} (${primeBrokerId})`,
                  },
              ]
            : []),
        ...(whitelabel ? [{ title: "Whitelabel", value: whitelabel }] : []),
        { title: "Tags", value: client && displayClientTags(client) },
        { title: "Type", value: client && getClientTypeTitle(client.type) },
        {
            title: "Last trade",
            value: client?.lastTradeAt ? format(client.lastTradeAt * 1000, DATE_TIME_FORMAT) : "",
        },
        { title: "", value: "" },
        { title: "HRP ID", value: hrpId },
        { title: "Authrized trades", value: authorizedTrading ? "Yes" : "No" },
        { title: "", value: "" },
        { title: "Terms and conditions", value: "" },
        { title: "Status", value: isSigned(clientInfo) ? "Signed" : "Not Signed" },
        {
            title: "Date and time",
            value: clientInfo?.signedAt ? format(clientInfo.signedAt * 1000, DATE_TIME_FORMAT) : "",
        },
        { title: "Signatory name", value: clientInfo?.signatory },
        { title: "Position", value: clientInfo?.signatoryPosition },
        {
            title: "License fee",
            value: clientInfo && `${ValueFormat.deltaRate(clientInfo.makerFee)}%`,
        },
        { title: "FM markup share", value: clientInfo && `${clientInfo.fineryShare}%` },
    ];
};

export default useCommonExportData;
