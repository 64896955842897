import {
    FieldGroup,
    Form,
    FormActions,
    FormBody,
    Header as FormHeader,
    HeaderTitle,
    SimpleDropdown,
    SimpleInput,
} from "@fm-frontend/uikit";
import { EMPTY_ARRAY } from "@fm-frontend/uikit/src/const";
import { ValueParse } from "@fm-frontend/utils";
import { FC, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import FormError from "~components/FormError";
import { PrimaryButton } from "~components/PrimaryButton";
import { ERROR_MESSAGES } from "~constants/errors";
import { ValidateErrors, ValidateRules } from "~constants/form";
import { ClientType } from "~entities/client";
import { CounterpartyLimit } from "~entities/counterpartyLimit";
import { useClientsApi } from "~hooks/api/useClientsApi";
import { useCounterpartyLimitsApi } from "~hooks/api/useCounterpartyLimitsApi";
import { useInstrumentsApi } from "~hooks/api/useInstrumentsApi";
import useAppSelector from "~hooks/useAppSelector";
import { useClientId } from "~hooks/useClientId";
import useNotifications from "~hooks/useNotifications";
import {
    combineMarkupLabel,
    isMarginEditable,
    isMarkupEditable,
} from "~pages/RisksManagement/utils/limit";
import { getClient, getClients, isClientsFetching } from "~store/clients/selectors";
import { requestAdminery } from "~utils/api";
import { prepareRequestError } from "~utils/prepareRequestError";

const FormContainer = styled(Form)`
    width: 100%;
    min-width: initial;
    padding: 8px;
`;

type AddCounterpartyLimitFormType = {
    counterpartyId: string;
    currency: string;
    grossLimit: string;
    maintenanceMargin: string;
    restrictedTrading: string;
    initialMargin: string;
    takerMarkup: string;
};

type AddCounterpartyLimitFormProps = {
    onDone: () => void;
};

export const AddCounterpartyLimitForm: FC<AddCounterpartyLimitFormProps> = ({ onDone }) => {
    useClientsApi();

    const isClientsLoading = useAppSelector(isClientsFetching);
    const clients = useAppSelector(getClients);
    const getClientById = useAppSelector(getClient);
    const { showSuccessNotification } = useNotifications();
    const clientId = useClientId();
    const client = getClientById(clientId);

    const {
        data: counterpartyLimitsData,
        isLoading: isCounterpartyLimitsLoading,
        mutate,
    } = useCounterpartyLimitsApi(clientId);

    const { data: instruments, isLoading: isInstrumentsLoading } = useInstrumentsApi();
    const { currencies = EMPTY_ARRAY } = instruments ?? {};

    const {
        control,
        register,
        setFocus,
        watch,
        formState: { isSubmitting, errors },
        handleSubmit,
    } = useForm<AddCounterpartyLimitFormType>({
        mode: "onSubmit",
        defaultValues: {
            counterpartyId: "",
            currency: "",
            grossLimit: "",
            maintenanceMargin: "0",
            restrictedTrading: "0",
            initialMargin: "0",
            takerMarkup: "0",
        },
    });
    const [submitError, setSubmitError] = useState<string>();

    const counterpartyId = Number(watch("counterpartyId"));
    const maintenanceMargin = Number(watch("maintenanceMargin"));
    const restrictedTrading = Number(watch("restrictedTrading"));
    const initialMargin = Number(watch("initialMargin"));

    const counterparty = getClientById(counterpartyId);

    const markupEditable = isMarkupEditable(client, counterparty);
    const marginEditable = isMarginEditable(client, counterparty);

    const requiredMargin = Boolean(maintenanceMargin || restrictedTrading || initialMargin);

    useEffect(() => {
        setFocus("grossLimit");
    }, [setFocus]);

    const counterpartiesOptions = useMemo(
        () =>
            clients
                .filter(({ type }) => {
                    switch (client?.type) {
                        case ClientType.Maker:
                            return type === ClientType.Taker || type === ClientType.PrimeBroker;
                        case ClientType.Taker:
                            return type === ClientType.Maker || type === ClientType.PrimeBroker;
                        case ClientType.PrimeBroker:
                            return true;
                        default:
                            return false;
                    }
                })
                .map(({ id, username }) => ({ text: `${username} (${id})`, value: String(id) }))
                .sort((a, b) => a.text.localeCompare(b.text)),
        [client?.type, clients],
    );
    const currenciesOptions = useMemo(
        () =>
            currencies
                .map(({ name }) => ({ text: name, value: name }))
                .sort((a, b) => a.text.localeCompare(b.text)),
        [currencies],
    );

    const handleModifySubmit = handleSubmit(async (data) => {
        try {
            setSubmitError(undefined);

            const clientResponse = await requestAdminery("setCLimit", {
                clientId,
                counterpartyId: Number(data.counterpartyId),
                currency: data.currency,
                grossLimit: ValueParse.size(data.grossLimit),
                ...(marginEditable && {
                    maintenanceMargin: ValueParse.percent(data.maintenanceMargin),
                }),
                ...(marginEditable && {
                    restrictedTrading: ValueParse.percent(data.restrictedTrading),
                }),
                ...(marginEditable && {
                    initialMargin: ValueParse.percent(data.initialMargin),
                }),
                ...(markupEditable && {
                    takerMarkup: ValueParse.percent(data.takerMarkup),
                }),
            });
            const error = clientResponse?.error;

            if (!error) {
                await mutate([
                    ...(counterpartyLimitsData ?? []),
                    {
                        clientId,
                        counterpartyId: Number(data.counterpartyId),
                        currencyName: data.currency,
                        grossLimit: ValueParse.size(data.grossLimit),
                        mutualLimitCurrencyName: null,
                        mutualGrossLimit: null,
                        grossLimitUtilisation: 0n,
                        equity: 0n,
                        ...(marginEditable && {
                            maintenanceMargin: ValueParse.percent(data.maintenanceMargin),
                        }),
                        ...(marginEditable && {
                            restrictedTrading: ValueParse.percent(data.restrictedTrading),
                        }),
                        ...(marginEditable && {
                            initialMargin: ValueParse.percent(data.initialMargin),
                        }),
                        ...(markupEditable && {
                            takerMarkup: ValueParse.percent(data.takerMarkup),
                        }),
                        tradingDisabledFlags: 0,
                    } as CounterpartyLimit,
                ]);
                onDone();
                showSuccessNotification(`Counterparty limit was successfully added`);
            }
            if (error) {
                setSubmitError(ERROR_MESSAGES[error] ?? error);
            }
        } catch (e) {
            setSubmitError(prepareRequestError(e));
        }
    });

    return (
        <FormContainer onSubmit={handleModifySubmit}>
            <FormHeader>
                <HeaderTitle title="Add Counterparty Limit" />
            </FormHeader>
            <FormBody alignItems="stretch">
                <FieldGroup>
                    <SimpleDropdown
                        label="Counterparty"
                        name="counterpartyId"
                        control={control}
                        rules={{ required: ValidateErrors.required }}
                        error={errors.counterpartyId?.message}
                        options={counterpartiesOptions}
                        disabled={isClientsLoading}
                        searchable
                    />
                </FieldGroup>
                <FieldGroup>
                    <SimpleDropdown
                        label="Currency"
                        name="currency"
                        control={control}
                        rules={{ required: ValidateErrors.required }}
                        error={errors.currency?.message}
                        options={currenciesOptions}
                        disabled={isInstrumentsLoading}
                        searchable
                    />
                    <SimpleInput
                        label="Gross limit"
                        placeholder="0.00000000"
                        disabled={isCounterpartyLimitsLoading}
                        error={errors.grossLimit?.message}
                        {...register("grossLimit", {
                            required: ValidateErrors.required,
                            validate: { size: ValidateRules.size },
                        })}
                    />
                </FieldGroup>
                <FieldGroup>
                    <SimpleInput
                        label="Maintenance margin, %"
                        placeholder="00.00"
                        disabled={isCounterpartyLimitsLoading || !marginEditable}
                        error={errors.maintenanceMargin?.message}
                        {...register("maintenanceMargin", {
                            required: requiredMargin ? ValidateErrors.required : false,
                            min: { value: 0, message: ValidateErrors.min(0) },
                            max: {
                                value: Math.min(restrictedTrading, initialMargin) ?? 100,
                                message: ValidateErrors.max(
                                    Math.min(restrictedTrading, initialMargin) ?? 100,
                                ),
                            },
                            validate: { decimalPercent: ValidateRules.decimalPercent },
                        })}
                    />
                    <SimpleInput
                        label="Margin call, %"
                        placeholder="00.00"
                        disabled={isCounterpartyLimitsLoading || !marginEditable}
                        error={errors.restrictedTrading?.message}
                        {...register("restrictedTrading", {
                            required: requiredMargin ? ValidateErrors.required : false,
                            min: { value: 0, message: ValidateErrors.min(0) },
                            max: {
                                value: initialMargin ?? 100,
                                message: ValidateErrors.max(initialMargin ?? 100),
                            },
                            validate: { decimalPercent: ValidateRules.decimalPercent },
                        })}
                    />
                    <SimpleInput
                        label="Initial margin, %"
                        placeholder="00.00"
                        disabled={isCounterpartyLimitsLoading || !marginEditable}
                        error={errors.initialMargin?.message}
                        {...register("initialMargin", {
                            required: requiredMargin ? ValidateErrors.required : false,
                            min: { value: 0, message: ValidateErrors.min(0) },
                            max: { value: 100, message: ValidateErrors.max(100) },
                            validate: { decimalPercent: ValidateRules.decimalPercent },
                        })}
                    />
                </FieldGroup>
                <FieldGroup>
                    <SimpleInput
                        label={combineMarkupLabel(counterparty?.type)}
                        placeholder="00.00"
                        disabled={isCounterpartyLimitsLoading || !markupEditable}
                        error={errors.takerMarkup?.message}
                        {...register("takerMarkup", {
                            validate: { decimalPercent: ValidateRules.decimalPercent },
                            min: { value: -10, message: ValidateErrors.min(-10) },
                            max: { value: 10, message: ValidateErrors.max(10) },
                        })}
                    />
                </FieldGroup>
                {submitError && <FormError message={submitError} />}
            </FormBody>
            <FormActions variant="plain">
                <PrimaryButton size="large" fullWidth loading={isSubmitting}>
                    Add
                </PrimaryButton>
            </FormActions>
        </FormContainer>
    );
};
