import { FC } from "react";
import styled from "styled-components";

const Container = styled.div`
    display: flex;
`;

const Percent = styled.div`
    flex: 1;
`;

const Value = styled.div`
    flex: 1;
    text-align: right;
`;

type MarginValueProps = {
    percent: string;
    value: string;
};

export const MarginValue: FC<MarginValueProps> = ({ percent, value }) => {
    return (
        <Container>
            <Percent>{percent}</Percent>
            <Value>{value}</Value>
        </Container>
    );
};
