import { PBold } from "@fm-frontend/uikit";
import { FC } from "react";

type SideCellProps = {
    value: number | bigint;
};

export const SideCell: FC<SideCellProps> = ({ value }) => {
    return <PBold>{value < 0n ? "In" : "Out"}</PBold>;
};
