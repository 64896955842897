import {
    BasicInput,
    Form,
    FormActions,
    FormBody,
    Header as FormHeader,
    HeaderTitle,
    ShadowCard,
} from "@fm-frontend/uikit";
import { FC, useContext, useState } from "react";
import { useForm } from "react-hook-form";
import FormError from "~components/FormError";
import { PrimaryButton } from "~components/PrimaryButton";
import { AuthContext } from "~pages/Auth/context";
import useCompleteNewPassword from "~pages/Auth/hooks/useCompleteNewPassword";
import { ValidationRules } from "~pages/Auth/utils";

type ResetPasswordFrom = {
    code: string;
    password: string;
    confirmPassword: string;
};

const ChangePasswordContent: FC = () => {
    const { userState, setAuthState } = useContext(AuthContext);
    const { completeNewPassword, loading } = useCompleteNewPassword(userState);
    const [error, setError] = useState<string>();
    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
    } = useForm<ResetPasswordFrom>({
        mode: "onChange",
    });
    const newPassword = watch("password");

    const handleFormSubmit = handleSubmit(({ password }) => {
        completeNewPassword(password)
            .then(setAuthState)
            .catch((e) => setError(e?.message));
    });

    return (
        <Form onSubmit={handleFormSubmit}>
            <ShadowCard>
                <FormHeader>
                    <HeaderTitle title="Change Password" />
                </FormHeader>
                <FormBody>
                    <BasicInput
                        type="password"
                        label="Password"
                        placeholder="Password"
                        error={errors.password?.message}
                        {...register("password", {
                            ...ValidationRules.password,
                        })}
                    />
                    <BasicInput
                        type="password"
                        label="Confirm Password"
                        placeholder="Confirm Password"
                        error={errors.confirmPassword?.message}
                        {...register("confirmPassword", {
                            ...ValidationRules.confirmPassword(newPassword),
                        })}
                    />
                    {error && <FormError message={error} />}
                </FormBody>
                <FormActions variant="plain">
                    <PrimaryButton type="submit" fullWidth size="large" loading={loading}>
                        Change Password
                    </PrimaryButton>
                </FormActions>
            </ShadowCard>
        </Form>
    );
};

export default ChangePasswordContent;
