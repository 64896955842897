import { useCallback, useEffect } from "react";
import useAppDispatch from "~hooks/useAppDispatch";
import WebSocketApi from "~utils/WebSocketApi";

let subscribers = 0;

const useWebSocketApi = () => {
    const webSocketApi = WebSocketApi.getInstance();
    const dispatch = useAppDispatch();
    const handleError = useCallback(
        (error: unknown) => {
            dispatch({ type: "setError", payload: { error } });
        },
        [dispatch],
    );

    useEffect(() => {
        subscribers += 1;

        if (subscribers === 1) {
            webSocketApi.start();
            webSocketApi.addEventListener("error", handleError);
        }

        return () => {
            subscribers -= 1;

            if (subscribers === 0) {
                webSocketApi.stop();
                webSocketApi.removeEventListener("error", handleError);
            }
        };
    }, [handleError, webSocketApi]);

    return {
        send: webSocketApi.send,
        subscribe: webSocketApi.subscribe,
        onRefreshToken: webSocketApi.onRefreshToken,
    };
};

export default useWebSocketApi;
