import { EmptySearch } from "@fm-frontend/uikit/src/components/common/EmptySearch";
import { FC } from "react";
import Gap from "~components/Gap";
import MarketDepth from "~components/MarketDepth";
import { Spread } from "~components/Spread";
import OptionsContainer from "~components/Table/Options/Container";
import Title from "~components/Table/Options/Title";
import { InstrumentName } from "~entities/instrument";
import { useOrderBooksFeed } from "~hooks/feeds/useOrderBooksFeed";

type ContentProps = {
    instrument: InstrumentName;
};

const Content: FC<ContentProps> = ({ instrument }) => {
    const { bidList, askList } = useOrderBooksFeed(instrument);
    const empty = bidList.length === 0 && askList.length === 0;

    return (
        <>
            <OptionsContainer>
                <Title>{instrument}</Title>
                <Gap />
                <Spread bidPrice={bidList[0]?.[0]} askPrice={askList[0]?.[0]} />
            </OptionsContainer>
            {empty && <EmptySearch description="There's nothing to show here" />}
            {!empty && <MarketDepth bidList={bidList} askList={askList} />}
        </>
    );
};

export default Content;
