import { TabContext, TabPanel } from "@fm-frontend/uikit";
import { useState } from "react";
import { Tabs } from "~pages/Overview/ClientsOverviewTabs";
import { ClientsView } from "~pages/Overview/ClientsView";
import { InvitationsView } from "~pages/Overview/InvitationsView";

export const OverviewPage = () => {
    const [tab, setTab] = useState<string>(Tabs.Clients);

    return (
        <TabContext value={tab} handleClick={setTab}>
            <TabPanel value={Tabs.Clients} overflow>
                <ClientsView />
            </TabPanel>
            <TabPanel value={Tabs.Invitations} overflow>
                <InvitationsView />
            </TabPanel>
        </TabContext>
    );
};
