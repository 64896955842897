import {
    BasicInput,
    Form,
    FormActions,
    FormBody,
    Header as FormHeader,
    HeaderTitle,
    ShadowCard,
} from "@fm-frontend/uikit";
import { FC, useContext, useState } from "react";
import { useForm } from "react-hook-form";
import FormError from "~components/FormError";
import { PrimaryButton } from "~components/PrimaryButton";
import { AuthContext } from "~pages/Auth/context";
import useForgotPasswordSubmit from "~pages/Auth/hooks/useForgotPasswordSubmit";
import { ValidationRules } from "~pages/Auth/utils";

type ResetPasswordFrom = {
    code: string;
    password: string;
    confirmPassword: string;
};

const ResetPasswordSubmitContent: FC = () => {
    const { email, setAuthState } = useContext(AuthContext);
    const { forgotPasswordSubmit, loading } = useForgotPasswordSubmit();
    const [error, setError] = useState<string>();
    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
    } = useForm<ResetPasswordFrom>({
        mode: "onChange",
    });
    const newPassword = watch("password");

    const handleFormSubmit = handleSubmit(async ({ code, password }) => {
        if (email === undefined) {
            setError("Email must be specified in the first step.");
            return;
        }

        forgotPasswordSubmit(email, code, password)
            .then((nextAuthState) => {
                setError(undefined);
                setAuthState(nextAuthState);
            })
            .catch((e) => setError(e?.message));
    });

    return (
        <Form onSubmit={handleFormSubmit}>
            <ShadowCard>
                <FormHeader>
                    <HeaderTitle title="Reset password" />
                </FormHeader>
                <FormBody>
                    <BasicInput
                        type="number"
                        label="Code"
                        placeholder="000000"
                        error={errors.code?.message}
                        {...register("code", {
                            ...ValidationRules.code,
                        })}
                    />
                    <BasicInput
                        type="password"
                        label="Password"
                        placeholder="Password"
                        error={errors.password?.message}
                        {...register("password", {
                            ...ValidationRules.password,
                        })}
                    />
                    <BasicInput
                        type="password"
                        label="Confirm Password"
                        placeholder="Confirm Password"
                        error={errors.confirmPassword?.message}
                        {...register("confirmPassword", {
                            ...ValidationRules.confirmPassword(newPassword),
                        })}
                    />
                    {error && <FormError message={error} />}
                </FormBody>
                <FormActions variant="plain">
                    <PrimaryButton type="submit" fullWidth size="large" loading={loading}>
                        Reset password
                    </PrimaryButton>
                </FormActions>
            </ShadowCard>
        </Form>
    );
};

export default ResetPasswordSubmitContent;
