import { ValueFormat } from "@fm-frontend/utils";
import { FC } from "react";
import styled, { css } from "styled-components";

const Container = styled.div`
    display: flex;
    position: relative;
    height: 32px;
    line-height: 32px;
`;

const Price = styled.div<{ $align: string; $bold: boolean; $positive: boolean }>`
    color: ${(p) => (p.$positive ? p.theme.colors.positive100 : p.theme.colors.negative100)};
    font-size: 14px;
    font-weight: ${(p) => (p.$bold ? 600 : 400)};
    text-align: ${(p) => p.$align};
    padding: 0 22px;
    width: 100%;
`;

const Size = styled.div`
    text-align: right;
    padding: 0 12px;
`;

const Chart = styled.div<{ $positive: boolean; $right: boolean }>`
    position: absolute;
    background: ${(p) => (p.$positive ? p.theme.colors.positive8 : p.theme.colors.negative8)};
    transition: transform 75ms ease-out;
    height: 100%;
    top: 0;
    ${(p) =>
        p.$right
            ? css`
                  right: 0;
              `
            : css`
                  left: 0;
              `};
`;

type MarketDepthItemProps = {
    price: number | bigint;
    size: number | bigint;
    percent: number;
    type: "bid" | "ask";
    first: boolean;
};

const MarketDepthItem: FC<MarketDepthItemProps> = ({ price, size, percent, type, first }) => {
    if (type === "bid") {
        return (
            <Container>
                <Size>{ValueFormat.size(size)}</Size>
                <Price $align="right" $bold={first} $positive>
                    {ValueFormat.price(price)}
                </Price>
                <Chart $positive $right style={{ width: `${percent}%` }} />
            </Container>
        );
    }

    if (type === "ask") {
        return (
            <Container>
                <Chart $positive={false} $right={false} style={{ width: `${percent}%` }} />
                <Price $align="left" $bold={first} $positive={false}>
                    {ValueFormat.price(price)}
                </Price>
                <Size>{ValueFormat.size(size)}</Size>
            </Container>
        );
    }

    return null;
};

export default MarketDepthItem;
