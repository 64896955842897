import { FC } from "react";
import styled from "styled-components";
import List from "~components/MarketDepth/List";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    overflow: auto;
    font-size: 12px;
    min-width: 540px;
`;

const Header = styled.div`
    display: flex;
    box-shadow: inset 0px -1px 0px rgba(36, 48, 52, 0.2);
`;

const Body = styled.div`
    display: flex;
    flex-direction: row;
    overflow: auto;
`;

const Title = styled.div<{ $grow: number; $align: "left" | "center" | "right" }>`
    flex-grow: ${(p) => p.$grow || 1};
    text-align: ${(p) => p.$align};
    padding: 0 12px;
    height: 24px;
`;

type MarketDepthProps = {
    bidList: [price: number | bigint, size: number | bigint][];
    askList: [price: number | bigint, size: number | bigint][];
};

const MarketDepth: FC<MarketDepthProps> = ({ bidList, askList }) => {
    return (
        <Container>
            <Header>
                <Title $grow={1} $align="left">
                    Bid
                </Title>
                <Title $grow={2} $align="center">
                    Price
                </Title>
                <Title $grow={1} $align="right">
                    Ask
                </Title>
            </Header>
            <Body>
                <List type="bid" list={bidList} />
                <List type="ask" list={askList} />
            </Body>
        </Container>
    );
};

export default MarketDepth;
