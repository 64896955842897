import { IconButton, Icons } from "@fm-frontend/uikit";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import ModalButton from "~components/ModalButton";
import { useClientId } from "~hooks/useClientId";
import { useIsMaster } from "~hooks/useIsMaster";
import { useIsSubaccount } from "~hooks/useIsSubaccount";
import { EditGlobalGrossForm } from "~pages/RisksManagement/components/EditGlobalGrossForm";
import { EditInstrumentWhitelistAction } from "~pages/RisksManagement/components/EditInstrumentsWhitelistAction";
import { EditPrimeBrokerGlobalGrossForm } from "~pages/RisksManagement/components/EditPrimeBrokerGlobalGrossForm";
import { Item, RowType, SubItem } from "~pages/RisksManagement/List/types";
import { getRiskManagementViewUrl } from "~pages/Router";

type ActionCellProps = {
    item: Item | SubItem;
};

export const ActionsCell: FC<ActionCellProps> = ({ item }) => {
    const { id } = item;
    const clientId = useClientId();
    const isMaster = useIsMaster();
    const isSubaccount = useIsSubaccount();
    const navigate = useNavigate();

    const handleViewClick = () => {
        if (id !== undefined) {
            navigate(getRiskManagementViewUrl(clientId, id));
        }
    };

    switch (item.type) {
        case RowType.GlobalGross:
            return (
                <ModalButton
                    renderModalContent={(closePopover) =>
                        isMaster ? (
                            <EditPrimeBrokerGlobalGrossForm
                                onDone={closePopover}
                                globalGrossType={item.globalGrossType}
                            />
                        ) : (
                            <EditGlobalGrossForm onDone={closePopover} />
                        )
                    }
                    renderButton={(handleClick) => (
                        <IconButton
                            disabled={isSubaccount}
                            title="Edit"
                            variant="plain"
                            Icon={Icons.Pencil}
                            onClick={handleClick}
                        />
                    )}
                />
            );
        case RowType.GlobalInstrumentsWhitelist:
            return (
                <EditInstrumentWhitelistAction
                    renderButton={(handleClick) => (
                        <IconButton
                            title="Edit"
                            variant="plain"
                            Icon={Icons.Pencil}
                            onClick={handleClick}
                        />
                    )}
                />
            );
        case RowType.Counterparty:
            return (
                <IconButton
                    title="View"
                    variant="plain"
                    Icon={Icons.Forward}
                    onClick={handleViewClick}
                />
            );
        default:
            return null;
    }
};
