import { resetCss, spinKeyframesCss } from "@fm-frontend/uikit";
import { createGlobalStyle, css } from "styled-components";

const commonCss = css`
    body,
    body a,
    body button {
        color: ${(p) => p.theme.colors.ui100};
    }

    table {
        font-size: 14px;
    }

    mark {
        background-color: ${(p) => p.theme.colors.brand32};
    }
`;

const GlobalStyle = createGlobalStyle`
    ${spinKeyframesCss}
    ${resetCss}
    ${commonCss}
`;

export default GlobalStyle;
