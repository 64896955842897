import useSWR from "swr";
import { Key } from "~entities/keys";
import { requestAdminery } from "~utils/api";

type RawKeyEntity = [key: string, allowWrite: 0 | 1, createdAt: number, tag: string];

const prepareData = ([key, allowWrite, createdAt, tag]: RawKeyEntity): Key => ({
    key,
    allowWrite: allowWrite === 1,
    createdAt,
    tag,
});

type SWRKey = string;

const fetcher = () => requestAdminery("keys", {}).then((data) => data.map(prepareData));

export const useKeysApi = () => useSWR<Key[], unknown, SWRKey>("useKeysApi", fetcher);
