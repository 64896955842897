import { HStack, Tab, TabList as UIKitTabList } from "@fm-frontend/uikit";
import { FC } from "react";
import styled from "styled-components";

export enum Tabs {
    TradingLimits = "Trading limits",
    AssetsControl = "Assets control",
    InstrumentList = "Instrument list",
}

const StyledTabList = styled(UIKitTabList)`
    width: 316px;
`;

const Container = styled(HStack)`
    width: 100%;
    justify-content: center;
`;

export const TabList: FC = () => (
    <Container>
        <StyledTabList size="small">
            <Tab title="Trading limits" value={Tabs.TradingLimits} />
            <Tab title="Assets control" value={Tabs.AssetsControl} />
            <Tab title="Instrument list" value={Tabs.InstrumentList} />
        </StyledTabList>
    </Container>
);
