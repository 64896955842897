import { BasicButton } from "@fm-frontend/uikit";
import { ValueFormat } from "@fm-frontend/utils";
import { format } from "date-fns";
import { FC, useContext, useState } from "react";
import styled from "styled-components";
import { Headline, Property } from "~components/Specification";
import { DATE_TIME_FORMAT } from "~constants/date";
import useNotifications from "~hooks/useNotifications";
import { ProfileContext } from "~pages/Profile/Context";
import { isSigned } from "~pages/Profile/utils";
import { requestAdminery } from "~utils/api";
import { prepareRequestError } from "~utils/prepareRequestError";

const Header = styled(Headline)`
    display: flex;
    justify-content: space-between;
    align-items: baseline;
`;

const Status = styled.span<{ positive: boolean }>`
    color: ${(p) => (p.positive ? p.theme.colors.positive100 : p.theme.colors.negative100)};
`;

const TermsAndConditionsGroup: FC = () => {
    const { clientInfo } = useContext(ProfileContext);
    const { showSuccessNotification, showErrorNotification } = useNotifications();
    const [resetting, setResetting] = useState(false);
    const signed = isSigned(clientInfo);

    const handleResetTAndC = async () => {
        const clientId = clientInfo?.id;

        if (clientId === undefined) {
            return;
        }

        try {
            setResetting(true);

            await requestAdminery("resetLegalData", { clientId });
            showSuccessNotification("T&C have been successfully reset");
        } catch (e) {
            showErrorNotification(prepareRequestError(e));
        } finally {
            setResetting(false);
        }
    };

    return (
        <>
            <Header>
                Terms and conditions
                <BasicButton size="small" loading={resetting} onClick={handleResetTAndC}>
                    Reset legal data
                </BasicButton>
            </Header>
            <Property title="Status">
                {clientInfo && signed && <Status positive>Signed</Status>}
                {clientInfo && !signed && <Status positive={false}>Not Signed</Status>}
            </Property>
            <Property title="Date and time">
                {clientInfo?.signedAt ? format(clientInfo.signedAt * 1000, DATE_TIME_FORMAT) : ""}
            </Property>
            <Property title="Signatory name">{clientInfo?.signatory}</Property>
            <Property title="Position">{clientInfo?.signatoryPosition}</Property>
            <Property title="License fee">
                {clientInfo && `${ValueFormat.deltaRate(clientInfo.makerFee)}%`}
            </Property>
            <Property title="FM markup share">
                {clientInfo && `${clientInfo.fineryShare}%`}
            </Property>
        </>
    );
};

export default TermsAndConditionsGroup;
