import { NavLink } from "react-router-dom";
import styled, { css } from "styled-components";

const ItemMixin = css`
    display: flex;
    align-items: center;
    justify-content: space-between;

    cursor: pointer;
    background: transparent;
    border-radius: 6px;
    height: 28px;
    padding: 0 4px 0 8px;
    gap: 10px;
    font-weight: 600;
    line-height: 28px;

    :hover,
    &.active {
        color: ${(p) => p.theme.colors.uiWhite100};
        background: ${(p) => p.theme.colors.ui52};
        text-decoration: none;

        path {
            fill: ${(p) => p.theme.colors.uiWhite100};
            fill-opacity: 1;
        }
    }
`;

export const LinkItem = styled(NavLink)`
    ${ItemMixin}
`;

export const ButtonItem = styled.button`
    ${ItemMixin}
`;
