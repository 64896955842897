import { IconButton, Icons } from "@fm-frontend/uikit";
import { CancelInvitationAction } from "pages/Overview/CancelInvitationAction";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { ClientId, ClientType } from "~entities/client";
import { ReferralStatus } from "~entities/referral";
import { InviteAction } from "~pages/Overview/InviteAction";
import { getClientUrl } from "~pages/Router";

type InvitationActionProps = {
    clientId: ClientId;
    referralEmail: string;
    referralType: ClientType;
    status: ReferralStatus;
};

export const InvitationAction: FC<InvitationActionProps> = ({
    clientId,
    referralEmail,
    referralType,
    status,
}) => {
    const navigate = useNavigate();
    const handleClick = () => {
        navigate(getClientUrl(clientId));
    };

    if (status === ReferralStatus.Sent) {
        return <CancelInvitationAction referralEmail={referralEmail} />;
    }
    if (status === ReferralStatus.Expired) {
        return <InviteAction referralEmail={referralEmail} referralType={referralType} />;
    }
    if (clientId !== 0) {
        return (
            <IconButton
                title="View"
                variant="plain"
                Icon={Icons.ArrowRight}
                onClick={handleClick}
            />
        );
    }

    return null;
};
