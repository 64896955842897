import { AnyAction, createSlice } from "@reduxjs/toolkit";
import { ErrorType } from "~constants/errors";
import { refreshAuthenticatedUser, signOut } from "~store/user/actions";
import { UserStatus } from "./types";

const isAuthErrorAction = (action: AnyAction) => {
    const { type, payload } = action;

    return type.endsWith("setError") && ErrorType.NotAuthorized === payload.error;
};

// type AuthorizedUserState = {
//     status: UserStatus.Authorized;
//     email: string;
// };
//
// type NotAuthorizedUserState = {
//     status: Omit<UserStatus, UserStatus.Authorized>;
//     email: null;
// };
//
// type UserState = AuthorizedUserState | NotAuthorizedUserState;

type UserState = {
    status: UserStatus;
    email: string | null;
};

const initialState: UserState = {
    status: UserStatus.Undefined,
    email: null,
};

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(refreshAuthenticatedUser.fulfilled, (state, { payload }) => ({
            status: UserStatus.Authorized,
            email: payload.email,
        }));
        builder.addCase(refreshAuthenticatedUser.rejected, () => ({
            status: UserStatus.NotAuthorized,
            email: null,
        }));
        builder.addCase(signOut.fulfilled, () => ({
            status: UserStatus.NotAuthorized,
            email: null,
        }));
        builder.addCase(signOut.rejected, () => ({
            status: UserStatus.NotAuthorized,
            email: null,
        }));
        builder.addMatcher(isAuthErrorAction, () => ({
            status: UserStatus.AuthorizationError,
            email: null,
        }));
    },
});

export default userSlice.reducer;
