import { FC, useState } from "react";
import styled from "styled-components";
import EditAction from "~components/Table/Action/EditAction";
import RemoveAction from "~components/Table/Action/RemoveAction";
import { Instrument } from "~entities/instrument";
import { updateInstrumentsApi } from "~hooks/api/useInstrumentsApi";
import useNotifications from "~hooks/useNotifications";
import { EditForm } from "~pages/AssetsAndInstruments/Instruments/EditForm";
import { requestAdminery, requestHrpReporting } from "~utils/api";

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 8px;
`;

type InstrumentActionsProps = {
    instrument: Instrument;
};

const InstrumentActions: FC<InstrumentActionsProps> = ({ instrument }) => {
    const { showSuccessNotification, showErrorNotification } = useNotifications();
    const [removing, setRemoving] = useState(false);

    const removeHRPInstrument = async () => {
        try {
            const hrpDeleteResponse = await requestHrpReporting("delInstrument", {
                fmInstrument: instrument.name,
            });

            if (hrpDeleteResponse?.error) {
                showErrorNotification(
                    `An error occurred when deleting HRP instrument for ${instrument.name}.`,
                );
            }
        } catch (e) {
            showErrorNotification(
                `An error occurred when deleting HRP instrument for ${instrument.name}. Reason: ${e}`,
            );
        }
    };
    const handleRemove = async () => {
        setRemoving(true);

        try {
            if (instrument.hrpSymbol !== undefined) {
                await removeHRPInstrument();
            }

            const response = await requestAdminery("delInstrument", {
                name: instrument.name,
            });

            await updateInstrumentsApi();

            if (!response?.error) {
                showSuccessNotification(
                    `${instrument.name} instrument has been successfully deleted.`,
                );
            }
            if (response?.error) {
                showErrorNotification(
                    `An error occurred when deleting ${instrument.name} instrument.`,
                );
            }
        } catch (e) {
            showErrorNotification(`An error occurred when deleting ${instrument.name} instrument.`);
        } finally {
            setRemoving(false);
        }
    };

    return (
        <Container>
            <EditAction
                renderEditForm={(closePopover) => (
                    <EditForm
                        name={instrument.name}
                        hrpSymbol={instrument.hrpSymbol}
                        onDone={closePopover}
                    />
                )}
            />
            <RemoveAction
                title={`Remove instrument "${instrument.name}"`}
                loading={removing}
                onDone={handleRemove}
            />
        </Container>
    );
};

export default InstrumentActions;
