import {
    ConfirmationModal,
    DesctructiveButton,
    FieldGroup,
    Form,
    FormActions,
    FormBody,
    Header as FormHeader,
    HeaderTitle,
    SimpleDropdown,
    SimpleInput,
} from "@fm-frontend/uikit";
import { EMPTY_ARRAY } from "@fm-frontend/uikit/src/const";
import { ValueFormat, ValueParse } from "@fm-frontend/utils";
import { FC, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import FormError from "~components/FormError";
import { PrimaryButton } from "~components/PrimaryButton";
import { ERROR_MESSAGES } from "~constants/errors";
import { ValidateErrors, ValidateRules } from "~constants/form";
import { useGlobalLimitsApi } from "~hooks/api/useGlobalLimitsApi";
import { useInstrumentsApi } from "~hooks/api/useInstrumentsApi";
import { useClientId } from "~hooks/useClientId";
import useNotifications from "~hooks/useNotifications";
import { requestAdminery } from "~utils/api";
import { prepareRequestError } from "~utils/prepareRequestError";

const FormContainer = styled(Form)`
    width: 100%;
    min-width: initial;
    padding: 8px;
`;

type EditGlobalGrossFormType = {
    currency: string;
    grossLimit: string;
};

type EditGlobalGrossFormProps = {
    onDone: () => void;
};

export const EditGlobalGrossForm: FC<EditGlobalGrossFormProps> = ({ onDone }) => {
    const { showSuccessNotification } = useNotifications();
    const clientId = useClientId();
    const {
        data: globalLimitsData,
        isLoading: isGlobalLimitsLoading,
        mutate,
    } = useGlobalLimitsApi(clientId);
    const globalLimit = globalLimitsData?.[0];
    const globalLimitTitle = "Global gross";
    const { currencyName, grossLimit } = globalLimit ?? {};
    const { data: instruments, isLoading: isInstrumentsLoading } = useInstrumentsApi();
    const { currencies = EMPTY_ARRAY } = instruments ?? {};

    const {
        control,
        register,
        setFocus,
        setValue,
        formState: { isSubmitting, errors },
        handleSubmit,
    } = useForm<EditGlobalGrossFormType>({
        defaultValues: {
            currency: currencyName ?? "",
            grossLimit: ValueFormat.formSize(grossLimit ?? 0),
        },
    });
    const [submitError, setSubmitError] = useState<string>();
    const [confirmationShown, setConfirmationShown] = useState(false);
    const [removing, setRemoving] = useState<boolean>(false);

    useEffect(() => {
        setFocus("grossLimit");
    }, [setFocus]);

    useEffect(() => {
        setValue("currency", currencyName ?? "");
    }, [currencyName, setValue]);
    useEffect(() => {
        setValue("grossLimit", ValueFormat.formSize(grossLimit ?? 0));
    }, [grossLimit, setValue]);

    const currenciesOptions = useMemo(
        () =>
            currencies
                .map(({ name }) => ({ text: name, value: name }))
                .sort((a, b) => a.text.localeCompare(b.text)),
        [currencies],
    );

    const handleModifySubmit = handleSubmit(async (data) => {
        try {
            setSubmitError(undefined);

            const clientResponse = await requestAdminery("setLimit", {
                clientId,
                currency: data.currency,
                grossLimit: ValueParse.size(data.grossLimit),
            });
            const error = clientResponse?.error;

            if (!error) {
                await mutate([
                    {
                        currencyName: data.currency,
                        grossLimit: ValueParse.size(data.grossLimit),
                        grossLimitUtilisation: globalLimit?.grossLimitUtilisation ?? 0n,
                    },
                    ...(globalLimitsData?.slice(1) ?? []),
                ]);
                onDone();
                showSuccessNotification(`${globalLimitTitle} was successfully modified`);
            }
            if (error) {
                setSubmitError(ERROR_MESSAGES[error] ?? error);
            }
        } catch (e) {
            setSubmitError(prepareRequestError(e));
        }
    });

    const handleRemove = async () => {
        try {
            setSubmitError(undefined);
            setRemoving(true);

            const response = await requestAdminery("delLimit", {
                clientId,
            });

            if (!response?.error) {
                await mutate([
                    {
                        currencyName: "USD",
                        grossLimit: 0n,
                        grossLimitUtilisation: 0n,
                    },
                    ...(globalLimitsData?.slice(1) ?? []),
                ]);
                onDone();
                showSuccessNotification(`${globalLimitTitle} limit was removed`);
            }

            if (response?.error) {
                setSubmitError(ERROR_MESSAGES[response?.error] ?? response?.error);
            }
        } catch (e) {
            setSubmitError(prepareRequestError(e));
        } finally {
            setRemoving(false);
        }
    };

    const handleConfirmationDone = () => {
        setConfirmationShown(false);
        handleRemove();
    };
    const handleConfirmationClose = () => {
        setConfirmationShown(false);
    };

    return (
        <FormContainer onSubmit={handleModifySubmit}>
            <FormHeader>
                <HeaderTitle title={`Modify ${globalLimitTitle}`} />
            </FormHeader>
            <FormBody alignItems="stretch">
                <FieldGroup>
                    <SimpleDropdown
                        label="Currency"
                        name="currency"
                        control={control}
                        options={currenciesOptions}
                        disabled={isInstrumentsLoading}
                    />
                    <SimpleInput
                        label="Gross Limit"
                        placeholder="0.00000000"
                        disabled={isGlobalLimitsLoading}
                        error={errors.grossLimit?.message}
                        {...register("grossLimit", {
                            required: ValidateErrors.required,
                            validate: { size: ValidateRules.size },
                        })}
                    />
                </FieldGroup>
                {submitError && <FormError message={submitError} />}
            </FormBody>
            <FormActions variant="plain">
                <DesctructiveButton
                    type="button"
                    size="large"
                    fullWidth
                    loading={isSubmitting || removing}
                    onClick={() => setConfirmationShown(true)}
                >
                    Remove Limit
                </DesctructiveButton>
                {confirmationShown && (
                    <ConfirmationModal
                        title={`Remove ${globalLimitTitle} Limit`}
                        description="This action is irreversible. Are you sure?"
                        confirmButtonTitle="Yes, delete"
                        onConfirm={handleConfirmationDone}
                        onClose={handleConfirmationClose}
                    />
                )}
                <PrimaryButton size="large" fullWidth loading={isSubmitting}>
                    Modify
                </PrimaryButton>
            </FormActions>
        </FormContainer>
    );
};
