import {
    FieldGroup,
    Form,
    FormActions,
    FormBody,
    Header as FormHeader,
    HeaderTitle,
    SimpleInput,
    SimpleMultiDropdown,
} from "@fm-frontend/uikit";
import { FC, useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import FormError from "~components/FormError";
import { PrimaryButton } from "~components/PrimaryButton";
import { ERROR_MESSAGES } from "~constants/errors";
import { ValidateErrors } from "~constants/form";
import { Network } from "~entities/network";
import { useInstrumentsApi } from "~hooks/api/useInstrumentsApi";
import useNotifications from "~hooks/useNotifications";
import { requestAdminery } from "~utils/api";
import { prepareRequestError } from "~utils/prepareRequestError";

const FormContainer = styled(Form)`
    width: 100%;
    min-width: initial;
    padding: 8px;
`;

type EditNetworkFormType = {
    name: string;
    description: string;
    currencies?: string[];
};

type EditFormProps = {
    network: Network;
    onDone: () => void;
};

export const EditForm: FC<EditFormProps> = ({ network, onDone }) => {
    const { data: apiData, mutate } = useInstrumentsApi();
    const { currencies = [], networks = [] } = apiData ?? {};

    const networkCurrencies = currencies.reduce((acc, currency) => {
        if (currency.networks.includes(network.name)) {
            acc.push(currency.name);
        }

        return acc;
    }, [] as string[]);

    const { showSuccessNotification } = useNotifications();
    const {
        control,
        register,
        formState: { errors },
        handleSubmit,
    } = useForm<EditNetworkFormType>({
        defaultValues: {
            name: network.name,
            description: network.description,
            currencies: networkCurrencies,
        },
    });
    const [loading, setLoading] = useState(false);
    const [submitError, setSubmitError] = useState<string>();

    const handleNetworkSubmit = handleSubmit(async (data) => {
        try {
            setSubmitError(undefined);
            setLoading(true);
            const response = await requestAdminery("modNetwork", {
                name: data.name,
                description: data.description,
            });

            if (!response?.error && apiData !== undefined) {
                const networkIndex = networks.findIndex((item) => item.name === network.name);

                mutate({
                    ...apiData,
                    networks: [
                        ...networks.slice(0, networkIndex),
                        {
                            name: data.name,
                            description: data.description,
                        },
                        ...networks.slice(networkIndex + 1),
                    ],
                });
            }

            if (!response?.error) {
                onDone();
                showSuccessNotification(`Network ${data.name} was successfully edited`);
            }
            if (response?.error) {
                setSubmitError(ERROR_MESSAGES[response.error] ?? response.error);
            }
        } catch (e) {
            setSubmitError(prepareRequestError(e));
        } finally {
            setLoading(false);
        }
    });

    const currenciesOptions = currencies.map((currency) => ({
        text: currency.name,
        value: currency.name,
    }));

    return (
        <FormContainer onSubmit={handleNetworkSubmit}>
            <FormHeader>
                <HeaderTitle title={`Edit network ${network.name}`} />
            </FormHeader>
            <FormBody alignItems="stretch">
                <FieldGroup>
                    <SimpleInput
                        label="Name"
                        placeholder="ERC20"
                        disabled
                        error={errors.name?.message}
                        {...register("name", {
                            required: ValidateErrors.required,
                        })}
                    />
                    <SimpleInput
                        label="Description"
                        placeholder="Simple description"
                        error={errors.description?.message}
                        {...register("description", {
                            required: ValidateErrors.required,
                        })}
                    />
                </FieldGroup>
                <FieldGroup>
                    <SimpleMultiDropdown
                        options={currenciesOptions}
                        control={control}
                        name="currencies"
                        label="Assets"
                        fullWidth
                        disabled
                    />
                </FieldGroup>
                {submitError && <FormError message={submitError} />}
            </FormBody>
            <FormActions variant="plain">
                <PrimaryButton size="large" fullWidth loading={loading}>
                    Edit network
                </PrimaryButton>
            </FormActions>
        </FormContainer>
    );
};
