import { formatPercent, ValueFormat } from "@fm-frontend/utils";
import { AssetLimit } from "~entities/assetLimit";

export const getEnableTrading = (limit: AssetLimit | undefined) => {
    const { grossLimit } = limit ?? {};

    if (grossLimit === undefined) {
        return "";
    }

    return grossLimit > 0;
};

export const getExposureAbs = (exposure: number | bigint = 0) =>
    exposure < 0 ? BigInt(exposure) * -1n : BigInt(exposure);

export const getGrossFree = (grossLimit: number | bigint = 0, exposure: number | bigint = 0) => {
    const exposureAbs = getExposureAbs(exposure);
    const freeLimit = BigInt(grossLimit) - exposureAbs;

    return ValueFormat.size(freeLimit);
};

export const getAssetStatus = (
    limit: AssetLimit | undefined,
    exposure: number | bigint | undefined,
) => {
    if (limit?.grossLimit === 0) {
        return "Stopped";
    }

    if (limit?.grossLimit !== undefined && exposure !== undefined && limit.grossLimit > 0) {
        const exposureAbs = getExposureAbs(exposure);
        const grossLimit = BigInt(limit.grossLimit);
        const freeLimit = grossLimit - exposureAbs;
        return formatPercent((BigInt(100 * 1e4) * freeLimit) / grossLimit);
    }

    return "";
};
