import { P } from "@fm-frontend/uikit";
import { FC, useContext } from "react";
import MatchStartsWith from "~components/Table/Cell/MatchStartsWith";
import { TableContext } from "~components/Table/TableContext";

type OrderIdCellProps = {
    value: number;
};

export const OrderIdCell: FC<OrderIdCellProps> = ({ value }) => {
    const { query } = useContext(TableContext);

    return (
        <P>
            <MatchStartsWith value={String(value)} query={query} />
        </P>
    );
};
