import { HStack } from "@fm-frontend/uikit";
import { FC, useMemo } from "react";
import { EditInstrumentMarkupAction } from "~pages/RisksManagement/components/EditInstrumentMarkupAction";
import { Item, RowType, SubItem } from "~pages/RisksManagement/View/InstrumentsList/types";

type ActionCellProps = {
    item: Item | SubItem;
};

export const ActionsCell: FC<ActionCellProps> = ({ item, children }) => {
    const actions = useMemo(() => {
        switch (item.type) {
            case RowType.Instrument:
                return <EditInstrumentMarkupAction instrument={item.instrument} />;
            default:
                return null;
        }
    }, [item]);

    return (
        <HStack alignItems="center" spacing={12} justifyContent="end">
            {children} {actions}
        </HStack>
    );
};
