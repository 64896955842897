import { ClientId, ClientType, SubsFmRelationType } from "~entities/client";

export enum ReferralStatus {
    Accepted = "Accepted",
    Sent = "Sent",
    Expired = "Expired",
}

export type Referral = {
    email: string;
    name: string;
    isSubaccount: boolean;
    createdAt: number;
    expiresAt: number;
    clientId: ClientId;
    status: ReferralStatus;
    clientType: ClientType;
    subsFmRelationType?: SubsFmRelationType;
};
