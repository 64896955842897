import useSWR from "swr";
import { ClientId } from "~entities/client";
import { OvernightFee } from "~entities/overnightFee";
import { requestSettings } from "~utils/api";

type SWRKey = [clientId: ClientId, key: string];

const fetcher = ([clientId]: SWRKey) =>
    requestSettings("getOvernightRates", { clientId }) as Promise<OvernightFee[]>;
export const useOvernightFees = (clientId: ClientId) =>
    useSWR<OvernightFee[], unknown, SWRKey>([clientId, "useOvernightFees"], fetcher);
