import { ClientId } from "~entities/client";

/* eslint-disable no-bitwise */
export enum TradingDisabledFlagsBitmask {
    DisabledByClient = 1 << 0,
    DisabledByCounterparty = 1 << 1,
}
/* eslint-enable no-bitwise */

export type CounterpartyLimit = {
    counterpartyId: ClientId;
    currencyName: string;
    grossLimit: bigint;
    mutualLimitCurrencyName: string | null;
    mutualGrossLimit: bigint | null;
    grossLimitUtilisation: bigint;
    maintenanceMargin: number;
    restrictedTrading: number;
    initialMargin: number;
    equity: bigint;
    takerMarkup: number;
    tradingDisabledFlags: number;
};
