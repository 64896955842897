import { FC, useState } from "react";
import ModalButton from "~components/ModalButton";
import { PrimaryButton } from "~components/PrimaryButton";
import { NewKey } from "~entities/keys";
import { AddForm } from "~pages/MyAdminKeys/AddForm";
import { NewKeyModal } from "~pages/MyAdminKeys/NewKeyModal";

type ModalProps = {
    onClose: () => void;
};

const Modal: FC<ModalProps> = ({ onClose }) => {
    const [newKey, setNewKey] = useState<NewKey>();

    if (newKey) {
        return <NewKeyModal newKey={newKey} onClose={onClose} />;
    }

    return <AddForm onDone={setNewKey} />;
};

export const AddButton: FC = () => (
    <ModalButton
        renderModalContent={(closePopover) => <Modal onClose={closePopover} />}
        renderButton={(handleClick) => (
            <PrimaryButton size="small" onClick={handleClick}>
                Add new
            </PrimaryButton>
        )}
    />
);
