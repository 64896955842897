import { BasicButton, PlainButton } from "@fm-frontend/uikit";
import { FC } from "react";
import PopoverButton from "~components/PopoverButton";
import useAppDispatch from "~hooks/useAppDispatch";
import useAppSelector from "~hooks/useAppSelector";
import { signOut } from "~store/user/actions";
import { getEmail } from "~store/user/selectors";

const Profile: FC = () => {
    const dispatch = useAppDispatch();
    const email = useAppSelector(getEmail);

    const handleLogout = () => {
        dispatch(signOut());
    };

    return (
        <PopoverButton
            renderButton={(handleClick) => (
                <BasicButton size="medium" onClick={handleClick}>
                    {email}
                </BasicButton>
            )}
            renderPopoverContent={() => (
                <PlainButton size="medium" fullWidth onClick={handleLogout}>
                    Logout
                </PlainButton>
            )}
        />
    );
};

export default Profile;
